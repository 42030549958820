/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Api from './Api';
import { useSelector } from 'react-redux';
import BaseRelatedPages from '../../context/components/Base/BaseRelatedPages';
import { useLocation } from 'react-router-dom';

function TBS_Related_Pages() {
  const location = useLocation();
  const [menus, setMenus] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getMenus = async () => {
    const api = new Api();
    const response = await api.getMenuDetail({ language: activeLang });
    const parsedData = await response;
    const menusData = parsedData;
    setMenus(menusData.data.items);
    return menus;
  };

  const [sections, setSections] = useState([]);
  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getMenus();
    getSectionDetail();
  }, []);

  const trans = sections[0]?.page_url.split('/');

  const currentPath = menus?.filter((men) => men?.title.replace(/\s+/g, '-').toLowerCase() === trans[1]);

  const getIdCurrentPath = currentPath[0]?.children?.filter((men) => men?.title.replace(/\s+/g, '-').toLowerCase() === trans[2]);

  const newCurrentPath =
    getIdCurrentPath && getIdCurrentPath[0]
      ? currentPath[0]?.children?.filter((men) => men?.id !== getIdCurrentPath[0].id)
      : currentPath[0]?.children?.filter((men) => men?.title.replace(/\s+/g, '-').toLowerCase() !== trans[2]);

  let title;
  let sectionName;
  if (sections) {
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text.content;

            break;
          case 2:
            break;
          case 3:
            break;
          default:
            break;
        }
      });
    }
  }

  return (
    <div className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto  mt-10 z-[5000] ">
      {newCurrentPath && newCurrentPath.length > 0 && (
        <div className="">
          <BaseRelatedPages top={160} title={title || 'Related Pages'} menus={newCurrentPath || []} />
        </div>
      )}
    </div>
  );
}

export default TBS_Related_Pages;
