/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PARSE from "html-react-parser";
import Api from "./Api";
import Styles from "./TBS_Partners.module.css";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
// import AdimitraLogo from './assets/adimitra-logo.png';

function TBS_Partners() {
  const [sections, setSection] = useState([]);
  const [slugName, setSlugName] = useState([]);
  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const FetchData = async () => {
      const api = new Api();
      try {
        const sectionResponse = await api.getSectionDetail({
          language: activeLang,
        });
        const sectionData = await sectionResponse.data;
        const sectionWidgets = sectionData.results[0].widgets;
        const sectionId = sectionData.results[0].slug_name;

        setSlugName(sectionId);
        setSection(sectionWidgets);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [activeLang]);

  let text;
  let sectionTitle;
  let sectionSummary;
  const subsidiaries = [];
  sections.forEach((data) => {
    if (data.index === 1) {
      text = data.widget_contents[0].content;
    }

    if (data.index === 2) {
      // console.log('data ', data);

      const { title } = data.widget_contents[0].article_language;
      const { summary } = data.widget_contents[0].article_language;

      sectionTitle = title;
      sectionSummary = summary;
    }

    if (data.index === 3) {
      const { widget_contents } = data;

      widget_contents.forEach((widgetContentDatas) => {
        subsidiaries.push(widgetContentDatas);
      });
    }
  });

  return (
    <BaseContainerPl
      sectionName={slugName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      <div className={`section-width ${Styles["subsidiaries-items"]}`}>
        <div className={Styles.title}>
          <h2 className="heading-title">{sectionTitle}</h2>
          {sectionSummary && (
            <h3 className="heading-sub-title">{sectionSummary}</h3>
          )}
        </div>
        <div
          className={`grid grid-cols-1 md:grid-cols-2 gap-y-10 gap-x-8 ${Styles["items-card-field"]}`}
        >
          {subsidiaries.map((val) => {
            const contentNames = val.article_language.title;
            const contentStatus = val.article_language.content;
            const contentAddress = val.article_language.summary;
            const contentImage = replaceAssetLink(val.assets[0]?.file_small);

            return (
              <div key={val.id} className={Styles.card}>
                <div className={`${Styles["card-items"]}`}>
                  {contentImage && (
                    <div className="h-[50px]">
                      <img
                        className="max-h-[100%] min-w-[20%]"
                        src={contentImage}
                        alt={contentNames}
                      />
                    </div>
                  )}
                  <div className={Styles["company-name"]}>
                    <h2>{contentNames}</h2>
                  </div>
                  <div className={Styles["company-address"]}>
                    <p className="">{contentAddress}</p>
                  </div>
                  <div className={Styles["company-status"]}>
                    <p className="">{contentStatus && PARSE(contentStatus)}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BaseContainerPl>
  );
}

export default TBS_Partners;

