/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PARSE from 'html-react-parser';
import Api from './Api';
import { useSelector } from 'react-redux';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';
import replaceAssetLink from '../../context/utils/replaceAssetLink';

export default function TBSSubHeadArticle() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);
  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let mainArticle;
  let subArticle;

  let sectionName;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            mainArticle = text;
            break;
          case 1:
            const media = section.widget_contents;
            subArticle = media;
            break;

            break;
          default:
            break;
        }
      });
    }
  }

  const doc = new DOMParser().parseFromString(mainArticle?.article_language?.content, 'text/html');

  const tagsToRemove = 'img';
  for (const elm of doc.querySelectorAll('*')) {
    if (elm.matches(tagsToRemove)) {
      elm.replaceWith(`<img src='${elm.src}' alt='${mainArticle?.article_language?.title}' />`);
    }
  }

  return (
    <BaseContainerPl sectionName={sectionName} sectionIndex={sections && sections.length > 0 && sections[0].index}>
      <div className="section-width">
        {mainArticle && (
          <div className="">
            <h2 className="heading-title"> {mainArticle?.article_language?.title}</h2>
            <h3 className="heading-summary  "> {mainArticle?.article_language?.summary}</h3>
            <div className="mt-[45px]">
              {mainArticle?.assets[0]?.asset_file && (
                <img src={replaceAssetLink(mainArticle?.assets[0]?.asset_file)} alt={mainArticle?.article_language?.title} className=" mb-3 w-full" />
              )}
            </div>
            {mainArticle?.article_language?.content && <div className="mt-[45px] typo typo-style">{PARSE(doc.body.innerHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>'))}</div>}
          </div>
        )}

        <div className="mt-[45px] space-y-[45px]">
          {subArticle?.map((mis) => {
            const misIcon = replaceAssetLink(mis?.assets[0].asset_file);
            const misTitle = mis?.article_language?.title;
            const misContent = mis?.article_language?.content;

            const subArticleImgContent = new DOMParser().parseFromString(misContent, 'text/html');

            const tagsToRemove = 'img';
            for (const elm of subArticleImgContent.querySelectorAll('*')) {
              if (elm.matches(tagsToRemove)) {
                elm.replaceWith(`<img src='${elm.src}' alt='${misTitle}' />`);
              }
            }

            return (
              <div key={mis.id} className=" space-y-3 items-center">
                <div className="flex space-x-[15px] items-center">
                  <img src={misIcon} alt={misTitle} className="w-[50px] h-[50px]" />
                  <h4 className="heading-sub-title">{misTitle}</h4>
                </div>
                <div>
                  {misContent && <div className="typo typo-style prose-ul:list-disc">{PARSE(subArticleImgContent.body.innerHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>'))}</div>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BaseContainerPl>
  );
}
