/* eslint-disable */
import React, { Component, createRef } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { isMobile  } from 'react-device-detect';
import TBS_MENU_356 from '../../sections/TBS_Menu_356/TBS_Menu';
import TBS_HOMEPAGE_HERO_288 from '../../sections/TBS_Homepage_Hero_288/TBS_Homepage_Hero';
import TBS_HOME_BUSINESS_TYPE_290 from '../../sections/TBS_Home_Business_Type_290/TBS_Home_Business_Type';
import TBS_HOME_NEWS_650 from '../../sections/TBS_Home_News_650/TBS_Home_News';
import TBS_HOME_OUR_REPORTS_292 from '../../sections/TBS_Home_Our_Reports_292/TBS_Home_Our_Reports';
import TBS_HOMEPAGE_SHARE_PRICES_309 from '../../sections/TBS_Homepage_Share_Prices_309/TBS_Homepage_Share_Prices';
import TBS_HOMEPAGE_JOIN_US_295 from '../../sections/TBS_Homepage_Join_Us_295/TBS_Homepage_Join_Us';
import TBS_HOMEPAGE_FAQ_297 from '../../sections/TBS_Homepage_FAQ_297/TBS_Homepage_FAQ';
import TBS_FOOTER_289 from '../../sections/TBS_Footer_289/TBS_Footer';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class project1 extends Component {
   constructor(props) {
       super(props);
       this.state = {
           currentPage: null,
           currentSection: null,
           override: true,
           section: [
               { id: 1, slug: localStorage.getItem('language') === "English"? 'tbs-homepage-hero' : 'tbs-homepage-hero'},
               { id: 2, slug: localStorage.getItem('language') === "English"? 'business-type' : 'business-type'},
               { id: 3, slug: localStorage.getItem('language') === "English"? 'news' : 'news'},
               { id: 4, slug: localStorage.getItem('language') === "English"? 'read-our-reports' : 'read-our-reports'},
               { id: 5, slug: localStorage.getItem('language') === "English"? 'share-prices' : 'share-prices'},
               { id: 6, slug: localStorage.getItem('language') === "English"? 'join-us' : 'join-us'},
               { id: 7, slug: localStorage.getItem('language') === "English"? 'faq' : 'faq'},
           ],
       };
       this.scrollToSection = this.scrollToSection.bind(this);
       this.scrollAction = this.scrollAction.bind(this);
        this.isStateChanging = false
   }
   componentDidMount() {
       this.scrollToSection();
       ReactGA.initialize('G-7WJJLX00XL')
       ReactGA.pageview(window.location.pathname + window.location.search);
   }
   componentWillUnmount(){
   }
   section1 = createRef()
   section2 = createRef()
   section3 = createRef()
   section4 = createRef()
   section5 = createRef()
   section6 = createRef()
   section7 = createRef()
   scrollToSection() {
       let currentLocation = window.location.href
       const hasSectionAnchor = currentLocation.includes('/#')
       if (hasSectionAnchor) {
           let anchorSectionId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
           setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
       }
   }
   scrollAction(anchorSectionId) {
       const uri_dec = decodeURIComponent(anchorSectionId)
       const key = this.state.section.filter(x => x.slug == uri_dec).map(obj => `section${obj.id}`)
       const getY = (element) => element.getBoundingClientRect().top + window.pageYOffset - 140;
       if (key) {
          if (key[0] === 'section1') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section1.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section2') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section2.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section3') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section3.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section4') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section4.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section5') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section5.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section6') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section6.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section7') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section7.current), behavior: 'smooth' })}, 1000)
            }
       }
   }
   render() {
       const getHelmetTitle = () => {
           var title;
           switch (localStorage.getItem('language')) {
               case 'English':
                   title = <title> Towards a Better Society | TBS Energi Utama</title>;
                   break;
               case 'Indonesian':
                   title = <title> Menuju Masyarakat Yang Lebih Baik | TBS Energi Utama</title>;
                   break;
               case 'Vietnamese':
                   title = <title> Towards a Better Society | TBS Energi Utama</title>;
                   break;
               default:
                   title = <title> Towards a Better Society | TBS Energi Utama</title>;
                   break;
           }
           return title;
       };
       const getHelmetDescription = () => {
           var description;
           switch (localStorage.getItem('language')) {
               case 'English':
                   description = <meta name='description' content='As an integrated energy company, TBS focuses on developing clean and renewable energy that delivers meaningful contributions to communities' />;
                   break;
               case 'Indonesian':
                   description = <meta name='description' content='Sebagai perusahaan energi terintegrasi, TBS berfokus pada pengembangan energi bersih dan terbarukan yang memberikan kontribusi berarti bagi masyarakat' />;
                   break;
               case 'Vietnamese':
                   description = <meta name='description' content='None' />;
                   break;
               default:
                   description = <meta name='description' content='As an integrated energy company, TBS focuses on developing clean and renewable energy that delivers meaningful contributions to communities' />;
                   break;
           }
           return description;
       };
       const getHelmetKeywords = () => {
           var keywords;
           switch (localStorage.getItem('language')) {
               case 'English':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Indonesian':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Vietnamese':
                   keywords = <meta name='keywords' content='' />;
                   break;
               default:
                   keywords = <meta name='keywords' content='' />;
                   break;
           }
           return keywords;
       };
         let helmet = getHelmetTitle();
         let description = getHelmetDescription();
         let keywords = getHelmetKeywords();
       return (
           <>
               <Helmet>
                   {helmet}
                   {description}
                   {keywords}
               </Helmet>
            <div ref={this.section1}>
                <TBS_HOMEPAGE_HERO_288 />
            </div>
            <div ref={this.section2}>
                <TBS_HOME_BUSINESS_TYPE_290 />
            </div>
            <div ref={this.section3}>
                <TBS_HOME_NEWS_650 />
            </div>
            <div ref={this.section4}>
                <TBS_HOME_OUR_REPORTS_292 />
            </div>
            <div ref={this.section5}>
                <TBS_HOMEPAGE_SHARE_PRICES_309 />
            </div>
            <div ref={this.section6}>
                <TBS_HOMEPAGE_JOIN_US_295 />
            </div>
            <div ref={this.section7}>
                <TBS_HOMEPAGE_FAQ_297 />
            </div>
           </>
       );
   }
}
export default withParams(project1);