/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import PARSE from 'html-react-parser';
import { Link, useSearchParams, createSearchParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Api from './Api';
import GlobalApi from '../../context/api/GlobalApi';
import BaseArrowLinkTw from '../../context/components/Base/BaseArrowLinkTw';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, InputBase } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import TruncateMarkup from 'react-truncate-markup';
import moment from 'moment';
import replaceAssetLink from '../../context/utils/replaceAssetLink';
// import { useLocation } from 'react-router';

export default function TBSSearch() {
  const [sections, setSections] = useState([]);
  const [pageSection, setPageSection] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [category, setCategory] = useState('new');
  const [searchParams, setSearchParams] = useSearchParams();

  const [articleCount, setArticleCount] = useState(0);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [year, setYear] = useState('');
  const [listArticles, setListArticles] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const location = useLocation();

  const pageSize = 10;
  const count = Math.ceil(parseInt(articleCount, 10) / pageSize);

  const param = searchParams.get('search');

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    try {
      const fetchSection = api.getSectionDetail({ language: activeLang });

      fetchSection.then((res) => {
        const section = res.data.results;
        const sectionsWidgets = section[0]?.widgets;
        setSections(section);
        setWidgets(sectionsWidgets);
      });
    } catch (err) {
      console.log(err);
    }
  }, [activeLang]);

  const getAllArticles = useCallback(() => {
    const api = new GlobalApi();
    try {
      const fetchSingleArticlePromises = api.getArticleDetailByYearDescendingPaginationLang(681, '', pageSize, param, page, '');

      fetchSingleArticlePromises.then((res) => {
        setFileLoaded(false);
        setListArticles(res?.data?.results);
        //  setListWidgetArticle(res);
        setArticleCount(res.data.count);
        setTimeout(() => setFileLoaded(true), 500);
      });
    } catch (err) {
      console.log(err);
    }
  }, [param, page]);

  const getPageSection = useCallback(() => {
    const api = new GlobalApi();
    try {
      const fetchPageSection = api.getSearchPageSection(param);

      fetchPageSection.then((res) => {
        //  setListWidgetArticle(res);
        setPageSection(res?.data);
        // setPageSection([{
        //   name: 'test'
        // }]);
      });
    } catch (err) {
      console.log(err);
    }
  }, [param]);

  useEffect(() => {
    getSectionDetail();
    getAllArticles();
    getPageSection();
  }, [getAllArticles, getPageSection, getSectionDetail]);

  let title;
  let summary;
  let content;
  let icon;
  let sectionName;
  let path;
  let textContent;
  if (sections) {
    sectionName = sections[0]?.slug_name;

    if (widgets && widgets.length !== 0) {
      widgets.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents;
            textContent = text;
            break;
          case 1:
            const pathVal = section.widget_contents[0];
            path = pathVal?.article_language?.path;
            break;
          default:
            break;
        }
      });
    }
  }

  const onSearcTitle = (event) => {
    setSearch(event);
  };

  const handleChange = (e, p) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setFileLoaded(false);
    setTimeout(() => setFileLoaded(true), 500);
    setPage(p);
    _DATA.jump(p);
  };

  const safeText = textContent && textContent.length > 0 ? textContent : [{ content: '' }];

  const numb = pageSize * page - pageSize;
  const min = articleCount - numb;

  const getOfNumb = articleCount - min + pageSize;

  return (
    <div className="">
      <div className="bg-blue w-full md:h-[120px] h-[80px]"></div>
      <div className=" mt-[80px]">
        <div className="w-full ">
          <div
            id={`${sectionName}`}
            className={`section-container max-w-[88%] md:w-full hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto scrollTop `}
          >
            <div className="my-3 flex gap-x-3 bg-white w-full px-5 items-center py-2 border-b border-gray-300 ">
              <SearchIcon className="text-black" />
              <InputBase
                fullWidth
                defaultValue={param || ''}
                placeholder={safeText[0]?.content}
                onChange={(e) => onSearcTitle(e.target.value)}
                inputProps={{
                  onKeyPress: (event) => {
                    if (event.key === 'Enter') {
                      setSearchParams(createSearchParams({ search: search }));
                      event.preventDefault();
                    }
                  },
                }}
                sx={{
                  input: {
                    color: 'black',
                    '&::placeholder': {
                      color: 'gray',
                      opacity: 1,
                    },
                  },
                }}
                className="!text-[24px] ml-6"
              />
              <button onClick={() => setSearchParams(createSearchParams({ search: search }))} className="">
                {safeText[1]?.content}
              </button>
            </div>
          </div>
        </div>
        <div
          id={`${sectionName}`}
          className={`section-container max-w-[88%] md:w-full hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto scrollTop ${
            sections.length > 0 && sections[0].index === 3 ? ' md:mt-[40px] mt-[80px]' : 'md:mt-[40px] mt-[80px]'
          }`}
        >
          {param && listArticles.length > 0 ? (
            <div>
              <div className="font-inter text-2xl px-5">
                {safeText[2]?.content} {page === 1 ? '1' : numb + 1} - <span>{page === count ? articleCount : getOfNumb}</span> {safeText[3]?.content} <span>{articleCount}</span>{' '}
                {safeText[4]?.content} <span className="text-blue font-inter-bold">{param}</span>
              </div>

              <div className="mt-[40px]">
                {fileLoaded && listArticles && listArticles.length > 0 ? (
                  <div className="">
                    {listArticles.map((art) => {
                      const valId = art.id;
                      const valSlug = art?.article_languages?.slug_title;
                      const valTitle = art?.article_languages?.title;
                      const valSummary = art?.article_languages?.summary;
                      const valContent = art?.article_languages?.content;
                      const valCategories = art?.categories && art?.categories.length > 0 ? art?.categories : [{ name: 'Event & News' }];
                      const valDate = art?.date;
                      function LinkWrapper({ children }) {
                        let pathHtml;
                        if (art?.article_assets[0]?.asset?.thumbnail_file) {
                          return (pathHtml = <a href={replaceAssetLink(art?.article_assets[0]?.asset?.asset_file)}>{children}</a>);
                        } else return (pathHtml = <Link to={path + `/${valId}` + `/${valSlug}`}>{children}</Link>);
                      }
                      return (
                        <LinkWrapper>
                          <div className="border-b border-blue py-5 space-y-1 hover:bg-gray-100 px-5 group">
                            <div className="space-y-1">
                              <div className="flex space-x-2">
                                {valCategories.map((cate, i) => {
                                  const valCatName = cate?.name;
                                  return (
                                    <div className="font-inter-bold text-blue  flex">
                                      {valCatName} {valCategories.length - 1 !== i ? ' - ' : ''}
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="text-left text-sm font-inter"> {moment(valDate).format(' MMMM D, YYYY')}</div>
                            </div>
                            <div className="space-y-3">
                              <div className="font-inter-bold text-[20px]">{valTitle}</div>
                              {!valSummary && valContent ? (
                                <TruncateMarkup lines={3}>
                                  <div className=" font-inter max-w-2xl">{PARSE(valContent)}</div>
                                </TruncateMarkup>
                              ) : (
                                <TruncateMarkup lines={3}>
                                  <div className=" font-inter max-w-2xl">{valSummary}</div>
                                </TruncateMarkup>
                              )}
                              <div className="flex space-x-3 items-center">
                                <div className="font-inter-bold">{safeText[5]?.content}</div>
                                <BaseArrowLinkTw size="small" color="black" />
                              </div>
                            </div>
                          </div>
                        </LinkWrapper>
                      );
                    })}
                  </div>
                ) : (
                  <CircularProgress />
                )}
                <div className="mt-10 flex items-center justify-end gap-x-5">
                  <Pagination count={count} color="primary" size="medium" page={page} variant="outlined" shape="rounded" onChange={handleChange} />
                </div>
              </div>
            </div>
          ) : (
            <div className="px-5">
              <div className="font-inter text-2xl ">
                {safeText[2]?.content} <span>{listArticles.length}</span> {safeText[4]?.content} <span className="text-blue font-inter-bold">{param}</span>
              </div>
              <div className="font-inter pt-4 ">
                <span className="pr-1 text-blue font-inter-bold">{safeText[7]?.content}</span>
                {safeText[6]?.content}
              </div>
            </div>
          )}

          <div className="pt-10">
            {param && pageSection.length > 0 ? (
              <div>
                <div className="font-inter text-2xl px-5">
                  {safeText[2]?.content} <span>{pageSection.length}</span> {safeText[4]?.content} <span className="text-blue font-inter-bold">{param}</span>
                </div>

                <div className="mt-[40px]">
                  {fileLoaded && pageSection && pageSection.length > 0 && (
                    <div className="mt-[40px]">
                      {pageSection?.map((url) => {
                        return (
                          <div className="border-b border-blue py-5 space-y-1 hover:bg-gray-100 px-5 group">
                            <Link to={url?.url__path || '/'}>
                              <div className="space-y-3">
                                <div className="font-inter-bold text-blue  flex">{safeText[8]?.content}</div>
                                <div className="font-inter-bold text-[20px]">{url?.name}</div>
                                <div className="flex space-x-3 items-center">
                                  <div className="font-inter-bold">{safeText[5]?.content}</div>
                                  <BaseArrowLinkTw size="small" color="black" />
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="px-5">
                <div className="font-inter text-2xl ">
                  {safeText[2]?.content} <span>{pageSection.length}</span> {safeText[4]?.content} <span className="text-blue font-inter-bold">{param}</span>
                </div>
                <div className="font-inter pt-4 ">
                  <span className="pr-1 text-blue font-inter-bold">{safeText[8]?.content}</span>
                  {safeText[6]?.content}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
