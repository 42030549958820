/* eslint-disable */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PARSE from "html-react-parser";
import styled from "styled-components";
import InnerImageZoom from "react-inner-image-zoom";
import Api from "./Api";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

export default function TBSAboutUsOrganization() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let icon;
  let sectionName;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text?.article_language?.title;
            summary = text?.article_language?.summary;
            content = text?.article_language?.content;
            icon = replaceAssetLink(text?.assets[0]?.asset_file);

            break;
          case 2:
            break;
          case 3:
            break;
          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      <div className="section-width">
        <h2 className="heading-title"> {title}</h2>
        <h3 className="heading-summary"> {summary}</h3>
        <div className="relative space-y-4 overflow-hidden margin-content">
          <InnerImageZoom
            src={icon}
            imgAttributes={{
              src: icon,
              alt: title,
            }}
          />
          {/* <div
            className={`block cursor-zoom-in opacity-100 ${Styles.figure}`}
            onMouseMove={handleMouseMove}
            style={styled}
            onClick={handleZoomImage}
          >
            <BasedImg opacity={opacity} className="w-full block pointer-events-none" src={icon} alt="" />
          </div> */}

          {content && (
            <div className="bg-[#F5F5F5] w-fit p-4 mt-6">
              <article className="">{PARSE(content)}</article>
            </div>
          )}
        </div>
      </div>
    </BaseContainerPl>
  );
}

