import { createSlice, current } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const authSlice = createSlice({
  name: 'auth',
  initialState: { isLoggedIn: false },
  reducers: {
    setLogging(state, action) {
      const currState = state;
      const objState = current(currState);
      console.log('🚀 ~ file: authSlice.js ~ line 13 ~ setLogging ~ objState', objState);
      currState.isLoggedIn = action.payload;
      Cookies.set('isLoggedIn', true, { path: '*' });
    },
    setLogout(state) {
      const currState = state;
      currState.isLoggedIn = false;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
