/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PARSE from "html-react-parser";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import TruncateMarkup from "react-truncate-markup";
import Api from "./Api";
import BaseArrowLinkTw from "../../context/components/Base/BaseArrowLinkTw";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

export default function TBS_RegularArticleNew() {
  const [sections, setSections] = useState([]);
  const [widgets, setWidgets] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    const sectionsData = response.data.results;
    const sectionsWidgets = sectionsData[0]?.widgets;

    setSections(sectionsData);
    setWidgets(sectionsWidgets);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let media;
  let sectionName;
  let path;
  let textLink;
  if (sections) {
    sectionName = sections[0]?.slug_name;

    if (widgets && widgets.length !== 0) {
      widgets.forEach((section, i) => {
        switch (i) {
          case 0:
            const article = section.widget_contents[0];
            const {
              title: articleTitle,
              summary: articleSummary,
              content: articleContent,
              path: articlePath,
            } = article?.article_language || {};

            title = articleTitle;
            summary = articleSummary;
            content = articleContent;
            path = articlePath;
            // media = replaceAssetLink(assets?.[0]?.file_medium || assets?.[0]?.asset_file);

            break;
          case 1:
            const assets = section.widget_contents;
            media = assets;
            break;
          case 2:
            break;
          default:
            break;
        }
      });
    }
  }

  const doc = new DOMParser().parseFromString(content, "text/html");

  const tagsToRemove = "img";
  for (const elm of doc.querySelectorAll("*")) {
    if (elm.matches(tagsToRemove)) {
      elm.replaceWith(
        `<img src='${elm.src}' alt='${title}' height='692' width='auto' />`
      );
    }
  }

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      {sections.length > 0 ? (
        <div className="section-width-new">
          <h2 className="heading-title-new"> {title}</h2>
          {/* <h3 className="heading-summary"> {summary}</h3> */}
          <div className="margin-content-new">
            <div className="">
              {media && <img src={media} alt="" className=" mb-3 w-full" />}
            </div>
            {content && (
              <article className="typo-style prose-h2:flex prose-h2:items-center">
                {PARSE(
                  doc.body.innerHTML.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
                )}
              </article>
            )}
          </div>
          {widgets && widgets.length > 1 ? (
            <div className="w-full grid md:grid-cols-3 s:grid-cols-2 gap-[1.4rem] my-3">
              {media &&
                media.map((data) => {
                  return (
                    <div key={data.id} className="justify-start text-left  ">
                      <div className="">
                        <div title="" className="cursor-pointer ">
                          <div className="">
                            <a
                              href={replaceAssetLink(data?.file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  replaceAssetLink(data?.thumbnail) ??
                                  "https://bzpublishassets.blob.core.windows.net/media/assets/Screen_Shot_2022-06-02_at_19.28_12.png"
                                }
                                alt={data?.description}
                                className="drop-shadow-lg w-full md:max-h-[30vh] md:min-h-[330px] max-w-[270px] object-cover"
                              />
                              <div className="space-y-2 mt-5">
                                {/* <div className="text-left text-sm font-inter"> {moment(list?.document_date).format(' MMMM D, YYYY')}</div> */}
                                <div className="text-[#004A98] font-inter-bold">
                                  {data?.categories[0]?.name}
                                </div>
                                <TruncateMarkup lines={2}>
                                  <div className=" font-inter-bold h-full text-lg  text-left">
                                    {data?.description ||
                                      "This Placeholder Description"}
                                  </div>
                                </TruncateMarkup>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="section-width">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </BaseContainerPl>
  );
}
