/* eslint-disable */
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import PARSE from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "./Api";

export default function TBSRegulerArticleYoutube() {
  const [sections, setSections] = useState([]);
  const [widgets, setWidgets] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    const sectionsData = response.data.results;
    const sectionsWidgets = sectionsData[0]?.widgets;

    setSections(sectionsData);
    setWidgets(sectionsWidgets);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let media;
  let sectionName;
  let path;
  let textLink;
  if (sections) {
    sectionName = sections[0]?.slug_name;

    if (widgets && widgets.length !== 0) {
      widgets.forEach((section, i) => {
        switch (i) {
          case 0:
            const article = section.widget_contents[0];
            const {
              title: articleTitle,
              summary: articleSummary,
              content: articleContent,
              path: articlePath,
            } = article?.article_language || {};

            title = articleTitle;
            summary = articleSummary;
            content = articleContent;
            path = articlePath;
            // media = replaceAssetLink(assets?.[0]?.file_medium || assets?.[0]?.asset_file);

            break;
          case 1:
            const assets = section.widget_contents;
            media = assets;
            break;
          case 2:
            break;
          default:
            break;
        }
      });
    }
  }

  const doc = new DOMParser().parseFromString(content, "text/html");

  const tagsToRemove = "img";
  for (const elm of doc.querySelectorAll("*")) {
    if (elm.matches(tagsToRemove)) {
      elm.replaceWith(
        `<img src='${elm.src}' alt='${title}' height='692' width='auto' />`
      );
    }
  }

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.classList.add("span-editor");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(content);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute("url");
    //Replace 'watch?v' with 'embed/'
    url = url.replace("watch?v=", "embed/");

    //Create a iframe tag
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "100%");
    newIframe.setAttribute("height", "100%");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    newIframe.classList.add("youtube-embed");
    if (url) {
      newIframe.setAttribute("src", url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML;

  return (
    <div className="h-full max-w-[800px] mx-auto mt-[80px] px-8">
      {sections.length > 0 ? (
        <div className="">
          <h2 className="heading-title"> {title}</h2>
          <h3 className="heading-summary"> {summary}</h3>
          <div className="margin-content">
            <div className="">
              {media && <img src={media} alt="" className=" mb-3 w-full" />}
            </div>
            {content && (
              <article className="">
                <div className="typo-style">
                  {content && PARSE(contentToRender)}
                </div>
              </article>
            )}
          </div>
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="section-width">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </div>
  );
}
