/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PARSE from 'html-react-parser';
import Api from './Api';
import { useSelector } from 'react-redux';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';

export default function TBSCorporateInformationAuditor() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let readHere;
  let icon;
  let sectionName;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text?.article_language?.title;
            summary = text?.article_language?.summary;
            content = text?.article_language?.content;
            icon = text?.assets[0]?.file_medium;

            break;
          case 2:
            break;
          case 3:
            break;
          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl sectionName={sectionName} sectionIndex={sections && sections.length > 0 && sections[0].index}>
      <div className="section-width">
        <h2 className="heading-title"> {title}</h2>
        <h2 className="heading-summary"> {summary}</h2>
        {content && (
          <div className="bg-[#F5F5F5] w-[325px] p-4 mt-6">
            <div className="">{icon && <img src={icon} alt="" className="w-[50px] h-[50px] mb-3" />}</div>
            <div className="">{PARSE(content)}</div>
          </div>
        )}
      </div>
    </BaseContainerPl>
  );
}
