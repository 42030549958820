/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import PARSE from 'html-react-parser';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AccessTime, Room } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
import Api from './Api';
import GlobalApi from '../../context/api/GlobalApi';
import TruncateMarkup from 'react-truncate-markup';
import ContentAccordion from './ContentAccordion';

export default function TBSCareer() {
  const [sections, setSections] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [valueTab, setValueTab] = useState(0);
  const [widget, setWidget] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [minSallary, setMinSallary] = useState('');
  const [maxSallary, setMaxSallary] = useState('');
  const [currency, setCurrency] = useState('');
  const [inpTitle, setInpTitle] = useState('');
  const [inpLocation, setInpLocation] = useState('');
  const [typeJob, setTypeJob] = useState({ id: '', checked: false });
  const [educationSelected, setEducationSelected] = useState({ id: '', checked: false });
  const [page, setPage] = useState(1);
  const [jobCount, setJobCount] = useState(0);
  const [jobTypeData, setJobTypeData] = useState(null);
  const [educationData, setEducationData] = useState(null);

  const [search, setSearch] = useState({ inpString: '', submit: false });
  const langNum = useSelector((state) => state.content.activeLanguage);
  const clientId = 22;

  const count = Math.ceil(jobCount / 6);

  const handleChangePage = (e, p) => {
    setPage(p);
  };

  const getJobs = useCallback(() => {
    const api = new GlobalApi();
    api
      .getJobPosters(clientId, company, position, widget, currency, typeJob.id, search.submit ? search.inpString : '', page, educationSelected.id)
      .then((res) => {
        setJobs(res.data.results);
        setJobCount(res.data.count);
      })
      .catch((err) => {
        console.log('err', err);
      });
    // console.log(response);
  }, [company, position, widget, currency, typeJob.id, search.submit, search.inpString, page, educationSelected.id]);

  const getJobTypes = useCallback(() => {
    const api = new GlobalApi();
    api
      .getJobType()
      .then((res) => {
        setJobTypeData(res.data.results);
      })
      .catch((err) => {
        console.log('err', err);
      });
    // console.log(response);
  }, []);

  const getEducations = useCallback(() => {
    const api = new GlobalApi();
    api
      .getEducation({ client: clientId })
      .then((res) => {
        setEducationData(res.data.results);
      })
      .catch((err) => {
        console.log('err', err);
      });
    // console.log(response);
  }, []);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api
      .getSectionDetail({ language: activeLang })
      .then((res) => {
        setSections(res.data.results);
      })
      .catch((err) => {
        console.log('err', err);
      });
    // console.log(response);
  }, [activeLang]);

  useEffect(() => {
    getSectionDetail();
    getJobs();
    getJobTypes();
    getEducations();
  }, [page, typeJob, search, educationSelected, getSectionDetail, getJobs, getJobTypes, getEducations]);

  let title;
  let summary;
  let content;
  let sectionName;
  let textVal;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents;
            textVal = text;
            break;
          case 2:
            break;
          case 3:
            break;
          default:
            break;
        }
      });
    }
  }

  const safeText = textVal && textVal.length > 0 ? textVal : [{ content: '' }];

  const handleTypeJob = (id) => {
    setPage(1);
    setTypeJob({ id, checked: !typeJob.checked });
    if (typeJob.id === id && typeJob.checked === false) {
      setTypeJob({ id: '', checked: false });
    } else setTypeJob({ id, checked: false });
  };

  const handleEducation = (id) => {
    setPage(1);
    setEducationSelected({ id, checked: !educationSelected.checked });
    if (educationSelected.id === id && educationSelected.checked === false) {
      setEducationSelected({ id: '', checked: false });
    } else setEducationSelected({ id, checked: false });
  };

  const onSearcTitle = (event) => {
    setInpTitle(event.target.value);
  };

  const onSearcLocation = (event) => {
    setInpLocation(event.target.value);
  };

  return (
    <div
      id={`${sectionName}`}
      className="section-container max-w-[88%] md:w-full hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto relative "
    >
      <div className="lg:-mt-[34px] mt-[34px] w-full z-[6000] relative">
        <div className="bg-white shadow-md  mx-auto p-2 z-[300]">
          <div className="lg:flex lg:divide-x lg:divide-y-0 divide-y items-center">
            <div className="lg:w-6/12">
              <div className="my-3 flex gap-x-3 bg-white w-full ">
                <SearchIcon color="disabled" />
                <InputBase fullWidth placeholder={safeText[0]?.content} onChange={onSearcTitle} className="" />
              </div>
            </div>
            <div className="lg:w-6/12 lg:pl-3 ">
              <div className="my-3 flex gap-x-3 bg-white w-full items-center">
                <RoomIcon color="disabled" />
                <InputBase fullWidth placeholder={safeText[1]?.content} onChange={onSearcLocation} className="" />
                {/* <span className="absolute bg-white p-2 hidden group-hover:block capitalize text-sm font-inter-bold text-gray-400">This Feature will be ready soon</span> */}
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  const mixString = inpTitle ? `${inpTitle} ${inpLocation}` : inpLocation;
                  [setPage(1), setSearch({ inpString: mixString, submit: true })];
                }}
                type="button"
                className="w-[120px] p-2 bg-[#004a98] text-white font-inter-bold lg:mt-0 mt-[10px]"
              >
                {safeText[2]?.content}
              </button>
            </div>
          </div>
        </div>
        <div className="w-full mt-16 lg:flex">
          <div className="lg:w-3/12 ">
            <p className="font-inter-bold text-sm text-gray-400 pb-4">{safeText[3]?.content}</p>
            <div className="space-y-5">
              <div>
                <p className="font-inter-bold">{safeText[4]?.content}</p>
                <div className="grid grid-cols-2 mt-2 space-y-1">
                  {jobTypeData?.map((type) => (
                    <div key={type.id} className="flex items-center space-x-2">
                      {' '}
                      <input type="checkbox" checked={typeJob.id === type.id} name="job-type" value={type.id} onChange={() => handleTypeJob(type.id)} className=" w-4 h-4" />
                      <p className="">{type?.name}</p>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div>
                <div className="font-inter-bold">Education</div>
                <div className="grid grid-cols-2 mt-2 space-y-1">
                  {educationData?.map((edu) => (
                    <div key={edu.id} className="flex items-center space-x-2">
                      {' '}
                      <input type="checkbox" checked={educationSelected.id === edu.id} name="lang" value={edu.id} onChange={() => handleEducation(edu.id)} className=" w-4 h-4" />
                      <div className="">{edu?.name}</div>
                    </div>
                  ))}
                </div>
              </div> */}
            </div>
          </div>
          <div className="lg:w-9/12">
            {jobs?.map((job) => {
              const valType = job?.job_type;
              const valLocation = job?.location?.name;
              const valTitle = job?.title;
              const valDate = job?.post_date;
              const valStatus = job?.status;
              const valContent = job?.article_languages?.content;
              let modifiedContent

              function renderStatus(status) {
                let htm;
                if (valStatus.toLowerCase() === 'published') {
                  return (htm = <p className="bg-green text-white px-2 font-inter-bold rounded md:text-base text-sm">{safeText[5]?.content}</p>);
                }
                if (valStatus.toLowerCase() === 'closed') {
                  return (htm = <p className="bg-gray-600 text-white px-2 font-inter-bold rounded md:text-base text-sm">{safeText[6]?.content}</p>);
                }
                if (valStatus.toLowerCase() === 'drafted') {
                  return (htm = <p className="bg-gray-600 text-white px-2 font-inter-bold rounded md:text-base text-sm">{safeText[6]?.content}</p>);
                }

                return htm;
              }

              if (valContent) {
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = valContent;
  
                const h4Elements = tempDiv.querySelectorAll('h3');
  
                h4Elements.forEach(element => {
                  element.classList.add('text-sm')
                });
  
                modifiedContent = tempDiv.innerHTML;
              }

              return (
                <div key={job.id} className="w-full border p-5 my-4">
                  <div className="">
                    <div className="md:flex-row flex-col flex md:items-center md:justify-between justify-start items-start   md:space-x-6 md:space-y-0 space-y-3 w-full">
                      <div className="flex space-x-6 items-center ">
                        {' '}
                        <div className="flex  space-x-6 items-center">
                          <div className="flex items-center space-x-1">
                            <AccessTime />
                            <div className="md:text-base text-sm">{valType}</div>
                          </div>
                          <div className="flex items-center space-x-1">
                            <Room />
                            <div className="md:text-base text-sm">{valLocation}</div>
                          </div>
                          <div className="flex items-center md:block hidden">{renderStatus(valStatus)}</div>
                        </div>
                      </div>
                      <div className=" flex items-center  space-x-6 ">
                        <div className=" md:hidden block md:text-base text-sm">{renderStatus(valStatus)}</div>
                        <p className="md:text-base text-sm  ">{moment(valDate).format(' MMMM D, YYYY')}</p>
                      </div>
                    </div>
                    {/* <Link to={`/career/career-detail/${job?.id}/${job?.article_languages?.slug_title}`}> */}
                    <h2 className="font-inter-bold border-b pb-3 md:text-[24px] text-[20px] md:pt-[22px] pt-[16px] md:leading-[34px] leading-[26px]">{valTitle}</h2>
                    {/* </Link> */}
                    {valContent && <ContentAccordion content={modifiedContent && modifiedContent} />}
                    {/* <TruncateMarkup lines={8}>{valContent && <div className="max-w-2xl py-3 ">{PARSE(valContent)}</div>}</TruncateMarkup> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="my-5 flex items-center justify-end gap-x-5 ">
          <Pagination count={count} color="primary" size="medium" page={page} variant="outlined" shape="rounded" onChange={handleChangePage} />
        </div>
      </div>
    </div>
  );
}
