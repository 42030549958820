/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PARSE from 'html-react-parser';
import Api from './Api';
import Styles from './TBS_Our_People_Commitment.module.css';

function TBS_Our_People_Commitment() {
  const [section, setSection] = useState([]);
  const [slugName, setSlugName] = useState('');
  const activeLang = useSelector((state) => state.content.activeLanguage);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const api = new Api();
        const sectionResponse = await api.getSectionDetail({ language: activeLang });
        const sectionData = sectionResponse.data.results[0];
        const { widgets, slug_name: sectionSlugName } = sectionData;
  
        setSection(widgets);
        setSlugName(sectionSlugName);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [activeLang]);

  const content = [];
  if (section) {
    section?.forEach((data, idx) => {
      const { widget_contents } = data;

      if (idx === 0) {
        content.push(...widget_contents);
      }
    });
  }

  return (
    <div id={`${slugName}`} className="w-full flex flex-col lg:flex-row fhd:mx-auto fhd:max-w-[1536px] mt-[80px]">
      {content &&
        content.map((value, id) => {
          return (
            <div key={value.id} className={`w-full h--full ${id % 2 === 0 ? 'bg-gray-200' : 'bg-blue-500'} `}>
              <div className={`mx-auto ${Styles['left-side']}`}>
                <div className="h-full flex flex-col justify-between">
                  <div className={id % 2 !== 0 ? 'text-white' : ''}>
                    <h2 className={`text-[18px] s:text-[21px] font-bold leading-[25px] ${id === 0 ? 'mb-[33px]' : 'mb-[25px]'}`}>{value?.article_language?.title}</h2>
                    {value && PARSE(value?.article_language?.content)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default TBS_Our_People_Commitment;
