/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HtmlReactParse from "html-react-parser";
import { Link } from "react-router-dom";
import Api from "./Api";
import Styles from "./TBS_Homepage_Join_Us.module.css";
// import BaseArrowLink from '../../context/components/Base/BaseArrowLink';
import BaseArrowLinkTw from "../../context/components/Base/BaseArrowLinkTw";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

function TBS_Homepage_Join_Us() {
  const [section, setSection] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const fetchData = async () => {
      const api = new Api();

      try {
        const sectionResponse = await api.getSectionDetail({
          language: activeLang,
        });
        const sectionData = await sectionResponse.data;
        const sections = sectionData.results[0].widgets;

        setSection(sections);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [activeLang]);

  let contents;
  let checkInstagram;
  let instagramLink;
  const sosmed = [];
  const sosmedIcon = [];
  if (section) {
    section.forEach((data, id) => {
      const { widget_contents } = data;

      if (id === 0) {
        contents = widget_contents;
      }

      if (id === 1) {
        widget_contents.forEach((val, id) => {
          if (id === 2) {
            checkInstagram = val?.article_language?.title;
            instagramLink = val?.article_language?.path;
          } else {
            sosmed.push(val?.article_language);
            sosmedIcon.push({
              icon: val?.assets[0]?.file_small,
              desc: val?.assets[0]?.description,
            });
          }
        });
      }
    });
  }

  return (
    <div className="w-full">
      <div className="h-full grid grid-cols-1 md:grid-cols-2 gap-y-10 lg:flex lg:flex-row justify-between mt-[80px] max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto">
        {contents?.map((val, id) => {
          const { title } = val.article_language;
          const { summary } = val.article_language;
          const { content } = val.article_language;
          const { path } = val.article_language;

          return (
            <div
              key={val.id}
              className={`w-full my-card flex flex-col  md:w-[320px] lg:w-[280px] xl:w-[345px] space-y-[15px] ${Styles.card}`}
            >
              <div className={Styles["card-title"]}>
                <h2 className="">{title}</h2>
              </div>
              <div className={Styles["card-text"]}>
                <p>{HtmlReactParse(content)}</p>
              </div>
              {id === 1 &&
                sosmed?.map((val, id) => {
                  return (
                    <div>
                      <div className="flex gap-x-[17px]">
                        <img
                          className="w-[20px] h-[20px]"
                          src={replaceAssetLink(sosmedIcon[id].icon)}
                          alt={sosmedIcon[id].desc}
                        />
                        <p>{val.title}</p>
                      </div>
                    </div>
                  );
                })}
              {id === 1 ? (
                <div className="space-y-[15px]">
                  <div className={`group ${Styles["card-link"]}`}>
                    <a
                      href={`${instagramLink}` ?? "/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="flex gap-x-3" href="/">
                        <p>{checkInstagram}</p>
                        <BaseArrowLinkTw size="small" color="black" />
                      </div>
                    </a>
                  </div>
                  <div className={`group ${Styles["card-link"]}`}>
                    <a href={`${path}` ?? "/"} target="_blank" rel="noreferrer">
                      <div className="flex gap-x-3" href="/">
                        <p>{summary}</p>
                        <BaseArrowLinkTw size="small" color="black" />
                      </div>
                    </a>
                  </div>
                </div>
              ) : (
                <div className={`group ${Styles["card-link"]}`}>
                  <Link to={`${path}`}>
                    <div className="flex gap-x-3" href="/">
                      <p>{summary}</p>
                      <BaseArrowLinkTw size="small" color="black" />
                    </div>
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TBS_Homepage_Join_Us;
