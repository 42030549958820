/* eslint-disable */

import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getSectionTag } from '../../store/slices/sectionSlice';

export default function BaseContainerPl({ sectionName, sectionIndex, children }) {
  // start -> store active section to redux
  const dispatch = useDispatch();
  const boxRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const [position, setPosition] = useState();
  const [onScroll, setOnScroll] = useState(false);

  useEffect(() => {
    if (location.pathname && location.pathname.slice(-1) !== '/') {
      navigate(`${location.pathname}/`, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (position?.top < 180 && !(position?.top < 0)) {
      setOnScroll(true);
    } else setOnScroll(false);
  }, [position?.top]);

  const getPosition = useCallback(() => {
    const watchPosition = boxRef.current.getBoundingClientRect();
    setPosition(watchPosition);
  }, []);

  useEffect(() => {
    getPosition();
  }, [getPosition]);

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', getPosition, { passive: true });
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', getPosition);
    };
  }, [getPosition]);

  useEffect(() => {
    if (position?.top < 180) {
      dispatch(getSectionTag({ isTagData: sectionName }));
    }
    // if (onScroll && position?.top < 180 && sectionName && location.hash !== `#${sectionName}`) {
    //   navigate(`#${sectionName}`, { replace: true });
    // }
  }, [dispatch, sectionName, position?.top, location.hash, navigate, onScroll]);
  // end -> store active section to redux

  return (
    <div ref={boxRef} id={`${sectionName}`} className={`section-container-pl  ${sectionIndex && sectionIndex === 3 ? 'mt-index-section__3' : 'mt-index-section'}`}>
      {children}
    </div>
  );
}
