/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Api from "./Api";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import clsxm from "../../context/utils/clsxm";
import PARSE from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";

function TBS_Messages_Board() {
  const [sections, setSections] = useState([]);
  const [slugName, setSlugName] = useState("");

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      const apiSectionData = res.data.results;
      setSections(apiSectionData);
      setSlugName(apiSectionData[0].slug_name);

      return sections;
    });
  }, [sections]);

  useEffect(() => {
    getSectionDetail();
  }, []);

  let board;

  let listContent = [];

  if (sections) {
    let sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            board = section?.widget_contents;
            break;
          case 1:
            listContent = section?.widget_contents;

            break;

          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl
      sectionName={slugName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      <div
        className="section-width-new relative"
        style={{
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFF 100%)",
        }}
      >
        <div
          className="absolute w-full h-full -z-[1]  bottom-0 md:bottom-auto"
          style={{
            background: "rgba(0, 74, 152, 0.20)",
          }}
        ></div>
        <div className="mt-[103px] flex lg:flex-row flex-col s:items-center  justify-center relative lg:pl-[39px] lg:space-y-0 space-y-[47px] lg:mb-0 mb-[66px]">
          {board?.map((data, idx) => {
            const title = data.article_language.title;
            const summary = data.article_language.summary;
            const content = data.article_language.content;
            const contentImage = replaceAssetLink(data.assets[0]?.asset_file);
            const contentDesc = replaceAssetLink(data.assets[0]?.description);

            return (
              <div
                key={data.id}
                className={clsxm("lg:w-full  flex  items-center  relative")}
              >
                <div className=" flex flex-col  gap-y-[18px]">
                  <div
                    className={clsxm(
                      idx === 0
                        ? "md:ml-[calc(121px-39px)] ml-[31px]"
                        : "ml-[31px]"
                    )}
                  >
                    <LazyLoadImage
                      className="h-auto w-auto"
                      src={contentImage}
                      alt={contentDesc || content}
                    />
                  </div>
                  <div
                    // className="absolute bottom-[32px] py-[12px] px-[21px] left-0"
                    className={clsxm(
                      "absolute bottom-[32px] py-[12px] px-[21px] left-0",
                      idx === 0 ? "md:w-[355px]" : "w-[250px]"
                    )}
                    style={{
                      background:
                        "linear-gradient(90deg, #83BB1F 25%, rgba(131, 187, 31, 0) 100%)",
                    }}
                  >
                    <p className="text-[16px] font-inter-bold">{title}</p>
                    <p className="text-[11px] font-inter-bold text-tbs-green">
                      {summary}
                    </p>
                    {content && (
                      <div className=" prose-p:text-[11px] prose-p:font-inter-bold text-white">
                        {PARSE(content)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`section-width-new `}>
        <div
          className={clsxm("w-full   flex  items-center  mt-[48px] mb-[72px]")}
        >
          <div className=" flex justify-center  w-full">
            {listContent[0]?.article_language.content && (
              <div className="content-grid-2023">
                {PARSE(listContent[0]?.article_language.content)}
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseContainerPl>
  );
}

export default TBS_Messages_Board;
