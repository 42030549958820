/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Api from "./Api";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import clsxm from "../../context/utils/clsxm";
import PARSE from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LiteYoutubeEmbed } from "react-lite-yt-embed";
import {
  getEmbedCode,
  getEmbedCodeWatch,
} from "../../context/utils/youtubeRenderer";
import { Link } from "react-router-dom";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";

function TBS_Trusted_Partner() {
  const [sections, setSections] = useState([]);
  const [sectionName, setSectionName] = useState("");

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      const apiSectionData = res.data.results;
      setSections(apiSectionData);
      setSectionName(apiSectionData[0].slug_name);

      return sections;
    });
  }, [sections]);

  useEffect(() => {
    getSectionDetail();
  }, []);

  let headTitle;
  let headContent;
  let descriptionText;
  let headAsset;
  let listContent;
  let wmPlayer = [];
  let mediaReport = [];
  let board = [];
  let boardLink;

  if (sections) {
    let sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const header =
              section?.widget_contents ?? section.widget_contents[0];
            headTitle = header[0]?.article_language?.title;
            headContent = header[0]?.article_language?.content;
            descriptionText = header[0]?.article_language?.summary;
            headAsset = header[0]?.assets[0];
            break;
          case 1:
            wmPlayer = section?.widget_contents;

            break;
          case 2:
            listContent = section?.widget_contents;

            break;
          case 3:
            mediaReport = section?.widget_contents;

            break;
          case 4:
            board = section?.widget_contents;

            break;
          case 5:
            boardLink = section.widget_contents[0]?.article_language;

            break;

          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
      className="section-container-pl-full-moblie"
    >
      <div className="">
        <div className="">
          <div className="section-width-new lg:!max-w-[849px] !max-w-[88%]">
            <div className="flex items-end border-b-[2px] space-x-[24px] pb-[12px]">
              <LazyLoadImage
                className="h-auto w-auto"
                src={replaceAssetLink(headAsset?.asset_file)}
                alt={headAsset?.description}
              />
              <h2 className="heading-titile-grid-2023 mt-[7px]">{headTitle}</h2>
            </div>
            <div className="space-y-[25px] mt-[40px]">
              <div
                className={clsxm(
                  "w-full border rounded-[10px] border-tbs-blue  md:px-[42px] px-[17px] pt-[28px] pb-[21px]"
                  // idx > 3 ? "col-span-2" : ""
                )}
              >
                <p className="text-[18px] font-inter-bold text-center">
                  {wmPlayer[0]?.article_language.title}
                </p>
                <div className="mt-[15px] grid md:grid-cols-3 grid-cols-1 md:gap-[49px] gap-[25px] w-full">
                  {wmPlayer.slice(1, 3).map((pl, idx) => {
                    const content = pl.article_language.content;
                    const assets = pl.assets;
                    return (
                      <div
                        className={clsxm(
                          "",
                          idx > 0
                            ? "md:col-span-2"
                            : "md:col-span-1 w-fit mx-auto"
                        )}
                      >
                        <div className="px-[22px] py-[15px] flex justify-between border ">
                          {" "}
                          {assets.map((pl) => {
                            // const content = pl.article_language.content;
                            // const assets_file = pl.assets_file;

                            return (
                              <div className="max-h-[41.28px]">
                                <LazyLoadImage
                                  className="max-h-[41.28px] w-auto"
                                  src={replaceAssetLink(pl?.asset_file)}
                                  alt={pl?.description}
                                />
                              </div>
                            );
                          })}
                        </div>
                        {content && (
                          <div className="content-grid-2023 text-center">
                            {PARSE(content)}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={` grid grid-cols-2 lg:grid-cols-3 md:gap-[24px] gap-[18px] `}
              >
                {listContent &&
                  listContent?.map((data, idx) => {
                    const content = data.article_language.content;
                    const contentImage = replaceAssetLink(
                      data.assets[0]?.asset_file
                    );
                    const contentDesc = replaceAssetLink(
                      data.assets[0]?.description
                    );

                    return (
                      <div
                        key={data.id}
                        className={clsxm(
                          "w-full border rounded-[10px] border-tbs-blue  flex  items-center md:px-[20px] px-[11px] md:py-[24px] py-[10px]"
                          // idx > 3 ? "col-span-2" : ""
                        )}
                      >
                        <div className=" flex flex-col  gap-y-[18px]">
                          <div className="">
                            <LazyLoadImage
                              className="h-auto w-auto"
                              src={contentImage}
                              alt={contentDesc || content}
                            />
                          </div>
                          {content && (
                            <div className="content-grid-2023">
                              {PARSE(content)}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="-full-moblie">
          <div
            className="mt-[69px] relative lg:max-w-[849px] xl:mx-0 mx-auto"
            style={{
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFF 100%)",
            }}
          >
            <div
              className="absolute w-full h-full -z-[1] lg:mb-0 -mb-[66px] bottom-0 lg:bottom-auto"
              style={{
                background: "rgba(0, 74, 152, 0.20)",
              }}
            ></div>
            <div className="pt-[40px] border-t lg:max-w-full max-w-[88%] mx-auto ">
              {" "}
              {headContent && (
                <div className="content-grid-2023">{PARSE(headContent)}</div>
              )}
            </div>
            <div className="mt-[59px] flex items-center lg:flex-row flex-col w-full lg:justify-between justify-center pl-[30px] pr-[34px] lg:space-x-[35px] lg:space-y-0 space-y-[30px] ">
              {" "}
              {mediaReport?.map((pl, idx) => {
                const content = pl.description;
                const assets = pl;
                return pl?.asset_type === 2 ? (
                  <div className="yt-trusted w-full max-w-[415px]">
                    <LiteYoutubeEmbed
                      mute={false}
                      className="yt-lite-react block"
                      id={
                        assets?.link.includes("watch")
                          ? getEmbedCodeWatch(assets?.link)
                          : getEmbedCode(assets?.link)
                      }
                    />
                    {content && (
                      <div className="font-inter-bold max-w-[134px] mx-auto text-[14px] leading-[18.2px] mt-[15px] text-center">
                        {PARSE(content)}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="lg:w-[150px] s:w-[304px] w-auto">
                    <a
                      href={replaceAssetLink(assets?.file)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LazyLoadImage
                        className="w-full object-cover rounded-[10px] lg:h-[230px] s:h-[467px] h-auto"
                        src={replaceAssetLink(assets?.thumbnail)}
                        alt={assets?.description}
                      />
                    </a>
                    {content && (
                      <div className="font-inter-bold max-w-[134px] mx-auto text-[14px] leading-[18.2px] mt-[15px] text-center">
                        {PARSE(content)}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="mt-[103px] flex lg:flex-row flex-col s:items-center  justify-center relative lg:pl-[39px] lg:space-y-0 space-y-[47px] lg:mb-0 mb-[66px]">
              {board?.map((data, idx) => {
                const title = data.article_language.title;
                const summary = data.article_language.summary;
                const content = data.article_language.content;
                const contentImage = replaceAssetLink(
                  data.assets[0]?.asset_file
                );
                const contentDesc = replaceAssetLink(
                  data.assets[0]?.description
                );

                return (
                  <div
                    key={data.id}
                    className={clsxm("lg:w-full  flex  items-center  relative")}
                  >
                    <div className=" flex flex-col  gap-y-[18px]">
                      <div
                        className={clsxm(
                          idx === 0 ? "ml-[calc(121px-39px)]" : "ml-[31px]"
                        )}
                      >
                        <LazyLoadImage
                          className="h-auto w-auto"
                          src={contentImage}
                          alt={contentDesc || content}
                        />
                      </div>
                      <div
                        // className="absolute bottom-[32px] py-[12px] px-[21px] left-0"
                        className={clsxm(
                          "absolute bottom-[32px] py-[12px] px-[21px] left-0",
                          idx === 0 ? "w-[355px]" : "w-[250px]"
                        )}
                        style={{
                          background:
                            "linear-gradient(90deg, #83BB1F 25%, rgba(131, 187, 31, 0) 100%)",
                        }}
                      >
                        <p className="text-[16px] font-inter-bold">{title}</p>
                        <p className="text-[11px] font-inter-bold text-tbs-green">
                          {summary}
                        </p>
                        {content && (
                          <div className=" prose-p:text-[11px] prose-p:font-inter-bold text-white">
                            {PARSE(content)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="">
          <div className="section-width-new lg:mt-[23px] mt-[104px] flex items-center justify-center">
            <div className="px-[44px] py-[9px] border rounded-[20px] button-new ">
              <Link className="" to={`${boardLink?.path} ` ?? "/"}>
                {boardLink?.title}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BaseContainerPl>
  );
}

export default TBS_Trusted_Partner;
