/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PARSE from "html-react-parser";
import randomColor from "randomcolor";
import Api from "./Api";
import tw, { styled } from "twin.macro";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

export default function TBSAboutUsOurValues() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);
  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let headerText;
  let headerMedia = {};
  let visionArticle;
  let missionArticle;
  let valueArticle;

  let summary;
  let content;
  let readHere;
  let seeAllIcon;
  let sectionName;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents;
            headerText = text;
            break;
          case 1:
            const media = section.widget_contents[0];
            console.log(
              "🚀 ~ file: TBS_AboutUs_Our_Values.jsx:54 ~ sec.forEach ~ media:",
              media
            );
            headerMedia = {
              asset:
                replaceAssetLink(media?.file_small) ||
                replaceAssetLink(media?.asset_file),
              desc: media?.description,
            };

            break;
          case 2:
            const visArt = section.widget_contents[0];
            visionArticle = visArt?.article_language;
            break;
          case 3:
            const misArt = section.widget_contents;
            missionArticle = misArt;
            break;
          case 4:
            const valArt = section.widget_contents;
            valueArticle = valArt;
            break;
          default:
            break;
        }
      });
    }
  }

  const safeText =
    headerText && headerText.length > 0 ? headerText : [{ content: "" }];

  const borderColor = ["#004A98", "#61AFD7", "#D9D9D9", "#316A47", "#50966B"];

  const WrapperDiv = ({ index, children }) => {
    const BorderedDiv = styled.div`
      border-left: ${index < 5
        ? `5px solid ${borderColor[index]}`
        : `5px solid ${randomColor()}`};
      ${tw`flex space-x-6 space-y-5 items-center`}
    `;
    return <BorderedDiv>{children}</BorderedDiv>;
  };

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      <div className="section-width">
        <div className="">
          <h2 className="heading-title "> {safeText[0]?.content}</h2>
          <h3 className="heading-summary  "> {safeText[1]?.content}</h3>
          {content && (
            <article className="section-width mt-[45px] typo-style">
              {PARSE(content)}
            </article>
          )}
        </div>
        <img
          src={headerMedia?.asset}
          alt={headerMedia?.desc}
          className="w-full mt-[45px]"
        />
        <div className="mt-[45px]">
          <h4 className="font-inter-bold text-xl">{visionArticle?.title}</h4>
          {visionArticle?.content && (
            <article className="!mt-3 typo-style ">
              {PARSE(visionArticle?.content)}
            </article>
          )}
        </div>
        <div className="mt-[45px]">
          <h4 className="font-inter-bold text-xl">{safeText[2]?.content}</h4>
          {missionArticle?.map((mis) => {
            const misIcon = replaceAssetLink(mis?.assets[0].asset_file);
            const misTitle = mis?.article_language?.title;
            const misContent = mis?.article_language?.content;
            return (
              <div
                key={mis.id}
                className="flex space-x-6 space-y-3 items-center"
              >
                <img
                  src={misIcon}
                  alt={misTitle}
                  className="w-[48px] h-[48px]"
                />
                <div>
                  <h5 className="font-inter-bold">{misTitle}</h5>
                  {misContent && (
                    <article className="w-11/12 typo-style !mt-0">
                      {PARSE(misContent)}
                    </article>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-[45px] space-y-4">
          <h4 className="font-inter-bold text-xl">{safeText[3]?.content}</h4>
          {valueArticle?.map((mis, i) => {
            // const misIcon = mis?.assets[0].asset_file;
            const valTitle = mis?.article_language?.title;
            const valContent = mis?.article_language?.content;
            return (
              <WrapperDiv index={i} key={mis.id}>
                {/* <img src={misIcon} alt="" className="w-[48px] h-[48px]" /> */}
                <div className="pl-7">
                  <h5 className="font-inter-bold">{valTitle}</h5>
                  {valContent && (
                    <article className="w-11/12 typo-style !mt-0">
                      {PARSE(valContent)}
                    </article>
                  )}
                </div>
              </WrapperDiv>
            );
          })}
        </div>
      </div>
    </BaseContainerPl>
  );
}

