/* eslint-disable */

import React, { useState, useCallback, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { useSelector } from "react-redux";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { Link, useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Api from "./Api";
import GlobalApi from "../../context/api/GlobalApi";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import Skeleton from "@mui/material/Skeleton";

const OptionSplide = {
  type: "slide",
  rewind: "false",
  arrows: false,
  gap: "1.5rem",
  pagination: false,
  breakpoints: {
    623: {
      perPage: 1,
      perMove: 1,
    },

    800: {
      perPage: 2,
      perMove: 1,
    },

    1000: {
      perPage: 2,
      perMove: 1,
    },
    1024: {
      perPage: 3,
      perMove: 1,
    },
    1100: {
      perPage: 3,
      perMove: 1,
    },
    1150: {
      perPage: 3,
      perMove: 1,
    },
    1200: {
      perPage: 4,
      perMove: 1,
    },
    1600: {
      perPage: 4,
      perMove: 1,
    },
    1920: {
      perPage: 4,
      perMove: 1,
    },
    2560: {
      perPage: 4,
      perMove: 1,
    },
    100000: {
      perPage: 4,
      perMove: 1,
    },
  },
};

function sortArrayById(array, idOrder) {
  return array.sort((a, b) => idOrder.indexOf(a.id) - idOrder.indexOf(b.id));
}

export default function TBSNewsArticle() {
  const activeLang = useSelector((state) => state.content.activeLanguage);
  const location = useLocation();

  const [sections, setSections] = useState([]);
  const [text, setText] = useState([]);
  const [widgetId, setWidgetId] = useState();
  const [listCategoryArticle, setListCategoryArticle] = useState([]);
  const [articleCount, setArticleCount] = useState(0);
  const [page, setPage] = useState(1);
  const [optionYear, setOptionYear] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [fireApi, setFireApi] = useState(true);
  const [listCategory, setListCategory] = useState([]);
  const [filter, setFilter] = useState({
    category: "",
    year: "",
    submit: false,
  });
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [multiList, setMultiList] = useState(true);
  const [newsDetailPath, setNewsDetailPath] = useState("");
  const pageSize = 12;

  const count = Math.ceil(parseInt(articleCount, 10) / pageSize);

  const handleChange = (e, p) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPage(p);
    setFileLoaded(false);
    setFilter({ ...filter, submit: true });
    // setTimeout(() => setFileLoaded(true), 1000);
  };

  const getSectionsDetail = useCallback(() => {
    const api = new Api();
    api
      .getSectionDetail({ language: activeLang })
      .then((res) => {
        if (res.status === 200 && res.data.results) {
          const sectionFill = res.data.results;
          // setArticleCount(res.data.count);
          setSections(sectionFill);
          if (sectionFill) {
            const sec = sectionFill[0]?.widgets;
            setOptionYear(sec[0]?.article_years);
            setText(sectionFill[0]?.widgets[1]);
            if (sec && sec.length !== 0) {
              setListCategory(sec[0]?.article_categories);
              setSections(sec);
              setWidgetId(sec[0].id);
              setNewsDetailPath(
                sec[2]?.widget_contents[0]?.article_language?.path
              );
            }
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    // console.log(response);
  }, [activeLang]);

  useEffect(() => {
    getSectionsDetail();
    // setTimeout(() => setFileLoaded(true), 1500);
  }, [getSectionsDetail]);

  const listIdCategory = listCategory?.map((cat) => `&id=${cat.id}`);

  const getAllArticles = useCallback(() => {
    const api = new GlobalApi();
    if (listIdCategory) {
      try {
        const catId = listIdCategory.join("");
        const fetchGetGroupArticle = api.getGroupArticle({
          widget: widgetId,
          category: catId,
          limit: 8,
        });

        fetchGetGroupArticle.then((res) => {
          setListCategoryArticle(
            sortArrayById(res?.data?.results.reverse(), [17, 63, 70, 18])
          );
          res?.data?.results &&
            res?.data?.results.length > 0 &&
            setFileLoaded(true);
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, [listIdCategory, widgetId]);

  const getSingle = useCallback(() => {
    const api = new GlobalApi();
    if (filter.submit) {
      api
        .getArticleDetailByYearDescendingPagination(
          widgetId,
          filter.category,
          pageSize,
          "",
          page,
          filter.year
        )
        .then((res) => {
          setFilteredArticles(res.data?.results);
          setArticleCount(res?.data?.count);
          res?.data?.results &&
            res?.data?.results.length > 0 &&
            setFileLoaded(true);
          setFilter({ ...filter, submit: false });
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    // console.log(response);
  }, [filter.category, filter.year, page, widgetId, filter.submit]);

  useEffect(() => {
    if (filter.submit || page > 1) {
      getSingle();
    }
  }, [filter.submit, getSingle, page]);

  useEffect(() => {
    if (listIdCategory.length > 0 && multiList) {
      getAllArticles();
    }
  }, [listCategory, listIdCategory.length, multiList]);

  const onChangeCategorySeeAll = (name) => {
    setMultiList(false);
    const getIdCategory = listCategory.filter((list) => list?.name === name);
    setFileLoaded(false);
    // setTimeout(() => setFileLoaded(true), 500);
    setPage(1);
    setFilter({ category: getIdCategory[0]?.id, year: "", submit: true });
  };

  const safeText =
    text?.widget_contents && text?.widget_contents.length > 0
      ? text?.widget_contents
      : [{ content: "" }];

  function renderMultiCategoryListArt(list) {
    return (
      <div className="justify-start text-left pt-10 w-full">
        <div className="flex justify-between">
          <h2 className="font-inter-bold capitalize text-[28px] ">
            {list?.name}
          </h2>
          <button
            type="button"
            onClick={() => onChangeCategorySeeAll(list?.name)}
            className="font-inter-bold text-[16px] cursor-pointer"
          >
            {safeText[2]?.content}
          </button>
        </div>
        <Splide className="md:pt-[35px] pt-[15px]" options={OptionSplide}>
          {list?.articles?.map((art) => {
            return (
              <SplideSlide key={art.id} className="group">
                <div key={art.id} title="" className=" ">
                  <div className="">
                    <div className="overflow-hidden shadow-lg">
                      <LazyLoadImage
                        title={art?.article_assets[0]?.asset?.description}
                        src={
                          replaceAssetLink(
                            art?.article_assets[0]?.asset?.thumbnail_file
                          ) ||
                          replaceAssetLink(
                            art?.article_assets[0]?.asset?.file_small
                          )
                        }
                        alt={art?.article_assets[0]?.asset?.description}
                        className="drop-shadow-lg object-cover transition duration-700 ease-in-out group-hover:scale-110"
                        width="819"
                        height="461"
                      />
                    </div>
                    <div className="space-y-2 mt-5">
                      <div className="text-left text-sm font-inter">
                        {" "}
                        {moment(art.date).format(" MMMM D, YYYY")}
                      </div>
                      {/* <div className="text-[#2F9967] font-inter-bold">{art?.categories[0]?.name}</div> */}
                      {art?.article_assets[0]?.asset?.thumbnail_file ? (
                        <a
                          href={replaceAssetLink(
                            art?.article_assets[0]?.asset?.asset_file
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <div className=" font-inter-bold h-full text-lg  text-left line-clamp-2 ">
                            {art?.article_languages?.title}
                          </div>
                        </a>
                      ) : (
                        <Link
                          className="cursor-pointer"
                          to={
                            `${newsDetailPath}/${art?.id}/${art?.article_languages?.slug_title} ` ??
                            "/"
                          }
                        >
                          <div className=" font-inter-bold h-full text-lg  text-left line-clamp-2">
                            {art?.article_languages?.title}
                          </div>
                        </Link>
                      )}
                      {/* <Link className="cursor-pointer" to={`${newsDetailPath}/${art?.id}/${art?.article_languages?.slug_title} ` ?? '/'}>
                        <TruncateMarkup lines={2}>
                          <div className=" font-inter-bold h-full text-lg  text-left">{art?.article_languages?.title}</div>
                        </TruncateMarkup>
                      </Link> */}
                    </div>
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
    );
  }

  function renderSingleCategoryListArt(list) {
    return (
      <div key={list.id} title="" className=" group overflow-hidden">
        <div className="">
          <div className="overflow-hidden shadow-lg">
            {list?.article_assets && list?.article_assets.length > 0 && (
              <LazyLoadImage
                title={list?.article_assets[0]?.asset?.description}
                src={
                  replaceAssetLink(
                    list?.article_assets[0]?.asset?.thumbnail_file
                  ) ||
                  replaceAssetLink(list?.article_assets[0]?.asset?.file_small)
                }
                alt={list?.article_assets[0]?.asset?.description}
                className="drop-shadow-lg object-cover transition duration-700 ease-in-out group-hover:scale-110"
                width="819"
                height="461"
              />
            )}
          </div>
          <div className="space-y-2 mt-5">
            <div className="text-left text-sm font-inter">
              {" "}
              {moment(list.date).format(" MMMM D, YYYY")}
            </div>
            {/* <div className="text-[#2F9967] font-inter-bold">{art?.categories[0]?.name}</div> */}
            {list?.article_assets[0]?.asset?.thumbnail_file ? (
              <a
                href={replaceAssetLink(
                  list?.article_assets[0]?.asset?.asset_file
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <div className=" font-inter-bold h-full text-lg  text-left line-clamp-2">
                  {list?.article_languages?.title}
                </div>
              </a>
            ) : (
              <Link
                className="cursor-pointer"
                to={
                  `${newsDetailPath}/${list?.id}/${list?.article_languages?.slug_title} ` ??
                  "/"
                }
              >
                <div className=" font-inter-bold h-full text-lg  text-left line-clamp-2">
                  {list?.article_languages?.title}
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }

  const onChangeCategory = (event) => {
    return setFilter({ ...filter, category: event.target.value });
  };
  const onChangeYear = (event) => {
    setFilter({ ...filter, year: event.target.value });
  };

  const reversCategoryList = sortArrayById(listCategory, [17, 63, 70, 18]);

  const backupYear = optionYear.sort((a, b) => b - a);

  return (
    <div className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1auto] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto  lg:mt-28 mt-16  relative">
      <div className="!justify-start md:space-x-6 md:space-x-3 space-x-0 md:space-y-0 space-y-3 flex md:flex-row flex-col items-center">
        <div className="">
          <select
            name=""
            className="h-fit w-[auto] border-black border bg-white px-[10px] py-[10px] capitalize font-inter"
            id=""
            onChange={(e) => onChangeCategory(e)}
            value={filter.category}
          >
            <option value="" className="capitalize">
              {safeText[0]?.content}
            </option>
            {reversCategoryList?.map((opt) => (
              <option key={opt.id} value={opt.id} className="capitalize">
                {opt.name}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <select
            name=""
            className="h-fit w-[auto] border-black border bg-white px-[10px] py-[10px] capitalize font-inter"
            id=""
            onChange={(e) => onChangeYear(e)}
            value={filter.year}
          >
            <option value="" className="capitalize">
              {safeText[1]?.content}
            </option>
            {backupYear?.map((y) => (
              <option key={y} value={y} className="capitalize">
                {y}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => {
              setPage(1);
              if (filter.category || filter.year) {
                setMultiList(false);
              } else setMultiList(true);

              return (
                setFileLoaded(false),
                // setTimeout(() => setFileLoaded(true), 1500),
                setFilter({
                  category: filter.category,
                  year: filter.year,
                  submit: true,
                })
              );
            }}
            type="button"
            className="w-[auto] p-2 bg-[#004a98] text-white font-inter-bold"
          >
            {safeText[3]?.content}
          </button>
        </div>
      </div>
      {fileLoaded ? (
        <div className="">
          {!multiList && (
            <div className="justify-start text-left pt-10 w-full">
              <div className=" capitalize font-inter-bold text-[28px] mb-[35px]">
                {" "}
                {listCategory?.map((opt) => {
                  let name;
                  if (opt?.id?.toString() === filter.category.toString()) {
                    return (name = opt.name);
                  }
                  return name;
                })}
              </div>
              <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 s:grid-cols-2 grid-cols-1 gap-[1.4rem]">
                {filteredArticles?.map((list) =>
                  renderSingleCategoryListArt(list)
                )}
              </div>
            </div>
          )}
          {multiList &&
            listCategoryArticle?.map((list) =>
              renderMultiCategoryListArt(list)
            )}

          {!multiList && (
            <div className="mt-[45px] flex items-center justify-end gap-x-5">
              <Pagination
                count={count}
                color="primary"
                size="medium"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="pt-10">
          <div className="flex justify-between">
            <Skeleton width="200px" />
          </div>
          <div className="mt-[45px] h-[50vh] grid lg:grid-cols-4 md:grid-cols-3 s:grid-cols-2 grid-cols-1  gap-[1.5rem]">
            {Array.from({ length: 12 }, (_, index) => (
              <Box>
                <Skeleton
                  key={index}
                  variant="rect"
                  width="100%"
                  height={150}
                />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton width="60%" />
                  <Skeleton />
                </Box>
              </Box>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
