/* eslint-disable max-len */
import React from 'react';

export default function Search({ fill = '#ffffff' }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0495 11.9567L17 15.9072L15.9072 17L11.9567 13.0495C10.6957 14.0703 9.0897 14.6818 7.34091 14.6818C3.28664 14.6818 0 11.3952 0 7.34091C0 3.28664 3.28664 0 7.34091 0C11.3952 0 14.6818 3.28664 14.6818 7.34091C14.6818 9.0897 14.0703 10.6957 13.0495 11.9567ZM7.34091 13.1364C10.5416 13.1364 13.1364 10.5416 13.1364 7.34091C13.1364 4.14017 10.5416 1.54545 7.34091 1.54545C4.14017 1.54545 1.54545 4.14017 1.54545 7.34091C1.54545 10.5416 4.14017 13.1364 7.34091 13.1364Z"
        fill={fill}
      />
    </svg>
  );
}
