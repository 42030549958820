/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';

import MotionDiv from './MotionDiv';
import styles from '../TBS_Menu.module.css';
import ItemMobile from './ItemMobile';

const itemVariants = {
  closed: { opacity: 0 },
  open: { opacity: 1 },
};

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0,
      staggerDirection: -1,
      duration: 0.1,
    },
  },
  open: {
    transition: {
      // staggerChildren: 0.1,
      // staggerDirection: 1,
      duration: 0.2,
    },
  },
};

function Item({ styleItem = 'border-b', children }) {
  return (
    <motion.div variants={itemVariants} className={['py-2 pl-0 pr-4 flex items-center border-black border-opacity-30', styleItem].join(' ')}>
      {children}
    </motion.div>
  );
}

export default function MobileMenuChild({ item, goBack, setPagination, pagination, renderChildItem, closeMenu }) {
  const [activeItem, setActiveItem] = useState(false);

  const total = pagination.length;
  const renderPagination = pagination.map((itm, i) => {
    const config = {
      htmlBreadcrumb: <ArrowBackIos fontSize="14" style={{ color: 'red' }} onClick={goBack} />,
      styleItem: 'border-b',
    };
    if (total > 1 && i === total - 1) {
      config.htmlBreadcrumb = <div />;
      config.styleItem = 'border-0';
    }
    return (
      <Item key={itm} styleItem={config.styleItem}>
        {config.htmlBreadcrumb}
        <span className={[styles.menuTitleM, 'w-full', item.children?.length > 0 && 'mr-2', config.styleItem === 'border-0' && 'text-black opacity-70'].join(' ')}>{itm}</span>
      </Item>
    );
  });

  return (
    <div className="w-full h-full absolute top-0">
      <AnimatePresence>
        <MotionDiv styles={{ width: '100%' }}>
          <motion.div className="container bg-white" initial="closed" animate="open" exit="closed" variants={sideVariants}>
            <div className="w-full h-[100vh] flex flex-col">
              {renderPagination}
              {item.children?.length > 0 &&
                item.children.map((itm) => (
                  <Item key={itm.id} itm={item.title}>
                    <ItemMobile itm={itm} closeMenu={closeMenu} />
                    {itm.children?.length > 0 && (
                      <ArrowForwardIos
                        fontSize="14"
                        style={{ color: 'red' }}
                        onClick={() => {
                          setPagination([...pagination, itm.title]);
                          setActiveItem(itm);
                        }}
                      />
                    )}
                  </Item>
                ))}
              {activeItem && (
                <MobileMenuChild
                  item={activeItem}
                  title={activeItem.title}
                  goBack={() => {
                    setActiveItem(false);
                    setPagination(pagination.filter((e) => e !== activeItem.title));
                  }}
                  setPagination={setPagination}
                  pagination={pagination}
                  closeMenu={closeMenu}
                />
              )}
            </div>
          </motion.div>
        </MotionDiv>
      </AnimatePresence>
    </div>
  );
}
