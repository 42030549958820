// /* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Api from './Api';
import { getSectionTag } from '../../context/store/slices/sectionSlice';

export default function TBS_Spacer() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api
      .getSectionDetail({ language: activeLang })
      .then((res) => {
        setSections(res.data.results);
      })
      .catch((err) => {
        console.log('err', err);
      });
    // console.log(response);
  }, [activeLang]);

  useEffect(() => {
    getSectionDetail();
  }, [getSectionDetail]);

  let sectionName;
  if (sections) {
    sectionName = sections[0]?.slug_name;
  }

  // strat -> store active section to redux
  const dispatch = useDispatch();
  const boxRef = useRef();

  const [position, setPosition] = useState();

  const getPosition = useCallback(() => {
    const watchPosition = boxRef.current.getBoundingClientRect();
    setPosition(watchPosition);
  }, []);

  useEffect(() => {
    getPosition();
  }, [getPosition]);

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', getPosition, { passive: true });
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', getPosition);
    };
  }, [getPosition]);

  useEffect(() => {
    if (position?.top < 180) {
      dispatch(getSectionTag({ isTagData: sectionName }));
    }
  }, [dispatch, sectionName, position?.top]);
  // end -> store active section to redux

  return (
    <div ref={boxRef} id={sectionName} className="scrollTop invisible">
      TBS_Spacer
    </div>
  );
}
