/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PARSE from 'html-react-parser';
import { Link } from 'react-router-dom';
import Api from './Api';
import GlobalApi from '../../context/api/GlobalApi';
import BaseArrowLinkTw from '../../context/components/Base/BaseArrowLinkTw';
// import image from './assets/image-1.png';
import Styles from './TBS_Leadership.module.css';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';
import replaceAssetLink from '../../context/utils/replaceAssetLink';

function TBS_Leadership() {
  const [section, setSection] = useState([]);
  const [article, setArticle] = useState([]);
  const [slugName, setSlugName] = useState([]);
  const [idSection, setIdSection] = useState();

  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const FetchData = async () => {
      const api = new Api();
      const globalApi = new GlobalApi();
      try {
        const sectionResponse = await api.getSectionDetail({
          language: activeLang,
        });
        const sectionData = await sectionResponse.data;
        const { index } = await sectionData;
        const sectionId = await sectionData.results[0].slug_name;
        const sectionWidgets = sectionData.results[0].widgets;
        const sections = sectionWidgets.filter((idx) => idx.is_internal !== false);

        if (sectionWidgets) {
          const sec = sectionWidgets.filter((data) => data.is_internal === false);
          const articleResponseDirectors = await globalApi.getArticleAllLangFalse({ widgets: sec[0].id });
          const articleResponseCommissioners = await globalApi.getArticleAllLangFalse({ widgets: sec[1].id });

          const articleDataDirectors = await articleResponseDirectors.data.results;
          const articleDataCommissioners = await articleResponseCommissioners.data.results;
          setArticle([articleDataDirectors, articleDataCommissioners]);
        } else {
          throw new Error('Error trying to get property result');
        }

        setSlugName(sectionId);
        setSection(sections);
        setIdSection(index);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [activeLang]);

  const title = [];
  const position = [];
  let ReadMoreText;
  if (section) {
    section?.forEach((data, idx) => {
      const { widget_contents } = data;

      if (idx === 0) {
        widget_contents?.forEach((txt) => {
          title.push(txt.content);
        });
      }
      if (idx === 1) {
        widget_contents?.forEach((txt) => {
          position.push(txt.content);
        });
      }
      if (idx === 2) {
        ReadMoreText = widget_contents[0]?.content;
      }
    });
  }

  return (
    <BaseContainerPl sectionName={slugName} sectionIndex={section && section.length > 0 && section[0].index}>
      <div className="section-width">
        <div className="mb-[45px]">
          <h2 className="heading-title">{title[0]}</h2>
          {title[1] && <h3 className="heading-sub-title">{title[1]}</h3>}
        </div>
        {position &&
          position.map((pos, posId) => (
            <div key={pos.id} className="flex flex-col gap-y-[42px]">
              <div className="text-[18px] font-inter-bold">
                <p>{pos}</p>
              </div>
              {article &&
                article?.map((list, listId) => {
                  return (
                    posId === listId && (
                      <div key={list.id} className="h-auto grid grid-cols-1 s:grid-cols-2 gap-x-[30px] gap-y-12 mb-20">
                        {list.map((data) => {
                          const image = replaceAssetLink(data?.article_assets[0]?.asset?.file_small);
                          const articles = data?.article_languages;
                          const name = articles?.title;
                          const slugTitle = articles?.slug_title;
                          const occ = articles?.summary;
                          const idArticle = articles?.article;
                          // const path = articles[0]?.path;

                          return (
                            <div key={data.id} className={`w-full h-auto flex flex-col gap-y-4 justify-between group ${Styles.card}`}>
                              <div className={`w-full h-[250px] bg-center bg-cover overflow-hidden object-cover ${Styles['card-img']}`}>
                                <img
                                  className="w-full h-[250px] transition duration-700 ease-in-out object-cover bg-cover group-hover:scale-[1.1]"
                                  src={image}
                                  alt={name}
                                  width={331}
                                  height={250}
                                />
                              </div>
                              <div className="w-full h-[105px] mdl:h-[105px] flex flex-col justify-between">
                                <div className={Styles.name}>
                                  <h2>{name}</h2>
                                </div>
                                <div className={Styles.position}>{occ && PARSE(occ)}</div>
                                <div className="flex h-[20px] mr-3 w-full">
                                  <Link className="flex gap-x-3" to={`${pos.toLowerCase().replaceAll(' ', '-')}/${idArticle}/${slugTitle} ` ?? '/'}>
                                    <p className={Styles.more}>{ReadMoreText}</p>
                                    <div className="mt-[3px]">
                                      <BaseArrowLinkTw size="small" color="black" />
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )
                  );
                })}
            </div>
          ))}
      </div>
    </BaseContainerPl>
  );
}

export default TBS_Leadership;
