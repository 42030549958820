/* eslint-disable */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PARSE from "html-react-parser";
import moment from "moment";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import _ from "lodash";
import { getSectionTag } from "../../context/store/slices/sectionSlice";
import Api from "./Api";
import { Box, Skeleton } from "@mui/material";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

export default function TBSArticleFilterYear() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const [listArticle, setListArticle] = useState([]);
  const [yearSelected, setYearSelected] = useState();
  const [filteredArlicle, setFilteredArlicle] = useState();
  const [activeYear, setActiveYear] = useState();
  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData.data.results;
    setListArticle(sectionsData[0]?.widgets);

    // console.log(sectionsData.data.results);
    setSections(sectionsData);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let name;
  let yearText;
  let sectionName;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0: {
            const header = section.widget_contents[0];
            name = header?.name;
            title = header?.article_language?.title;
            summary = header?.article_language?.summary;
            content = header?.article_language?.content;
            // media = text?.assets[0].file_medium;

            break;
          }
          case 2:
            const text = section.widget_contents[0];
            yearText = text?.content;
            break;
          case 3:
            break;
          default:
            break;
        }
      });
    }
  }

  const groupedByDate = _.groupBy(
    listArticle[1]?.widget_contents,
    function (item) {
      const dateMoment = moment(item.date);
      return dateMoment.day(String).format("YYYY");
    }
  );

  const finalArticle = Object.entries(groupedByDate).map((obj) => ({ ...obj }));

  const yearList = finalArticle?.map((art) => {
    return {
      name: art[0],
      count: art[1].length,
    };
  });

  const artLis = finalArticle?.map((art) => art[1]);

  useEffect(() => {
    if (listArticle) {
      if (yearList.length > 0) {
        const getLastYear = yearList?.length - 1;
        setYearSelected(yearList[getLastYear].name);
        if (yearSelected) {
          const trans = artLis.filter((t) => {
            if (t[0]?.date?.toLowerCase().includes(yearSelected)) {
              return t;
            }
            return false;
          });
          setFilteredArlicle(trans[0]);
        }
      }
    }
  }, [listArticle, yearSelected]);

  const onChangeArticle = (event) => {
    setActiveYear(event);
    if (event) {
      const trans = artLis?.filter((t) => {
        if (t[0]?.date.toLowerCase().includes(event)) {
          return t;
        }
        return false;
      });

      if (trans.length) {
        setFilteredArlicle(trans[0]);
      }
    }
  };

  const reverseYear = _.orderBy(yearList, "name").reverse();

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      {sections.length > 0 ? (
        <div className="section-width">
          <h2 className="heading-title"> {title}</h2>
          <h3 className="heading-summary"> {summary}</h3>
          <div className="margin-content">
            {/* <div className="">{icon && <img src={icon} alt="" className=" mb-3 w-full" />}</div> */}
            {content && <div className="typo-style">{PARSE(content)}</div>}
          </div>
          <div className="flex  md:space-x-10 space-x-3 ">
            <div className=" w-[60px] ">
              <div className="px-3 font-inter-bold uppercase text-xs mb-2">
                {yearText}
              </div>
              <ul className="max-h-[400px] overflow-y-auto">
                {reverseYear.map((year, i) => (
                  <li
                    key={year.name}
                    type="button"
                    onClick={() => onChangeArticle(year.name)}
                    className={`${
                      activeYear?.toString() === year.name.toString() ||
                      (i === 0 && !activeYear)
                        ? "bg-[#F5F5F5]  px-2 mb-2 border-l-4 border-[#004A98] relative cursor-pointer"
                        : "mb-2 px-3 text-[#636363] relative cursor-pointer"
                    }`}
                  >
                    {year.name}
                    {/* <span className="absolute right-2 ">{` (${year.count})`}</span> */}
                  </li>
                ))}
              </ul>
            </div>
            <div className="!w-9/12">
              <Splide
                className="custom-splide !h-fit"
                options={{
                  type: "slide",
                  rewind: "false",
                  arrows: false,
                  perPage: 1,
                  perMove: 1,
                  gap: "1rem",
                }}
              >
                {filteredArlicle?.map((article) => (
                  <SplideSlide key={article.id} className="rounded-md ">
                    <div className="">
                      <div className="w-full ">
                        <img
                          src={
                            replaceAssetLink(article?.assets[0].file_small) ||
                            replaceAssetLink(article?.assets[0]?.asset_file)
                          }
                          alt={
                            article?.assets[0].description ||
                            article?.assets[0].name
                          }
                          className="w-full  max-h-[260px]  object-cover"
                        />
                      </div>
                      {article?.article_language.content && (
                        <div className="!mt-[25px] typo-style ">
                          {PARSE(article.article_language.content)}
                        </div>
                      )}
                    </div>
                    {/* <div className="w-3/12">test</div> */}
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="section-width">
          <div className="">
            <Skeleton width="60%" />
            <Skeleton />
            <Skeleton />
            <div className="flex w-full space-x-8 mt-[45px]">
              <div className="w-2/12">
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
              <div className="w-10/12 ">
                <Skeleton variant="rectangular" width="100%" height={118} />
              </div>
            </div>
          </div>
        </Box>
      )}
    </BaseContainerPl>
  );
}

