import * as React from 'react';
import { styled } from '@mui/material/styles';
import PARSE from 'html-react-parser';
import { Link } from 'react-router-dom';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BaseArrowLinkTw from './BaseArrowLinkTw';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(() => ({
  marginBottom: '12px',
  borderBottom: '1.2px solid black',
  borderRadius: '0',
  '&::before': { backgroundColor: 'transparent!important' },
  '&:last-of-type': { borderRadius: '0' },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ArrowRightIcon style={{ color: 'black' }} />} {...props} />)(() => ({
  minHeight: '0',
  alignItems: 'inherit',
  padding: '0',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotate(90deg)', marginBottom: '12px' },
  '& .MuiAccordionSummary-expandIconWrapper': { height: 'fit-transform!important' },
  '& .MuiAccordionSummary-content': { marginTop: '0' },
  '& .MuiTypography-root': {
    width: '150px',
    // '@media (max-width: 374px)': { width: '150px' },
    // '@media (max-width: 375px)': { width: '280px' },
    '@media (min-width: 376px)': { width: '320px' },
    '@media (min-width: 768px)': { width: '450px' },
    '@media (min-width: 1024px)': { width: '350px' },
    '@media (min-width: 1440px)': { width: '455px' },
  },
}));

function BaseAccordion({ data }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {data &&
        data.map((val, index) => {
          return (
            <Accordion key={val.id} expanded={expanded === index} onChange={handleChange(index)}>
              <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography sx={{ width: '50%', flexShrink: 0 }}>{(val && val.article_language.title) ?? ''}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{(data && PARSE(val?.article_language?.content)) ?? ''}</Typography>
                {val.article_language?.path ? (
                  <Link to={`${val.article_language?.path}` ?? '/'}>
                    <Typography sx={{ marginTop: '10px', display: 'flex', columnGap: '0.75rem', fontWeight: 600 }}>
                      {(val && PARSE(val.article_language?.summary)) ?? ''}{' '}
                      <div className="mt-[3px]">
                        <BaseArrowLinkTw size="small" color="black" />
                      </div>
                    </Typography>
                  </Link>
                ) : (
                  ''
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
}

export default BaseAccordion;
