/** @jsxImportSource @emotion/react */
/* eslint-disable */

import { ButtonBase, Popover } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/react";
import Whistle from "./assets/Button.svg";
import { Link } from "react-router-dom";
import MenuContext from "./MenuContext";
import styles from "./TBS_Menu.module.css";
import logoColor from "./assets/logo-tbs-color.svg";
import logoWhite from "./assets/logo-tbs-white.svg";
import Globe from "./assets/Globe";
import Search from "./assets/Search";
import Expand from "./assets/Expand";
import ActiveItem from "./components/ActiveItem";
import Color from "../../context/constants/Color";
import MenuSearch from "./components/MenuSearch";
import { Fade, Reveal } from "react-reveal";
import { NavHashLink } from "react-router-hash-link";

export default function DesktopMenu() {
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.content.languages);
  const activeLanguage = useSelector((state) => state.content.activeLanguage);
  const menuContext = useContext(MenuContext);
  const { data, isSearch, menu } = menuContext;
  const [activeItem, setActiveItem] = useState({
    id: null,
    open: false,
    data: null,
    count: 0,
  });
  const [activeFill, setActiveFill] = useState(Color.white);
  const [activeLogo, setActiveLogo] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isReaveal, setIsReaveal] = useState(false);
  const [isShowingDeepItem, setIsShowingDeepItem] = useState(false);
  const [isInitialize, setIsInitialize] = useState(false);
  const [heightDraw, setHeightDraw] = useState(0);
  const [whistleHovered, setWhistleHovered] = useState(false);
  const [isFade, setIsFade] = useState(false);

  const mainVariants = {
    hidden: { height: 0, top: -100, opacity: 0 },
    show: {
      height: 120,
      top: 0,
      opacity: 1,
      transition: { delay: 0, duration: 0.5, ease: "easeInOut" },
    },
  };

  const itemVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { delay: 0.5, duration: 0.5, ease: "easeInOut" },
    },
    exit: {
      opacity: 0,
      transition: { delay: 0, duration: 0.1, ease: "easeInOut" },
    },
  };

  const menuVariants = {
    initial: { height: 0, top: -464, opacity: 0 },
    animate: {
      height: heightDraw,
      top: 0,
      opacity: 1,
      transition: { delay: 0, duration: 0.5, ease: "easeInOut" },
    },
    exit: {
      top: -464,
      opacity: 0,
      height: 0,
      transition: { delay: 0, duration: 0.5, ease: "linear" },
    },
  };

  const hoverVariants = {
    initial: { width: 0 },
    animate: {
      transition: { width: "auto", duration: 0.5, ease: "easeInOut" },
    },
    exit: {
      width: 0,
      transition: { delay: 0, duration: 0.1, ease: "easeInOut" },
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setIsFade(true);
    }, 50);
    if (
      (data && isScrolling) ||
      (!isScrolling &&
        data &&
        activeItem.open &&
        activeFill !== Color.blackPrimary)
    ) {
      setTimeout(() => {
        setActiveFill(Color.blackPrimary);
        setActiveLogo(data.logo[2] ? data.logo[2].file : logoColor);
      }, 100);
    }
  }, [isScrolling, data, activeItem.open, activeFill]);

  useEffect(() => {
    if (
      !isScrolling &&
      data &&
      !activeItem.open &&
      activeFill === Color.blackPrimary
    ) {
      setTimeout(() => {
        setActiveFill(Color.white);
        setActiveLogo(
          data.logo[1] ? data.logo[1].file : data.logo[2].file || logoWhite
        );
      }, 100);
    }
  }, [isScrolling, data, activeItem.open, activeFill]);

  useEffect(() => {
    if (!isInitialize && data) {
      if (window.scrollY > 0) {
        setIsScrolling(true);
      } else {
        setActiveLogo(
          data.logo[1] ? data.logo[1].file : data.logo[2].file || logoWhite
        );
      }
      setIsInitialize(true);
    }
  }, [isInitialize, data]);

  //* Scrolling event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && !isScrolling) {
        setIsScrolling(true);
      }
      if (window.scrollY === 0) {
        setIsScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolling]);

  // sample set default selected menu item
  // menuContext.menu?.forEach((el, i) => {
  //   if (!activeItem && i === 1) {
  //     setActiveItem(el);
  //   }
  // });

  const handleActiveMenu = ({ id, open, data }) => {
    setIsReaveal(false);
    if (data.title.toLowerCase() === "about us" || id === 227) {
      setHeightDraw(600);
    }
    if (data.title.toLowerCase() === "business" || id === 465) {
      setHeightDraw(540);
    }
    if (data.title.toLowerCase() === "sustainability" || id === 480) {
      setHeightDraw(540);
    }
    if (data.title.toLowerCase() === "governance" || id === 500) {
      setHeightDraw(500);
    }
    if (data.title.toLowerCase() === "investor" || id === 523) {
      setHeightDraw(500);
    }
    if (data.title.toLowerCase() === "news and media" || id === 535) {
      setHeightDraw(300);
    }
    if (data.title.toLowerCase() === "career" || id === 541) {
      setHeightDraw(470);
    }
    setIsShowingDeepItem(false);
    setActiveItem({ id, open: !activeItem.open, data, count: 1 });
    if (activeItem.id === id && activeItem.open === true) {
      setActiveItem({ id: null, open: false });
    } else setActiveItem({ id, open: true, data });
    setTimeout(() => {
      setIsReaveal(true);
    }, 200);
  };

  const handleClickLang = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLang = () => {
    setAnchorEl(null);
  };

  const openLang = Boolean(anchorEl);
  const id = openLang ? "simple-popover" : undefined;

  const isScrollActive = activeFill === Color.blackPrimary;
  const itmActive = css`
    &:after {
      background: ${isScrollActive ? Color.blackPrimary : Color.white};
    }
  `;

  return (
    <>
      <div
        id="tbs-navigation"
        className="relative w-full h-full"
        onMouseLeave={() => setActiveItem(false)}
      >
        <AnimatePresence>
          {data && isInitialize && activeLogo && (
            <>
              <motion.div
                style={{
                  backgroundColor: isScrollActive ? Color.white : "transparent",
                }}
                className={[
                  styles.dsktpContainer,
                  "w-full h-[120px] pt-[50px] z-[10000] ",
                  isScrollActive && !isSearch
                    ? styles.dsktpContainerScroll
                    : styles.gradientBg,
                  isSearch && styles.dsktpContainerSearch,
                ].join(" ")}
                initial="hidden"
                animate="show"
                variants={mainVariants}
              >
                <div
                  className={[
                    `section-container relative h-full z-[10000]
             `,
                    activeItem.open && styles.dsktpContainerActive,
                  ].join(" ")}
                >
                  <div className="flex justify-between">
                    <div className="h-full flex items-center space-x-16 ">
                      <Link to="/" onClick={() => setActiveItem(false)}>
                        <div className="flex items-center w-[96px]">
                          {/* {data.logo[0]?.file && <img src={data?.logo[0]?.file_small} className="pb-[25px]" alt="tbs logo" />} */}
                          {isScrollActive ? (
                            <img
                              src={
                                isSearch
                                  ? data?.logo[0]?.file_small
                                  : data?.logo[1]?.file_small
                              }
                              className="mb-[25px]  w-[96px] h-[42px] -ml-[9px]"
                              alt="tbs logo"
                            />
                          ) : (
                            <img
                              src={
                                !isSearch
                                  ? data?.logo[0]?.file_small
                                  : data?.logo[1]?.file_small
                              }
                              className="mb-[25px]  w-[96px] h-[42px] -ml-[9px]"
                              alt="tbs logo"
                            />
                          )}
                          {/* <img src={!isSearch ? data?.logo[0]?.file_small : data?.logo[1]?.file_small} className="pb-[25px]" alt="tbs logo" /> */}
                        </div>
                      </Link>
                      <div className=" flex items-center space-x-8  ">
                        {menu?.map((itm) => {
                          const contentHtml = (
                            <span
                              style={{
                                color: !isSearch
                                  ? activeFill
                                  : Color.blackPrimary,
                              }}
                              className={[styles.dsktpItm, "pb-[25px]"].join(
                                " "
                              )}
                            >
                              {itm.title}
                            </span>
                          );
                          const contentLink = (
                            <Link
                              to={itm.url || "/"}
                              style={{
                                color: !isSearch
                                  ? activeFill
                                  : Color.blackPrimary,
                              }}
                              className={[styles.dsktpItm, "pb-[25px]"].join(
                                " "
                              )}
                            >
                              {itm.title}
                            </Link>
                          );
                          let html =
                            itm.id === 535 || itm.id === 480 ? (
                              <ButtonBase
                                key={itm.title}
                                css={itmActive}
                                className={[
                                  "h-[70px] !pb-[65pyx]  flex items-center ",
                                  styles.btnDsktpItm,
                                  activeItem?.data?.title === itm.title &&
                                    styles.dsktpItmActive,
                                  !activeItem?.data?.title &&
                                    isSearch &&
                                    styles.dsktpItmActiveS,
                                ].join(" ")}
                                // onClick={() => {
                                //   if (itm.children) {
                                //     menuContext.setIsSearch(false);
                                //     handleActiveMenu({
                                //       id: itm.id,
                                //       open: true,
                                //       data: itm,
                                //     });
                                //   }
                                // }}
                                disableRipple
                              >
                                {contentLink}
                              </ButtonBase>
                            ) : (
                              <ButtonBase
                                key={itm.title}
                                css={itmActive}
                                className={[
                                  "h-[70px] !pb-[65pyx]  flex items-center ",
                                  styles.btnDsktpItm,
                                  activeItem?.data?.title === itm.title &&
                                    styles.dsktpItmActive,
                                  !activeItem?.data?.title &&
                                    isSearch &&
                                    styles.dsktpItmActiveS,
                                ].join(" ")}
                                onClick={() => {
                                  if (itm.children) {
                                    menuContext.setIsSearch(false);
                                    handleActiveMenu({
                                      id: itm.id,
                                      open: true,
                                      data: itm,
                                    });
                                  }
                                }}
                                disableRipple
                              >
                                {contentHtml}
                              </ButtonBase>
                            );
                          // if (itm.url) {
                          //   html = (
                          //     <Link
                          //       to={itm.url}
                          //       key={itm.title}
                          //       css={itmActive}
                          //       className={[
                          //         'h-full !pb-[25px] flex items-center',
                          //         styles.btnDsktpItm,
                          //         activeItem?.data && activeItem?.data?.title === itm.title && styles.dsktpItmActive,
                          //         !activeItem && isSearch && styles.dsktpItmActiveS,
                          //       ].join(' ')}
                          //       // onClick={() => (itm.children ? setActiveItem(itm) : {})}
                          //     >
                          //       {contentHtml}
                          //     </Link>
                          //   );
                          // }
                          return html;
                        })}
                      </div>
                    </div>
                    <div className="flex items-center pb-[25px]">
                      <ButtonBase
                        onClick={() => {
                          setActiveItem(false);
                          menuContext.setIsSearch(true);
                        }}
                      >
                        <Search fill={activeFill} />
                      </ButtonBase>
                      <ButtonBase
                        className="flex items-center !ml-6 space-x-4"
                        aria-describedby={id}
                        onClick={handleClickLang}
                      >
                        <Globe fill={activeFill} />
                        <Expand fill={activeFill} className="ml-4" />
                      </ButtonBase>
                    </div>
                    <Popover
                      id={id}
                      open={openLang}
                      anchorEl={anchorEl}
                      onClose={handleCloseLang}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      disableScrollLock
                      sx={{
                        zIndex: 100001,
                        "& > div.MuiPaper-root": { top: "85px !important" },
                      }}
                    >
                      <div className="flex py-2 px-4">
                        <div className="flex flex-col space-y-2 items-start">
                          {languages.map((l) => {
                            const isActive =
                              activeLanguage && l.id === activeLanguage;
                            const activeStyle = isActive
                              ? "text-black font-semibold flex text-left"
                              : "text-black flex text-left";
                            return (
                              <ButtonBase
                                key={l.name}
                                onClick={() => {
                                  if (window.location.hash) {
                                    window.history.replaceState(
                                      "",
                                      document.title,
                                      window.location.pathname
                                    );
                                  }
                                  if (!isActive) {
                                    dispatch({
                                      type: "content/setActiveLanguage",
                                      payload: l,
                                    });
                                  }
                                  handleCloseLang();
                                }}
                              >
                                <p className={activeStyle}>
                                  <div className="w-[25px]">
                                    {l.abbreviation.toUpperCase()}
                                  </div>{" "}
                                  | {l.name}
                                </p>
                              </ButtonBase>
                            );
                          })}
                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
                <AnimatePresence>
                  {activeItem.open && (
                    <motion.div
                      className={[
                        "absolute top-[120px] w-full bg-white z-[9999]",
                        styles.dsktpActiveContainer,
                      ].join(" ")}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      variants={menuVariants}
                    >
                      <motion.div
                        variants={itemVariants}
                        className="w-full h-auto relative mt-[120px]"
                      >
                        <Reveal when={isReaveal}>
                          <ActiveItem
                            item={activeItem?.data}
                            action={setActiveItem}
                            isShowingDeepItem={isShowingDeepItem}
                            setIsShowingDeepItem={setIsShowingDeepItem}
                            setHeightDraw={setHeightDraw}
                            setActiveItem={setActiveItem}
                          />
                        </Reveal>
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
              <AnimatePresence>
                {menuContext.isSearch && <MenuSearch />}
              </AnimatePresence>
            </>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        <div
          title="Whistleblower"
          onMouseEnter={() => setWhistleHovered(true)}
          onMouseLeave={() => setWhistleHovered(false)}
          className="fixed z-[31000] bottom-5 left-5 w-fit group"
        >
          {data?.link[0]?.assets[0]?.asset_file && (
            <NavHashLink
              className="flex space-x-2 items-center bg-[#004A98] rounded-full group-hover:pr-4 w-fit  "
              // onClick={() => setMenuSelected(tag.id)}
              smooth
              to={data?.link[0]?.article_language?.path || "/"}
            >
              <img
                src={data?.link[0]?.assets[0]?.asset_file}
                alt={data?.link[0]?.assets[0]?.description}
                className="w-[48px] h-[48px] "
              />
              {whistleHovered && (
                <motion.div
                  className="text-white block !w-fit !bg-[#004A98] font-inter uppercase transition-all duration-300"
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={hoverVariants}
                >
                  {data?.link[0]?.article_language?.title}
                </motion.div>
              )}
            </NavHashLink>
          )}
        </div>
      </AnimatePresence>
    </>
  );
}
