/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PARSE from 'html-react-parser';
import YouTube from 'react-youtube';
import Api from './Api';
import GlobalApi from '../../context/api/GlobalApi';
import BaseArrowLinkTw from '../../context/components/Base/BaseArrowLinkTw';
import Styles from './TBS_Career_People.module.css';
import DefaultImage from './assets/empty_default.png';
import replaceAssetLink from '../../context/utils/replaceAssetLink';

function TBS_Career_People() {
  const [section, setSection] = useState([]);
  const [article, setArticle] = useState([]);
  const [slugName, setSlugName] = useState('');
  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const FetchData = async () => {
      const api = new Api();
      const globalApi = new GlobalApi();

      try {
        const sectionResponse = await api.getSectionDetail({ language: activeLang });
        const sectionData = await sectionResponse.data;
        const sections = sectionData.results[0];
        const sectionWidgets = sections.widgets;
        const sectionSlugName = sections.slug_name;

        if (sectionWidgets) {
          const sec = sectionWidgets.filter((data) => data.is_internal === false);
          const articleResponse = await globalApi.getArticle({ widgets: sec[0].id, max_size: true });

          const articleData = await articleResponse.data.results;
          // const commissionersData = articleData.filter((data) => data?.categories[0].id === 21);

          // setSection(sections);
          //   setCekSection(cekSections);
          //   console.log('articleData ', articleData);
          //   console.log('commissionersData : ', commissionersData);
          setArticle(articleData);
        } else {
          throw new Error('Error trying to get property result');
        }

        setSection(sectionWidgets);
        setSlugName(sectionSlugName);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [activeLang]);

  let data_people;
  let assVideo;
  const title = [];
  const pathDetail = [];
  let read_more;
  if (section) {
    section.forEach((data, id) => {
      const { widget_contents } = data;

      if (id === 0) {
        widget_contents?.forEach((v) => {
          title.push(v.content);
        });
      }

      if (id === 1) {
        read_more = widget_contents[0]?.content;
      }

      if (id === 3) {
        widget_contents?.forEach((data) => {
          pathDetail.push(data.article_language?.path);
        });
      }
      if (id === 4) {
        assVideo = widget_contents[0]?.content;
      }
    });
  }

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      rel: 0,
      showinfo: 0,
      mute: 1,
    },
  };

  return (
    <div
      id={`${slugName}`}
      className="section-container h-auto max-w-[88%] md:w-full hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1400px] fhd:max-w-[1450px] mx-auto scrollTop flex flex-col gap-y-11 mb-10 pb-10 mt-[80px] relative"
    >
      {assVideo && <YouTube videoId={assVideo} className="aspect-w-16 aspect-h-9" iframeClassName="h-full w-full" opts={videoOptions} />}

      <div className="h-auto relative ">
        <div className="w-full h-full  ">
          <div className={`w-full s:w-[50%] s:!mt-[200px]  s:absolute ${Styles.title}`}>
            <h2 className="heading-title">{title[0]}</h2>
            <h3 className="heading-sub-title">{title[1]}</h3>
          </div>
        </div>
        <div className="relative s:mt-[60px] mt-[45px]">
          {article &&
            article?.map((data, idx) => {
              const image = data?.article_assets[0]?.asset.file_small;
              const imgDesc = data?.article_assets[0]?.asset.description;
              const articleData = data?.article_languages.filter((d) => d.language === activeLang);
              const name = articleData[0]?.title;
              const slugTitle = articleData[0]?.slug_title;
              const profileContent = articleData[0]?.content;
              const occ = articleData[0]?.summary;
              const idArticle = articleData[0]?.article;

              return (
                idx < 4 && (
                  <div key={data.id} className={idx % 2 === 0 ? `w-full h-auto flex mb-10 s:justify-end ` : `w-full h-auto flex mb-10 s:justify-start `}>
                    <div className="w-full s:w-[48%] h-full group">
                      <div className={`w-full h-[400px] overflow-hidden ${Styles['card-img']}`}>
                        <img
                          className="w-full h-full bg-center transition duration-700 ease-in-out object-cover bg-cover group-hover:scale-[1.1]"
                          src={replaceAssetLink(image) || DefaultImage}
                          alt={imgDesc}
                          width={737}
                          height={400}
                        />
                      </div>
                      <div className="w-full s:pr-14">
                        <div className="w-full flex flex-col gap-y-3 justify-between shadow-lg rounded relative mt-[-1.8rem] bg-white p-6">
                          <div className={Styles.name}>
                            <p>{name}</p>
                          </div>
                          <p className={Styles.position}>{occ && PARSE(occ)}</p>
                          <div className="flex h-[20px] mr-3 w-full">
                            {profileContent ? (
                              <Link to={`${pathDetail[0]}/${idArticle}/${slugTitle}` ?? '/'}>
                                <div className="flex gap-x-3" href="/">
                                  <p className={Styles.more}>{read_more}</p>
                                  <div className="mt-[3px]">
                                    <BaseArrowLinkTw size="small" color="black" />
                                  </div>
                                </div>
                              </Link>
                            ) : (
                              <div className="flex gap-x-3" href="/">
                                <p className={Styles.more}>{read_more}</p>
                                <div className="mt-[3px]">
                                  <BaseArrowLinkTw size="small" color="black" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
      <div className="w-full mt-[4rem] flex justify-center">
        {article && (
          <Link to={`${pathDetail[1]}`}>
            <div className="w-fit p-2 border-2 border-[#004A98]">
              <p className="text-[#004A98]">Meet Our People</p>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}

export default TBS_Career_People;
