const Colors = {
  white: '#ffffff',
  white1: '#000000',
  lightWhite: '#ececec',
  lighterWhite: '#f4f4f4',
  blue: '#18467f',
  blackPrimary: '#000000',
  black: '#1a1a1a',
  green: '#2d947a',
  red: '#e30000',
};

export default Colors;
