/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PARSE from 'html-react-parser';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Api from './Api';
import Styles from './TBS_Homepage_Hero.module.css';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// import Vector1 from './assets/learn-more-arrow.svg';
// import SectionAccordion from './section_accordion';
import BaseArrowLinkTw from '../../context/components/Base/BaseArrowLinkTw';
import BaseAccordion from '../../context/components/Base/BaseAccordion';
import BaseHeroCard from '../../context/components/Base/BaseHeroCard';
import BgImage from './assets/bg-image.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Style } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import replaceAssetLink from '../../context/utils/replaceAssetLink';
import Logo from './assets/logo.png';

const style = {
  position: 'absolute',
  bgcolor: 'background.paper',
  'border-radius': '15px',
  boxShadow: 24,
  p: 4,
  outline: '0!important',
};

const dropIn = {
  hidden: {
    y: 600,
    opacity: 0,
  },
  visible: {
    y: 680,
    opacity: 1,
    transition: {
      duration: 0.7,
      type: 'spring',
      bounce: 0,
    },
  },
  exit: {
    y: 600,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    maxWidth: '701px',
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TBS_Homepage_Hero() {
  const [section, setSection] = useState([]);
  const [menus, setMenus] = useState([]);
  const [showUp, setShowUp] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalShowUP, setModalShowUp] = useState(false);
  const [newTab, setNewTab] = useState(true);

  let is_modal_show = window.sessionStorage.getItem('alreadyShow');
  const handleClose = () => {
    setModalOpen(false);
  };

  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const fetchData = async () => {
      const api = new Api();

      try {
        const sectionResponse = await api.getSectionDetail({
          language: activeLang,
        });
        const menuResponse = await api.getMenuDetail({ language: activeLang });
        const menuData = await menuResponse;
        const sectionData = await sectionResponse.data;
        const sections = sectionData.results[0].widgets;

        setMenus(menuData.data.items);
        setSection(sections);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [activeLang]);

  useEffect(() => {
    if (is_modal_show != 'already shown') {
      setModalShowUp(true);
      window.sessionStorage.setItem('alreadyShow', 'already shown');
    }
  }, []);

  let title;
  let bg_video;
  let bg_image;
  let learn_more_text;
  let hero_2_title;
  let hero_2_content;
  let modal_content;
  let modal_asset;
  let modal_assetDes;
  let pathName;
  let imageHeader;
  let imageHeaderDes;
  const accordion_content = [];
  if (section) {
    section.forEach((data, id) => {
      const { widget_contents } = data;

      if (id === 0) {
        title = widget_contents[0].article_language.title;
        bg_image = widget_contents[0].assets[0].asset_file;
        bg_video = widget_contents[0].assets[1].asset_file;
      }
      if (id === 1) {
        learn_more_text = widget_contents[0].content;
      }
      if (id === 2) {
        // console.log(`id-${id} : `, widget_contents);
        hero_2_title = widget_contents[0].article_language.title;
        hero_2_content = widget_contents[0].article_language.content;
      }
      if (id === 3) {
        widget_contents.forEach((val) => {
          accordion_content.push(val);
        });
      }
      if (id === 4) {
        modal_asset = widget_contents[0]?.assets[0]?.asset_file;
        modal_assetDes = widget_contents[0]?.assets[0]?.description;

        const content = widget_contents[0]?.article_language.content;
        const data_content = content?.replaceAll('PT TBS Energi Utama Tbk' || '<ol><li>PT TBS Energi Utama Tbk', `<span className="text-[#004A98]">PT TBS Energi Utama Tbk</span>`);

        modal_content = data_content;
      }
      if (id === 5) {
        pathName = widget_contents[0]?.article_language.path;
      }
      if (id === 6) {
        imageHeader = widget_contents[0]?.file_small;
        imageHeaderDes = widget_contents[0]?.description;
      }
    });
  }

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error('Error attempting to play', error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    if (videoEl !== null) {
      setTimeout(() => {
        setShowUp(true);
      }, 2000);

      setTimeout(() => {
        setModalOpen(true);
      }, 3000);
    }
  }, []);

  return (
    <div className="w-full relative">
      {/* <BaseHeroCard type="video" background={bg_video} description="hollaaa" /> */}
      {modalShowUP === true && (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalOpen} sx={{ zIndex: 15000, outline: 'none' }} className={Styles['modal-box']}>
          <BootstrapDialogTitle id="customized-dialog-title">
            <div className="p-[2px] md:p-[15px] flex justify-between">
              <img className="w-[128px] h-[50px]" src={modal_asset} alt={modal_assetDes} />
              <div className="flex items-center">
                <CloseIcon className="cursor-pointer active:text-[#808080]" onClick={handleClose} />
              </div>
            </div>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography className="p-[8px] md:p-[30px] prose prose-md" id="modal-modal-title">
              {modal_content && PARSE(modal_content)}
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      )}
      <div className="w-full relative">
        <video
          className=" h-[70vh] object-cover w-full re"
          style={{
            maxWidth: '100%',
            width: '120%',
            height: '',
            margin: '',
          }}
          autoPlay
          loop
          muted
          plays-inline
          alt={title}
          src={replaceAssetLink(bg_video)}
          ref={videoEl}
        />
        <div
          className="w-full h-full relative inset-0"
          style={{
            backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 9.77%, rgba(0, 0, 0, 0) 45.86%)',
            opacity: '.8',
          }}
        />
        <div className="absolute inset-0 h-[20vh] max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto mt-[29vh]">
          {showUp && (
            <AnimatePresence>
              <motion.div className="listing" initial={{ x: -500, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ type: 'spring', bounce: 0 }}>
                <div className={`absolute my-auto md:w-[700px] ${Styles['hero-content']}`}>
                  <div className="">
                    <img className="w-[303.2px] h-[45px]" src={replaceAssetLink(imageHeader)} alt={imageHeaderDes} />
                  </div>
                  <div className={`group ${Styles['hero-learn-more']}`}>
                    <a className="pl-[5px]" href={`${pathName}`}>
                      <p>{learn_more_text}</p>
                      <div className="mt-[2px]">
                        <BaseArrowLinkTw size="small" />
                      </div>
                    </a>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          )}
        </div>
      </div>
      <div className={`h-full ${Styles['hero-2']}`}>
        <div className="mt-[80px] mb-[80px] max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto">
          <div className={` ${Styles['hero-2-section-title']}`}>
            <h1>{hero_2_title}</h1>
          </div>
          <div className={`h-full text-justify ${Styles['hero-2-section-content']}`}>
            <div className={`h-full text-justify ${Styles['hero-2-content-text']}`}>{hero_2_content && PARSE(hero_2_content)}</div>
            <div className={Styles.link}>
              <ul>
                {menus?.map((val) => {
                  if (val.url) {
                    return (
                      <Link key={val.id} className={Styles.link} to={val.url}>
                        <li>
                          <p>{val.title}</p>
                          <ArrowRightIcon />
                        </li>
                      </Link>
                    );
                  } else {
                    return (
                      <li>
                        <p>{val.title}</p>
                        <ArrowRightIcon />
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TBS_Homepage_Hero;
