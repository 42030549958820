/* eslint-disable */

import React, { useState, useEffect, useRef } from "react";
import PARSE from "html-react-parser";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Api from "./Api";
import Left from "./assets/button-right.png";
import Right from "./assets/button-right.png";
import BaseArrowLinkTw from "../../context/components/Base/BaseArrowLinkTw";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

export default function TBSArticlesliderTop() {
  const [sections, setSections] = useState([]);
  const [widgets, setWidgets] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    const section = sectionsData.data.results;
    const sectionsWidgets = section[0]?.widgets;
    // console.log('section ', section);

    setSections(section);
    setWidgets(sectionsWidgets);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let assetFile;
  let sectionName;
  let path;
  let textLink;
  if (sections) {
    sectionName = sections[0]?.slug_name;

    if (widgets && widgets.length !== 0) {
      widgets.forEach((section, i) => {
        switch (i) {
          case 0:
            const article = section.widget_contents[0];
            title = article?.article_language?.title;
            summary = article?.article_language?.summary;
            content = article?.article_language?.content;
            path = article?.article_language?.path;
            assetFile = article?.assets;

            break;
          case 1:
            const text = section.widget_contents[0];
            textLink = text?.content;
            break;
          case 2:
            break;
          default:
            break;
        }
      });
    }
  }

  const ref = useRef(null);
  function goToNextSlide() {
    ref.current.splide.go(ref.current.splide.index + 1);
  }

  function goToPrevSlide() {
    ref.current.splide.go(ref.current.splide.index - 1);
  }

  const doc = new DOMParser().parseFromString(content, "text/html");

  const tagsToRemove = "img";
  for (const elm of doc.querySelectorAll("*")) {
    if (elm.matches(tagsToRemove)) {
      elm.replaceWith(`<img src='${elm.src}' alt='${title}' />`);
    }
  }

  return (
    <div
      id={`${sectionName}`}
      className={`section-container-pl  ${
        sections.length > 0 && sections[0].index === 3
          ? "mt-index-section__3"
          : "mt-index-section"
      }`}
    >
      {sections.length > 0 ? (
        <div className="section-width">
          <h2 className="heading-title"> {title}</h2>
          <h3 className="heading-summary"> {summary}</h3>
          <div className="margin-content">
            <div className="">
              {assetFile && (
                <div className="">
                  <Splide
                    className=""
                    ref={ref}
                    options={{
                      type: "slide",
                      rewind: "true",
                      // arrows: true,
                      autoplay: true,
                      // gap: '1.5rem',
                      pagination: false,
                      perPage: 1,
                      perMove: 1,
                    }}
                    renderControls={() => (
                      <div className="">
                        <div className="flex gap-x-3">
                          <div className="splide__arrows">
                            <div className=" splide__arrow--prev" role="button">
                              <img
                                src={Left}
                                alt="left"
                                className="block splide__arrow-next "
                                role="button"
                              />
                            </div>
                            <div className="splide__arrow--next" role="button">
                              <img
                                src={Right}
                                alt="right"
                                className="block splide__arrow-next"
                                role="button"
                              />
                            </div>
                          </div>

                          {/* <div className="w-8 h-8 p-2 border-2 rounded-full bcolor " role="button" onClick={goToPrevSlide}>
                          </div>

                          <div className="w-8 h-8 p-2 border-2 rounded-full bcolor" role="button" onClick={goToNextSlide}>
                          </div> */}
                        </div>
                      </div>
                    )}
                  >
                    {assetFile?.map((art, i) => (
                      <SplideSlide key={art.id} className="group">
                        <img
                          src={replaceAssetLink(art?.file_medium)}
                          alt={art?.description || art?.name}
                          className=" object-cover h-full w-full transition duration-700 ease-in-out group-hover:scale-110"
                        />
                      </SplideSlide>
                    ))}
                  </Splide>
                </div>
              )}
            </div>
            <div className="margin-content">
              {content && (
                <div className="typo-style">
                  {" "}
                  {PARSE(
                    doc.body.innerHTML
                      .replace(/&lt;/g, "<")
                      .replace(/&gt;/g, ">")
                  )}
                </div>
              )}
            </div>
          </div>
          {widgets && widgets.length > 1 ? (
            <div className="flex h-[20px] mr-3 w-full mt-[45px] group">
              <Link className="flex gap-x-3" to={`${path}` ?? `/`}>
                <p className="font-inter-bold">{textLink && textLink}</p>
                <div className="mt-[3px]">
                  <BaseArrowLinkTw size="small" color="black" />
                </div>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="section-width">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </div>
  );
}

