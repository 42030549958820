/* eslint-disable */

import React, { useState, useCallback, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import TruncateMarkup from "react-truncate-markup";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import PARSE from "html-react-parser";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

import { Link, useLocation } from "react-router-dom";
import { Box, CircularProgress, Modal, Skeleton } from "@mui/material";
import Api from "./Api";
import usePagination from "./Pagination";
import clsxm from "../../context/utils/clsxm";

const theme = createTheme({});

export default function TBS_ReportsNew() {
  const activeLang = useSelector((state) => state.content.activeLanguage);
  const location = useLocation();
  const [pdfUrl, setPdfUrl] = useState("");
  const [sections, setSections] = useState([]);
  const [widgetId, setWidgetId] = useState();
  const [listWidgetArticle, setListWidgetArticle] = useState([]);
  const [category, setCategory] = useState("new");
  const [search, setSearch] = useState("");
  const [articleCount, setArticleCount] = useState(0);
  const [page, setPage] = useState(1);
  const [year, setYear] = useState("");
  const [listArticleYear, setListArticleYear] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreOther, setLoadMoreOther] = useState(false);
  const pageSize = 9;

  const getSectionsDetail = useCallback(() => {
    const api = new Api();
    api
      .getSectionDetail({ language: activeLang })
      .then((res) => {
        if (res.status === 200 && res.data.results) {
          const sectionFill = res.data.results;
          // setArticleCount(res.data.count);
          setSections(sectionFill);
          setFileLoaded(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    // console.log(response);
  }, [activeLang]);

  useEffect(() => {
    getSectionsDetail();
  }, [getSectionsDetail]);

  let summary;
  let content;
  let sectionName;
  let title;
  let icon;
  let listFiles;
  let listOtherFiles;
  let otherHeadConText;
  let listText = [];
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    // const sec = sections;

    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text?.article_language?.title;
            summary = text?.article_language?.summary;
            content = text?.article_language?.content;
            icon = replaceAssetLink(text?.assets[0]?.asset_file);
            break;
          case 1:
            const files = section.widget_contents;
            listFiles = files;
            break;
          case 2:
            const other = section.widget_contents[0];
            otherHeadConText = other.article_language;
            break;
          case 3:
            const otherFiles = section.widget_contents;
            listOtherFiles = otherFiles;
            break;
          case 4:
            const listT = section.widget_contents;
            listText = listT;
            break;
          default:
            break;
        }
      });
    }
  }

  const groupedByDate = _.groupBy(listFiles, function (item) {
    const dateMoment = moment(item.document_date);
    return dateMoment.day(String).format("YYYY");
  });

  const finalArticle = Object.entries(groupedByDate).map((obj) => ({ ...obj }));

  const groupedByDateOther = _.groupBy(listFiles, function (item) {
    const dateMoment = moment(item.document_date);
    return dateMoment.day(String).format("YYYY");
  });

  const finalArticleOther = Object.entries(groupedByDateOther).map((obj) => ({
    ...obj,
  }));

  const yearList = finalArticle?.map((art) => {
    return {
      name: art[0],
      count: art[1],
    };
  });

  const onChangeYear = (event) => {
    setYear(event.target.value);
  };

  const handleFind = useCallback(
    (event) => {
      setPage(1);
      _DATA.jump(1);
      setListArticleYear([]);
      const filtered = listFiles?.filter((file) =>
        file.document_date.includes(year)
      );
      setFileLoaded(false);
      setListArticleYear(year !== "" ? filtered : listFiles);
      setTimeout(() => setFileLoaded(true), 500);
    },
    [listFiles, year]
  );

  const sortedArray =
    listArticleYear?.length > 0
      ? _.orderBy(listArticleYear, "documnet_date").reverse()
      : _.orderBy(listFiles, "documnet_date").reverse();

  const PER_PAGE = 9;

  const _DATA = usePagination(sortedArray, PER_PAGE);

  const sortedArrayOther = _.orderBy(listOtherFiles, "documnet_date").reverse();

  const _DATAOther = usePagination(sortedArrayOther, PER_PAGE);

  const handleChange = (e, p) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setFileLoaded(false);
    setTimeout(() => setFileLoaded(true), 500);
    setPage(p);
    _DATA.jump(p);
  };

  const reverseYear = _.orderBy(yearList, "name").reverse();

  const stringToHTML = function (str) {
    const domContainer = document.createElement("span");
    domContainer.classList.add("span-editor");
    domContainer.innerHTML = str;
    return domContainer;
  };

  const parentEmbed = stringToHTML(content);

  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);

  for (const i in oldIframe) {
    //Get the url from oembed tag
    let url = oldIframe[i].getAttribute("url");
    //Replace 'watch?v' with 'embed/'
    url = url.replace("watch?v=", "embed/");

    //Create a iframe tag
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("width", "100%");
    newIframe.setAttribute("height", "100%");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    newIframe.classList.add("youtube-embed");
    if (url) {
      newIframe.setAttribute("src", url);
    }
    // replace oldIframe with newIframe
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }

  const contentToRender = parentEmbed.outerHTML;

  return (
    <div
      id={`${sectionName}`}
      className={`  ${
        sections.length > 0 && sections[0].index === 3
          ? "mt-index-section__3"
          : "mt-index-section"
      }`}
    >
      <div className="section-container-pl">
        <div className="max-w-[849px] xl:mx-0 mx-auto ">
          {title || summary ? (
            <>
              <h2 className="heading-title-new"> {title}</h2>
              {/* <h3 className="heading-summary"> {summary}</h3> */}
            </>
          ) : (
            <Skeleton width="300" />
          )}
          <div className="margin-content-new">
            {/* <div className="">{icon && <img src={icon} alt="" className="max-w-2xl mb-3 w-full" />}</div> */}
            {content && (
              <div className="mt-[45px]">
                <div className="max-w-[849px] xl:mx-0 mx-auto typo-style ">
                  {content && PARSE(contentToRender)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="justify-start space-x-6 flex section-width mt-[36px]">
        <div className="">
          <select
            name=""
            className="h-fit w-[200px] border-black border bg-white px-[10px] py-[10px] capitalize font-inter cursor-pointer"
            id=""
            onChange={(e) => onChangeYear(e)}
            value={year}
          >
            <option value="" className="capitalize cursor-pointer">
              Year
            </option>
            {reverseYear?.map((y) => (
              <option
                key={y.name}
                value={y.name}
                className="capitalize cursor-pointer"
              >
                {y.name}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={() => handleFind()}
          type="button"
          className="w-[200px] p-2 bg-[#004a98] text-white font-inter-bold"
        >
          Find
        </button>
      </div> */}
      {fileLoaded ? (
        <div className="mt-[36px]  md:w-full hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto fhd:pl-[500px] xl:pl-[400px]">
          <div className="mdl:max-w-[849px] xl:mx-0  mx-auto bg-tbs-dark-green/5 py-[31px] mdl:px-[43px]">
            <div className=" grid  lg:grid-cols-3 s:grid-cols-2 mdl:gap-[109px] macx-w-[88%] md:w-full  mx-auto gapx-[33px] gap-y-[33px] ">
              {(loadMore
                ? _DATA.currentData()
                : _DATA.currentData().slice(0, 3)
              ).map((list) => {
                return (
                  <div
                    key={list.id}
                    className="justify-start text-left flex flex-col items-center justify-center w-full h-full "
                  >
                    <div className="">
                      <div title="" className="cursor-pointer ">
                        <div className="">
                          <a
                            href={replaceAssetLink(list?.file)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                replaceAssetLink(list?.thumbnail) ??
                                "https://bzpublishassets.blob.core.windows.net/media/assets/Screen_Shot_2022-06-02_at_19.28_12.png"
                              }
                              width={216}
                              height={330}
                              alt={list?.description || list?.file}
                              className="drop-shadow-lg w-full md:max-h-[30vh] mdl:min-h-[288px] min-h-[378px] max-w-[270px] object-cover rounded-[10px]"
                            />
                            <div className="space-y-2 mt-[12px]">
                              {/* <div className="text-left text-sm font-inter"> {moment(list?.document_date).format(' MMMM D, YYYY')}</div> */}
                              {/* <div className="text-[#004A98] font-inter-bold">
                                {list?.categories[0]?.name}
                              </div> */}
                              <TruncateMarkup lines={2}>
                                <div className=" font-inter-bold h-full text-[15px] leading-[130%] text-tbs-dark-green  text-left pxl-[19px] text-center">
                                  {list?.description ||
                                    "This Placeholder Description"}
                                </div>
                              </TruncateMarkup>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <div className="my-10 flex items-center justify-end gap-x-5">
              <ThemeProvider theme={theme}>
                <Pagination
                  count={count}
                  color="primary"
                  size="medium"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </ThemeProvider>
            </div> */}

            <div
              onClick={() => setLoadMore(!loadMore)}
              className="w-fit mt-[23.5px] px-[44px] py-[9px] text-tbs-gray bg-white border rounded-[20px] flex justify-center  mx-auto cursor-pointer button-new"
            >
              {!loadMore ? listText[0]?.content : listText[1]?.content}
            </div>
          </div>
          <div className="mt-[59px]  ">
            <div className="font-inter-600 text-[20px] mdl:mb-0 mb-[37px] xl:text-left text-center ">
              {otherHeadConText?.title}
            </div>
            <div className="mdl:max-w-[849px] xl:mx-0  mx-auto bg-tbs-dark-green/5 py-[31px] mdl:px-[43px] mdl:mt-[16px]">
              <div className="max-w-[88%] lg:max-w-full mx-auto">
                <div className="flex md:flex-row flex-col lg:space-x-[60px]  md:space-x-[30px] md:space-y-0 space-y-[33px]">
                  {(loadMoreOther
                    ? _DATAOther.currentData()
                    : _DATAOther.currentData().slice(0, 3)
                  ).map((list) => {
                    console.log("🚀 ~ ).map ~ list:", list);
                    return (
                      <div
                        key={list.id}
                        // className="justify-start text-left flex flex-col itetyms-center justifky-center w-full h-full "
                        className={clsxm(
                          list?.categories[0]?.id
                            ? "w-fit"
                            : "lg:w-[230px] s:w-[230px] w-[270px]",
                          "justify-start text-left flex flex-col itemss-center justifsy-center  mx-auto  "
                        )}
                      >
                        <div className="w-fit">
                          <div title="" className="cursor-pointer w-fit">
                            <div className="">
                              <a
                                href={replaceAssetLink(list?.file)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={
                                    replaceAssetLink(list?.thumbnail) ??
                                    "https://bzpublishassets.blob.core.windows.net/media/assets/Screen_Shot_2022-06-02_at_19.28_12.png"
                                  }
                                  alt={list?.description || list?.file}
                                  className={clsxm(
                                    list?.categories[0]?.id
                                      ? "w-fit"
                                      : "lg:w-[270px] s:w-[304px] ",
                                    "drop-shadow-lg w-auto  object-cover rounded-[10px]  md:h-[288px]"
                                  )}
                                />
                                <div className="space-y-2 mt-[12px]">
                                  {/* <div className="text-left text-sm font-inter"> {moment(list?.document_date).format(' MMMM D, YYYY')}</div> */}
                                  {/* <div className="text-[#004A98] font-inter-bold">
                                {list?.categories[0]?.name}
                              </div> */}
                                  <TruncateMarkup lines={2}>
                                    <div className=" font-inter-bold h-full text-[15px] leading-[130%] text-tbs-dark-green  text-left pxl-[19px] text-center">
                                      {list?.description ||
                                        "This Placeholder Description"}
                                    </div>
                                  </TruncateMarkup>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <div className="my-10 flex items-center justify-end gap-x-5">
              <ThemeProvider theme={theme}>
                <Pagination
                  count={count}
                  color="primary"
                  size="medium"
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </ThemeProvider>
            </div> */}

              {/* <div
                onClick={() => setLoadMoreOther(!loadMoreOther)}
                className="w-fit mt-[23.5px] px-[44px] py-[9px] text-tbs-gray bg-white border rounded-[20px] flex justify-center  mx-auto cursor-pointer button-new"
              >
                {listText[0]?.content}
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="section-container-pl">
          <div className="section-width">
            <div className="mt-[45px]  grid  lg:grid-cols-3 s:grid-cols-2 gap-[1.4rem]">
              {Array.from({ length: 12 }, (_, index) => (
                <Box>
                  <Skeleton
                    key={index}
                    variant="rect"
                    width="100%"
                    height={330}
                  />
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton width="60%" />
                    <Skeleton />
                  </Box>
                </Box>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* <AnimatePresence initial={false}>
          {pdfUrl && (
            <Modal open={pdfUrl} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" sx={{ zIndex: 15000, outline: 'none' }}>
              <motion.div className={`absolute inset-0 flex items-center justify-center`} variants={dropIn} initial="hidden" animate="visible" exit="exit">
                <div className="h-[80vh] w-[75vw] ">
                  <button onClick={() => setPdfUrl(null)} className="absolute -mt-10 font-inter-bold bg-red-600 bg-white p-2 rounded-t-lg">
                    Close
                  </button>
                  <object data={pdfUrl} style={{ height: '80vh', width: '75vw', display: 'block' }} type="application/pdf">
                    <embed src={pdfUrl} style={{ height: '80vh', width: '75vw', display: 'block' }} type="application/pdf" />
                  </object>
                </div>
              </motion.div>
            </Modal>
          )}
        </AnimatePresence> */}
    </div>
  );
}
