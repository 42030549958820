/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import PARSE from "html-react-parser";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { CircularProgress } from "@mui/material";
import {
  CheckCircleOutline,
  AddBoxIcon,
  IndeterminateCheckBoxIcon,
} from "@mui/icons-material";
import {
  useForm,
  Controller,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import Api from "./Api";
import GlobalApi from "../../context/api/GlobalApi";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "701px",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },

  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    // width: 'auto',
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"inter"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function TBSAboutUsContact() {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({});

  const [sections, setSections] = useState([]);
  const [messageData, setMessageData] = useState({});
  const [reportSent, setReportSent] = useState({
    modal: false,
    text: "Your email has been send, Thank You",
    success: false,
  });
  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api
      .getSectionDetail({ language: activeLang })
      .then((res) => {
        setSections(res.data.results);
      })
      .catch((err) => {
        console.log("err", err);
      });
    // console.log(response);
  }, [activeLang]);

  useEffect(() => {
    getSectionDetail();
  }, [getSectionDetail]);

  let title;
  let summary;
  let content;
  let media;
  let sectionName;
  let inputText;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text?.article_language?.title;
            summary = text?.article_language?.summary;
            content = text?.article_language?.content;
            media =
              replaceAssetLink(text?.assets[0]?.file_small) ||
              replaceAssetLink(text?.assets[0]?.asset_file);

            break;

          case 1: {
            const input =
              section?.widget_contents ?? section.widget_contents[0];
            if (input && input.length !== 0) {
              inputText = input;
            }
            break;
          }
          default:
            break;
        }
      });
    }
  }

  const handleClose = () => {
    setReportSent(false);
  };

  const handleActionSubmit = async (data) => {
    const api = new GlobalApi();
    const phonePattern = /^[0-9\b\+\-\(\)]+/;
    const emailPattern = /\S+@\S+\.\S+/;

    const emailData = {
      email_data: data,
      language: activeLang,
      email_content: 17,
    };

    try {
      if (!Object.values(emailData?.email_data).every((value) => !!value)) {
        swal({
          icon: "error",
          title: "Oops...",
          text: "All Input cannot be empty!",
          button: true,
        });
      } else if (!emailPattern.test(emailData.email_data?.email)) {
        swal({
          icon: "error",
          title: "Oops...",
          text: "wrong email address format!",
          button: true,
        });
      } else if (!phonePattern.test(emailData.email_data?.phone)) {
        swal({
          icon: "error",
          title: "Oops...",
          text: "wrong phone number format!",
          button: true,
        });
      } else {
        setReportSent({ modal: true, text: "Sending Report", success: false });
        const sendEmail = await api.sendEmail(emailData);
        return (
          sendEmail,
          setReportSent({
            modal: true,
            text: "The report that you have sent has been submitted.",
            success: true,
          })
        );

        // console.log(emailData)
        // console.log(emailData.email_data?.email)
      }
    } catch (error) {
      swal({
        icon: "error",
        title: "Oops...",
        text: error,
        button: true,
      });
      console.error(error);
    }
  };

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      <div className="section-width">
        <h2 className="heading-title"> {title}</h2>
        <h3 className="heading-summary"> {summary}</h3>
        <div className="margin-content">
          <div className="">
            {media && <img src={media} alt={title} className=" mb-3 w-full" />}
          </div>
          {content && <div className="typo-style">{PARSE(content)}</div>}
        </div>
        {inputText && inputText.length > 0 && (
          <form onSubmit={handleSubmit(handleActionSubmit)}>
            <div className="mt-[45px] bg-[#F5F5F5]  justify-center shadow-xl rounded p-[30px]">
              <div className=" space-y-5">
                <div className="font-inter-bold"> {inputText[0]?.content}</div>
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className="font-inter-bold "
                  >
                    {inputText[1]?.content}
                  </InputLabel>
                  <BootstrapInput
                    name="name"
                    {...register(`name`)}
                    placeholder={inputText[2]?.content}
                    id="bootstrap-input"
                  />
                </FormControl>
                <div className="flex space-x-6">
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className="font-inter-bold"
                    >
                      {inputText[3]?.content}
                    </InputLabel>
                    <BootstrapInput
                      name="email"
                      {...register(`email`)}
                      placeholder={inputText[4]?.content}
                      id="bootstrap-input"
                    />
                  </FormControl>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className="font-inter-bold"
                    >
                      {inputText[5]?.content}
                    </InputLabel>
                    <BootstrapInput
                      name="phone"
                      {...register(`phone`)}
                      placeholder={inputText[6]?.content}
                      id="bootstrap-input"
                    />
                  </FormControl>
                </div>
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className="font-inter-bold"
                  >
                    {inputText[7]?.content}
                  </InputLabel>
                  <BootstrapInput
                    name="message"
                    {...register(`message`)}
                    rows={6}
                    multiline={true}
                    placeholder={inputText[8]?.content}
                    id="bootstrap-input"
                  />
                </FormControl>
                <button
                  type="submit"
                  className="bg-white py-2 font-inter-bold px-3 rounded"
                >
                  {inputText[9]?.content}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={reportSent.modal}
        sx={{ zIndex: 15000, outline: "none" }}
      >
        <DialogContent dividers>
          <div className="flex flex-col justify-center items-center p-5 gap-y-7">
            <div className="">
              {reportSent.success ? (
                <CheckCircleOutline className="text-green !w-20 !h-20" />
              ) : (
                <CircularProgress />
              )}
            </div>
            <div className="font-inter">{reportSent.text}</div>
            {reportSent.success && (
              <div className="flex items-center ">
                <button
                  className=" bg-blue text-white rounded font-inter-bold p-2"
                  onClick={handleClose}
                  type="button"
                >
                  Close
                </button>
                {/* <CloseIcon className="cursor-pointer active:text-[#808080]" onClick={handleClose} /> */}
              </div>
            )}
          </div>
        </DialogContent>
      </BootstrapDialog>
    </BaseContainerPl>
  );
}

