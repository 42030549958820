/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Api from "./Api";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import clsxm from "../../context/utils/clsxm";
import PARSE from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";

function TBS_Transformation() {
  const [sections, setSections] = useState([]);
  const [sectionName, setSectionName] = useState("");

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      const apiSectionData = res.data.results;
      setSections(apiSectionData);
      setSectionName(apiSectionData[0].slug_name);

      return sections;
    });
  }, [sections]);

  useEffect(() => {
    getSectionDetail();
  }, []);

  let headTitle;
  let headContent;
  let descriptionText;
  let headAsset;
  let listContent;
  let gmsContent;

  if (sections) {
    let sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const header =
              section?.widget_contents ?? section.widget_contents[0];
            headTitle = header[0]?.article_language?.title;
            descriptionText = header[0]?.article_language?.summary;
            headContent = header[0]?.article_language?.content;
            headAsset = header[0]?.assets[0];
            break;
          case 1:
            gmsContent = section?.widget_contents[0];

            break;
          case 2:
            listContent = section?.widget_contents;

            break;

          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      <div className="section-width-new">
        <div className=" border-b-[2px] space-x-[24px] pb-[16px]">
          <h2 className="heading-titile-grid-2023 ">{headTitle}</h2>
        </div>
        <div className="relative mt-[45px]">
          {headContent && (
            <div className="mt-[45px]">
              <div className="font-inter mt-[45px] prose-p:text-[16px] prose-p:leading-[26px] text-[#1A1A1A] leading-[26px] prose-strong:font-inter-bold prose-strong:font-semibold prose-strong:leading-[25px] !text-justify">
                {PARSE(headContent)}
              </div>
            </div>
          )}
        </div>

        <div className="mt-[31px]">
          {" "}
          <LazyLoadImage
            className="h-auto md:w-auto !w-[1360px] "
            src={replaceAssetLink(gmsContent?.assets[0]?.file_medium)}
            alt={gmsContent?.assets[0]?.description}
          />
          {gmsContent?.article_language.content && (
            <div className="content-grid-2023 mt-[31px]">
              {PARSE(gmsContent?.article_language.content)}
            </div>
          )}
        </div>

        <div className={` gflex flex-row gap-[39px] space-y-[13px] mt-[54px]`}>
          {listContent &&
            listContent?.map((data, idx) => {
              const content = data.article_language.content;
              const title = data.article_language.title;
              const summary = data.article_language.summary;
              const contentImage = replaceAssetLink(data.assets[0]?.asset_file);
              const contentDesc = replaceAssetLink(data.assets[0]?.description);

              return (
                <div
                  key={data.id}
                  className=" flex relative bg-tbs-dark-green/5 "
                >
                  <div className="py-[10px] pl-[10px] pr-[24px]">
                    <div className="md:w-[160px] w-[81px] rounded-[10px] md:h-[160px] h-[81px] bg-tbs-dark-green border flex items-center justify-center relative ">
                      <img
                        src={contentImage}
                        alt={contentDesc}
                        className="w-auto md:h-[70px] h-[40px] mx-auto"
                      />
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col md:justify-around w-full">
                    {content && (
                      <div className="font-inter text-[18px] leading-[24px]  typo-stylce  py-[21px] !w-fit  ">
                        <div className="max-w-[388px]">{PARSE(content)}</div>
                      </div>
                    )}
                    <div className="font-inter-600 text-[20px] leading-[22px]  md:p-[21px] py-[22px] md:border-l md:border-t-0 border-t border-tbs-dark-green/20 md:w-[182px] md:ml-auto">
                      {title}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </BaseContainerPl>
  );
}

export default TBS_Transformation;
