/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { AdvancedChart } from "react-tradingview-embed";
import axios from "axios";
import moment from "moment";
import Api from "./Api";
import Styles from "./TBS_Stock_Chart.module.css";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";

function TBS_Stock_Chart() {
  const [section, setSection] = useState([]);
  const [slugName, setSlugName] = useState("");
  const [stockData, setStockData] = useState([]);
  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getData = useCallback(() => {
    axios
      .get("https://api.bzpublish.com/utilities/stock_market_detail/")
      .then((response) => {
        setStockData(response?.data.optionChain.result[0]?.quote);
      });
  }, []);

  useEffect(() => {
    getData();
  }, [activeLang]);

  useEffect(() => {
    const FetchData = async () => {
      const api = new Api();
      try {
        const sectionResponse = await api.getSectionDetail({
          language: activeLang,
        });
        const sectionData = await sectionResponse.data;
        const sections = sectionData.results[0];
        const sectionWidget = sections.widgets;
        const sectionSlugName = sections.slug_name;

        setSection(sectionWidget);
        setSlugName(sectionSlugName);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [activeLang]);

  const MarketStatus = (status) => {
    if (status === true) {
      return "OPEN";
    } else {
      return "CLOSED";
    }
  };

  return (
    <BaseContainerPl
      sectionName={slugName}
      sectionIndex={section && section.length > 0 && section[0].index}
    >
      <div className="section-width space-y-4">
        <div className={Styles.title}>
          <h2 className="heading-title">
            {section && section[0]?.widget_contents[0]?.content}
          </h2>
          <h3 className="heading-sub-title">
            {section && section[0]?.widget_contents[1]?.content}
          </h3>
        </div>
        <div className="space-y-4 w-full">
          <AdvancedChart
            widgetProps={{
              height: "427px",
              range: "D",
              theme: "light",
              timezone: "Asia/Jakarta",
              symbol: "IDX:TOBA",
              interval: "D",
              style: "9",
              locale: "en",
              enable_publishing: false,
              hide_top_toolbar: true,
              hide_side_toolbar: true,
              toolbar_bg: "#f1f3f6",
              withdateranges: true,
              allow_symbol_change: true,
            }}
          />
          <div className="w-full border-2 mt-4">
            <div className="flex flex-col s:flex-row gap-y-2 justify-between p-2">
              <p>{moment().format("DD MMMM YYYY")}</p>
              <p>PT TBS Energi Utama Tbk</p>
            </div>
            <div className="w-full flex border-t-2">
              <div className="w-full border-r-2 1">
                <div className="w-full py-4 px-2 min-h-[80px] s:h-fit">
                  <p className="font-bold">TBS Energi Utama Tbk. (TOBA.JK)</p>
                </div>
                <div className="w-full flex justify-between border-t-2">
                  <div className="w-full">
                    <div className="p-2 border-b-2 font-semibold">HIGH</div>
                    <div className="p-2 font-semibold">LOW</div>
                  </div>
                  <div className="w-full">
                    <div className="p-2 border-b-2 grid justify-end">
                      <p>
                        {Intl.NumberFormat("en-US").format(
                          stockData?.regularMarketDayHigh
                        )}
                      </p>
                    </div>
                    <div className="p-2 grid justify-end">
                      <p>
                        {Intl.NumberFormat("en-US").format(
                          stockData?.regularMarketDayLow
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full 2">
                <div className="w-full min-h-[80px] s:h-fit py-4 px-2 grid justify-end">
                  <p className="font-bold">
                    {stockData?.bid !== 0
                      ? stockData?.bid
                      : stockData?.regularMarketPreviousClose}
                  </p>
                </div>
                <div className="w-full flex justify-between border-t-2">
                  <div className="w-full">
                    <div className="p-2 border-b-2 font-semibold">OPEN</div>
                    <div className="p-2 font-semibold">VOLUME</div>
                  </div>
                  <div className="w-full">
                    <div className="p-2 border-b-2 grid justify-end">
                      <p>{stockData?.regularMarketOpen}</p>
                    </div>
                    <div className="p-2 grid justify-end">
                      <p>{stockData?.regularMarketVolume}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col s:flex-row gap-y-2 s:gap-y-0 justify-between">
            <div>{`Jakarta Delayed Price.  Currency: ${stockData?.currency}`}</div>
            <div>{`As of ${moment().format("hh:mm A")}.`}</div>
          </div>
        </div>
      </div>
    </BaseContainerPl>
  );
}

export default TBS_Stock_Chart;
