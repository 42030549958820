/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "./Api";
// import Image from './assets/logo.png';
import replaceAssetLink from "../../context/utils/replaceAssetLink";

function TBS_Partners_Name() {
  const [sections, setSection] = useState([]);
  const [slugName, setSlugName] = useState("");
  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const FetchData = async () => {
      const api = new Api();
      try {
        const sectionResponse = await api.getSectionDetail({
          language: activeLang,
        });
        const sectionData = await sectionResponse.data;
        const sectionWidgets = sectionData.results[0].widgets;
        const sectionId = sectionData.results[0].slug_name;

        setSlugName(sectionId);
        setSection(sectionWidgets);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [activeLang]);

  let partners;
  if (sections) {
    sections.forEach((data, id) => {
      const { widget_contents } = data;

      if (id === 0) {
        partners = widget_contents;
      }
    });
  }

  return (
    <div
      id={`${slugName}`}
      className={`section-container-pl  ${
        sections.length > 0 && sections[0].index === 3
          ? "mt-index-section__3"
          : "mt-index-section"
      }`}
    >
      <div
        className={`section-width grid grid-cols-1 md:grid-cols-2 gap-[26px]`}
      >
        {partners &&
          partners?.map((data) => {
            const contentNames = data.article_language.title;
            const contentPath = data?.article_language?.path;
            const contentImage = replaceAssetLink(data.assets[0]?.file_small);
            const contentDesc = replaceAssetLink(data.assets[0]?.description);

            return contentPath ? (
              <div
                key={data.id}
                className="w-full h-[138px] bg-[#F5F5F5] flex justify-center items-center"
              >
                <a href={contentPath} target="_blank">
                  <div className="w-[250px] flex flex-col justify-between gap-y-[18px]">
                    <div className="flex justify-center">
                      <img
                        className="h-[38px] w-auto"
                        src={contentImage}
                        alt={contentDesc || contentNames}
                      />
                    </div>
                    <h3 className="text-[16px] font-bold flex justify-center text-center">
                      {contentNames}
                    </h3>
                  </div>
                </a>
              </div>
            ) : (
              <div
                key={data.id}
                className="w-full h-[138px] bg-[#F5F5F5] flex justify-center items-center"
              >
                <div className="w-[250px] flex flex-col justify-between gap-y-[18px]">
                  <div className="flex justify-center">
                    <img
                      className="h-[38px] w-auto"
                      src={contentImage}
                      alt={contentDesc || contentNames}
                    />
                  </div>
                  <h3 className="text-[16px] font-bold flex justify-center text-center">
                    {contentNames}
                  </h3>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TBS_Partners_Name;

