/* eslint-disable */

import React, { useState, useEffect, useCallback } from "react";
import TruncateMarkup from "react-truncate-markup";
import PARSE from "html-react-parser";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Api from "./Api";
import BaseArrowLinkTw from "../../context/components/Base/BaseArrowLinkTw";
import { useSelector } from "react-redux";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

import { LiteYoutubeEmbed } from "react-lite-yt-embed";

import { Splide, SplideSlide } from "@splidejs/react-splide";

export default function TBSHomeBusinessType() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      // console.log(sectionsData.data.results);
      const apiSectionData = res.data.results;
      setSections(apiSectionData);

      return sections;
    });
    // console.log(response);
  }, [sections]);

  useEffect(() => {
    getSectionDetail();
  }, []);

  let learnText;
  let title;
  let content;
  let artList;
  let asset;
  let summary;
  let arrow;
  let tbsVideo;
  let listMediaVideo;

  if (sections) {
    let sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const header =
              section?.widget_contents ?? section.widget_contents[0];
            title = header[0]?.article_language.title;
            content = header[0]?.article_language.content;
            summary = header?.article_language?.summary;
            asset = header?.assets && header?.assets[0]?.file_small;
            break;
          case 1:
            artList = section?.widget_contents;
            // setArticle(section?.widget_contents);

            break;
          case 2:
            const text = section?.widget_contents ?? section.widget_contents[0];
            if (text && text.length !== 0) {
              learnText = text[0].content;
            }
            break;
          case 3:
            const media = section.widget_contents[0];
            if (media && media.length !== 0) {
              arrow = replaceAssetLink(media.file);
            }
            break;
          case 4:
            const mediaVideo = section.widget_contents[1];
            listMediaVideo = section.widget_contents;
            if (mediaVideo && mediaVideo.length !== 0) {
              tbsVideo = mediaVideo.link || mediaVideo.file;
            }
            break;
          default:
            break;
        }
      });
    }
  }

  function getEmbedCode(url) {
    let embedCode = url.split("/").pop().split("?")[0];
    return embedCode;
  }

  function getEmbedCodeWatch(url) {
    let params = new URLSearchParams(url.split("?")[1]);
    let embedCode = params.get("v");
    return embedCode;
  }

  return (
    <div className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mt-[80px] mx-auto">
      <div className="   hd:max-w-[80%] xl:max-w-[90%]  mx-auto ">
        <Splide
          className="custom-splide-yt !h-fit"
          options={{
            type: "slide",
            // rewind: "false",
            arrows: true,
            perPage: 1,
            perMove: 1,
            gap: "1rem",
          }}
        >
          {listMediaVideo?.map((vid) => (
            <SplideSlide key={vid.id} className="rounded-md ">
              {" "}
              <LiteYoutubeEmbed
                mute={false}
                // defaultPlay
                id={
                  vid?.link.includes("watch")
                    ? getEmbedCodeWatch(vid?.link)
                    : getEmbedCode(vid?.link)
                }
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <div className="hd:mt-[80px] mt-[45px]">
        {title && <h2 className="heading-summary ">{title}</h2>}
        <h2 className="heading-summary"> {summary}</h2>
        <div className="margin-content">
          <div className="">
            {asset && (
              <LazyLoadImage
                src={replaceAssetLink(asset)}
                alt=""
                className=" mb-3 w-full"
              />
            )}
          </div>
          {/* {content && <div className="typo-style">{PARSE(content)}</div>} */}
        </div>
        <div className="mt-[45px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mdl:grid-cols-3 md:gap-y-5 hd:gap-x-11  gap-x-5   gap-y-6 justify-items-center">
          {artList?.map((art, i) => (
            <div
              key={art.id}
              className="w-fit flex group relative !self-center"
            >
              <div className="space-y-8 relative overflow-hidden">
                {art?.assets[0]?.file_small && (
                  <LazyLoadImage
                    src={replaceAssetLink(art?.assets[0]?.file_small)}
                    className="max-h-[540px] min-h-[450px] transition duration-700 ease-in-out w-[100vw] object-top group-hover:scale-105"
                    title={art?.assets[0]?.description}
                    alt={art?.assets[0]?.description}
                  />
                )}
              </div>
              <div className="absolute  xl:px-11 md:px-5 s:px-3 px-7 flex flex-col hdl:bottom-24 hd:bottom-16 xl:bottom-16 mdl:bottom-6  md:bottom-12 s:bottom-32 bottom-12 gap-y-[18px]">
                <div className="space-y-4">
                  <h3 className="text-[#ECF5F1] font-inter-bold hd:text-[28px] md:text-[24px] text-[22px] leading-[28px]">
                    {art?.article_language?.title}
                  </h3>
                  <TruncateMarkup lines={17}>
                    <div className="text-[#ECF5F1] max-w-lg font-inter  leading-[20px]  text-[16px]">
                      {art?.article_language?.summary}
                    </div>
                  </TruncateMarkup>
                </div>
                <Link to={`${art?.article_language?.path}`}>
                  <div className="flex items-center gap-x-4">
                    <div className="text-[#ECF5F1] font-inter-bold ">
                      {learnText}
                    </div>
                    <BaseArrowLinkTw size="small" color="white" />
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
