/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PARSE from "html-react-parser";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Api from "./Api";
import BaseArrowLinkTw from "../../context/components/Base/BaseArrowLinkTw";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

export default function TBS_Article_HasLink() {
  const [sections, setSections] = useState([]);
  const [widgets, setWidgets] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    const sectionsData = response.data.results;
    const sectionsWidgets = sectionsData[0]?.widgets;

    setSections(sectionsData);
    setWidgets(sectionsWidgets);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let media;
  let sectionName;
  let path;
  let textLink;
  if (sections) {
    sectionName = sections[0]?.slug_name;

    if (widgets && widgets.length !== 0) {
      widgets.forEach((section, i) => {
        switch (i) {
          case 0:
            const article = section.widget_contents[0];
            const {
              title: articleTitle,
              summary: articleSummary,
              content: articleContent,
              path: articlePath,
              assets,
            } = article?.article_language || {};

            title = articleTitle;
            summary = articleSummary;
            content = articleContent;
            path = articlePath;
            media = replaceAssetLink(
              assets?.[0]?.file_medium || assets?.[0]?.asset_file
            );

            break;
          case 1:
            const text = section.widget_contents[0];
            textLink = text?.content;

            break;
          case 2:
            break;
          default:
            break;
        }
      });
    }
  }

  const doc = new DOMParser().parseFromString(content, "text/html");

  const tagsToRemove = "img";
  for (const elm of doc.querySelectorAll("*")) {
    if (elm.matches(tagsToRemove)) {
      elm.replaceWith(
        `<img src='${elm.src}' alt='${title}' height='692' width='auto' />`
      );
    }
  }

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      {sections.length > 0 ? (
        <div className="section-width">
          <h2 className="heading-title"> {title}</h2>
          <h3 className="heading-summary"> {summary}</h3>
          <div className="margin-content">
            <div className="">
              {media && <img src={media} alt="" className=" mb-3 w-full" />}
            </div>
            {content && (
              <article className="typo-style prose-h2:flex prose-h2:items-center">
                {PARSE(
                  doc.body.innerHTML.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
                )}
              </article>
            )}
          </div>
          {widgets && widgets.length > 1 ? (
            <div className="flex h-[20px] mr-3 w-full mt-[45px] group">
              <Link className="flex gap-x-3" to={`${path}` ?? `/`}>
                <p className="font-inter-bold">{textLink && textLink}</p>
                <div className="mt-[3px]">
                  <BaseArrowLinkTw size="small" color="black" />
                </div>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="section-width">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </BaseContainerPl>
  );
}