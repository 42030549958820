import React, { Suspense, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import Api from "./Api";

const getWidgetContent = (widget) => {
  if (!widget.length) {
    return false;
  }
  return widget.map((w) => w);
};

const getData = (item) => {
  const items = {
    media: [],
    text: [],
  };
  if (item.widgets.length) {
    item.widgets.forEach((itm, i) => {
      if (i === 0) {
        items.media = getWidgetContent(itm.widget_contents);
      }
      if (i === 1) {
        items.text = getWidgetContent(itm.widget_contents);
      }
    });
  }
  return items;
};

const title = "404";
const description = "This page could not be found";
const api = new Api();
export default function TBS_Error() {
  const activeLanguage = useSelector((state) => state.content.activeLanguage);

  const [sections, setSections] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const getSection = useCallback(() => {
    api
      .getSectionDetail({ language: activeLanguage })
      .then((res) => {
        if (res.status === 200 && res.data.results?.length) {
          setSections(getData(res.data.results[0]));
        }
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [activeLanguage]);

  React.useLayoutEffect(() => {
    if (!isLoaded && !sections) {
      getSection();
    }
  }, [isLoaded, sections, getSection]);

  return (
    <Suspense fallback={<div />}>
      <div className="relative w-screen h-[75vh] flex justify-center items-center font">
        <div className="flex items-center">
          <h1 className="font-inter-bold text-[18px] md:text-[26px] hd:text-[32px] fhd:text-[38px] border-r border-black pr-4">
            {sections?.text[1]?.content ?? title}
          </h1>
          <p className="font-inter pl-4">
            {sections?.text[2]?.content ?? description}
          </p>
        </div>
      </div>
    </Suspense>
  );
}

