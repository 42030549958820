/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Api from "./Api";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import clsxm from "../../context/utils/clsxm";
import PARSE from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";

function TBS_Empowered_People() {
  const [sections, setSections] = useState([]);
  const [sectionName, setSectionName] = useState("");

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      const apiSectionData = res.data.results;
      setSections(apiSectionData);
      setSectionName(apiSectionData[0].slug_name);

      return sections;
    });
  }, [sections]);

  useEffect(() => {
    getSectionDetail();
  }, []);

  let headTitle;
  let descriptionText;
  let headAsset;
  let listContent;

  if (sections) {
    let sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const header =
              section?.widget_contents ?? section.widget_contents[0];
            headTitle = header[0]?.article_language?.title;
            descriptionText = header[0]?.article_language?.summary;
            headAsset = header[0]?.assets[0];
            break;
          case 1:
            listContent = section?.widget_contents;

            break;

          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      <div className="section-width-new">
        <div className="flex items-end border-b-[2px] space-x-[24px] pb-[12px]">
          <LazyLoadImage
            className="h-auto w-auto"
            src={replaceAssetLink(headAsset?.asset_file)}
            alt={headAsset?.description}
          />
          <h2 className="heading-titile-grid-2023 mt-[7px]">{headTitle}</h2>
        </div>
        <div
          className={` grid grid-cols-2 md:grid-cols-3 md:gap-[24px] gap-[18px] mt-[40px]`}
        >
          {listContent &&
            listContent?.map((data, idx) => {
              const content = data.article_language.content;
              const contentImage = replaceAssetLink(data.assets[0]?.asset_file);
              const contentDesc = replaceAssetLink(data.assets[0]?.description);

              return (
                <div
                  key={data.id}
                  className={clsxm(
                    "w-full border rounded-[10px] border-tbs-blue  flex  items-center md:px-[20px] px-[11px] md:py-[24px] py-[10px]"
                    // idx > 3 ? "col-span-2" : ""
                  )}
                >
                  <div className=" flex flex-col  gap-y-[18px]">
                    <div className="flex justify-center">
                      <LazyLoadImage
                        className="h-auto w-auto"
                        src={contentImage}
                        alt={contentDesc || content}
                      />
                    </div>
                    {content && (
                      <div className="content-grid-2023">{PARSE(content)}</div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </BaseContainerPl>
  );
}

export default TBS_Empowered_People;
