/* eslint-disable */

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import PARSE from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import Api from "./Api";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";

export default function TBS_2030New() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let downloadFile;
  let title;
  let summary;
  let content;
  let sectionName;
  let cardArticle;
  let futureArticle;
  let progressArticle;

  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const articleContent = section.widget_contents[0];
            title = articleContent?.article_language?.title;
            summary = articleContent?.article_language?.summary;
            content = articleContent?.article_language?.content;

            break;

          case 1:
            const cardContent = section.widget_contents;
            cardArticle = cardContent;
            break;
          case 2:
            const otherContent = section.widget_contents[0];
            futureArticle = otherContent;
            break;

          case 3:
            progressArticle = section.widget_contents[0];

            break;
          default:
            break;
        }
      });
    }
  }

  return (
      <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      {sections.length > 0 ? (
        <div className="relative  section-width-new xl:mx-0 mx-auto">
          <div className="">
            <h2 className="heading-title-new"> {title}</h2>
            <div className="relative mt-[45px]">
              {content && (
                <div className="mt-[45px]">
                  <div className="font-inter mt-[45px] prose-p:text-[16px] prose-p:leading-[26px] text-[#1A1A1A] leading-[26px] prose-strong:font-inter-bold prose-strong:font-semibold prose-strong:leading-[25px] !text-justify">
                    {PARSE(content)}
                  </div>
                </div>
              )}
            </div>
            <div className="grid md:grid-cols-3 s:grid-cols-2 grid-cols-1 md:gap-[24px] gap-[48px] mt-[75px]">
              {cardArticle.map((art) => {
                const artTitle = art?.article_language?.title;
                const artAsset = art?.assets[0]?.asset_file;
                const artContent = art?.article_language?.content;

                return (
                  <div
                    key={art.id}
                    className="p-[20px] bg-[#15363F0D] relative"
                  >
                    <div className="flex space-x-2 items-center">
                      <div className="absolute top-0 p-[10px] border -translate-y-1/2 left-1/2 -translate-x-1/2 rounded-full bg-white  h-[73px] w-[73px] flex items-center justify-center">
                        {artAsset && (
                          <img
                            src={artAsset}
                            alt={art?.assets[0]?.description}
                            className="w-auto h-auto mx-auto"
                          />
                        )}
                      </div>
                      <div className="font-inter-bold text-[20px] leading-[22px]  mt-[31px] text-center w-full">
                        {artTitle}
                      </div>
                    </div>
                    {artContent && (
                      <div className="font-inter text-[18px] leading-[24px] mt-[24px]">
                        {PARSE(artContent)}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-[45px] ">
            <h4 className="text-[25px] font-inter-600 !font-[600]">
              <strong>{futureArticle?.article_language?.title}</strong>
            </h4>
            {futureArticle?.article_language?.content && (
              <div className="!my-[25px] typo-style">
                {PARSE(futureArticle?.article_language?.content)}
              </div>
            )}
            <a
              href={replaceAssetLink(futureArticle?.assets[0]?.asset_file)}
              target="_blank"
              rel="noreferrer"
            >
              <div className="md:w-fit w-full text-center mt-[23.5px] px-[44px] py-[9px] button-new border rounded-[20px] md:text-[16px] text-[14px]">
                {futureArticle?.article_language?.summary}
              </div>
            </a>
          </div>

          <div className="mt-[39px]">
            <h4 className="text-[25px] font-inter-600 !font-[600]">
              <strong>{progressArticle?.article_language?.title}</strong>
            </h4>
            <div className="mt-[25px] typo-style">
              {progressArticle?.article_language?.content &&
                PARSE(progressArticle?.article_language?.content)}
            </div>
            <a
              href={replaceAssetLink(progressArticle?.assets[0]?.asset_file)}
              target="_blank"
              rel="noreferrer"
            >
              <div className="md:w-fit w-full text-center mt-[23.5px] px-[44px] py-[9px] button-new border rounded-[20px] md:text-[16px] text-[14px]">
                {progressArticle?.article_language?.summary}
              </div>
            </a>
          </div>
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="max-w-2xl">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </BaseContainerPl>
  );
}
