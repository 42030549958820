/* eslint-disable */

import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import replaceAssetLink from "../../utils/replaceAssetLink";

export default function BaseRelatedPages({ menus, title }) {
  function renderSplide(menu) {
    const trans = menu?.url?.replace(/-/g, " ");
    const splitTrans = trans?.split("/");

    const asset = menu?.article?.article_assets[0]?.asset.file_medium;
    return (
      <SplideSlide key={menu.id} className="w-full">
        <Link to={menu?.url ?? "/"}>
          <div className="">
            <div className="bg-[#F5F5F5] h-[175px] p-[30px]">
              {splitTrans?.map((t, i) => (
                <div key={t} className="">
                  {i === splitTrans?.length - 2 && (
                    <div className="capitalize font-inter text-sm">{t}</div>
                  )}
                  {i === splitTrans?.length - 1 && (
                    <div className=" font-inter-bold">{menu.title}</div>
                  )}
                </div>
              ))}
            </div>
            <div className="w-full">
              <img
                src={
                  asset
                    ? replaceAssetLink(asset)
                    : "https://bzpublishassets.blob.core.windows.net/media/assets/the-blowup-qafbp6O0Rr0-unsplash_16.png"
                }
                alt={menu.title}
                className="w-full h-60 object-cover"
              />
            </div>
          </div>
        </Link>
      </SplideSlide>
    );
  }

  return (
    <div className="w-full">
      <div className="py-10 font-inter-bold text-2xl">{title}</div>
      <Splide
        className="w-full"
        options={{
          type: "slide",
          rewind: "false",
          perPage: 4,
          perMove: 1,
          gap: "1rem",
          pagination: false,
          arrows: false,
          breakpoints: {
            623: {
              perPage: 1,
              perMove: 1,
            },
            1000: {
              perPage: 2,
              perMove: 1,
            },
            1024: {
              perPage: 3,
              perMove: 1,
            },
            1600: {
              perPage: 4,
              perMove: 1,
            },
            1920: {
              perPage: 4,
              perMove: 1,
            },
            2560: {
              perPage: 4,
              perMove: 1,
            },
          },
        }}
      >
        {menus?.map((menu) => renderSplide(menu))}
      </Splide>
    </div>
  );
}
