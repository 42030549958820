/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useParams, useLocation, useNavigate } from 'react-router';
import PARSE from 'html-react-parser';
import Api from './Api';
import GlobalApi from '../../context/api/GlobalApi';
import BaseArrowLinkTw from '../../context/components/Base/BaseArrowLinkTw';
import Styles from './TBS_People.module.css';
import DefaultImage from './assets/empty_default.png';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';
import replaceAssetLink from '../../context/utils/replaceAssetLink';

function TBS_People() {
  const [section, setSection] = useState([]);
  const [article, setArticle] = useState([]);
  const [slugName, setSlugName] = useState('');
  const activeLang = useSelector((state) => state.content.activeLanguage);

  const location = useLocation()

  // console.log('location ', location);

  useEffect(() => {
    const FetchData = async () => {
      const api = new Api();
      const globalApi = new GlobalApi();

      try {
        const sectionResponse = await api.getSectionDetail({ language: activeLang });
        const sectionData = await sectionResponse.data;
        const sections = sectionData.results[0];
        const sectionWidgets = sections.widgets;
        const sectionSlugName = sections.slug_name;

        if (sectionWidgets) {
          const sec = sectionWidgets.filter((data) => data.is_internal === false);
          const secInternal = sectionWidgets.filter((data) => data.is_internal === true);
          const articleResponse = await globalApi.getArticleAllLangFalse({ widgets: sec[0].id, max_size: true });
          const articleData = await articleResponse.data.results;

          setArticle(articleData);
          setSection(secInternal);
        } else {
          throw new Error('Error trying to get property result');
        }


        setSlugName(sectionSlugName);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [activeLang]);

  let pathname;
  let textTitle;
  let read_more;
  if (section) {
    section.forEach((data, id) => {
      const { widget_contents } = data;

      if (id === 0) {
        textTitle = widget_contents[0]?.article_language;
      }

      if (id === 1) {
        read_more = widget_contents[0]?.content;
        // data_people = widget_contents;
      }

      if (id === 2) {
        pathname = widget_contents[0]?.article_language.path;
      }
    });
  }

  return (
    <BaseContainerPl sectionName={slugName} sectionIndex={section && section.length > 0 && section[0].index}>
      <div className="section-width min-h-[61.8rem] relative space-y-10">
        <div className="w-full h-full relative">
          <div className={`w-full ${Styles.title}`}>
            <h2 className="heading-title">{textTitle?.title}</h2>
            <div className="w-full prose text-justify mt-[45px]">{textTitle && PARSE(textTitle?.content)}</div>
          </div>
        </div>
        <div className="relative mt-0 space-y-10">
          {article &&
            article?.map((data, idx) => {
              const dataAssets = data?.article_assets[0]?.asset;
              const {article_languages} = data;
              const name = article_languages?.title;
              const slugTitle = article_languages?.slug_title;
              const profileContent = article_languages?.content;
              const occ = article_languages?.summary;
              const idArticle = article_languages?.article;

              return (
                <div key={data.id} className={idx % 2 === 0 ? `w-full h-auto flex justify-center` : `w-full h-auto flex justify-center`}>
                  <div className="w-full s:w-[25rem] h-full group">
                    <div className={`w-full h-[270px] overflow-hidden ${Styles['card-img']}`}>
                      <img 
                        className="w-full h-full transition duration-700 ease-in-out object-cover bg-cover group-hover:scale-[1.1]" 
                        src={data?.article_assets.length ? replaceAssetLink(dataAssets.file_medium) : replaceAssetLink(DefaultImage)} 
                        alt={dataAssets?.description} 
                        width={400}
                        height={270}
                      />
                    </div>
                    <div className="w-full flex flex-col gap-y-2 justify-between shadow-lg rounded relative mt-[-1.8rem] bg-white p-4">
                      <div className={Styles.name}>
                        <p>{name}</p>
                      </div>
                      <div className={Styles.position}>{occ && PARSE(occ)}</div>
                      <div className="flex h-[20px] mr-3 w-full">
                        { pathname &&
                          profileContent ? (
                            <Link to={`${pathname}/${idArticle}/${slugTitle}` ?? '/'}>
                              <div className="flex gap-x-3">
                                <p className={Styles.more}>{read_more}</p>
                                <div className="mt-[3px]">
                                  <BaseArrowLinkTw size="small" color="black" />
                                </div>
                              </div>
                            </Link>
                          ) : (
                            <div className="flex gap-x-3">
                              <p className={Styles.more}>{read_more}</p>
                              <div className="mt-[3px]">
                                <BaseArrowLinkTw size="small" color="black" />
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </BaseContainerPl>
  );
}

export default TBS_People;
