/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PARSE from 'html-react-parser';
import Api from './Api';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';

export default function TBSAboutShareholder() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let name;

  let summary;
  let content;
  let path;
  let sectionName;
  let tableHeader;
  let tableContent;
  let tableName;
  let mainTabel;
  let bgTable;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;

    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text?.article_language?.title;
            name = text?.name;
            summary = text?.article_language?.summary;
            content = text?.article_language?.content;

            break;
          case 1:
            const table = section.widget_contents[0];
            mainTabel = table;

            tableHeader = table.table_language.headers;
            tableContent = table.table_language.content;
            tableName = table.table_language.name;

            break;
          case 2:
            const link = section.widget_contents[0];
            path = link?.article_language?.content;

            break;
          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl sectionName={sectionName} sectionIndex={sections && sections.length > 0 && sections[0].index}>
      <div className="section-width">
        <h2 className="heading-title"> {title}</h2>
        <h3 className="heading-summary"> {summary}</h3>
        <div className="margin-content">
          {/* <div className="">{icon && <img src={icon} alt="" className=" mb-3 w-full" />}</div> */}
          {content && <article className="typo-style ">{PARSE(content)}</article>}
        </div>
        <div className="mt-10">
          <table id="customers" className="table-auto w-full">
            <thead>
              <tr className="">
                {tableHeader?.map((head) => (
                  <th className="border-2 border-white px-4 py-2 text-white bg-[#18467f] font-medium">{head}</th>
                ))}
              </tr>
            </thead>
            <tbody className="border-2 border-white px-4 py-2  font-medium">
              <th colspan="3" className="border-2 border-white px-4 py-2 text-white bg-[#50966b] font-medium text-left">
                {tableName}
              </th>
              {tableContent?.map((head) => (
                <tr key={head} className="border-2 border-white px-4 py-2 bg-[#F2F5F6] font-medium ">
                  {head?.map((cont, i) => (
                    <td className={` ${i === 0 ? 'text-left px-4' : 'pr-5 text-right  ' + 'border-2 border-white  py-2 bg-[#F2F5F6] font-medium '} `}>{cont}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {path && <div className="typo-style prose-p:!text-[14px] !mt-[15px] prose-a:!no-underline">{PARSE(path)}</div>}
        </div>
      </div>
    </BaseContainerPl>
  );
}
