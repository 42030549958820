/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import PARSE from 'html-react-parser';
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from 'react-redux';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';
import replaceAssetLink from '../../context/utils/replaceAssetLink';
import Api from './Api';

export default function TBSRegulerTable() {
  const [sections, setSections] = useState([]);
  const dispatch = useDispatch();

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let icon;
  let sectionName;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text?.article_language?.title;
            summary = text?.article_language?.summary;
            content = text?.article_language?.content;
            icon = replaceAssetLink(text?.assets[0]?.asset_file);

            break;
          case 1:
            break;
          case 2:
            break;
          default:
            break;
        }
      });
    }
  }

  const doc = new DOMParser().parseFromString(content, 'text/html');
  let arrH4Tag = [];

  const tagsToRemove = 'h4';
  for (const elm of doc.querySelectorAll('*')) {
    if (elm.matches(tagsToRemove)) {
      arrH4Tag.push(elm.outerText);
      elm.replaceWith(
        `<div id='${elm.outerText
          .replace(/[, ]+/g, "-")
          .toLowerCase()}' class='scrollTop'><h4>${elm.outerText}</h4></div>`
      );
    }

    if (elm.matches('tbody')) {
      if (elm.matches('a')) {
      }
    }
  }

  return (
    <BaseContainerPl sectionName={sectionName} sectionIndex={sections && sections.length > 0 && sections[0].index}>
      {
        sections.length > 0 ? (
          <div className="section-width">
            <h2 className="heading-title"> {title}</h2>
            <h3 className="heading-summary"> {summary}</h3>
            {icon && (
              <div className="mt-[45px]">
                {' '}
                <img src={icon} alt="" className="  w-full" />
              </div>
            )}
            {content && (
              <div className="mt-[45px]">
                {/* <TocComponent toc={PARSE(content)} /> */}
                {/* <div className="reguler-table prose">{PARSE(doc.body.innerHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>'))}</div> */}
                <div className="reguler-table prose font-inter text-black !table-auto">{PARSE(doc.body.innerHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>'))}</div>
              </div>
            )}
          </div>
        ):(
          <Box className="section-width space-y-[45px]">
            <Skeleton className='h-[100px]' />
            <Skeleton className='min-h-[900px]' />
          </Box>
        )
      }
    </BaseContainerPl>
  );
}
