/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PARSE from "html-react-parser";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InnerImageZoom from "react-inner-image-zoom";
import Api from "./Api";
import BaseArrowLinkTw from "../../context/components/Base/BaseArrowLinkTw";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { LiteYoutubeEmbed } from "react-lite-yt-embed";

function getEmbedCode(url) {
  let embedCode = url.split("/").pop().split("?")[0];
  return embedCode;
}

function getEmbedCodeWatch(url) {
  let params = new URLSearchParams(url.split("?")[1]);
  let embedCode = params.get("v");
  return embedCode;
}

export default function TBS_Whistleblower_Policy() {
  const [sections, setSections] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [openFormUrl, setOpenFormUrl] = useState(false);

  // console.log('isDisabledButton ', isDisabledButton);
  // console.log('openFormUrl ', openFormUrl);

  const activeLang = useSelector((state) => state.content.activeLanguage);
  // let is_modal_show = window.sessionStorage.getItem('alreadyShow');

  // useEffect(() => {
  //   window.sessionStorage.setItem('policyApproval', false)
  // }, [])

  useEffect(() => {
    if (openFormUrl === true) {
      window.sessionStorage.setItem("policyApproval", true);
    }
  }, [openFormUrl]);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    const section = sectionsData.data.results;
    const sectionsWidgets = section[0]?.widgets;

    setSections(section);
    setWidgets(sectionsWidgets);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let media;
  let sectionName;
  let path;
  let tbsVideo;
  const textLink = [];
  if (sections) {
    sectionName = sections[0]?.slug_name;

    if (widgets && widgets.length !== 0) {
      widgets.forEach((section, i) => {
        switch (i) {
          case 0:
            const article = section.widget_contents[0];
            title = article?.article_language?.title;
            summary = article?.article_language?.summary;
            content = article?.article_language?.content;
            path = article?.article_language?.path;
            media =
              replaceAssetLink(article?.assets[0]?.file_medium) ||
              replaceAssetLink(article?.assets[0]?.asset_file);

            break;
          case 1:
            section.widget_contents?.map((data) => {
              textLink.push(data?.content);
            });
            break;
          case 2:
            const listMediaVideo = section.widget_contents[0];
            console.log(
              "🚀 ~ widgets.forEach ~ listMediaVideo:",
              listMediaVideo
            );
            if (listMediaVideo && listMediaVideo.length !== 0) {
              tbsVideo = listMediaVideo.link || listMediaVideo.file;
            }
            break;
          default:
            break;
        }
      });
    }
  }

  const handleCheckboxClick = () => {
    setIsDisabledButton(!isDisabledButton);
    setOpenFormUrl(!openFormUrl);
  };

  return (
    <BaseContainerPl
      sectionName={`${sectionName} break-all`}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      {sections.length > 0 ? (
        <div className="section-width">
          <div className="mb-[45px]">
            {" "}
            {/* <video
              style={{
                backgroundColor: "black",
                maxWidth: "100%",
                maxHeight: "100%",
                width: "100%",
                height: "auto",
              }}
              controls
              alt="Whistleblower Policy"
            >
              <source
                src={`https://bzpublishassets.blob.core.windows.net/media/assets/TBS_Video_EverydayRevisi_7_1.mp4`}
                type="video/mp4"
              />
            </video> */}
            <LiteYoutubeEmbed
              mute={false}
              id={
                tbsVideo.includes("watch")
                  ? getEmbedCodeWatch(tbsVideo)
                  : getEmbedCode(tbsVideo)
              }
            />
          </div>
          <h2 className="heading-title"> {title}</h2>
          <h3 className="heading-summary"> {summary}</h3>
          <div className="margin-content">
            {content && (
              <div className="typo-style prose-h2:flex prose-h2:items-center break-words">
                {PARSE(content)}
              </div>
            )}
            <div className="my-6">
              {media && <InnerImageZoom src={media} />}
            </div>
          </div>
          <div className="w-full h-full">
            <div className="w-full">
              <FormControlLabel
                control={<Checkbox onClick={handleCheckboxClick} />}
                label={textLink[0]}
              />
            </div>
            <div className="w-full flex justify-end">
              <Link to={`${path}` ?? `/`}>
                <button
                  className={`p-2 border rounded-lg ${
                    !isDisabledButton ? "bg-blue" : "bg-gray-400"
                  } text-white`}
                  disabled={isDisabledButton}
                >
                  {textLink[1]}
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="section-width">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </BaseContainerPl>
  );
}
