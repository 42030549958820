/* eslint-disable */

import React, { useState, useCallback, useEffect, useRef } from 'react';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import TruncateMarkup from 'react-truncate-markup';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import PARSE from 'html-react-parser';

import { Link, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { CircularProgress, Modal } from '@mui/material';
import Api from './Api';
import GlobalApi from '../../context/api/GlobalApi';
import usePagination from './Pagination';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';
import replaceAssetLink from '../../context/utils/replaceAssetLink';

export default function TBSCompanyFiles() {
  const activeLang = useSelector((state) => state.content.activeLanguage);
  const location = useLocation();
  const [pdfUrl, setPdfUrl] = useState('');
  const [sections, setSections] = useState([]);
  const [position, setPosition] = useState(0);
  const [widgetId, setWidgetId] = useState();
  const [listWidgetArticle, setListWidgetArticle] = useState([]);
  const [category, setCategory] = useState('new');
  const [search, setSearch] = useState('');
  const [articleCount, setArticleCount] = useState(0);
  const [page, setPage] = useState(1);
  const [year, setYear] = useState('');
  const [listArticleYear, setListArticleYear] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const pageSize = 9;

  const getSectionsDetail = useCallback(() => {
    const api = new Api();
    api
      .getSectionDetail({ language: activeLang })
      .then((res) => {
        if (res.status === 200 && res.data.results) {
          const sectionFill = res.data.results;
          // setArticleCount(res.data.count);
          setSections(sectionFill);
          setFileLoaded(true);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
    // console.log(response);
  }, [activeLang]);

  useEffect(() => {
    getSectionsDetail();
  }, [getSectionsDetail]);

  let summary;
  let content;
  let sectionName;
  let title;
  let icon;
  let listFiles;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    // const sec = sections;

    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text?.article_language?.title;
            summary = text?.article_language?.summary;
            content = text?.article_language?.content;
            icon = replaceAssetLink(text?.assets[0]?.asset_file);
            break;
          case 1:
            const files = section.widget_contents;
            listFiles = files;
            break;
          case 3:
            break;
          default:
            break;
        }
      });
    }
  }

  const groupedByDate = _.groupBy(listFiles, function (item) {
    const dateMoment = moment(item.document_date);
    return dateMoment.day(String).format('YYYY');
  });

  const finalArticle = Object.entries(groupedByDate).map((obj) => ({ ...obj }));

  const yearList = finalArticle?.map((art) => {
    return {
      name: art[0],
      count: art[1],
    };
  });

  const onChangeYear = (event) => {
    setYear(event.target.value);
  };

  const handleFind = useCallback(
    (event) => {
      setPage(1);
      _DATA.jump(1);
      setListArticleYear([]);
      const filtered = listFiles?.filter((file) => file.document_date.includes(year));
      setFileLoaded(false);
      setListArticleYear(year !== '' ? filtered : listFiles);
      setTimeout(() => setFileLoaded(true), 500);
    },
    [listFiles, year]
  );

  const sortedArray = listArticleYear?.length > 0 ? _.orderBy(listArticleYear, 'documnet_date').reverse() : _.orderBy(listFiles, 'documnet_date').reverse();

  const arrSort = sortedArray?.map((obj) => {
    return { ...obj, document_date: new Date(obj.document_date) };
  });

  const sortedAsc = arrSort.sort((objA, objB) => Number(objA.document_date) - Number(objB.document_date)).reverse();

  const PER_PAGE = 9;

  const count = Math.ceil(sortedAsc.length / PER_PAGE);
  const _DATA = usePagination(sortedAsc, PER_PAGE);

  const handleChange = (e, p) => {
    // window.scrollTo({
    //   top: position?.top,
    //   left: 0,
    //   behavior: 'smooth',
    // });
    setFileLoaded(false);
    setTimeout(() => setFileLoaded(true), 500);
    setPage(p);
    _DATA.jump(p);
  };

  const reverseYear = _.orderBy(yearList, 'name').reverse();

  const cardRef = useRef();

  useEffect(() => {
    if (sectionName) {
      const currenPosition = cardRef.current.getBoundingClientRect();
      setPosition(currenPosition);
    }
  }, [sectionName]);

  return (
    <BaseContainerPl sectionName={sectionName} sectionIndex={sections && sections.length > 0 && sections[0].index}>
      {fileLoaded ? (
        <div ref={cardRef} className="section-width">
          <h2 className="heading-title"> {title}</h2>
          <h2 className="heading-summary"> {summary}</h2>
          <div className="margin-content">
            {/* <div className="">{icon && <img src={icon} alt="" className="max-w-2xl mb-3 w-full" />}</div> */}
            {content && (
              <div className="mt-[45px]">
                <div className=" typo-style ">{PARSE(content)}</div>
              </div>
            )}
          </div>
          <div className=" grid  md:grid-cols-3 s:grid-cols-2 gap-[1.4rem]">
            {_DATA.currentData().map((list) => {
              return (
                <div key={list.id} className="justify-start text-left  ">
                  <div className="">
                    <div title="" className="cursor-pointer ">
                      <div className="">
                        <a href={replaceAssetLink(list?.file)} target="_blank" rel="noopener noreferrer">
                          <img
                            src={replaceAssetLink(list?.thumbnail) ?? 'https://bzpublishassets.blob.core.windows.net/media/assets/Screen_Shot_2022-06-02_at_19.28_12.png'}
                            alt={list?.description}
                            className="drop-shadow-lg w-full md:max-h-[30vh] md:min-h-[330px] max-w-[270px] object-cover"
                          />
                          <div className="space-y-2 mt-5">
                            {/* <div className="text-left text-sm font-inter"> {moment(list?.document_date).format(' MMMM D, YYYY')}</div> */}
                            <div className="text-[#004A98] font-inter-bold">{list?.categories[0]?.name}</div>
                            <TruncateMarkup lines={2}>
                              <div className=" font-inter-bold h-full text-lg  text-left">{list?.description || 'This Placeholder Description'}</div>
                            </TruncateMarkup>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="my-10 flex items-center justify-end gap-x-5">
            <Pagination count={count} color="primary" size="medium" page={page} variant="outlined" shape="rounded" onChange={handleChange} />
          </div>
        </div>
      ) : (
        <div className={`section-container-pl min-h-[62.5rem] ${sections.length > 0 && sections[0].index === 3 ? 'mt-index-section__3' : 'mt-index-section'}`}>
          <CircularProgress />
        </div>
      )}
    </BaseContainerPl>
  );
}
