/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import BaseArrowLinkTw from '../../context/components/Base/BaseArrowLinkTw';
import { Link } from 'react-router-dom';
import Api from './Api';
import GlobalApi from '../../context/api/GlobalApi';
import Styles from './TBS_Our_People_Inside.module.css';
import DefaultImage from './assets/empty_default.png';
import replaceAssetLink from '../../context/utils/replaceAssetLink';

const theme = createTheme({});

function TBS_Our_People_Inside() {
  const [section, setSection] = useState([]);
  const [articles, setArticles] = useState([]);
  const [category, setCategory] = useState({ cat: [], selectedCat: 0 });
  const [widgetId, setWidgetId] = useState(0);
  const [filterNum, setFilterNum] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const activeLang = useSelector((state) => state.content.activeLanguage);
  // const activeClass = "filter-active"
  const filterByList = ['All', 'Latest Update', 'Year'];

  useEffect(() => {
    const FetchData = async () => {
      const api = new Api();
      try {
        const sectionResponse = await api.getSectionDetail({ language: activeLang });
        const sectionData = await sectionResponse.data;
        const sections = await sectionData.results[0];

        if (sections) {
          const sectionWidgets = sectionData.results[0].widgets;
          const sec = sectionWidgets.filter((data) => data.is_internal === false);
          const secInternal = sectionWidgets.filter((data) => data.is_internal === true);

          setCategory({ cat: sec[0].article_categories, selectedCat: 0 });
          setWidgetId(sec[0].id);
          setSection(secInternal);
        } else {
          throw new Error('Error trying to get data');
        }
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [activeLang]);

  let pathname;
  if (section) {
    const checkData = section[0];
    pathname = checkData?.widget_contents[0]?.article_language.path;

    // console.log('pathname ', pathname);
    // console.log('widget_contents ', widget_contents);
  }

  const getArticlesByCat = useCallback(async () => {
    const globalApi = new GlobalApi();

    if (category.selectedCat !== 0) {
      const fetchArticlePromises = await globalApi.getArticleDetailByYearDescendingPagination(widgetId, category.selectedCat, 15, '', page);
      const ArticleData = await fetchArticlePromises.data.results;

      setArticles(ArticleData);
      setCount(Math.ceil(fetchArticlePromises.data.count / 15));
    } else {
      const getArticleData = await globalApi.getArticleDetailByYearDescendingPagination(widgetId, '', 15, '', page);
      const ArticleData = await getArticleData.data.results;

      setArticles(ArticleData);
      setCount(Math.ceil(getArticleData.data.count / 15));
    }
  }, [section, category, page]);

  const CardTitle = styled.h1`
    font-family: 'inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    // color: var(--blue);
  `;

  const CardText = styled.p`
    font-family: 'inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: (--black);
  `;

  const FilterButton = styled.button`
    background-color: var(--lightWhite);
    padding: 6px 12px;
  `;

  const FilterButtonActive = styled.button`
    background-color: var(--black);
    padding: 6px 12px;
    color: white;
  `;

  const getFilterArticle = (val) => {
    switch (filterNum) {
      case 0:
        setArticles(_.sortBy(articles, 'id'));
        // console.log(articles);
        break;
      case 1:
        // setArticles(data)
        setArticles(_.sortBy(articles, 'date'));
        // console.log(filterNum);
        break;
      case 2:
        const year = new Date(articles[0]?.date).getFullYear();
        // console.log(year);
        // console.log(articles);
        // console.log(filterNum);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (widgetId) {
      getArticlesByCat();
    }
  }, [section, category, page]);

  useEffect(() => {
    if (section) {
      getFilterArticle(articles);
    }
  }, [section, filterNum]);

  function HandleCategory(event) {
    setPage(1);
    setCategory((data) => {
      return { ...data, selectedCat: parseInt(event.target.value) };
    });
  }

  function HandleClickFilter(event) {
    return setFilterNum(parseInt(event.target.value));
  }

  const handleChange = (e, val) => {
    setPage(val);
  };


  const ShowArticles = [];
  if (articles) {
    ShowArticles.push([
      articles.map((data) => {
        const { article_languages } = data;
        // const dataByLang = article_languages?.filter((val) => val?.language === activeLang);
        const asset = data.article_assets[0]?.asset.file_small;
        const descAsset = data.article_assets[0]?.asset.description;
        const slugName = article_languages?.slug_title;
  
        return (
          <div key={data.id} className="w-full h-auto shadow-sm rounded-sm group">
            <div
              className="w-full h-[210px] border-[1px] border-lightWhite bg-center bg-cover relative overflow-hidden"
              role='img'
              aria-label={descAsset}
              style={{ backgroundImage: `url(${data?.article_assets.length ? replaceAssetLink(asset) : replaceAssetLink(DefaultImage)})` }}
            />
              {/* <img className="w-full h-full bg-center object-cover bg-cover" src={data?.article_assets.length ? replaceAssetLink(asset) : replaceAssetLink(DefaultImage)} alt={descAsset} /> */}
            {/* </div> */}
            <div className="mt-[20px] h-[126px] lg:h-[140px] xl:h-[126px] p-4 pt-0 flex flex-col justify-between">
              <CardTitle>{article_languages && article_languages?.title}</CardTitle>
              <CardText>{article_languages && article_languages?.summary}</CardText>
              <Link className="flex gap-x-3" to={`${pathname}/${data.id}/${slugName}`}>
                <p className={Styles.more}>Read More</p>
                <div className="mt-[3px]">
                  <BaseArrowLinkTw size="small" color="black" />
                </div>
              </Link>
            </div>
          </div>
        );
      }),
    ])
  }

  return (
    <div className="section-container h-auto max-w-[88%] md:w-full hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1400px] fhd:max-w-[1450px] mx-auto scrollTop flex flex-col gap-y-8 mb-10 pb-10 mt-[80px] relative">
      <div className="flex flex-row gap-x-6">
        <select
          name=""
          className="h-fit md:w-[238px] w-[150px] border-black border bg-white px-[10px] py-[10px] capitalize font-inter"
          id=""
          nChange={(e) => HandleCategory(e)}
          value={category.selectedCat}
        >
          <option value="" className="capitalize">
            Filter
          </option>
          {category &&
            category.cat?.map((opt) => {
              return (
                <option key={opt.id} value={opt.id} className="capitalize">
                  {opt.name}
                </option>
              );
          })}
        </select>
      </div>
      <div className="w-full">
        <div className="w-full h-fit grid s:grid-cols-2 lg:grid-cols-3 hdl:grid-cols-4 gap-x-6 gap-y-11">{ShowArticles}</div>
        <div className="flex items-center justify-end gap-x-5 mt-10">
          <ThemeProvider theme={theme}>
            <Pagination count={count} color="primary" size="medium" variant="outlined" shape="rounded" onChange={handleChange} />
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}

export default TBS_Our_People_Inside;
