/* eslint-disable */

import React, { useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import PARSE from 'html-react-parser';

export default function ContentAccordion({ content }) {
  const [expand, setExpand] = useState(false);
  return (
    <div>
      {!expand && (
        <div className="relative py-3">
          <TruncateMarkup lines={8}>{content && <div className="typo-style-unique-career !mt-0">{PARSE(content)}</div>}</TruncateMarkup>
          <button className="text-blue underline" type="button" onClick={() => setExpand(!expand)}>
            Read More
          </button>
        </div>
      )}
      {expand && (
        <div className=" py-3 border-b-4 border-blue">
          <div className="typo-style-unique-career !mt-0">{PARSE(content)}</div>
          <div className="">
            <button className="underline" type="button" onClick={() => setExpand(!expand)}>
              Read Less
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
