/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PARSE from 'html-react-parser';
import Api from './Api';
import Styles from './TBS_Information.module.css';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';

function TBS_Information() {
  const [section, setSection] = useState([]);
  const [slugName, setSlugName] = useState('');
  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const FetchData = async () => {
      const api = new Api();
      try {
        const sectionResponse = await api.getSectionDetail({ language: activeLang });
        const sectionData = await sectionResponse.data;
        const sections = sectionData.results[0];
        const sectionWidget = sections.widgets;
        const sectionSlugName = sections.slug_name;

        setSection(sectionWidget);
        setSlugName(sectionSlugName);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [activeLang]);

  let content;
  if (section) {
    section.forEach((data) => {
      const { widget_contents } = data;

      content = widget_contents;
    });
  }

  return (
    <BaseContainerPl sectionName={slugName} sectionIndex={section && section.length > 0 && section[0].index}>
      <div className={`section-width space-y-4 ${section.length > 0 ? '' : 'min-h-[645px]'}`}>
        {content &&
          content?.map((value) => {
            const { article_language } = value;

            if (article_language?.content) {
              const doc = article_language?.content;
              const tempDiv = document.createElement('div');
              tempDiv.innerHTML = doc;

              const h4Elements = tempDiv.querySelectorAll('h4');

              h4Elements.forEach(element => {
                element.classList.add('text-lg', 'font-bold')
              });

              const modifiedStringValue = tempDiv.innerHTML;
  
              return (
                <>
                  <div key={value.id} className={Styles.title}>
                    <h2 className="heading-title ">{article_language?.title}</h2>
                    <h3 className="heading-sub-title">{article_language?.summary}</h3>
                  </div>
                  <div className="w-full prose text-justify">{value && PARSE(modifiedStringValue)}</div>
                </>
              );
            }
          })}
      </div>
    </BaseContainerPl>
  );
}

export default TBS_Information;
