import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Api from './Api';
// import SectionAccordion from './section_accordion';
import BaseAccordion from '../../context/components/Base/BaseAccordion';
import Styles from './TBS_Homepage_FAQ.module.css';

function TBS_Homepage_FAQ() {
  const [section, setSection] = useState([]);
  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const FetchData = async () => {
      const api = new Api();

      try {
        const sectionResponse = await api.getSectionDetail({ language: activeLang });
        const sectionData = await sectionResponse.data;
        const sections = sectionData.results[0].widgets;

        setSection(sections);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [activeLang]);

  let title;
  const faq_data = [];
  if (section) {
    section.forEach((data, index) => {
      const { widget_contents } = data;

      if (index === 0) {
        title = widget_contents[0].content;
      } else {
        const middleIndex = Math.ceil(widget_contents.length / 2);
        const data_1 = widget_contents.slice(0, middleIndex);
        const data_2 = widget_contents.slice(middleIndex, widget_contents.length);

        faq_data.push(data_1, data_2);
      }
    });
  }

  return (
    <div className="w-full h-full">
      <div className="h-full mt-[80px] max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto">
        <div className={`${Styles.title}`}>
          <h2 className="font-inter-bold text-[28px]">{title}</h2>
        </div>
        <div className="w-full grid grid-cols-1 mdl:grid-cols-2 gap-x-7">
          <BaseAccordion data={faq_data[0]} />
          <BaseAccordion data={faq_data[1]} />
        </div>
      </div>
    </div>
  );
}

export default TBS_Homepage_FAQ;
