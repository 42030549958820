/* eslint-disable */
import React, { Component, createRef } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { isMobile  } from 'react-device-detect';
import TBS_HERO_461 from '../../sections/TBS_Hero_461/TBS_Hero';
import TBS_SIDENAVIGATION_595 from '../../sections/TBS_SideNavigation_595/TBS_SideNavigation';
import TBS_REGULERARTICLE_706 from '../../sections/TBS_RegulerArticle_706/TBS_RegulerArticle';
import TBS_ABOUTUS_OUR_HISTORY_561 from '../../sections/TBS_AboutUs_Our_History_561/TBS_AboutUs_Our_History';
import TBS_ABOUTUS_ORGANIZATION_562 from '../../sections/TBS_AboutUs_Organization_562/TBS_AboutUs_Organization';
import TBS_ABOUTUS_SHAREHOLDER_279 from '../../sections/TBS_AboutUs_Shareholder_279/TBS_AboutUs_Shareholder';
import TBS_ABOUTUS_GROUP_563 from '../../sections/TBS_AboutUs_Group_563/TBS_AboutUs_Group';
import TBS_ABOUTUS_OUR_VALUES_278 from '../../sections/TBS_AboutUs_Our_Values_278/TBS_AboutUs_Our_Values';
import TBS_LEADERSHIP_643 from '../../sections/TBS_Leadership_643/TBS_Leadership';
import TBS_PARTNERS_651 from '../../sections/TBS_Partners_651/TBS_Partners';
import TBS_ARTICLEFILTERYEAR_608 from '../../sections/TBS_ArticleFilterYear_608/TBS_ArticleFilterYear';
import TBS_ABOUTUS_CONTACT_610 from '../../sections/TBS_AboutUs_Contact_610/TBS_AboutUs_Contact';
import TBS_SPACER_663 from '../../sections/TBS_Spacer_663/TBS_Spacer';
import TBS_ABOUTUS_WHISTLEBLOWER_586 from '../../sections/TBS_AboutUs_Whistleblower_586/TBS_AboutUs_Whistleblower';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class project1 extends Component {
   constructor(props) {
       super(props);
       this.state = {
           currentPage: null,
           currentSection: null,
           override: true,
           section: [
               { id: 1, slug: localStorage.getItem('language') === "English"? 'tbs-hero' : 'tbs-hero'},
               { id: 2, slug: localStorage.getItem('language') === "English"? 'sidenav' : 'sidenav'},
               { id: 3, slug: localStorage.getItem('language') === "English"? 'overview' : 'ikhtisar'},
               { id: 4, slug: localStorage.getItem('language') === "English"? 'our-history' : 'sejarah-kami'},
               { id: 5, slug: localStorage.getItem('language') === "English"? 'organization-structure' : 'struktur-organisasi'},
               { id: 6, slug: localStorage.getItem('language') === "English"? 'shareholding-structure' : 'struktur-kepemilikan-saham'},
               { id: 7, slug: localStorage.getItem('language') === "English"? 'group-structure' : 'struktur-grup'},
               { id: 8, slug: localStorage.getItem('language') === "English"? 'our-values' : 'nilai-kami'},
               { id: 9, slug: localStorage.getItem('language') === "English"? 'management' : 'manajemen'},
               { id: 10, slug: localStorage.getItem('language') === "English"? 'subsidiaries' : 'anak-perusahaan'},
               { id: 11, slug: localStorage.getItem('language') === "English"? 'awards' : 'penghargaan'},
               { id: 12, slug: localStorage.getItem('language') === "English"? 'contact-us' : 'kontak-kami'},
               { id: 13, slug: localStorage.getItem('language') === "English"? 'whistleblowing' : 'whistleblowing'},
               { id: 14, slug: localStorage.getItem('language') === "English"? 'whistleblowi' : 'whistleblowi'},
           ],
       };
       this.scrollToSection = this.scrollToSection.bind(this);
       this.scrollAction = this.scrollAction.bind(this);
        this.isStateChanging = false
   }
   componentDidMount() {
       this.scrollToSection();
       ReactGA.initialize('G-7WJJLX00XL')
       ReactGA.pageview(window.location.pathname + window.location.search);
   }
   componentWillUnmount(){
   }
   section1 = createRef()
   section2 = createRef()
   section3 = createRef()
   section4 = createRef()
   section5 = createRef()
   section6 = createRef()
   section7 = createRef()
   section8 = createRef()
   section9 = createRef()
   section10 = createRef()
   section11 = createRef()
   section12 = createRef()
   section13 = createRef()
   section14 = createRef()
   scrollToSection() {
       let currentLocation = window.location.href
       const hasSectionAnchor = currentLocation.includes('/#')
       if (hasSectionAnchor) {
           let anchorSectionId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
           setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
       }
   }
   scrollAction(anchorSectionId) {
       const uri_dec = decodeURIComponent(anchorSectionId)
       const key = this.state.section.filter(x => x.slug == uri_dec).map(obj => `section${obj.id}`)
       const getY = (element) => element.getBoundingClientRect().top + window.pageYOffset - 140;
       if (key) {
          if (key[0] === 'section1') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section1.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section2') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section2.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section3') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section3.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section4') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section4.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section5') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section5.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section6') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section6.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section7') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section7.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section8') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section8.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section9') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section9.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section10') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section10.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section11') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section11.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section12') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section12.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section13') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section13.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section14') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section14.current), behavior: 'smooth' })}, 1000)
            }
       }
   }
   render() {
       const getHelmetTitle = () => {
           var title;
           switch (localStorage.getItem('language')) {
               case 'English':
                   title = <title> About Us | TBS Energi Utama</title>;
                   break;
               case 'Indonesian':
                   title = <title> Tentang Kami | TBS Energi Utama</title>;
                   break;
               case 'Vietnamese':
                   title = <title> About Us | TBS Energi Utama</title>;
                   break;
               default:
                   title = <title> About Us | TBS Energi Utama</title>;
                   break;
           }
           return title;
       };
       const getHelmetDescription = () => {
           var description;
           switch (localStorage.getItem('language')) {
               case 'English':
                   description = <meta name='description' content='TBS Energi Utama diversified from coal mining to become an integrated energy company, with investments in CFPPs and a focus on clean energy, including EVs.' />;
                   break;
               case 'Indonesian':
                   description = <meta name='description' content='TBS Energi Utama beralih dari pertambangan batubara ke perusahaan energi terintegrasi, berinvestasi di CFPP, dan fokus energi bersih, termasuk kendaraan listrik' />;
                   break;
               case 'Vietnamese':
                   description = <meta name='description' content='None' />;
                   break;
               default:
                   description = <meta name='description' content='TBS Energi Utama diversified from coal mining to become an integrated energy company, with investments in CFPPs and a focus on clean energy, including EVs.' />;
                   break;
           }
           return description;
       };
       const getHelmetKeywords = () => {
           var keywords;
           switch (localStorage.getItem('language')) {
               case 'English':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Indonesian':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Vietnamese':
                   keywords = <meta name='keywords' content='' />;
                   break;
               default:
                   keywords = <meta name='keywords' content='' />;
                   break;
           }
           return keywords;
       };
         let helmet = getHelmetTitle();
         let description = getHelmetDescription();
         let keywords = getHelmetKeywords();
       return (
           <>
               <Helmet>
                   {helmet}
                   {description}
                   {keywords}
               </Helmet>
            <div ref={this.section1}>
                <TBS_HERO_461 />
            </div>
            <div ref={this.section2}>
                <TBS_SIDENAVIGATION_595 />
            </div>
            <div ref={this.section3}>
                <TBS_REGULERARTICLE_706 />
            </div>
            <div ref={this.section4}>
                <TBS_ABOUTUS_OUR_HISTORY_561 />
            </div>
            <div ref={this.section5}>
                <TBS_ABOUTUS_ORGANIZATION_562 />
            </div>
            <div ref={this.section6}>
                <TBS_ABOUTUS_SHAREHOLDER_279 />
            </div>
            <div ref={this.section7}>
                <TBS_ABOUTUS_GROUP_563 />
            </div>
            <div ref={this.section8}>
                <TBS_ABOUTUS_OUR_VALUES_278 />
            </div>
            <div ref={this.section9}>
                <TBS_LEADERSHIP_643 />
            </div>
            <div ref={this.section10}>
                <TBS_PARTNERS_651 />
            </div>
            <div ref={this.section11}>
                <TBS_ARTICLEFILTERYEAR_608 />
            </div>
            <div ref={this.section12}>
                <TBS_ABOUTUS_CONTACT_610 />
            </div>
            <div ref={this.section13}>
                <TBS_SPACER_663 />
            </div>
            <div ref={this.section14}>
                <TBS_ABOUTUS_WHISTLEBLOWER_586 />
            </div>
           </>
       );
   }
}
export default withParams(project1);