/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import PARSE from 'html-react-parser';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Api from './Api';
import Styles from './TBS_Accordion_Reports.module.css';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';
import replaceAssetLink from '../../context/utils/replaceAssetLink';

const CustomAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(() => ({
  borderRadius: '0',
  '&::before': { backgroundColor: 'transparent!important' },
  '&:last-of-type': { borderRadius: '0' },
}));

const CustomAccordionSummary = styled((props) => <AccordionSummary expandIcon={<ArrowDropDownIcon style={{ color: 'black' }} />} {...props} />)(() => ({
  backgroundColor: '#F4F4F4',
  minHeight: '0',
  padding: '0 10px 0 0',
  '& .MuiAccordionSummary-content': { margin: '0' },
  '& .MuiTypography-root': { width: '200px' },
  '& .MuiAccordion-root:before': { height: '0' },
}));

function TBS_Accordion_Reports() {
  const [section, setSection] = useState([]);
  const [slugName, setSlugName] = useState('');
  const [tableHead, setTableHead] = useState([]);
  const activeLang = useSelector((state) => state.content.activeLanguage);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const api = new Api();
        const sectionResponse = await api.getSectionDetail({ language: activeLang });
        const sectionData = sectionResponse.data.results[0];
        const { widgets, slug_name: sectionSlugName } = sectionData;
  
        setSection(widgets);
        setSlugName(sectionSlugName);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [activeLang]);

  let title1;
  let title2;
  let meetingTable;
  let datas;
  const sortingDate = [];
  const getYears = [];
  const tableTitle = [];
  if (section) {
    section?.forEach((data, index) => {
      const { widget_contents } = data;

      if (index === 0) {
        widget_contents.forEach((value, idx) => {
          if (idx === 0) {
            title1 = value.content;
          }
          title2 = value.content;
        });
      }

      if (index === 1) {
        const getYear = widget_contents.map(data => new Date(data?.document_date).getFullYear());
        const arr = [...new Set(getYear)];
  
        getYears.push(...arr.sort().reverse());
        datas = widget_contents;
  
        sortingDate.push(...datas.sort((a, b) => Date.parse(new Date(a?.document_date)) - Date.parse(new Date(b?.document_date))).reverse());
      }
  
      if (index === 2) {
        widget_contents.forEach((value) => {
          tableTitle.push(value.content);
        });
      }
    });
  }

  return (
    <BaseContainerPl sectionName={slugName} sectionIndex={section && section.length > 0 && section[0].index}>
      <div className="section-width space-y-4 min-h-[1000px]">
        <div className={Styles.title}>
          <h2 className="heading-title">{title1}</h2>
          <h3 className="heading-sub-title">{title2}</h3>
        </div>
        <div className="flex flex-col gap-y-8">
          {getYears &&
            getYears?.map((val, idx) => {
              return (
                <CustomAccordion defaultExpanded={idx === 0 ? true : false} key={val.id}>
                  <CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <div className="flex gap-x-6">
                      <div className="w-[10px] h-[53px] bg-[#004A98]" />
                      <Typography className={Styles.Accordion_title}>{val}</Typography>
                    </div>
                  </CustomAccordionSummary>
                  <AccordionDetails sx={{ padding: '8px 0px 0px' }}>
                    <table id="customers" className="table-auto w-full">
                      <thead>
                        <th className="border-2 border-white px-4 py-2 text-white bg-[#18467f] font-medium">{tableTitle[0]}</th>
                        <th className="border-2 border-white px-4 py-2 text-white bg-[#18467f] font-medium">{tableTitle[1]}</th>
                      </thead>
                      <tbody className="border-2 border-white px-4 py-2  font-medium">
                        {sortingDate &&
                          sortingDate?.map((data) => {
                            // console.log('data ', data);
                            const ContentDate = new Date(data?.document_date).getFullYear();
                            const date = new Date(data.document_date).toLocaleString('en-US', { dateStyle: 'long', hour12: true });
                            if (ContentDate === val) {
                              return (
                                <tr key={data?.id} className="border-2 border-white px-4 py-2 bg-[#F2F5F6]">
                                  <td className={`w-[120px] text-left px-4 border-2 border-white py-2 ${Styles['data-text']}`}>
                                    {moment(data.document_date).format('DD MMMM YYYY')}
                                  </td>
                                  <td className={`w-[200px] text-left px-4 border-2 border-white py-2 underline ${Styles['data-text']}`}>
                                    <a href={replaceAssetLink(data.file)}>{data.description}</a>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  </AccordionDetails>
                </CustomAccordion>
              );
            })}
        </div>
      </div>
    </BaseContainerPl>
  );
}

export default TBS_Accordion_Reports;
