/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isBlog: false,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    getNewsCategory: (state, action) => {
      const currState = state;
      currState.isBlog = action.payload;

    },
  },
});

export const { getNewsCategory } = newsSlice.actions;

export default newsSlice;
