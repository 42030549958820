/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isTagData: null,
};

const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    getSectionTag: (state, action) => {
      const currState = state;
      currState.isTagData = action.payload.isTagData;

    },
  },
});

export const { getSectionTag } = sectionSlice.actions;

export default sectionSlice;
