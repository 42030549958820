// eslint-disable-next-line no-unused-vars
import { Skeleton } from "@mui/material";
import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BaseHeroCard from "../../context/components/Base/BaseHeroCard";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import Api from "./Api";

const api = new Api();

const generateDataItem = (data) => {
  if (!data.length) {
    return [];
  }
  return data.map((item) => item);
};

const generateData = (sect) => {
  const obj = {
    media: [],
    text: [],
    article: [],
  };
  const wids = sect.results[0].widgets;
  const isData = wids && wids.length;
  if (isData) {
    wids.forEach((wid, i) => {
      if (i === 0) {
        obj.media = generateDataItem(wid.widget_contents);
      }
      if (i === 1) {
        obj.text = generateDataItem(wid.widget_contents);
      }
      if (i === 2) {
        obj.article = generateDataItem(wid.widget_contents);
      }
    });
  }
  return obj;
};

export default function TBS_Hero_News_Detail() {
  const activeLanguage = useSelector((state) => state.content.activeLanguage);
  // eslint-disable-next-line no-unused-vars
  const [section, setSection] = useState(false);

  const isBlog = useSelector((state) => state.news.isBlog);

  const getSectionDetail = useCallback(() => {
    api
      .getSectionDetail({ language: activeLanguage })
      .then((res) => {
        if (res === 200 || res.data) {
          const newSection = generateData(res.data);
          setSection(newSection);
        }
      })
      .catch((err) => {
        console.log("~ err", err);
      });
  }, [activeLanguage]);

  let background;
  let backgroundSmall;
  let description;
  // let article;
  if (section) {
    background =
      replaceAssetLink(section.media[0]?.file_medium) ||
      replaceAssetLink(section.media[0]?.file);
    backgroundSmall =
      replaceAssetLink(section.media[0]?.file_small) ||
      replaceAssetLink(section.media[0]?.file);
    description = section.text[0]?.content;
    // article = section.article[0]?.article_language.content;
  }

  useEffect(() => {
    getSectionDetail();
    return () => {};
  }, [getSectionDetail]);

  return section ? (
    <div className="">
      <div className="xl:block hidden">
        <BaseHeroCard
          background={background}
          description={
            isBlog && section && section.text.length > 0
              ? section.text[1]?.content
              : section.text[0]?.content
          }
          article=""
        />
      </div>
      <div className="xl:hidden block">
        <BaseHeroCard
          background={backgroundSmall}
          description={
            isBlog && section && section.text.length > 0
              ? section.text[1]?.content
              : section.text[0]?.content
          }
          article=""
        />
      </div>
    </div>
  ) : (
    <Skeleton
      animation="wave"
      variant="rectangular"
      className="!qhd:h-[60vh] !md:h-[585px] !h-[50vh]"
    />
  );
}

