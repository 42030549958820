/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */

/* eslint-disable */

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import GlobalApi from "../../context/api/GlobalApi";
import Api from "./Api";

function TBS_News_Hero() {
  const [sections, setSections] = useState([]);
  const [article, setArticle] = useState([]);
  const [widgetId, setWidgetId] = useState();

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      // console.log(sectionsData.data.results);
      const apiSectionData = res.data.results;
      setSections(apiSectionData);
      if (apiSectionData) {
        const sec = apiSectionData[0]?.widgets;
        if (sec && sec.length !== 0) {
          setWidgetId(sec[1].id);
        }
      }

      return sections;
    });
    // console.log(response);
  }, [sections]);

  const getArticleDetail = useCallback(() => {
    const api = new GlobalApi();
    api;
    api
      .getArticleDetailByYearDescendingPagination(widgetId, "", 3, "", "")
      .then((res) => {
        const apiSectionData = res.data.results;
        setArticle(apiSectionData);
      });

    // console.log(response);
  }, [widgetId]);

  useEffect(() => {
    getSectionDetail();
    if (widgetId) {
      getArticleDetail();
    }
  }, [widgetId]);

  let titleText;
  let artList;

  if (sections) {
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const header =
              section?.widget_contents ?? section.widget_contents[0];
            titleText = header[0]?.content;

            break;
          case 1:
            artList = section?.widget_contents;

            break;

          default:
            break;
        }
      });
    }
  }

  const slide = [];
  artList?.forEach((data) => {
    slide.push(
      <SplideSlide key={data.id} className="">
        <div className="w-full qhd:h-[60vh] md:h-[585px] h-[50vh] relative">
          <div className="section-container">
            <div className="absolute   bottom-20 z-[500] text-white  font-inter-bold text-4xl">
              {titleText || ""}
            </div>
          </div>

          <div className=" qhd:h-[60vh] md:h-[585px] h-[50vh]  ">
            <img
              src={data?.file_medium}
              alt=""
              className="absolute left-0 w-full qhd:h-[60vh] md:h-[585px] h-[50vh] object-cover object-center "
            />
            {/* <div className="absolute -z-10 bg-gradient-to-b from-black via-transparant to-transparant qhd:h-[60vh] md:h-[585px] h-[50vh] w-full " /> */}
          </div>
        </div>
      </SplideSlide>
    );
  });

  return (
    <div className=" qhd:h-[60vh] md:h-[585px] h-[50vh] ">
      {artList && artList.length > 0 && (
        <img
          height="1358"
          width="585"
          src={artList[0]?.file_medium}
          fetchpriority="high"
          // alt={description}
          className="absolute left-0 w-full qhd:h-[60vh] md:h-[585px] h-[50vh] object-cover object-center "
        />
      )}{" "}
      {/* <div className="absolute -z-10 bg-gradient-to-b from-black via-transparant to-transparant qhd:h-[60vh] md:h-[585px] h-[50vh] w-full " /> */}
    </div>
    //  <div className="w-full">
    //   <Splide
    //     className=""
    //     options={{
    //       perPage: '1',
    //       rewind: 'true',
    //       type: 'loop',
    //       gap: '10px',
    //       perMove: 1,
    //       // perPage : 3,
    //       arrows: false,
    //       pagination: false,
    //     }}
    //   >
    //     {slide}
    //   </Splide>
    // </div>
  );
}

export default TBS_News_Hero;
