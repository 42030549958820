import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { Link } from 'react-router-dom';
import { NavHashLink } from "react-router-hash-link";
import Api from "./Api";
import Styles from "./TBS_Footer.module.css";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

function TBS_Footer() {
  const [menus, setMenus] = useState([]);
  const [sectionItems, setSectionItems] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const fetchData = async () => {
      const api = new Api();
      const menuResponse = await api.getMenuDetail({ language: activeLang });
      const sectionResponse = await api.getSectionDetail({
        language: activeLang,
      });
      const menuData = await menuResponse;
      const sectionData = await sectionResponse;

      setMenus(menuData.data.items);
      setSectionItems(sectionData.data.results[0]);
    };
    fetchData();
  }, [activeLang]);

  let logo;
  let address;
  let copyright;
  const contacts = [];
  const sosmed_icon = [];
  const link = [];
  if (sectionItems) {
    const sec_widgets = sectionItems.widgets;

    if (sec_widgets && sec_widgets !== 0) {
      sec_widgets.forEach((data, id) => {
        if (id === 0) {
          const sec_logo = data.widget_contents[0]?.file;
          logo = sec_logo;
        }

        if (id === 1) {
          const sec_widget_contents = data.widget_contents;

          sec_widget_contents.forEach((secData, index) => {
            if (index === 0) {
              address = secData.content;
            } else {
              contacts.push(secData.content);
            }
          });
        }

        if (id === 2) {
          const sec_sosmed_icon = data.widget_contents;

          sec_sosmed_icon.forEach((sosmed) => {
            sosmed_icon.push({ icon: sosmed.file, desc: sosmed.description });
          });
        }

        if (id === 3) {
          const sec_copyright = data.widget_contents;
          copyright = sec_copyright[0]?.content;
        }

        if (id === 4) {
          // console.log(data);
          const { widget_contents } = data;

          widget_contents?.forEach((val) => {
            link.push(val?.article_language?.summary);
          });
        }
      });
    }
  }

  return (
    <div id="footer" className={Styles["footer-container"]}>
      <div className="h-full mt-[80px] max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto">
        <div className={`h-auto  ${Styles["footer-fields-items"]}`}>
          <div className="w-full my-[80px] flex flex-col lg:flex lg:flex-row gap-x-[3rem] hd:gap-x-[5rem] hdxl:gap-x-[10rem]">
            <div className={Styles["background-items"]}>
              <div className={`w-[96px] ${Styles.logo}`}>
                {logo && (
                  <img
                    className="mb-[25px] w-[96px] h-[42px] -ml-[9px] -mt-[7px]"
                    src={replaceAssetLink(logo)}
                    alt="logo"
                  />
                )}
              </div>
              <div className={Styles["address-and-contact"]}>
                <p className="text-[10px]">{address}</p>
                <ul>
                  {contacts &&
                    contacts.map((contact) => {
                      return (
                        <li className="text-[10px]" key={contact}>
                          {contact}
                        </li>
                      );
                    })}
                </ul>
                <div className={Styles["sosmed-icon"]}>
                  {sosmed_icon &&
                    sosmed_icon.map((sosmed, id) => {
                      return (
                        <a
                          key={sosmed.id}
                          href={`${link[id]}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button type="button">
                            <img
                              src={replaceAssetLink(sosmed.icon)}
                              alt={sosmed.desc}
                            />
                          </button>
                        </a>
                      );
                    })}
                </div>
              </div>
            </div>
            {/* <div className={`flex flex-col lgm:flex lgm:flex-row md:flex md:flex-row gap-x-[2rem] mdl:gap-x-[1rem] hd:gap-x-[6rem] hdxl:gap-x-[10.5rem] ${Styles['footer-nav']}`}> */}
            <div
              className={`grid grid-cols-1 gap-y-[2.2rem] md:grid-cols-3 gap-x-[2rem] mdl:gap-x-[1rem] hd:gap-x-[6rem] hdxl:gap-x-[10.5rem] ${Styles["footer-nav"]}`}
            >
              {menus &&
                menus.map((menu) => {
                  return (
                    menu.title !== "Whistleblower" &&
                    menu?.children && (
                      <div key={menu.id} className={Styles["footer-menu"]}>
                        <div className={Styles["footer-nav-title"]}>
                          <h1>{menu.title}</h1>
                        </div>
                        <div className={Styles["footer-nav-list"]}>
                          <ul>
                            {menu?.children?.map((child) => {
                              return (
                                <li className="text-[10px]" key={child.id}>
                                  <NavHashLink smooth to={child.url || "/"}>
                                    {child.title}
                                  </NavHashLink>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
          </div>
        </div>
        <div className={Styles["footer-fields-copyright"]}>
          <div className={Styles.copyright}>
            <p>{copyright}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TBS_Footer;
