/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PARSE from "html-react-parser";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Api from "./Api";
import BaseArrowLinkTw from "../../context/components/Base/BaseArrowLinkTw";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

export default function TBSArticleGraySummary() {
  const [sections, setSections] = useState([]);
  const [widgets, setWidgets] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    const section = sectionsData.data.results;
    const sectionsWidgets = section[0]?.widgets;
    // console.log('section ', section);

    setSections(section);
    setWidgets(sectionsWidgets);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let media;
  let sectionName;
  let path;
  let textLink;
  if (sections) {
    sectionName = sections[0]?.slug_name;

    if (widgets && widgets.length !== 0) {
      widgets.forEach((section, i) => {
        switch (i) {
          case 0:
            const article = section.widget_contents[0];
            title = article?.article_language?.title;
            summary = article?.article_language?.summary;
            content = article?.article_language?.content;
            path = article?.article_language?.path;
            media = {
              asset:
                replaceAssetLink(article?.assets[0]?.file_medium) ||
                replaceAssetLink(article?.assets[0]?.asset_file),
              desc: article?.assets[0]?.description,
            };

            break;
          case 1:
            const text = section.widget_contents[0];
            textLink = text?.content;
            break;
          case 2:
            break;
          default:
            break;
        }
      });
    }
  }

  const doc = new DOMParser().parseFromString(content, "text/html");

  const tagsToRemove = "img";
  for (const elm of doc.querySelectorAll("*")) {
    if (elm.matches(tagsToRemove)) {
      elm.replaceWith(`<img src='${elm.src}' alt='${summary}' />`);
    }
  }

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      {sections.length > 0 ? (
        <div className="section-width">
          {title && <h2 className="heading-summary"> {title}</h2>}
          <h3 className="heading-title margin-content"> {summary}</h3>
          <div className="margin-content">
            <div className="">
              {" "}
              {media?.asset && (
                <img
                  src={media}
                  alt={media?.desc || title}
                  className=" mb-3 w-full"
                />
              )}
            </div>
            {content && (
              <div className="font-inter mt-[45px] prose text-justify">
                {PARSE(
                  doc.body.innerHTML.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
                )}
              </div>
            )}
          </div>
          {widgets && widgets.length > 1 ? (
            <div className="flex h-[20px] mr-3 w-full mt-[45px] group">
              <Link className="flex gap-x-3" to={`${path}` ?? `/`}>
                <p className="font-inter-bold">{textLink && textLink}</p>
                <div className="mt-[3px]">
                  <BaseArrowLinkTw size="small" color="black" />
                </div>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="section-width">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </BaseContainerPl>
  );
}



