/** @jsxImportSource @emotion/react */
import React from 'react';
import tw, { styled } from 'twin.macro';

import arrow from '../../assets/icon/arrow.svg';
import arrowWhite from '../../assets/icon/arrowWhite.svg';

export default function BaseArrowLinkTw({ size, color }) {
  const ArrowLink = styled.div`
    background-image: url(${color === 'black' ? arrow : arrowWhite});
    fill-[#243c5a];
    background-repeat: no-repeat;
    display: block;
    height: 16px;
    width: 20px;
    transition: 0.25s;
    transition-timing-function: ease-out;
    background-position: top right;
    ${size === 'large' ? tw`${'group-hover:w-[38px]'}` : tw`${'group-hover:w-[28px]'}`}
    ${tw`fill-[#243c5a] text-white`}
  `;
  return <ArrowLink size={size} />;
}
