/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PARSE from 'html-react-parser';
import { useParams, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

import Api from './Api';
import GlobalApi from '../../context/api/GlobalApi';
import BaseNoArticleFound from '../../context/components/Base/BaseNoArticleFound';
import replaceAssetLink from '../../context/utils/replaceAssetLink';

import DefaultImage from './assets/empty_default.png';
import Styles from './TBS_People_Detail.module.css';

function TBS_People_Detail() {
  const [section, setSection] = useState([]);
  const [article, setArticle] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [checkId, setCheckId] = useState(0);
  const [pathName, setPathName] = useState();
  const [allDataByCat, setAllDataByCat] = useState([]);
  const [listData, setListData] = useState([]);

  const paramID = useParams();
  const navigate = useNavigate();
  const locationPath = useLocation();

  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const loct = locationPath.pathname.split('/');
  
    const slicing = loct[1] === 'about-us' || loct[1] === 'tentang-kami' ? _.slice(loct, 1, 3).join('/') : _.slice(loct, 1, 4).join('/');
    setPathName(slicing);
  }, []);
  
  const fetchData = useCallback(async () => {
    const api = new Api();
    const globalApi = new GlobalApi();
  
    try {
      const sectionResponse = await api.getSectionDetail({ language: activeLang });
      const sectionData = sectionResponse.data.results[0];
      const sectionWidgets = sectionData.widgets;
      const sectionSlugName = sectionData.slug_name;
  
      if (sectionWidgets) {
        const sec = sectionWidgets.filter((data) => !data.is_internal);
        const sec_internal = sectionWidgets.filter((data) => data.is_internal);
  
        const articleResponse = await globalApi.getArticleAllLangFalse({ widgets: sec[0].id, max_size: true });
        const articleData = articleResponse.data.results;
        const filterByParam = articleData.filter((data) => data?.id === parseInt(paramID.param1));
  
        setArticle(...filterByParam);
        setAllDataByCat(articleData);
        setSection(sec_internal);
        setIsFetching(false);
      } else {
        throw new Error('Error trying to get property result');
      }
    } catch (error) {
      console.log(error);
    }
  }, [activeLang, paramID]);
  
  useEffect(() => {
    fetchData();
  }, [activeLang, fetchData]);
  
  useEffect(() => {
    const list = allDataByCat.map((data) => ({
      id: data.id,
      name: data?.article_languages?.slug_title,
    }));
    setListData(list);
  }, [allDataByCat]);

  useEffect(() => {
    const check = listData.find((c) => c.id === article?.id);
    setCheckId(listData.indexOf(check));
  }, [listData]);

  let profile;
  let back;
  let moreProfile;
  let previous;
  let next;
  let linkBack;
  let noArticleFound;
  if (section) {
    section.forEach((sec, idx) => {
      const { widget_contents } = sec;

      if (idx === 0) {
        widget_contents?.forEach((element, id) => {
          switch (id) {
            case 0:
              back = element?.content;
              break;
            case 1:
              profile = element?.content;
              break;
            case 2:
              moreProfile = element?.content;
              break;
            case 3:
              previous = element?.content;
              break;
            case 4:
              next = element?.content;
              break;
            case 5:
              noArticleFound = element?.content;
              break;
            default:
              break;
          }
        });
      }

      linkBack = widget_contents[0]?.article_language?.path;
    });
  }

  let articleContent;
  let articleImage;
  let AssetLength;
  if (article) {
    const { article_languages } = article;
    const { article_assets } = article;

    articleContent = article_languages;
    AssetLength = article_assets.length;

    if (article_assets.length === 2) {
      articleImage = article_assets[1]?.asset.file_medium;
    } else {
      articleImage = article_assets[0]?.asset.file_small;
    }
  }

  const nextButton = () => {
    const data = listData[checkId + 1];

    navigate(`/${pathName}/${data.id}/${data.name}`);
    setListData([]);
  };

  const previousButton = () => {
    const data = listData[checkId - 1];

    navigate(`/${pathName}/${data.id}/${data.name}`);
    setListData([]);
  };

  const slideProfiles = !isFetching ? (
    <div className="space-y-[45px] mb-[10px]">
      <div className="font-inter-bold font-bold text-[32px]">{moreProfile}</div>
      <Splide
        className="w-full"
        options={{
          type: 'slide',
          rewind: 'false',
          perPage: 5,
          perMove: 1,
          gap: '1rem',
          pagination: false,
          // arrows: false,
          breakpoints: {
            623: {
              perPage: 1,
              perMove: 1,
            },
            1000: {
              perPage: 2,
              perMove: 1,
            },
            1024: {
              perPage: 4,
              perMove: 1,
            },
            1600: {
              perPage: 5,
              perMove: 1,
            },
            1920: {
              perPage: 5,
              perMove: 1,
            },
            2560: {
              perPage: 5,
              perMove: 1,
            },
          },
        }}
      >
        {allDataByCat &&
          allDataByCat.map((val, idx) => {
            const profilePhoto = val?.article_assets.length ? val?.article_assets[0].asset.file_small : [];
            const descPhoto = val?.article_assets[0]?.asset.description;
            const profileInfo = val?.article_languages;
            const id = val?.id;

            return (
              <SplideSlide key={val.id} className="w-full">
                <Link to={`/${pathName}/${id}/${profileInfo.slug_title} ` ?? '/'}>
                  <div className="w-full bg-[#F5F5F5]">
                    <div>
                      <div className="space-y-2 px-6 pt-6 min-h-[150px]">
                        <p className="text-[14px] font-inter-bold font-bold">{profile}</p>
                        <p className="text-[16px] font-inter-bold font-bold capitalize">{profileInfo.title}</p>
                      </div>
                    </div>
                    <div className="h-[250px]">
                      <img
                        src={profilePhoto.length ? replaceAssetLink(profilePhoto) : replaceAssetLink(DefaultImage)}
                        alt={descPhoto}
                        className="w-full h-full object-cover"
                        width={294}
                        height={250}
                      />
                    </div>
                  </div>
                </Link>
              </SplideSlide>
            );
          })}
      </Splide>
    </div>
  ): (
    <Box className="w-full h-[300px]">
      <Skeleton animation="wave" variant="rectangular" height={300} />
    </Box>
  )

  const fliterLangKey = article?.article_keywords?.filter((key) => key?.language?.id === activeLang);

  const controlButton = (
    <div className="w-full mt-[80px] flex justify-between">
      <button className={checkId !== 0 ? 'p-[10px] border-2' : 'text-[#e5e7eb] p-[10px] border-2'} onClick={previousButton} disabled={checkId === 0}>
        {previous}
      </button>
      <button
        className={listData.length !== checkId + 1 ? 'p-[10px] border-2' : 'text-[#e5e7eb] p-[10px] border-2'}
        onClick={nextButton}
        disabled={listData.length === checkId + 1 ? true : false}
      >
        {next}
      </button>
    </div>
  );

  const renderHeroSection = () => !isFetching ? (
    <div
      className={`w-full qhd:h-[60vh] xs:h-[500px] md:h-[585px] h-[70vh] relative flex justify-center ${Styles.hero}`}
      style={{ backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.03) 100%)' }}
    >
      <LazyLoadImage
        className="h-[420px] md:h-[480px] fhd:h-[520px] qhd:h-[600px] aspect-square absolute bottom-0"
        src={replaceAssetLink(articleImage)}
        alt={articleContent?.title}
        height={600}
        width="auto"
      />
    </div>
  ) : (
    <Box className="w-full qhd:h-[60vh] xs:h-[500px] md:h-[585px] h-[70vh]">
      <Skeleton animation="wave" variant="rectangular" height={580} />
    </Box>
  );

  const renderContentSection = () => !isFetching ? (
    <div className="max-w-[15rem] xs:max-w-[20rem] s:max-w-[35rem] md:max-w-[40rem] hd:max-w-[800px] mx-auto">
      <div className="pb-[45px]">
        <div className="w-full relative">
          <div className="w-full flex justify-end relative s:absolute">
            <div smooth="true" onClick={() => navigate(linkBack)}>
              <button className="border-2 p-2" type="button">
                {back}
              </button>
            </div>
          </div>
          <div className="grid gap-y-4 mt-4 s:mt-0">
            <h1 className="text-[40px] font-inter-bold font-bold">{articleContent?.title}</h1>
            <h2 className="text-[25px] s:text-[32px] font-inter-bold font-bold">{articleContent?.summary}</h2>
          </div>
        </div>
      </div>
      <div className="space-y-[15px]">
        <h3 className="font-inter-bold font-bold text-[24px]">{profile}</h3>
        <div className="text-justify s:text-left typo-style">{PARSE(articleContent?.content || '')}</div>
      </div>
      {controlButton}
    </div>
  ):(
    <Box className="w-full max-w-[15rem] xs:max-w-[20rem] s:max-w-[35rem] md:max-w-[40rem] hd:max-w-[800px] mx-auto">
      <Skeleton animation="wave" variant="rectangular" height={600} />
    </Box>
  );

  return (!isFetching || article) ?  
    !isFetching && !article ? <BaseNoArticleFound label={noArticleFound} /> : (
      <>
        {articleContent && (
          <Helmet>
            <meta name="description" content={articleContent.description} />
            <meta name="keywords" content={fliterLangKey?.map((key) => key?.keyword?.name)} />
            <title>{articleContent.title}</title>
          </Helmet>
        )}
        <div className="w-full h-full">
          {AssetLength === 2 ? (
            <>
              {renderHeroSection()}
              <div className="flex flex-col my-[5rem]">{renderContentSection()}</div>
              <div className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto mt-[120px] md:mt-[120px]">
                <div>{slideProfiles}</div>
              </div>
            </>
          ) : (
            <>
              <div className="bg-blue s:bg-transparent w-full s:h-0 h-[80px]" />
              {
                !isFetching ? (
                  <>                
                    <div className="flex flex-col gap-y-11 pt-[5rem] s:pt-[160px]">
                      <div className="max-w-[17rem] xs:max-w-[20rem] s:max-w-[35rem] md:max-w-[40rem] hd:max-w-[800px] mx-auto">
                        <div className="pb-2">
                          <div className="w-full border-b-2 pb-8 relative">
                            <div className="w-full flex justify-end relative mb-4 s:mb-0 s:absolute">
                              <div smooth="true" onClick={() => navigate(linkBack)}>
                                <button className="border-2 p-2" type="button">
                                  {back}
                                </button>
                              </div>
                            </div>
                            <div className="gap-x-6 space-y-8 mt-4 s:mt-0">
                              <div className="w-full s:w-[350px]">
                                <img
                                  className="bg-cover w-full h-full"
                                  src={article?.article_assets.length ? replaceAssetLink(articleImage) : replaceAssetLink(DefaultImage)}
                                  alt=""
                                  width={350}
                                  height={277}
                                />
                              </div>
                              <div>
                                <h1 className="text-[40px] font-inter-bold">{articleContent?.title}</h1>
                                <h2 className="text-[25px] s:text-[32px] font-inter-bold">{articleContent?.summary}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="space-y-[15px]">
                          <h3 className="font-inter-bold text-[24px]">{profile}</h3>
                          <div className="text-justify s:text-left typo-style">{PARSE(articleContent?.content || '')}</div>
                        </div>
                        {controlButton}
                      </div>
                    </div>
                    <div className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto mt-[120px] md:mt-[120px]">
                      <div>{slideProfiles}</div>
                    </div>
                  </>
                ):(
                  <Box className="w-full h-full">
                    <Box className="w-full mt-[5rem] s:mt-[160px] max-w-[15rem] xs:max-w-[20rem] s:max-w-[35rem] md:max-w-[40rem] hd:max-w-[800px] mx-auto">
                      <Skeleton animation="wave" variant="rectangular" height={800} />
                    </Box>
                    <Box className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto mt-[120px] md:mt-[120px]">
                      <Skeleton animation="wave" variant="rectangular" height={300} />
                    </Box>
                  </Box>
                )
              }
            </>
          )}
        </div>
      </>
  ) : (
    <>
      <Box className="w-full qhd:h-[60vh] xs:h-[500px] md:h-[585px] h-[70vh]">
        <Skeleton animation="wave" variant="rectangular" height={800} />
      </Box>
      <Box className="w-full max-w-[15rem] xs:max-w-[20rem] s:max-w-[35rem] md:max-w-[40rem] hd:max-w-[800px] mx-auto my-[5rem] space-y-[40px]">
        <Skeleton animation="wave" variant="rectangular" height={105} />
        <Skeleton animation="wave" variant="rectangular" height={400} />
      </Box>
      <Box className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto mt-[120px] md:mt-[120px]">
        <Skeleton animation="wave" variant="rectangular" height={300} />
      </Box>
      {/* <div className="flex">
        <div className="w-full h-[300px] bg-gray-200 animate-pulse" />
      </div> */}
    </>
  );
}

export default TBS_People_Detail;
