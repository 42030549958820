/* eslint-disable */

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import PARSE from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clsxm from "../../context/utils/clsxm";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import Api from "./Api";

export default function TBS_Materiality_v2() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    const parsedData = await response;
    const sectionsData = parsedData;

    const apiSectionData = sectionsData.data.results;
    setSections(apiSectionData);

    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let icon;
  let sectionName;
  let cardArticle = [];
  let MainImgdesc;
  let texts = [];
  let collecSDG = [];

  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const articleContent = section.widget_contents[0];
            title = articleContent?.article_language?.title;
            summary = articleContent?.article_language?.summary;
            content = articleContent?.article_language?.content;
            icon = replaceAssetLink(articleContent?.assets[0]?.asset_file);
            MainImgdesc = articleContent?.assets[0]?.description;

            break;
          case 1:
            const cardContent = section.widget_contents;
            cardArticle = cardContent || [];
            break;
          case 2:
            const textContent = section.widget_contents;
            texts = textContent;

            break;
          case 3:
            collecSDG = section.widget_contents;
            break;

          default:
            break;
        }
      });
    }
  }

  return (
    <div
      id={`${sectionName}`}
      className={`section-container-pl  ${
        sections.length > 0 && sections[0].index === 3
          ? "mt-index-section__3"
          : "mt-index-section"
      }`}
    >
      {sections.length > 0 ? (
        <div className="relative section-width-new xl:mx-0 mx-auto">
          <div className="">
            {content && (
              <div className="relative  ">
                <div className="font-inter prose-p:text-[16px] prose-p:leading-[26px] text-[#1A1A1A] leading-[26px] prose-strong:font-inter-bold prose-strong:font-semibold prose-strong:leading-[25px] !text-justify">
                  {PARSE(content)}
                </div>
              </div>
            )}

            <div className="">
              {/* <div className="text-[23px] font-inter-600 leading-[130%] mb-[17px]">
                {texts[0]?.content}
              </div> */}
              {cardArticle && cardArticle.length > 0 && (
                <div className="mt-[47px] grid grid-cols-3 md:gap-[24px] gap-[9px]">
                  {cardArticle.map((art) => {
                    const artTitle = art?.article_language?.title;
                    const artSummary = art?.article_language?.summary;
                    const artAsset = art?.assets[0]?.asset_file;
                    const artContent = art?.article_language?.content;

                    return (
                      <div key={art.id} className="  relative  ">
                        <div className="rounded-[10px] md:h-[170px] h-[82px] bg-tbs-dark-green border flex items-center justify-center relative">
                          {/* <div className="absolute top-[10px] right-[10px] text-tbs-dark-green bg-white rounded-full text-[10px] font-inter-bold p-[5px] leading-[130%]">
                        {art?.assets[0]?.description}
                      </div> */}
                          {artAsset && (
                            <img
                              src={artAsset}
                              alt={art?.assets[0]?.description}
                              className="w-auto md:h-[70px] h-[40px] mx-auto"
                            />
                          )}
                        </div>
                        <div className=" md:mt-[22px] mt-[11px]">
                          <div className="pb-[17px] ">
                            <div className="font-inter-600 md:text-[20px] text-[15px] md:leading-[22px] leading-[20px] text-center ">
                              {artTitle}
                            </div>
                          </div>
                          {/* {artContent && (
                        <div className="font-inter text-[18px] leading-[24px] mt-[24px] typo-stylce">
                          {PARSE(artContent)}
                        </div>
                      )} */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}{" "}
              <div className="mt-[49px]">
                <div className="text-[23px] font-inter-600 leading-[130%] md:text-left text-center">
                  {texts[0]?.content}
                </div>
                <div className="section-width-new md:mt-0 mt-[81px] md:space-y-[24px] space-y-[81px]">
                  {_.chunk(collecSDG, 3).map((chunk, chunkIdx) => (
                    <div
                      key={chunkIdx}
                      className="flex md:flex-row flex-col md:justify-between justify-center  items-stretch md:mt-[24px] mt-[36px] relative hd-full md:!w-auto !w-fit mx-auto hdl:space-x-0 md:space-x-[16px] s:space-x-[8px]"
                    >
                      {chunk.map((item, idxCat) => {
                        const { article_language, assets: assetCats } = item;

                        const isSecondOrThirdInPattern =
                          idxCat % 3 === 2 || idxCat % 3 === 0;
                        const isAboveThirdIndex = idxCat > 2;

                        return idxCat % 3 === 0 ? (
                          <a
                            href={article_language?.path || ""}
                            target="_blank"
                            className=""
                          >
                            <img
                              key={idxCat}
                              src={assetCats[0]?.asset_file}
                              alt={article_language?.title}
                              className={clsxm(
                                "md:w-[170px] w-[112px] md:h-[170px] h-[112px] md:relative absolute md:top-0 -top-[56px] left-1/2 -translate-x-1/2 md:left-0 md:-translate-x-0",
                                isAboveThirdIndex && "mt-[30px]"
                              )}
                            />
                          </a>
                        ) : (
                          <div
                            key={idxCat}
                            className={clsxm(
                              "px-[24px] py-[14px] rounded-[10px] md:max-w-full s:max-w-[350px] h-sfull flex items-stretch ",
                              !isSecondOrThirdInPattern
                                ? "bg-[#DCE3E2] md:w-[267px]"
                                : "bg-tbs-dark-green/5 md:w-[364px]",
                              isAboveThirdIndex && "mt-[30px]",
                              idxCat === 2 ? "md:-mt-0 -mt-[20px] -z-[1] " : ""
                            )}
                          >
                            <div
                              className={clsxm(
                                "md:mt-0 ",
                                idxCat === 2
                                  ? "-mt-[0px] md:pt-0 pt-[30px]"
                                  : "mt-[81px]"
                              )}
                            >
                              <div
                                className={clsxm(
                                  "lg:text-[28px] text-[25px] font-inter font-[500] border-b border-black/20 leading-[32px] tracking-[-2%] pb-[34px] md:text-left text-center"
                                )}
                              >
                                {article_language?.title}
                              </div>
                              {article_language?.content && (
                                <div className="font-inter text-[18px] leading-[24px] mt-[24px] typo-stylce">
                                  {PARSE(article_language?.content)}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="max-w-2xl">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </div>
  );
}
