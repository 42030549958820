/* eslint-disable no-unused-vars */
import { ButtonBase, Popover, Skeleton } from '@mui/material';
import React, { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import { ArrowForwardIos, Close, Menu } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, experimental_sx as sx, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { NavHashLink } from 'react-router-hash-link';
import MenuContext from './MenuContext';
import MobileMenuChild from './components/MobileMenuChild';
import ItemMobile from './components/ItemMobile';
import MenuSearch from './components/MenuSearch';
import Color from '../../context/constants/Color';
import Whistle from './assets/Button.svg';
import styles from './TBS_Menu.module.css';
import Expand from './assets/Expand';
import Search from './assets/Search';
import logoColor from './assets/logo-tbs-color.svg';
import logoWhite from './assets/logo-tbs-white.svg';

const menuTheme = createTheme({ components: { MuiPopover: { styleOverrides: { root: sx({ zIndex: 100001 }) } } } });

const itemVariants = {
  closed: { opacity: 0 },
  open: { opacity: 1 },
};

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0,
      staggerDirection: -1,
      duration: 0.1,
      delay: 0.1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.2,
      duration: 0.2,
      staggerDirection: 1,
    },
  },
};

export default function MobileMenu() {
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.content.languages);
  const activeLanguage = useSelector((state) => state.content.activeLanguage);

  const menuContext = useContext(MenuContext);
  const { data, menu, isSearch } = menuContext;
  const [open, cycleOpen] = useCycle(false, true);
  const [activeItem, setActiveItem] = useState(false);
  const [pagination, setPagination] = useState(['Home']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFill, setActiveFill] = useState(Color.white);
  const [activeLogo, setActiveLogo] = useState(logoWhite);
  const [isScrolling, setIsScrolling] = useState(false);

  const prevOpenRef = useRef();

  const isScrollActive = activeFill === Color.blackPrimary;
  const bgContainer = isScrollActive ? Color.white : 'transparent';
  const mainVariants = {
    // initial: () => ({ opacity: 1 }),
    open: () => ({
      width: '100vw',
      opacity: open ? 0 : 1,
      zIndex: open ? '-1' : 10000,
      top: 0,
      // position: open ? 'absolute' : 'relative',
      backgroundColor: open ? 'transparent' : bgContainer,
      transition: { delay: open ? 0 : 0, duration: 0.3 },
    }),
    // closed: () => ({ transition: { delay: 0.3, duration: 0.3 } }),
  };

  useEffect(() => {
    prevOpenRef.current = open;
    if (prevOpenRef.current !== open) {
      setActiveItem(false);
      setPagination(['Home']);
    }
  }, [open]);

  const handleClickLang = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLang = () => {
    setAnchorEl(null);
  };

  const openLang = Boolean(anchorEl);
  const id = openLang ? 'simple-popover' : undefined;

  useEffect(() => {
    if ((data && isScrolling) || (data && isSearch)) {
      setTimeout(() => {
        setActiveFill(Color.blackPrimary);
        setActiveLogo(data.logo[2] ? data.logo[2].file : logoColor);
      }, 100);

      return () => {
        clearTimeout()
      }
    }
  }, [isScrolling, data, isSearch]);

  useEffect(() => {
    if (!isScrolling && data && activeFill === Color.blackPrimary && !isSearch) {
      setTimeout(() => {
        setActiveFill(Color.white);
        setActiveLogo(data.logo[1] ? data.logo[1].file_small : data.logo[2].file_small || logoWhite);
      }, 100);

      return () => {
        clearTimeout()
      }
    }
  }, [isScrolling, data, activeItem, activeFill, isSearch]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && !isScrolling) {
        setIsScrolling(true);
      }
      if (window.scrollY === 0) {
        setIsScrolling(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolling]);

  return (
    <ThemeProvider theme={menuTheme}>
      <Suspense
        fallback={
          <div className="flex">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        }
      >
        <AnimatePresence>
          {open && (
            <motion.aside
              className="z-[10001] fixed"
              initial={{ width: 0, right: -360, opacity: 0 }}
              animate={{ width: 360, right: 0, opacity: 1 }}
              exit={{
                right: -360,
                opacity: 0,
                width: 0,
                transition: { delay: 0.3, duration: 0.3 },
              }}
            >
              <motion.div className="container bg-white " initial="closed" animate="open" exit="closed" variants={sideVariants}>
                <div className="w-full h-[100vh] py-4 px-8 flex flex-col">
                  <div className="flex justify-between mb-4">
                    <ButtonBase className="flex !items-center space-x-4" aria-describedby={id} onClick={handleClickLang}>
                      <span className="uppercase">{activeLanguage ? languages.filter((l) => l.id === activeLanguage)[0]?.abbreviation : ''}</span>
                      <div className="w-full h-full ml-2 flex items-center">
                        <Expand fill="#000000" config={{ width: 12, height: 14 }} />
                      </div>
                    </ButtonBase>
                    <Popover
                      id={id}
                      open={openLang}
                      anchorEl={anchorEl}
                      onClose={handleCloseLang}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      disableScrollLock
                      sx={{ zIndex: 100001, '& > div.MuiPaper-root': { top: '48px !important' } }}
                    >
                      <div className="flex py-2 px-4">
                        <div className="flex flex-col space-y-2 items-start">
                          {languages.map((l) => {
                            const isActive = activeLanguage && l.id === activeLanguage;
                            const activeStyle = isActive ? 'text-black font-semibold' : 'text-black';
                            return (
                              <ButtonBase
                                key={l.name}
                                onClick={() => {
                                  if (!isActive) {
                                    dispatch({ type: 'content/setActiveLanguage', payload: l });
                                  }
                                  handleCloseLang();
                                }}
                              >
                                <p className={activeStyle}>
                                  {l.abbreviation.toUpperCase()} | {l.name}
                                </p>
                              </ButtonBase>
                            );
                          })}
                        </div>
                      </div>
                    </Popover>
                    <ButtonBase onClick={cycleOpen}>
                      <Close />
                    </ButtonBase>
                  </div>

                  <div className="w-full h-full relative">
                    {menu?.map((itm) => (
                      <motion.li key={itm.id} className="py-2 pl-0 pr-4 flex items-center border-b border-black border-opacity-30">
                        <ItemMobile itm={itm} closeMenu={cycleOpen} />
                        {itm.children?.length > 0 && (
                          <ArrowForwardIos
                            fontSize="14"
                            style={{ color: 'red' }}
                            onClick={() => {
                              setPagination([...pagination, itm.title]);
                              setActiveItem(itm);
                            }}
                          />
                        )}
                      </motion.li>
                    ))}
                    {activeItem && (
                      <MobileMenuChild
                        item={activeItem}
                        title={activeItem.title}
                        goBack={() => {
                          setActiveItem(false);
                          setPagination(pagination.filter((e) => e !== activeItem.title));
                        }}
                        setPagination={setPagination}
                        pagination={pagination}
                        closeMenu={cycleOpen}
                      />
                    )}
                  </div>
                </div>
              </motion.div>
            </motion.aside>
          )}
        </AnimatePresence>
        <motion.div
          className={['fixed top-0  h-full max-h-[80px]', styles.mContainer, isSearch && styles.mContainerSearch].join(' ')}
          initial="initial"
          animate="open"
          exit="closed"
          variants={mainVariants}
        >
          <div className="w-full h-full py-5 section-container flex justify-between items-center">
            <motion.div variants={itemVariants}>
              <Link to="/">
                <div className="flex items-center w-[96px]">
                  {isScrollActive ? (
                    <img src={isSearch ? data?.logo[1]?.file : data?.logo[1]?.file} className=" w-[96px] h-[42px] -ml-[9px]" alt="tbs logo" />
                  ) : (
                    <img src={!isSearch ? data?.logo[0]?.file : data?.logo[1]?.file} className=" w-[96px] h-[42px] -ml-[9px]" alt="tbs logo" />
                  )}
                </div>
              </Link>
            </motion.div>

            <motion.div className="flex items-center space-x-4" variants={itemVariants}>
              <ButtonBase onClick={() => menuContext.setIsSearch(true)}>
                <Search fill={activeFill} />
              </ButtonBase>
              <ButtonBase onClick={cycleOpen} className="w-8 h-8">
                <Menu fontSize="32" sx={{ width: 32, height: 32, color: activeFill }} />
              </ButtonBase>
            </motion.div>
          </div>
        </motion.div>
      </Suspense>
      <AnimatePresence>{menuContext.isSearch && <MenuSearch />}</AnimatePresence>
      <div className="fixed z-[31000] bottom-5 left-5">
        <NavHashLink
          className=" "
          // onClick={() => setMenuSelected(tag.id)}
          smooth
          to={data?.link[0]?.article_language?.path || '/'}
        >
          <img src={data?.link[0]?.assets[0]?.asset_file} alt="" className="w-10 h-10 " />
        </NavHashLink>
      </div>
    </ThemeProvider>
  );
}
