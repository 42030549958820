export function getEmbedCode(url) {
  let embedCode = url.split("/").pop().split("?")[0];
  return embedCode;
}

export function getEmbedCodeWatch(url) {
  let params = new URLSearchParams(url.split("?")[1]);
  let embedCode = params.get("v");
  return embedCode;
}
