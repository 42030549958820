/* eslint-disable */
// eslint-disable-next-line prettier/prettier
/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { NavHashLink } from "react-router-hash-link";
import PARSE from "html-react-parser";

import styles from "./ActiveItem.module.css";

const wrpStyle = css`
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
  &:hover {
    background-color: #f2f4f5;
  }
  z-index: 1000;
`;

const ItemTypeStr = ({ type, itm, config, children, action }) => {
  const itmStyle = css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    &:hover {
      background-color: #f2f4f5;
    }
    z-index: 1000;
    ${config && `margin-bottom: rem;`}
  `;

  const n = itm.url?.lastIndexOf("/");
  const resUrl = itm.url?.substring(n + 1);
  let htmlForImage;
  switch (type) {
    case "button":
      htmlForImage = (
        <button css={itmStyle} type="button">
          {children}
        </button>
      );
      break;
    case "internal":
      if (itm.url) {
        if (resUrl.substring(0, 1) === "#") {
          htmlForImage = (
            <NavHashLink
              css={itmStyle}
              smooth
              to={itm.url}
              onClick={() => action(false)}
            >
              {children}
            </NavHashLink>
          );
        } else {
          htmlForImage = (
            <Link
              css={itmStyle}
              to={itm.url}
              type="button"
              onClick={() => action(false)}
            >
              {children}
            </Link>
          );
        }
      }
      break;
    case "external":
      htmlForImage = (
        <a
          css={itmStyle}
          href={itm.url_extension}
          type="button"
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      );
      break;
    default:
      break;
  }
  if (itm.article.article_assets && itm.article.article_assets.length === 0) {
    return htmlForImage;
  } else
    return itm.article.article_assets[0]?.asset?.asset_type === 1 ? (
      htmlForImage
    ) : (
      <a
        css={itmStyle}
        href={itm.article.article_assets[0]?.asset?.asset_file}
        type="button"
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    );
};

const ItemTypeBtn = ({ type, itm, config, children, action }) => {
  const itmStyle = css`
    display: flex;
    justify-content: space-between;
    width: fit-content;
    padding: 2px;
    text-align: left;
    &:hover {
      color: #004a98;
      scale: 1.3;
    }
    z-index: 1000;
    ${config && `margin-bottom: rem;`}
  `;

  const n = itm.url?.lastIndexOf("/");
  const resUrl = itm.url?.substring(n + 1);
  let html;
  switch (type) {
    case "button":
      html = (
        <button css={itmStyle} type="button">
          {children}
        </button>
      );
      break;
    case "internal":
      if (itm.url) {
        if (resUrl.substring(0, 1) === "#") {
          html = (
            <NavHashLink
              css={itmStyle}
              smooth
              to={itm.url}
              onClick={() => action(false)}
            >
              {children}
            </NavHashLink>
          );
        } else {
          html = (
            <Link
              css={itmStyle}
              to={itm.url}
              type="button"
              onClick={() => action(false)}
            >
              {children}
            </Link>
          );
        }
      }
      break;
    case "external":
      html = (
        <a
          css={itmStyle}
          href={itm.url_extension}
          type="button"
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      );
      break;
    default:
      break;
  }
  return html;
};

const ItemTypeBtnHaveChild = ({ type, itm, config, children, action }) => {
  const itmStyle = css`
    width: 100%;
  `;

  const n = itm.url?.lastIndexOf("/");
  const resUrl = itm.url?.substring(n + 1);
  let html;
  switch (type) {
    case "button":
      html = (
        <button css={itmStyle} type="button">
          {children}
        </button>
      );
      break;
    case "internal":
      if (itm.url) {
        if (resUrl.substring(0, 1) === "#") {
          html = (
            <NavHashLink
              css={itmStyle}
              smooth
              to={itm.url}
              onClick={() => action(false)}
            >
              {children}
            </NavHashLink>
          );
        } else {
          html = (
            <Link
              css={itmStyle}
              to={itm.url}
              type="button"
              onClick={() => action(false)}
            >
              {children}
            </Link>
          );
        }
      }
      break;
    case "external":
      html = (
        <a
          css={itmStyle}
          href={itm.url_extension}
          type="button"
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      );
      break;
    default:
      break;
  }
  return html;
};

export default function ActiveItem({
  item,
  action,
  isShowingDeepItem,
  setIsShowingDeepItem,
  setHeightDraw,
  setActiveItem,
}) {
  const { article, children } = item;
  const [isShowing, setIsShowing] = useState(false);
  const [isDeepItem, setIsDeepItem] = useState({
    id: null,
    open: false,
    data: null,
  });
  useEffect(() => {
    if (!isShowingDeepItem) {
      setIsDeepItem({ id: null, open: false, data: null });
    }
  }, [isShowingDeepItem]);

  const handleDeepItem = ({ id, open, data }) => {
    // if (data.title.toLowerCase() === 'capital market supporting institution') {
    //   setHeightDraw(450);
    // }
    // setIsShowingDeepItem(true);
    setIsDeepItem({ id, open, data });
    // if (isDeepItem.id === id && isDeepItem.open === true) {
    //   setIsDeepItem({ id: id, open: true });
    // } else setIsDeepItem({ id, open: false, data });
  };

  const renderChildItem = (
    key,
    itm,
    config = { style: styles.menuItem, isChild: true, action: () => {} }
  ) => {
    let type = "button";
    if (itm.url_extension) {
      type = "external";
    }

    if (itm.url && itm.url !== "/") {
      type = "internal";
    }
    return (
      <div className="w-full h-auto ">
        {itm?.children && itm?.children?.length > 0 ? (
          <>
            <div
              onMouseEnter={() =>
                handleDeepItem({ id: itm.id, open: true, data: itm })
              }
              className={
                isDeepItem?.id === itm.id
                  ? "bg-gray-100 w-full cursor-pointer"
                  : " w-full cursor-pointer "
              }
              css={wrpStyle}
            >
              <ItemTypeBtnHaveChild
                key={key}
                type={type}
                itm={itm}
                config={config.isChild}
                action={config.action}
              >
                <button type="button" className="w-full text-left">
                  <p className={config.style}>{itm.title}</p>
                </button>
              </ItemTypeBtnHaveChild>
            </div>
            <button className=" absolute right-0 w-6 h-6 top-1 flex items-center z-30  ">
              <ItemTypeBtn
                key={key}
                type={type}
                itm={itm}
                config={config.isChild}
                action={config.action}
              >
                <KeyboardArrowRight />
              </ItemTypeBtn>
            </button>
          </>
        ) : (
          <div
            className=""
            onMouseEnter={() =>
              handleDeepItem({ id: itm.id, open: true, data: itm })
            }
          >
            <ItemTypeStr
              key={key}
              type={type}
              itm={itm}
              config={config.isChild}
              action={config.action}
            >
              <p className={config.style}>{itm.title}</p>
            </ItemTypeStr>
          </div>
        )}
      </div>
    );
  };

  const renderDeepItem = (
    key,
    itm,
    config = { style: styles.menuItem, isChild: true, action: () => {} }
  ) => {
    let type = "button";
    if (itm.url_extension) {
      type = "external";
    }

    if (itm.url && itm.url !== "/") {
      type = "internal";
    }
    return (
      <div className="w-full h-auto ">
        <ItemTypeStr
          key={key}
          type={type}
          itm={itm}
          config={config.isChild}
          action={config.action}
        >
          <p className={config.style}>{itm.title}</p>
        </ItemTypeStr>
      </div>
    );
  };

  useEffect(() => {
    if (!isShowing && item) {
      setTimeout(() => {
        setIsShowing(true);
      }, 330);
    }
    return () => {
      clearTimeout();
    };
  }, [isShowing, item]);

  return (
    isShowing && (
      <div className="section-container h-full mdl:max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto  ">
        <div className="w-full flex space-x-8  relative">
          <div className="pt-8 pb-6 !w-4/12 typo-style !mt-0">
            {article.content && (
              <p className={styles.menuDesc}>{PARSE(article.content)}</p>
            )}
          </div>
          {/* <Divider sx={{ borderColor: '#f5f5f5' }} /> */}
          <div className=" my-8 px-7 w-4/12 border-x  ">
            {item.url ? (
              <Link to={item.url} onClick={() => setActiveItem(false)}>
                <p className={["pl-3", styles.menuTitle].join(" ")}>
                  {item.title}
                </p>
              </Link>
            ) : (
              <p className={["pl-3", styles.menuTitle].join(" ")}>
                {item.title}
              </p>
            )}
            {children.map((itm) => {
              return (
                <div key={itm.id} className="">
                  <div key={itm.title} className="flex  pl-0 pr-6  relative ">
                    {renderChildItem(itm.id, itm, { action })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-4/12 my-8 pr-7 ">
            <div className=" flex flex-col ">
              <Fade when={isDeepItem.id && isDeepItem?.open} clear>
                <div className="">
                  {isDeepItem?.data?.children && (
                    <p className={["pl-3", styles.menuTitle].join(" ")}>
                      {isDeepItem?.data?.title}
                    </p>
                  )}
                  {isDeepItem?.data?.children?.map((c) => (
                    <div key={c.id} className=" w-full ">
                      {renderDeepItem(`${c.title}-${c.id}`, c, {
                        style: styles.menuItemChild,
                        isChild: false,
                        action,
                      })}
                    </div>
                  ))}
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
