import axios from 'axios';
import qs from 'qs';

export default class Api {
   base_api = 'https://api.bzpublish.com/'
   token = ''


   async getSectionDetail(param = {}) {
       const params = {page__id: 314, index: 2, ...param}
       let url = this.base_api + `sections/customer/`;
       return await axios.get(url, {
           params: params,
           headers: {
               'Content-Type': 'application/json',
           },
       });
   }

   async getMenuDetail(params = {}) {
       const url = this.base_api + `menus/22/get_structure_detail`;
       return axios.get(url, {
           params,
           headers: {
               'Content-Type': 'application/json',
           },
       });
   }

}
