/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { ExpandMore } from '@mui/icons-material';

function generateSdgColors(val) {
  let htm;
  const sdgClass = 'text-white rounded-full  text-center justify-center inline-block fhd:h-7 fhd:w-7 h-6 w-6 fhd:p-1 p-[3px] leading-[18px]';

  if (val) {
    switch (val) {
      case '1':
        htm = <div className={['bg-[#E8081A]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '2':
        htm = <div className={['bg-[#D3A028]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '3':
        htm = <div className={['bg-[#279B48]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '4':
        htm = <div className={['bg-[#C0172C]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '5':
        htm = <div className={['bg-[#EF402B]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '6':
        htm = <div className={['bg-[#02ADD8]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '7':
        htm = <div className={['bg-[#FDB713]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '8':
        htm = <div className={['bg-[#8F1838]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '9':
        htm = <div className={['bg-[#F36D25]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '10':
        htm = <div className={['bg-[#E11484]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '11':
        htm = <div className={['bg-[#F99D26]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '12':
        htm = <div className={['bg-[#CF8D2A]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '13':
        htm = <div className={['bg-[#48773E]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '14':
        htm = <div className={['bg-[#007DBC]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '15':
        htm = <div className={['bg-[#5DBB46]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '16':
        htm = <div className={['bg-[#173567]', sdgClass].join(' ')}>{val}</div>;
        break;
      case '17':
        htm = <div className={['bg-[#193768]', sdgClass].join(' ')}>{val}</div>;
        break;
      default:
        break;
    }
  }

  return htm;
}

export default function TableContent({ tableContent, tableName, spanVal }) {
  const [isTableOpen, setIsTableOpen] = useState(true);

  const toggleExpandTable = () => {
    setIsTableOpen(!isTableOpen);
  };

  return (
    <tbody className="border-2 border px-2 py-2  font-medium">
      <th colSpan={spanVal} className=" px-2 py-2 text-black font-medium text-left font-bold">
        <div className="flex justify-between">
          <h4 className=""> {tableName}</h4>
          <ExpandMore className="cursor-pointer" onClick={toggleExpandTable} />
        </div>
      </th>
      {isTableOpen && (
        <>
          {tableContent?.map((head, i) => (
            <tr key={head} className={[i % 2 === 0 ? 'bg-[#f1f1f1] px-2 py-2' : 'bg-white px-2 py-2', 'font-medium '].join(' ')}>
              {head?.map((cont, idx) => {
                const value = head[head.length - 1];
                const splitVal = value.split(' ');
                return (
                  <td className={`${idx === 0 ? 'text-left' : 'text-left'}  px-2   border-2 text-[12px]  py-2  font-medium max-w-[150px] `}>
                    {head.length - 1 === idx ? <div className="flex justify-end"> {splitVal.map((val) => generateSdgColors(val))}</div> : cont}
                  </td>
                );
              })}
            </tr>
          ))}
        </>
      )}
    </tbody>
  );
}
