/* eslint-disable */

import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import contentSlice from './slices/contentSlice';
import sectionSlice from './slices/sectionSlice';
import newsSlice from './slices/newsSlice';

const store = configureStore({ reducer: { auth: authSlice.reducer, content: contentSlice.reducer, section: sectionSlice.reducer, news: newsSlice.reducer } });

export default store;
