/* eslint-disable */
import React, { Component, createRef } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { isMobile  } from 'react-device-detect';
import TBS_HERO_724 from '../../sections/TBS_Hero_724/TBS_Hero';
import TBS_SIDENAVIGATION_726 from '../../sections/TBS_SideNavigation_726/TBS_SideNavigation';
import TBS_FINANCIAL_HIGHLIGHTS_728 from '../../sections/TBS_Financial_Highlights_728/TBS_Financial_Highlights';
import TBS_ACCORDION_REPORTS_725 from '../../sections/TBS_Accordion_Reports_725/TBS_Accordion_Reports';
import TBS_RELATED_PAGES_727 from '../../sections/TBS_Related_Pages_727/TBS_Related_Pages';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class project1 extends Component {
   constructor(props) {
       super(props);
       this.state = {
           currentPage: null,
           currentSection: null,
           override: true,
           section: [
               { id: 1, slug: localStorage.getItem('language') === "English"? 'financial-performance-hero' : 'financial-performance-hero'},
               { id: 2, slug: localStorage.getItem('language') === "English"? 'sidebar' : 'sidebar'},
               { id: 3, slug: localStorage.getItem('language') === "English"? 'financial-highlights' : 'sorotan-keuangan'},
               { id: 4, slug: localStorage.getItem('language') === "English"? 'financial-report' : 'laporan-keuangan'},
               { id: 5, slug: localStorage.getItem('language') === "English"? 'related-pages' : 'related-pages'},
           ],
       };
       this.scrollToSection = this.scrollToSection.bind(this);
       this.scrollAction = this.scrollAction.bind(this);
        this.isStateChanging = false
   }
   componentDidMount() {
       this.scrollToSection();
       ReactGA.initialize('G-7WJJLX00XL')
       ReactGA.pageview(window.location.pathname + window.location.search);
   }
   componentWillUnmount(){
   }
   section1 = createRef()
   section2 = createRef()
   section3 = createRef()
   section4 = createRef()
   section5 = createRef()
   scrollToSection() {
       let currentLocation = window.location.href
       const hasSectionAnchor = currentLocation.includes('/#')
       if (hasSectionAnchor) {
           let anchorSectionId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
           setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
       }
   }
   scrollAction(anchorSectionId) {
       const uri_dec = decodeURIComponent(anchorSectionId)
       const key = this.state.section.filter(x => x.slug == uri_dec).map(obj => `section${obj.id}`)
       const getY = (element) => element.getBoundingClientRect().top + window.pageYOffset - 140;
       if (key) {
          if (key[0] === 'section1') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section1.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section2') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section2.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section3') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section3.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section4') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section4.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section5') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section5.current), behavior: 'smooth' })}, 1000)
            }
       }
   }
   render() {
       const getHelmetTitle = () => {
           var title;
           switch (localStorage.getItem('language')) {
               case 'English':
                   title = <title> Financial Performance | TBS Energi Utama</title>;
                   break;
               case 'Indonesian':
                   title = <title> Kinerja Keuangan | TBS Energi Utama</title>;
                   break;
               case 'Vietnamese':
                   title = <title> Financial Performance | TBS Energi Utama</title>;
                   break;
               default:
                   title = <title> Financial Performance | TBS Energi Utama</title>;
                   break;
           }
           return title;
       };
       const getHelmetDescription = () => {
           var description;
           switch (localStorage.getItem('language')) {
               case 'English':
                   description = <meta name='description' content='Financial Performance - TBs Energy Utama' />;
                   break;
               case 'Indonesian':
                   description = <meta name='description' content='Kinerja Keuangan - TBS Energy Utama' />;
                   break;
               case 'Vietnamese':
                   description = <meta name='description' content='None' />;
                   break;
               default:
                   description = <meta name='description' content='Financial Performance - TBs Energy Utama' />;
                   break;
           }
           return description;
       };
       const getHelmetKeywords = () => {
           var keywords;
           switch (localStorage.getItem('language')) {
               case 'English':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Indonesian':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Vietnamese':
                   keywords = <meta name='keywords' content='' />;
                   break;
               default:
                   keywords = <meta name='keywords' content='' />;
                   break;
           }
           return keywords;
       };
         let helmet = getHelmetTitle();
         let description = getHelmetDescription();
         let keywords = getHelmetKeywords();
       return (
           <>
               <Helmet>
                   {helmet}
                   {description}
                   {keywords}
               </Helmet>
            <div ref={this.section1}>
                <TBS_HERO_724 />
            </div>
            <div ref={this.section2}>
                <TBS_SIDENAVIGATION_726 />
            </div>
            <div ref={this.section3}>
                <TBS_FINANCIAL_HIGHLIGHTS_728 />
            </div>
            <div ref={this.section4}>
                <TBS_ACCORDION_REPORTS_725 />
            </div>
            <div ref={this.section5}>
                <TBS_RELATED_PAGES_727 />
            </div>
           </>
       );
   }
}
export default withParams(project1);