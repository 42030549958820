/* eslint-disable */
import React, { Component, createRef } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { isMobile  } from 'react-device-detect';
import TBS_PEOPLE_DETAIL_743 from '../../sections/TBS_People_Detail_743/TBS_People_Detail';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class project1 extends Component {
   constructor(props) {
       super(props);
       this.state = {
           currentPage: null,
           currentSection: null,
           override: true,
           section: [
               { id: 1, slug: localStorage.getItem('language') === "English"? 'detail' : 'detail'},
           ],
       };
       this.scrollToSection = this.scrollToSection.bind(this);
       this.scrollAction = this.scrollAction.bind(this);
        this.isStateChanging = false
   }
   componentDidMount() {
       this.scrollToSection();
       ReactGA.initialize('G-7WJJLX00XL')
       ReactGA.pageview(window.location.pathname + window.location.search);
   }
   componentWillUnmount(){
   }
   section1 = createRef()
   scrollToSection() {
       let currentLocation = window.location.href
       const hasSectionAnchor = currentLocation.includes('/#')
       if (hasSectionAnchor) {
           let anchorSectionId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
           setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
       }
   }
   scrollAction(anchorSectionId) {
       const uri_dec = decodeURIComponent(anchorSectionId)
       const key = this.state.section.filter(x => x.slug == uri_dec).map(obj => `section${obj.id}`)
       const getY = (element) => element.getBoundingClientRect().top + window.pageYOffset - 140;
       if (key) {
          if (key[0] === 'section1') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section1.current), behavior: 'smooth' })}, 1000)
            }
       }
   }
   render() {
       const getHelmetTitle = () => {
           var title;
           switch (localStorage.getItem('language')) {
               case 'English':
                   title = <title> Read Profile | TBS Energi Utama</title>;
                   break;
               case 'Indonesian':
                   title = <title> Baca Profil | TBS Energi Utama</title>;
                   break;
               case 'Vietnamese':
                   title = <title> Read Profile | TBS Energi Utama</title>;
                   break;
               default:
                   title = <title> Read Profile | TBS Energi Utama</title>;
                   break;
           }
           return title;
       };
       const getHelmetDescription = () => {
           var description;
           switch (localStorage.getItem('language')) {
               case 'English':
                   description = <meta name='description' content='Read Profile' />;
                   break;
               case 'Indonesian':
                   description = <meta name='description' content='Baca Profil' />;
                   break;
               case 'Vietnamese':
                   description = <meta name='description' content='None' />;
                   break;
               default:
                   description = <meta name='description' content='Read Profile' />;
                   break;
           }
           return description;
       };
       const getHelmetKeywords = () => {
           var keywords;
           switch (localStorage.getItem('language')) {
               case 'English':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Indonesian':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Vietnamese':
                   keywords = <meta name='keywords' content='' />;
                   break;
               default:
                   keywords = <meta name='keywords' content='' />;
                   break;
           }
           return keywords;
       };
         let helmet = getHelmetTitle();
         let description = getHelmetDescription();
         let keywords = getHelmetKeywords();
       return (
           <>
               <Helmet>
                   {helmet}
                   {description}
                   {keywords}
               </Helmet>
            <div ref={this.section1}>
                <TBS_PEOPLE_DETAIL_743 param={this.props.params} />
            </div>
           </>
       );
   }
}
export default withParams(project1);