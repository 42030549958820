/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import Api from "./Api";
import { useSelector } from "react-redux";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import moment from "moment";
import GlobalApi from "../../context/api/GlobalApi";
import { Link, useParams } from "react-router-dom";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import { Box, Skeleton } from "@mui/material";

function trancate(clamp = 2, string) {
  const html = (
    <div className=" font-inter-bold h-full text-lg  text-left line-clamp-2">
      {string}
    </div>
  );
  return html;
}

function TBSRelatedPost() {
  const [widgetId, setWidgetId] = useState();
  const [sections, setSections] = useState([]);
  const [article, setArticle] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);

  const activeLang = useSelector((state) => state.content.activeLanguage);
  const { param1 } = useParams();

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      // console.log(sectionsData.data.results);
      const apiSectionData = res.data.results;
      setSections(apiSectionData);
      if (apiSectionData) {
        const sec = apiSectionData[0]?.widgets;
        if (sec && sec.length !== 0) {
          setWidgetId(sec[0].id);
        }
      }

      return sections;
    });
    // console.log(response);
  }, [sections]);

  const getArticleDetail = useCallback(() => {
    const api = new GlobalApi();
    api
      .getArticleDetailByYearDescendingPagination(widgetId, "", 10, "", "", "")
      .then((res) => {
        const apiSectionData = res.data.results;

        setArticle(apiSectionData);
        apiSectionData && apiSectionData.length > 0 && setFileLoaded(true);
      });

    // console.log(response);
  }, [widgetId]);

  useEffect(() => {
    getSectionDetail();
    if (widgetId) {
      getArticleDetail();
    }
  }, [widgetId]);

  let title;
  let sectionName;
  let linkNewsDetail;
  if (sections) {
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 1:
            const text = section.widget_contents[0];
            if (text) {
              title = text.content;
            }

            break;
          case 2:
            const link = section?.widget_contents ?? section.widget_contents[0];
            if (link && link.length !== 0) {
              linkNewsDetail = link[0].article_language?.path;
            }
            break;

          case 3:
            break;
          default:
            break;
        }
      });
    }
  }

  const filteredArticle = article?.filter(
    (art) => art.id.toString() !== param1
  );

  return fileLoaded ? (
    <div className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto  mt-[100px] z-[5000] ">
      <div className="lg:pt-10 pt-5 font-inter-bold text-2xl">{title}</div>
      <div className="mt-10">
        <Splide
          className=""
          options={{
            type: "slide",
            rewind: "false",
            arrows: false,
            gap: "1.5rem",
            pagination: false,
            breakpoints: {
              623: {
                perPage: 1,
                perMove: 1,
              },

              800: {
                perPage: 2,
                perMove: 1,
              },
              800: {
                perPage: 2,
                perMove: 1,
              },
              1000: {
                perPage: 2,
                gap: "0.5rem",
                perMove: 1,
              },
              1024: {
                perPage: 2,
                perMove: 1,
              },
              1100: {
                perPage: 3,
                perMove: 1,
              },
              1150: {
                perPage: 3,
                perMove: 1,
              },
              1200: {
                perPage: 4,
                gap: "1rem",
                perMove: 1,
              },
              1440: {
                perPage: 4,
                gap: "1.5rem",
                perMove: 1,
              },
              1600: {
                perPage: 4,
                perMove: 1,
              },
              1920: {
                perPage: 4,
                perMove: 1,
              },
              2560: {
                perPage: 4,
                perMove: 1,
              },
            },
          }}
        >
          {filteredArticle?.map((art, i) => {
            // const getActiveArtLang = art.article_languages.filter((ar) => ar.language === activeLang);
            // const activeArtLang = getActiveArtLang[0];
            const checkAsset =
              art.article_assets && art.article_assets.length > 0;
            const artAsset = checkAsset
              ? art.article_assets[0].asset.file_small
              : "https://bzpublishassets.blob.core.windows.net/media/assets_medium/6a2c8b6578016552737b1ed5b6224db2.jpg";

            const artCategorie =
              art.categories && art.categories.length > 0
                ? art.categories[0].name
                : "Event & News";
            return (
              <SplideSlide key={art.id} className="group">
                {art?.article_assets[0]?.asset?.thumbnail_file ? (
                  <a
                    href={replaceAssetLink(
                      art?.article_assets[0]?.asset?.asset_file
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <div className="">
                      <div className="  overflow-hidden shadow-lg">
                        <img
                          width="819"
                          height="461"
                          src={
                            replaceAssetLink(
                              art?.article_assets[0]?.asset?.thumbnail_file
                            ) ||
                            replaceAssetLink(
                              art?.article_assets[0]?.asset?.file_small
                            )
                          }
                          alt={art?.article_languages?.title}
                          className="drop-shadow-lg object-cover transition duration-700 ease-in-out group-hover:scale-110"
                        />
                      </div>
                      <div className="space-y-2 mt-5">
                        <div className="flex justify-between">
                          <div className="capitalize font-inter-bold text-[#004A98] text-[14px]">
                            {artCategorie}
                          </div>
                          <div className="text-left text-sm font-inter">
                            {" "}
                            {moment(art.date).format(" MMMM D, YYYY")}
                          </div>
                        </div>
                        {art?.article_languages?.title &&
                          trancate(2, art?.article_languages?.title)}
                      </div>
                    </div>
                  </a>
                ) : (
                  <Link
                    className="cursor-pointer"
                    to={
                      `${linkNewsDetail}/${art?.id}/${art?.article_languages?.slug_title} ` ??
                      "/"
                    }
                  >
                    <div className="">
                      <div className="  overflow-hidden shadow-lg">
                        <img
                          width="819"
                          height="461"
                          src={
                            replaceAssetLink(
                              art?.article_assets[0]?.asset?.thumbnail_file
                            ) ||
                            replaceAssetLink(
                              art?.article_assets[0]?.asset?.file_small
                            )
                          }
                          alt={art?.article_languages?.title}
                          className="drop-shadow-lg object-cover transition duration-700 ease-in-out group-hover:scale-110"
                        />
                      </div>
                      <div className="space-y-2 mt-5">
                        <div className="flex justify-between">
                          <div className="capitalize font-inter-bold text-[#004A98] text-[14px]">
                            {artCategorie}
                          </div>
                          <div className="text-left text-sm font-inter">
                            {" "}
                            {moment(art.date).format(" MMMM D, YYYY")}
                          </div>
                        </div>
                        {art?.article_languages?.title &&
                          trancate(2, art?.article_languages?.title)}
                      </div>
                    </div>
                  </Link>
                )}
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
    </div>
  ) : (
    <div className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1auto] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto  lg:mt-28 mt-16  relative">
      <div className="flex justify-between">
        <Skeleton width="200px" />
      </div>
      <div className="mt-10">
        <Splide
          className=""
          options={{
            type: "slide",
            rewind: "false",
            arrows: false,
            gap: "1.5rem",
            pagination: false,
            breakpoints: {
              623: {
                perPage: 1,
                perMove: 1,
              },

              800: {
                perPage: 2,
                perMove: 1,
              },
              800: {
                perPage: 2,
                perMove: 1,
              },
              1000: {
                perPage: 2,
                gap: "0.5rem",
                perMove: 1,
              },
              1024: {
                perPage: 2,
                perMove: 1,
              },
              1100: {
                perPage: 3,
                perMove: 1,
              },
              1150: {
                perPage: 3,
                perMove: 1,
              },
              1200: {
                perPage: 4,
                gap: "1rem",
                perMove: 1,
              },
              1440: {
                perPage: 4,
                gap: "1.5rem",
                perMove: 1,
              },
              1600: {
                perPage: 4,
                perMove: 1,
              },
              1920: {
                perPage: 4,
                perMove: 1,
              },
              2560: {
                perPage: 4,
                perMove: 1,
              },
            },
          }}
        >
          {Array.from({ length: 4 }, (_, index) => (
            <SplideSlide className="group">
              <Skeleton variant="rect" width="100%" height={150} />
              <Box sx={{ pt: 0.5 }}>
                <Skeleton width="60%" />
                <Skeleton />
              </Box>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
}

export default TBSRelatedPost;
