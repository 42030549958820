/* eslint-disable */
import React, { Component, createRef } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { isMobile  } from 'react-device-detect';
import TBS_HERO_573 from '../../sections/TBS_Hero_573/TBS_Hero';
import TBS_SIDENAVIGATION_601 from '../../sections/TBS_SideNavigation_601/TBS_SideNavigation';
import TBS_GRIDASSETS_ARTICLE_765 from '../../sections/TBS_GridAssets_Article_765/TBS_GridAssets_Article';
import TBS_REGULERARTICLE_763 from '../../sections/TBS_RegulerArticle_763/TBS_RegulerArticle';
import TBS_GRIDASSETS_ARTICLE_766 from '../../sections/TBS_GridAssets_Article_766/TBS_GridAssets_Article';
import TBS_ARTICLE_HASLINK_965 from '../../sections/TBS_Article_HasLink_965/TBS_Article_HasLink';
import TBS_RELATED_PAGES_633 from '../../sections/TBS_Related_Pages_633/TBS_Related_Pages';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class project1 extends Component {
   constructor(props) {
       super(props);
       this.state = {
           currentPage: null,
           currentSection: null,
           override: true,
           section: [
               { id: 1, slug: localStorage.getItem('language') === "English"? 'hero' : 'hero'},
               { id: 2, slug: localStorage.getItem('language') === "English"? 'sidenav' : 'sidenav'},
               { id: 3, slug: localStorage.getItem('language') === "English"? 'health-wellness-and-safety' : 'kesehatan-kesejahteraan-dan-keselamatan'},
               { id: 4, slug: localStorage.getItem('language') === "English"? 'inclusion-and-diversity' : 'inklusi-dan-keragaman'},
               { id: 5, slug: localStorage.getItem('language') === "English"? 'community-empowerment' : 'pemberdayaan-masyarakat'},
               { id: 6, slug: localStorage.getItem('language') === "English"? 'talent-attraction-and-development' : 'akuisisi-dan-pengembangan-talenta'},
               { id: 7, slug: localStorage.getItem('language') === "English"? 'related-pages' : 'related-pages'},
           ],
       };
       this.scrollToSection = this.scrollToSection.bind(this);
       this.scrollAction = this.scrollAction.bind(this);
        this.isStateChanging = false
   }
   componentDidMount() {
       this.scrollToSection();
       ReactGA.initialize('G-7WJJLX00XL')
       ReactGA.pageview(window.location.pathname + window.location.search);
   }
   componentWillUnmount(){
   }
   section1 = createRef()
   section2 = createRef()
   section3 = createRef()
   section4 = createRef()
   section5 = createRef()
   section6 = createRef()
   section7 = createRef()
   scrollToSection() {
       let currentLocation = window.location.href
       const hasSectionAnchor = currentLocation.includes('/#')
       if (hasSectionAnchor) {
           let anchorSectionId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
           setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
       }
   }
   scrollAction(anchorSectionId) {
       const uri_dec = decodeURIComponent(anchorSectionId)
       const key = this.state.section.filter(x => x.slug == uri_dec).map(obj => `section${obj.id}`)
       const getY = (element) => element.getBoundingClientRect().top + window.pageYOffset - 140;
       if (key) {
          if (key[0] === 'section1') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section1.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section2') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section2.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section3') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section3.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section4') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section4.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section5') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section5.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section6') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section6.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section7') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section7.current), behavior: 'smooth' })}, 1000)
            }
       }
   }
   render() {
       const getHelmetTitle = () => {
           var title;
           switch (localStorage.getItem('language')) {
               case 'English':
                   title = <title> Social | TBS Energi Utama</title>;
                   break;
               case 'Indonesian':
                   title = <title> Sosial | TBS Energi Utama</title>;
                   break;
               case 'Vietnamese':
                   title = <title> Social | TBS Energi Utama</title>;
                   break;
               default:
                   title = <title> Social | TBS Energi Utama</title>;
                   break;
           }
           return title;
       };
       const getHelmetDescription = () => {
           var description;
           switch (localStorage.getItem('language')) {
               case 'English':
                   description = <meta name='description' content='The welfare of our employees and others are important for harmonious relations and optimization of our productivity.' />;
                   break;
               case 'Indonesian':
                   description = <meta name='description' content='Kesejahteraan karyawan dan orang lain sangat penting untuk hubungan yang harmonis dan optimalisasi produktivitas kami.' />;
                   break;
               case 'Vietnamese':
                   description = <meta name='description' content='None' />;
                   break;
               default:
                   description = <meta name='description' content='The welfare of our employees and others are important for harmonious relations and optimization of our productivity.' />;
                   break;
           }
           return description;
       };
       const getHelmetKeywords = () => {
           var keywords;
           switch (localStorage.getItem('language')) {
               case 'English':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Indonesian':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Vietnamese':
                   keywords = <meta name='keywords' content='' />;
                   break;
               default:
                   keywords = <meta name='keywords' content='' />;
                   break;
           }
           return keywords;
       };
         let helmet = getHelmetTitle();
         let description = getHelmetDescription();
         let keywords = getHelmetKeywords();
       return (
           <>
               <Helmet>
                   {helmet}
                   {description}
                   {keywords}
               </Helmet>
            <div ref={this.section1}>
                <TBS_HERO_573 />
            </div>
            <div ref={this.section2}>
                <TBS_SIDENAVIGATION_601 />
            </div>
            <div ref={this.section3}>
                <TBS_GRIDASSETS_ARTICLE_765 />
            </div>
            <div ref={this.section4}>
                <TBS_REGULERARTICLE_763 />
            </div>
            <div ref={this.section5}>
                <TBS_GRIDASSETS_ARTICLE_766 />
            </div>
            <div ref={this.section6}>
                <TBS_ARTICLE_HASLINK_965 />
            </div>
            <div ref={this.section7}>
                <TBS_RELATED_PAGES_633 />
            </div>
           </>
       );
   }
}
export default withParams(project1);