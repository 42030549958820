/* eslint-disable */

import React, { useCallback, useEffect, useState } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GlobalApi from "../../context/api/GlobalApi";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import Api from "./Api";

import V from "./assets/Vector.png";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";

function trancate(clamp = 2, string) {
  const html = <h4 className=" s:lizne-clamp-4 line-clzamp-2">{string}</h4>;
  return html;
}

export default function TBS_Industry_Initiatives() {
  const [sections, setSections] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const [widgetId, setWidgetId] = useState();
  const [listCategoryArticle, setListCategoryArticle] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      // console.log(sectionsData.data.results);
      const apiSectionData = res.data.results;
      setSections(apiSectionData);
      setSectionName(apiSectionData[0].slug_name);
      if (apiSectionData) {
        const sec = apiSectionData[0]?.widgets;
        if (sec && sec.length !== 0) {
          setWidgetId(sec[1].id);
        }
      }

      return sections;
    });
    // console.log(response);
  }, [sections]);

  const getArticleDetail = useCallback(() => {
    const api = new GlobalApi();
    const fetchGetGroupArticle = api.getArticleAllLangFalse({
      widgets: widgetId,
    });

    fetchGetGroupArticle.then((res) => {
      setListCategoryArticle(res?.data?.results.reverse());
    });

    // console.log(response);
  }, [widgetId]);

  useEffect(() => {
    getSectionDetail();
    if (widgetId) {
      getArticleDetail();
    }
  }, [widgetId]);

  let titleText;
  let descriptionText;
  let linkText;
  let iconPlay;
  let hrefText;
  let artList;
  let linkNewsDetail;

  if (sections) {
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const header =
              section?.widget_contents ?? section.widget_contents[0];
            titleText = header[0]?.article_language?.title;
            descriptionText = header[0]?.article_language?.summary;
            linkText = header[0]?.article_language?.content;
            iconPlay = header[0]?.assets[0];
            hrefText = header[0]?.article_language?.path;
            break;
          case 1:
            artList = section?.widget_contents;
            // setArticle(section?.widget_contents);

            break;
          case 2:
            const link = section?.widget_contents ?? section.widget_contents[0];
            if (link && link.length !== 0) {
              linkNewsDetail = link[0].article_language;
            }
            break;
          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
      className="mb-[120px]"
    >
      <div className="section-width-new">
        <div className="flex items-end border-b-[2px] space-x-[24px] pb-[16px]">
          <LazyLoadImage
            className="h-auto w-auto"
            src={replaceAssetLink(iconPlay?.asset_file)}
            alt={iconPlay?.description}
          />
          <h2 className="heading-titile-grid-2023 ">{titleText}</h2>
        </div>
        <div className="mt-[2.6px] ">
          <Splide
            className="csplide-new"
            options={{
              type: "slide",
              rewind: "false",
              arrows: false,
              gap: "1.5rem",
              pagination: true,
            }}
          >
            {listCategoryArticle?.map((art, i) => {
              return (
                <SplideSlide key={art.id} className="group csplide-new ">
                  <div
                    className="cusrsor-pointer"
                    // to={
                    //   `${linkNewsDetail?.path}/${art?.id}/${art?.article_languages?.slug_title} ` ??
                    //   "/"
                    // }
                  >
                    <div className="">
                      <div className=" flex md:flex-row flex-col md:space-x-[24px] md:space-y-[0] space-y-[24px]">
                        <div className="relative md:h-[224px]  md:w-[461px]">
                          <LazyLoadImage
                            src={
                              replaceAssetLink(
                                art?.article_assets[0]?.asset?.thumbnail_file
                              ) ||
                              replaceAssetLink(
                                art?.article_assets[0]?.asset?.file_small
                              )
                            }
                            title={art?.article_assets[0]?.asset?.description}
                            alt={art?.article_assets[0]?.asset?.description}
                            className="md:h-[224px] h-full w-full md:w-[461px] object-cover z-[101]"
                          />
                          <img
                            src={V}
                            alt=""
                            className="w-full h-full  absolute z-[100] top-0  "
                          />
                        </div>
                        <div className="relative md:text-left text-xenter flex flex-col items-center md:justify-start justify-center w-auto md:w-[364px]">
                          <div className="text-[18px] lg:mt-[36px] s:mt-[10px] md:text-left text-center ">
                            {art?.article_languages?.summary &&
                              trancate(2, art?.article_languages?.summary)}
                          </div>{" "}
                          {/* <div className="px-[44px] py-[9px] border rounded-[20px]  w-fit md:absolute bottom-0 md:mt-0 mt-[36px] left-0 button-new">
                            {linkNewsDetail?.title}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </div>
    </BaseContainerPl>
  );
}
