/* eslint-disable */

import React, { useState, useEffect } from "react";
import PARSE from "html-react-parser";
import Api from "./Api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import replaceAssetLink from "../../context/utils/replaceAssetLink";

export default function TBSAboutUsWhistleblower() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let titleLink;
  let summary;
  let linkToWhistle;
  let content;
  let icon;
  let arrow;
  let sectionName;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text?.article_language?.title;
            summary = text?.article_language?.summary;
            content = text?.article_language?.content;
            icon = replaceAssetLink(text?.assets[0]?.asset_file);

            break;
          case 1:
            const link = section.widget_contents[0];
            titleLink = link?.article_language?.title;
            linkToWhistle = link?.article_language?.path;
            arrow = replaceAssetLink(link?.assets[0]?.asset_file);
            break;
          case 3:
            break;
          default:
            break;
        }
      });
    }
  }

  return (
    <div
      // id={sectionName}
      className="section-container   bg-[#18467F] relative mt-index-section"
    >
      <div className="mdl:py-20 py-10 w-full">
        <div className="absolute xl:bottom-1/2 xl:translate-y-1/2 bottom-0 hd2xl:left-left-[100px] xl:left-[60px] left-0">
          {icon && (
            <img
              src={icon}
              alt={title}
              className="hd3xl:max-w-full hdxl:max-w-[280px] mdl:max-w-[280px] max-w-[100px]"
            />
          )}
        </div>
        <div className="section-container-pl">
          <div className="section-width ">
            <h2 className="font-inter-bold text-[32px] text-white">
              {" "}
              {title}
            </h2>
            {/* <div className="font-inter-bold text-[32px] text-white">Be a whistleblower</div> */}
            <p className="font-inter-bold text-[32px]   "> {summary}</p>
            <div className="relative">
              {/* {content && (
              <div className="bg-[#F5F5F5] w-fit p-4 mt-6">
                <div className="">{PARSE(content)}</div>
              </div>
            )} */}
              <div className="   ">
                {content && (
                  <div className=" ">
                    <div className=" prose-p:!text-white typo-style ">
                      {PARSE(content)}
                    </div>
                  </div>
                )}
              </div>
              <Link to={linkToWhistle || "/"} className="w-fit flex items-center space-x-2 mt-[25px]">
                  <div className="font-inter-bold text-white text-[16px]">
                    {titleLink}
                  </div>
                  <ArrowForwardIosIcon fontSize="0.5rem" sx={{ color: "white"}} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

