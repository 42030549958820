/* eslint-disable */

import React, { useState, useEffect } from "react";
import { NavHashLink } from "react-router-hash-link";
import Sticky from "react-stickynode";
import { useLocation } from "react-router-dom";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import replaceAssetLink from "../../utils/replaceAssetLink";

const ln13TextVariants = {
  hidden: {
    opacity: 0,
    height: 0,
    // padding: 0,
    transition: { duration: 0.3, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
    },
  },
};

export default function BaseMenuToSection({
  title,
  menus,
  top,
  single,
  bottom,
}) {
  const location = useLocation();

  const currPosSection = useSelector((state) => state.section.isTagData);

  const [menuSelected, setMenuSelected] = useState(null);
  const [isDeepItem, setIsDeepItem] = useState({ id: null, open: false });
  const [isThirdItem, setIsThirdItem] = useState({ id: null, open: false });
  const [hashSelected, setHashSelected] = useState(null);
  const [firstRender, setFirstRender] = useState(false);

  function getCurrentPath() {
    if (menus[0]?.title === "About Us" || menus[0]?.id === 227) {
      return location.hash === ""
        ? menus[0]?.children?.filter((men) => men?.url + "/" === bottom)
        : menus[0]?.children?.filter(
            (men) => men.url === bottom + "/" + location.hash
          );
    } else
      return location.hash !== ""
        ? menus[0]?.children?.filter((men) => men?.url === bottom)
        : menus[0]?.children?.filter((men) => men.url === bottom);
  }

  const selectedPath = getCurrentPath() ?? "";

  useEffect(() => {
    if (menus[0]?.id) {
      setFirstRender(true);
    }
  }, [menus]);

  useEffect(() => {
    if (
      selectedPath &&
      selectedPath.length > 0 &&
      menus[0]?.id !== 227 &&
      firstRender
    ) {
      setTimeout(() => {
        setMenuSelected(selectedPath[0]?.id);
        setIsDeepItem({ id: selectedPath[0]?.id, open: true });
      }, 200);
    }
    setTimeout(() => {
      setFirstRender(false);
    }, 300);
  }, [selectedPath, menus]);

  useEffect(() => {
    if (currPosSection) {
      const sectionActive =
        menus[0]?.id === 227
          ? menus[0]?.children?.filter(
              (men) =>
                men?.title
                  .replace(/\s+/g, "-")
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9 ]/g, "-")
                  .replace(/^-+|-+$|-+/g, "-") === currPosSection
            )
          : selectedPath[0]?.children?.filter(
              (men) =>
                men?.title
                  .replace(/\s+/g, "-")
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9 ]/g, "-")
                  .replace(/^-+|-+$|-+/g, "-") === currPosSection
            );
      setHashSelected(sectionActive ? sectionActive[0]?.id : null);
    }
  }, [currPosSection, menus]);

  const handleDeepItem = ({ id, open }) => {
    setIsDeepItem({ id, open: !isDeepItem.open });
    if (isDeepItem.id === id && isDeepItem.open === true) {
      setIsDeepItem({ id: null, open: false });
    } else setIsDeepItem({ id, open: true });
  };

  const handleThirdItem = ({ id, open }) => {
    setIsThirdItem({ id, open: !isThirdItem.open });
    if (isThirdItem.id === id && isThirdItem.open === true) {
      setIsThirdItem({ id: null, open: false });
    } else setIsThirdItem({ id, open: true });
  };

  function getBottomNonAboutUs() {
    const bottom = selectedPath[0]?.children
      ? selectedPath[0]?.children[selectedPath[0]?.children.length - 1]
      : selectedPath[0];
    const trans = bottom?.title
      .replace(/\s+/g, "-")
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, "-")
      .replace(/^-+|-+$|-+/g, "-");

    return trans;
  }

  function getBottom() {
    const bottom = menus[0]?.children[menus[0]?.children.length - 1];
    const trans = bottom?.url
      ? bottom?.url.split("/").pop()
      : // .replace(/[^a-zA-Z0-9 ]/g, '')
        "footer";

    // console.log()
    return trans;
  }

  function ThirdLevelMenu(menu) {
    return menu?.map((mn) => {
      return mn?.article?.article_assets &&
        mn?.article?.article_assets.length > 0 ? (
        <div key={mn.id}>
          <div className={`${"flex items-center px-4   text-[#636363]"}`}>
            <a
              target="_blank"
              href={replaceAssetLink(
                mn?.article?.article_assets[0]?.asset?.asset_file
              )}
            >
              <li className="py-1 max-w-[230px]">{mn.title}</li>
            </a>
          </div>
        </div>
      ) : (
        <div key={mn.id}>
          <div
            className={`${
              hashSelected?.toString() === mn?.id.toString()
                ? " flex items-center px-4   text-[#004A98] font-inter-bold"
                : "flex items-center px-4   text-[#636363]"
            }`}
          >
            <NavHashLink
              className=" "
              // onClick={() => setHashSelected(mn.id)}
              smooth
              to={
                mn?.url ? mn?.url.replace(/,/g, "").replaceAll("-/", "-") : "/"
              }
            >
              <li className="py-1 max-w-[230px]">{mn.title}</li>
            </NavHashLink>
            {mn?.children && mn?.children?.length > 0 && (
              <button
                type="button"
                onClick={() => handleThirdItem({ id: mn.id, open: true })}
                className="absolute  w-3 h-3 right-3 flex items-center z-30  transition ease-in-out duration-300"
              >
                {isThirdItem.id === mn.id ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowDown />
                )}
              </button>
            )}
          </div>
          <AnimatePresence>
            {isThirdItem.open && isThirdItem.id === mn.id && (
              <motion.div
                className=" pl-3 mt-"
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={ln13TextVariants}
              >
                {/* {RecursiveMenu(tagsHeading)} */}
                {mn.children.map((tag) => (
                  // <div className="">{mn}</div>
                  <div
                    className={`${
                      "#" +
                        tag.title
                          .replace(/\s+/g, "-")
                          .toLowerCase()
                          // .replace(/[^a-zA-Z0-9 ]/g, '')
                          .replace(/,/g, "") ===
                      location.hash
                        ? "flex items-center px-4  text-[#004A98] font-inter-bold"
                        : "flex items-center px-4   text-[#636363]"
                    }`}
                  >
                    <NavHashLink
                      className=" "
                      // onClick={() => setMenuSelected(tag.id)}
                      smooth
                      to={
                        mn?.url.split("#")[0] +
                          "#" +
                          tag.title
                            .replace(/\s+/g, "-")
                            .toLowerCase()
                            // .replace(/[^a-zA-Z0-9 ]/g, '')
                            .replace(/,/g, "") ?? "/"
                      }
                    >
                      <li className="py-1">{tag.title}</li>
                    </NavHashLink>
                  </div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      );
    });
  }

  function RecursiveMenu(menu) {
    return menu?.map((menu) => (
      <div key={menu.id}>
        {menus[0]?.id !== 227 ? (
          <div
            className={`${
              menuSelected?.toString() === menu?.id.toString()
                ? "bg-[#F5F5F5] flex items-center px-4   border-l-4 border-[#004A98]"
                : "flex items-center px-4   text-[#636363]"
            }`}
          >
            <NavHashLink
              className=" "
              onClick={() => setMenuSelected(menu.id)}
              smooth
              to={menu?.url ?? "/"}
            >
              <li className="py-1 max-w-[260px]">{menu.title}</li>
            </NavHashLink>
            {menu?.children && menu?.children?.length > 0 && (
              <button
                type="button"
                onClick={() => handleDeepItem({ id: menu.id, open: true })}
                className={"absolute  w-3 h-3 right-3 flex items-center z-30 "}
              >
                {isDeepItem.id === menu.id ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowDown />
                )}
              </button>
            )}
          </div>
        ) : (
          <div
            className={`${
              hashSelected?.toString() === menu?.id.toString()
                ? " flex items-center px-4   text-[#004A98] font-inter-bold"
                : "flex items-center px-4   text-[#636363]"
            }`}
          >
            <NavHashLink
              className=" "
              // onClick={() => setHashSelected(menu.id)}
              smooth
              to={
                menu?.url
                  ? menu?.url.replace(/,/g, "").replaceAll("-/", "-")
                  : "/"
              }
            >
              <li className="py-1 max-w-[230px]">{menu.title}</li>
            </NavHashLink>
            {menu?.children && menu?.children?.length > 0 && (
              <button
                type="button"
                onClick={() => handleDeepItem({ id: menu.id, open: true })}
                className="absolute  w-3 h-3 right-3 flex items-center z-30  transition ease-in-out duration-300"
              >
                {isDeepItem.id === menu.id ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowDown />
                )}
              </button>
            )}
          </div>
        )}
        <AnimatePresence>
          {isDeepItem.open && isDeepItem.id === menu.id && (
            <motion.div
              className=" pl-3 mt-"
              initial="hidden"
              animate="show"
              exit="hidden"
              variants={ln13TextVariants}
            >
              {ThirdLevelMenu(menu.children)}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    ));
  }

  return (
    <div className="">
      {menus[0]?.title ? (
        <>
          {menus[0]?.title === "About Us" || menus[0]?.id === 227 ? (
            <Sticky top={top} bottomBoundary={getBottom() ?? "#footer"}>
              <div className="font-inter-bold text-[#004A98] px-4 uppercase ">
                {menus[0]?.title}
              </div>
              <ul className="mt-2">
                {RecursiveMenu(menus[0]?.children ? menus[0]?.children : menus)}
              </ul>
            </Sticky>
          ) : (
            <Sticky
              top={top}
              bottomBoundary={"#" + (getBottomNonAboutUs() || currPosSection)}
            >
              <div className="font-inter-bold text-[#004A98] px-4 uppercase">
                {menus[0]?.title}
              </div>
              <ul className="mt-2">
                {RecursiveMenu(menus[0]?.children ? menus[0]?.children : menus)}
              </ul>
            </Sticky>
          )}
        </>
      ) : (
        <>
          <Stack spacing={1} className="mt-[30px]">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Stack>
        </>
      )}
    </div>
  );
}
