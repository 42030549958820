/* eslint-disable */

import React, { useEffect, useRef } from "react";
import PARSE from "html-react-parser";
import BaseArrowLinkTw from "./BaseArrowLinkTw";

export default function BaseHeroCard({
  background,
  description,
  article,
  type,
  theLink = null,
  title = "",
  textLink = "",
}) {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="w-full qhd:h-[60vh] md:h-[585px] h-[50vh] relative">
      <div className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto relativ">
        {
          theLink === null ? (
            <div className="absolute lg:bottom-20 s:bottom-[3rem] bottom-[2rem] z-[500] text-white font-inter-bold text-4xl">
              {description || ""}
            </div>
          ) : (
            <div className="absolute mt-[22vh] z-[500]">
              <div className={`my-auto w-full`}>
              <div className="text-white font-bold text-[45px] leading-10">
                <h2>{title}</h2>
              </div>
              <div className={`group text-white mt-[25px] w-fit`}>
                <a className="pl-[5px] flex gap-x-[15px] text-[16px] leading-[20px] font-bold" href={`${theLink}`} target="_blank" rel="noreferrer">
                  <p>{textLink}</p>
                  <div className="mt-[2px]">
                    <BaseArrowLinkTw size="small" />
                  </div>
                </a>
              </div>
            </div>
            </div>
          )
        }
        {article && (
          <div className="w-full  py-[100px] bg-[#F2F5F6]">
            <div className="section-container h-full mdl:max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px]  font-inter text-center mx-auto text-[24px] text-[#1A1A1A] ">
              {PARSE(article)}
            </div>
          </div>
        )}
      </div>
      {type === "video" ? (
        <video
          className=" qhd:h-[60vh] md:h-[585px] h-[50vh] object-cover w-full "
          style={{
            maxWidth: "100%",
            width: "120%",
            height: "",
            margin: "",
          }}
          playsInline
          loop
          muted
          controls
          alt="All the devices"
          src={background}
          ref={videoEl}
        />
      ) : (
        <div className=" qhd:h-[60vh] md:h-[585px] h-[50vh] ">
          <img
            height="auto"
            width="auto"
            src={background}
            fetchpriority="high"
            alt={description}
            className="absolute left-0 w-full qhd:h-[60vh] md:h-[585px] h-[50vh] object-cover object-center "
          />
          {/* <div className="absolute -z-10 bg-gradient-to-b from-black via-transparant to-transparant qhd:h-[60vh] md:h-[585px] h-[50vh] w-full " /> */}
        </div>
      )}
    </div>
  );
}
