import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Close, Search } from '@mui/icons-material';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { InputBase } from '@mui/material';

import MenuContext from '../MenuContext';
import styles from '../TBS_Menu.module.css';

const itemVariants = {
  closed: { opacity: 0 },
  open: { opacity: 1 },
};

const menuVariants = {
  closed: {
    transition: {
      staggerChildren: 0,
      staggerDirection: -1,
      duration: 0.1,
      delay: 0,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
      duration: 1,
      delay: 0.3,
    },
  },
};

export default function MenuSearch() {
  const [search, setSearch] = useState('');
  const menuContext = useContext(MenuContext);
  const { data, isDesktop } = menuContext;
  useEffect(() => {
    document.body.style.overflowX = 'hidden';
    return () => {
      document.body.style.overflowX = 'unset';
    };
  }, []);

  let containerStyle = {
    top: 120,
    // height: 120,
  };
  let boxContentStyle = 'w-full';
  if (!isDesktop) {
    containerStyle = { top: 80 };
    boxContentStyle = 'w-4/5';
  }

  const navigate = useNavigate();

  const onSearcTitle = (event) => {
    setSearch(event);
  };

  console.log(data.text[1]?.content);

  return (
    <motion.div
      className={['fixed !-top-[120px] bg-white z-[9999] min-h-[600px]', styles.dsktpContainerScroll].join(' ')}
      initial={{ width: 0, height: '50vh', minHeight: '400px', right: '-100%', ...containerStyle, opacity: 0 }}
      animate={{ width: '100%', height: '50vh', right: 0, ...containerStyle, opacity: 1, transition: { delay: 0, duration: 0.3 } }}
      exit={{
        right: '-100%',
        opacity: 0,
        height: '50vh',

        ...containerStyle,
        transition: { delay: 0, duration: 0.3 },
      }}
    >
      <motion.div
        className="section-container relative h-full z-[1000]
            mdl:max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto my-[250px] min-h-[400px]"
        initial="closed"
        animate="open"
        exit="closed"
        variants={menuVariants}
      >
        <div className={['absolute top-8 z-[999] cursor-pointer', isDesktop ? 'right-0' : 'right-2'].join(' ')}>
          <Close sx={{ width: 32, height: 32 }} onClick={() => menuContext.setIsSearch(false)} />
        </div>
        <div className={['h-full max-w-[990px] pt-16 pb-8 flex flex-col mx-auto', boxContentStyle].join(' ')}>
          <motion.div variants={itemVariants} className="w-full flex items-center h-12 border-b">
            <Search sx={{ width: 32, height: 32, color: 'rgba(0, 0, 0, .5)' }} />
            <InputBase
              onChange={(e) => onSearcTitle(e.target.value)}
              placeholder={data.text[0]?.content}
              className={['ml-4 !w-full', styles.inputSearch].join(' ')}
              inputProps={{
                onKeyPress: (event) => {
                  if (event.key === 'Enter') {
                    navigate({
                      pathname: 'search',
                      search: `?${createSearchParams({ search })}`,
                    });
                    menuContext.setIsSearch(false);
                    event.preventDefault();
                  }
                },
              }}
            />
            <button
              type="button"
              onClick={() => [
                navigate({
                  pathname: 'search',
                  search: `?${createSearchParams({ search })}`,
                }),
                menuContext.setIsSearch(false),
              ]}
              className="ml-auto"
            >
              {data.text[1]?.content}
            </button>
          </motion.div>
          <div className="w-full flex flex-col mt-8">
            {/* <motion.h5 variants={itemVariants} className={['uppercase', styles.itemSearchTitle].join(' ')}>
              {data.text[1]?.content}
            </motion.h5> */}
            <div className="w-full grid grid-cols-3 gap-4 mt-4">
              {menuContext.menu?.items?.map((itm) => (
                <motion.div key={itm.title} variants={itemVariants} className={styles.itemSearch}>
                  <span>{itm.title}</span>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
