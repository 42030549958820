/* eslint-disable */

import React, { useState, useEffect } from 'react';
import BaseMenuToSection from '../../context/components/Base/BaseMenuToSection';
import Api from './Api';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function TBSSideNavigation() {
  const [menus, setMenus] = useState([]);

  // console.log(trans);
  // console.log(currentPath);
  // console.log(newCurrentPath);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getMenus = async () => {
    const api = new Api();
    const response = await api.getMenuDetail({ language: activeLang });
    const parsedData = await response;
    const menusData = parsedData;
    setMenus(menusData.data.items);
    return menus;
  };

  const [sections, setSections] = useState([]);
  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getMenus();
    getSectionDetail();
  }, []);

  const location = useLocation();
  const trans = sections[0]?.page_url?.split('/');


  const currentPath = menus?.filter((men) => men?.title?.replace(/\s+/g, '-').toLowerCase() === trans[1]);

  const newCurrentPath = currentPath[0]?.children?.filter((men) => men?.title?.replace(/\s+/g, '-').toLowerCase() === trans[2]);

  let title;
  if (sections) {
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text.content;

            break;
          case 2:
            break;
          case 3:
            break;
          default:
            break;
        }
      });
    }
  }

  return (
    <div className="relative h-full section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto  mt-[80px] z-[5000] lg:bg-transparent bg-white xl:block hidden">
      <div className="xl:w-[300px] lg:w-[270px] w-full lg:absolute relative">
        <BaseMenuToSection top={160} title="" single menus={currentPath || []} bottom={sections[0]?.page_url} />
      </div>
    </div>
  );
}
