import React from 'react';
import PropTypes from 'prop-types';

export default function BaseNoArticleFound({ label, customElement }) {
  const lang = localStorage.getItem('language') || 'English';
  let usedLabel = label;
  if (!label) {
    usedLabel = lang.toLowerCase() === 'english' ? 'Article not found' : 'Artikel tidak ditemukan';
  }
  return (
    <div className="w-full h-full">
      {!customElement ? (
        <div className="section-container max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto mt-[120px] md:mt-[120px]">
          <div className="relative flex items-center justify-center w-full h-32">
            <p className="text-center">{usedLabel}</p>
          </div>
        </div>
      ) : (
        customElement
      )}
    </div>
  );
}

BaseNoArticleFound.propTypes = {
  label: PropTypes.string.isRequired,
  customElement: PropTypes.element,
};

BaseNoArticleFound.defaultProps = { customElement: null };
