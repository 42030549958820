/* eslint-disable max-len */
import React from 'react';

export default function Expand({ fill = 'none', config = {} }) {
  return (
    <svg width="8" height="5" {...config} viewBox="0 0 8 5" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.45003 4.3625C4.36221 4.45043 4.24306 4.49989 4.11878 4.5H3.88128C3.75727 4.49856 3.6386 4.4493 3.55003 4.3625L0.34378 1.15C0.31449 1.12095 0.291241 1.08638 0.275376 1.0483C0.259511 1.01022 0.251343 0.969375 0.251343 0.928121C0.251343 0.886868 0.259511 0.846022 0.275376 0.807941C0.291241 0.76986 0.31449 0.735297 0.34378 0.706246L0.78753 0.262496C0.816044 0.233394 0.850079 0.210274 0.887641 0.19449C0.925203 0.178706 0.965536 0.170577 1.00628 0.170577C1.04702 0.170577 1.08736 0.178706 1.12492 0.19449C1.16248 0.210274 1.19652 0.233394 1.22503 0.262496L4.00003 3.04375L6.77503 0.262496C6.80408 0.233206 6.83864 0.209958 6.87673 0.194093C6.91481 0.178228 6.95565 0.170059 6.9969 0.170059C7.03816 0.170059 7.079 0.178228 7.11708 0.194093C7.15517 0.209958 7.18973 0.233206 7.21878 0.262496L7.65628 0.706246C7.68557 0.735297 7.70882 0.76986 7.72468 0.807941C7.74055 0.846022 7.74872 0.886868 7.74872 0.928121C7.74872 0.969375 7.74055 1.01022 7.72468 1.0483C7.70882 1.08638 7.68557 1.12095 7.65628 1.15L4.45003 4.3625Z"
        fill={fill}
      />
    </svg>
  );
}
