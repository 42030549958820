/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Api from "./Api";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import clsxm from "../../context/utils/clsxm";
import PARSE from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";

function TBS_ESG_Committee() {
  const [sections, setSections] = useState([]);
  const [sectionName, setSectionName] = useState("");

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      const apiSectionData = res.data.results;
      setSections(apiSectionData);
      setSectionName(apiSectionData[0].slug_name);

      return sections;
    });
  }, [sections]);

  useEffect(() => {
    getSectionDetail();
  }, []);

  let headTitle;
  let headContent;
  let descriptionText;
  let headAsset;
  let listContent;
  let gmsContent;

  if (sections) {
    let sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const header =
              section?.widget_contents ?? section.widget_contents[0];
            headTitle = header[0]?.article_language?.title;
            descriptionText = header[0]?.article_language?.summary;
            headContent = header[0]?.article_language?.content;
            headAsset = header[0]?.assets[0];
            break;
          case 1:
            listContent = section?.widget_contents;

            break;
          case 2:
            gmsContent = section?.widget_contents[0];

            break;

          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      <div className="section-width-new">
        <div className=" border-b-[2px] space-x-[24px] pb-[16px]">
          <h2 className="heading-titile-grid-2023 ">{headTitle}</h2>
        </div>
        <div className="relative mt-[45px]">
          {headContent && (
            <div className="mt-[45px]">
              <div className="font-inter mt-[45px] prose-p:text-[16px] prose-p:leading-[26px] text-[#1A1A1A] leading-[26px] prose-strong:font-inter-bold prose-strong:font-semibold prose-strong:leading-[25px] !text-justify">
                {PARSE(headContent)}
              </div>
            </div>
          )}
        </div>
        <div className={` gflex flex-row gap-[39px] space-y-[39px] mt-[54px]`}>
          {listContent &&
            listContent?.map((data, idx) => {
              const content = data.article_language.content;
              const title = data.article_language.title;
              const summary = data.article_language.summary;
              const description = data.article_language.description;
              const contentImage = replaceAssetLink(data.assets[0]?.asset_file);
              const contentDesc = replaceAssetLink(data.assets[0]?.description);

              return (
                <div
                  key={data.id}
                  className={clsxm(
                    "w-full border rounded-[10px] border-tbs-blue flex flex-col justify-end items-end  py-[45px] md:px-[97px] s:px-[30px] px-[20px] relative"
                    // idx > 3 ? "col-span-2" : ""
                  )}
                >
                  <div
                    className={clsxm(
                      "relative md:w-[431px] w-full ",
                      idx > 0 ? "flex justify-end" : ""
                    )}
                  >
                    <div className="flex  bg-tbs-teal md:w-[431px] w-full h-[220px] absolute bottom-0 -z-[1]"></div>
                    <img
                      className="h-auto w-auto -ml-[15px]"
                      src={contentImage}
                      alt={contentDesc || content}
                    />
                    <div
                      className={clsxm(
                        "leading-[22px] font-inter-600 md:text-[28px] s:text-[18px] text-[16px] text-white absolute  bottom-[20px] ",
                        idx === 0
                          ? "md:right-[20px] s:right-[10px] right-[5px]"
                          : "md:left-[20px] s:left-[10px] left-[5px]"
                      )}
                    >
                      {description}
                    </div>
                  </div>
                  <div className="pt-[14px] pb-[22px] space-y-[4px] text-right">
                    <div className="leading-[22px] font-inter-600 text-[18px]">
                      {title}
                    </div>
                    <div className="text-tbs-gray text-[14px] font-[400]">
                      {summary}
                    </div>
                  </div>
                  {content && (
                    <div className="content-grid-2023">{PARSE(content)}</div>
                  )}
                </div>
              );
            })}
        </div>
        <div className="mt-[31px]">
          {" "}
          <img
            className="h-auto w-auto "
            src={replaceAssetLink(gmsContent?.assets[0]?.file_medium)}
            alt={gmsContent?.assets[0]?.description}
          />
          {gmsContent?.article_language.content && (
            <div className="content-grid-2023 mt-[31px]">
              {PARSE(gmsContent?.article_language.content)}
            </div>
          )}
        </div>
      </div>
    </BaseContainerPl>
  );
}

export default TBS_ESG_Committee;
