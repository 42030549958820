/* eslint-disable */

import React, { useState, useEffect, useCallback } from "react";

import PARSE from "html-react-parser";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { Fade } from "react-reveal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Api from "./Api";
import BaseArrowLinkTw from "../../context/components/Base/BaseArrowLinkTw";
import GlobalApi from "../../context/api/GlobalApi";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

function trancate(clamp = 2, string) {
  const html = (
    <h4 className=" font-inter-bold h-full text-lg  text-left line-clamp-2">
      {string}
    </h4>
  );
  return html;
}

export default function TBSHomeNews() {
  const [sections, setSections] = useState([]);
  const [widgetId, setWidgetId] = useState();
  const [listCategoryArticle, setListCategoryArticle] = useState([]);
  const [listCategory, setListCategory] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      // console.log(sectionsData.data.results);
      const apiSectionData = res.data.results;
      setSections(apiSectionData);
      if (apiSectionData) {
        const sec = apiSectionData[0]?.widgets;
        if (sec && sec.length !== 0) {
          setWidgetId(sec[1].id);
          setListCategory(sec[1]?.article_categories);
        }
      }

      return sections;
    });
    // console.log(response);
  }, [sections]);

  const listIdCategory = listCategory?.map((cat) => `&id=${cat.id}`);

  const getArticleDetail = useCallback(() => {
    const api = new GlobalApi();
    const catId = listIdCategory.join("");
    const fetchGetGroupArticle = api.getGroupArticle({
      widget: widgetId,
      category: catId,
      limit: 2,
    });

    fetchGetGroupArticle.then((res) => {
      setListCategoryArticle(res?.data?.results.reverse());
    });

    // console.log(response);
  }, [widgetId]);

  useEffect(() => {
    getSectionDetail();
    if (widgetId) {
      getArticleDetail();
    }
  }, [widgetId]);

  let titleText;
  let descriptionText;
  let linkText;
  let iconPlay;
  let hrefText;
  let artList;
  let linkNewsDetail;

  if (sections) {
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const header =
              section?.widget_contents ?? section.widget_contents[0];
            titleText = header[0]?.article_language?.title;
            descriptionText = header[0]?.article_language?.summary;
            linkText = header[0]?.article_language?.content;
            iconPlay = replaceAssetLink(header[0]?.assets[0]?.asset_file);
            hrefText = header[0]?.article_language?.path;
            break;
          case 1:
            artList = section?.widget_contents;
            // setArticle(section?.widget_contents);

            break;
          case 2:
            const link = section?.widget_contents ?? section.widget_contents[0];
            if (link && link.length !== 0) {
              linkNewsDetail = link[0].article_language?.path;
            }
            break;
          default:
            break;
        }
      });
    }
  }

  const getExpect = listCategoryArticle?.map((art) => {
    return art.articles.map((a, i) => {
      if (a.categories.some((el) => el.id === 18)) {
        return a;
      }
      return i === 0 && a;
    });
  });

  const transArrToFlat = getExpect.flatMap((a) => a);

  const removeArtBoolean = transArrToFlat.filter(Boolean);

  const transDate = removeArtBoolean?.map((obj) => {
    return { ...obj, date: new Date(obj.date) };
  });

  const finalSortedAsc = transDate
    .sort((objA, objB) => Number(objA.date) - Number(objB.date))
    .reverse();

  return (
    <div className="section-container  mt-28">
      <div className="">
        <Fade bottom>
          <div className="space-y-2">
            <h2 className="font-inter-bold text-[28px]">{titleText}</h2>
            <div className="w-full s:w-[350px]">
              <p className="font-inter">{descriptionText}</p>
            </div>
            {linkText && (
              <Link
                to={hrefText ?? ""}
                className="flex items-center gap-x-2 group w-fit"
              >
                <div className="font-inter-bold">{PARSE(linkText)}</div>
                <BaseArrowLinkTw size="small" color="black" />
              </Link>
            )}
          </div>
        </Fade>
        {finalSortedAsc && (
          <div className="mt-10">
            <Splide
              className=""
              options={{
                type: "slide",
                rewind: "false",
                arrows: false,
                gap: "1.5rem",
                pagination: false,
                breakpoints: {
                  623: {
                    perPage: 1,
                    perMove: 1,
                  },

                  800: {
                    perPage: 2,
                    perMove: 1,
                  },
                  800: {
                    perPage: 2,
                    perMove: 1,
                  },
                  1000: {
                    perPage: 2,
                    gap: "0.5rem",
                    perMove: 1,
                  },
                  1024: {
                    perPage: 3,
                    perMove: 1,
                  },
                  1100: {
                    perPage: 3,
                    perMove: 1,
                  },
                  1150: {
                    perPage: 3,
                    perMove: 1,
                  },
                  1200: {
                    perPage: 4,
                    gap: "1rem",
                    perMove: 1,
                  },
                  1440: {
                    perPage: 4,
                    gap: "1.5rem",
                    perMove: 1,
                  },
                  1600: {
                    perPage: 4,
                    perMove: 1,
                  },
                  1920: {
                    perPage: 4,
                    perMove: 1,
                  },
                  2560: {
                    perPage: 4,
                    perMove: 1,
                  },
                  100000: {
                    perPage: 4,
                    perMove: 1,
                  },
                },
              }}
            >
              {finalSortedAsc?.map((art, i) => {
                const checkAsset =
                  art.article_assets && art.article_assets.length > 0;
                const artAsset = checkAsset
                  ? art.article_assets[0].asset.file_small
                  : "https://bzpublishassets.blob.core.windows.net/media/assets_medium/6a2c8b6578016552737b1ed5b6224db2.jpg";

                const artCategorie =
                  art.categories && art.categories.length > 0
                    ? art.categories[0].name
                    : "Event & News";
                return (
                  <SplideSlide key={art.id} className="group">
                    <Fade bottom delay={i < 4 && i * 100}>
                      {art?.article_assets[0]?.asset?.thumbnail_file ? (
                        <a
                          href={replaceAssetLink(
                            art?.article_assets[0]?.asset?.asset_file
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <div className="">
                            <div className="  overflow-hidden shadow-lg">
                              <LazyLoadImage
                                src={
                                  replaceAssetLink(
                                    art?.article_assets[0]?.asset
                                      ?.thumbnail_file
                                  ) ||
                                  replaceAssetLink(
                                    art?.article_assets[0]?.asset?.file_small
                                  )
                                }
                                alt={art?.article_assets[0]?.asset?.description}
                                className=" drop-shadow-lg object-cover transition duration-700 ease-in-out group-hover:scale-110"
                                title={
                                  art?.article_assets[0]?.asset?.description
                                }
                              />
                            </div>
                            <div className="space-y-2 mt-5">
                              <div className="flex justify-between">
                                <div className="capitalize font-inter-bold text-[#004A98] text-[14px]">
                                  {artCategorie}
                                </div>
                                <div className="text-left text-sm font-inter">
                                  {" "}
                                  {moment(art.date).format(" MMMM D, YYYY")}
                                </div>
                              </div>
                              {art?.article_languages?.title &&
                                trancate(2, art?.article_languages?.title)}
                            </div>
                          </div>
                        </a>
                      ) : (
                        <Link
                          className="cursor-pointer"
                          to={
                            `${linkNewsDetail}/${art?.id}/${art?.article_languages?.slug_title} ` ??
                            "/"
                          }
                        >
                          <div className="">
                            <div className=" overflow-hidden shadow-lg">
                              <LazyLoadImage
                                src={
                                  replaceAssetLink(
                                    art?.article_assets[0]?.asset
                                      ?.thumbnail_file
                                  ) ||
                                  replaceAssetLink(
                                    art?.article_assets[0]?.asset?.file_small
                                  )
                                }
                                title={
                                  art?.article_assets[0]?.asset?.description
                                }
                                alt={art?.article_assets[0]?.asset?.description}
                                className="drop-shadow-lg object-cover transition duration-700 ease-in-out group-hover:scale-110"
                              />
                            </div>
                            <div className="space-y-2 mt-5">
                              <div className="flex justify-between">
                                <div className="capitalize font-inter-bold text-[#004A98] text-[14px]">
                                  {artCategorie}
                                </div>
                                <div className="text-left text-sm font-inter">
                                  {" "}
                                  {moment(art.date).format(" MMMM D, YYYY")}
                                </div>
                              </div>
                              {art?.article_languages?.title &&
                                trancate(2, art?.article_languages?.title)}
                            </div>
                          </div>
                        </Link>
                      )}
                    </Fade>
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        )}
      </div>
    </div>
  );
}
