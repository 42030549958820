/* eslint-disable */

import React, { useState, useEffect, useCallback } from "react";

import PARSE from "html-react-parser";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import TruncateMarkup from "react-truncate-markup";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useSelector } from "react-redux";
import Api from "./Api";
import BaseArrowLinkTw from "../../context/components/Base/BaseArrowLinkTw";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

export default function TBSHomeOurReports() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api.getSectionDetail({ language: activeLang }).then((res) => {
      const apiSectionData = res.data.results;
      setSections(apiSectionData);

      return sections;
    });
  }, [sections]);

  useEffect(() => {
    getSectionDetail();
  }, []);

  let titleText;
  let descriptionText;
  let linkText;
  let iconPlay;
  let hrefText;
  let artList;
  let seeAll;

  if (sections) {
    let sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const header =
              section?.widget_contents ?? section.widget_contents[0];
            titleText = header[0]?.article_language?.title;
            descriptionText = header[0]?.article_language?.summary;
            linkText = header[0]?.article_language?.content;
            iconPlay = replaceAssetLink(header[0]?.assets[0]?.asset_file);
            hrefText = header[0]?.article_language?.path;
            break;
          case 1:
            artList = section?.widget_contents;

            break;
          case 2:
            const text = section?.widget_contents ?? section.widget_contents[0];
            if (text && text.length !== 0) {
              seeAll = text[0].content;
            }
            break;

          default:
            break;
        }
      });
    }
  }

  return (
    <div className="section-container h-full max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto my-28">
      <div className="lg:flex-row flex-col flex lg:space-x-16 lg:space-y-0 space-y-8 !justify-between">
        <div className="space-y-2 xl:w-5/12">
          <h2 className="font-inter-bold text-[28px]">{titleText}</h2>
          <div className="max-w-[350px]">
            <p className="font-inter w-full">{descriptionText}</p>
          </div>
        </div>
        <div className="lg:w-6/12 hdxl:w-6/12 hdl:w-[830px] hd:w-[900px] xl:w-[910px] w-full ml-auto flex justify-end">
          <div className="w-full h-full ">
            <Splide
              className=""
              options={{
                perPage: "auto",
                rewind: "true",
                type: "loop",
                gap: "10px",
                perMove: 1,
                // perPage : 3,
                arrows: false,
                pagination: false,
              }}
            >
              {artList?.map((art, i) => {
                const titleVal = art?.article_language?.title;
                const contentVal = art?.article_language?.content;
                const assetVal = replaceAssetLink(art?.assets[0]?.file_small);
                const pathVal = art?.article_language?.path;
                const descVal = art?.assets[0]?.description;
                return (
                  <SplideSlide key={art.id} className="h-full">
                    <Link to={pathVal || "/"}>
                      <div className="">
                        <LazyLoadImage
                          src={assetVal}
                          alt={descVal}
                          title={descVal}
                          className=" object-fill   md:h-[330px] h-[360px] max-w-[270px] shadow-lg"
                        />
                        <TruncateMarkup lines={1}>
                          <h3 className="capitalize font-inter-bold text-[#004A98]  !mt-[20px]">
                            {titleVal}
                          </h3>
                        </TruncateMarkup>
                        {contentVal && (
                          <TruncateMarkup lines={2}>
                            <div className=" font-inter-bold max-w-[240px] text-[16px] mt-[12px]">
                              {PARSE(contentVal)}
                            </div>
                          </TruncateMarkup>
                        )}
                        <div className="flex space-x-2 items-center mt-[20px] group">
                          <div className="font-inter-bold text-[16px] leading-[20px] ">
                            {seeAll}
                          </div>
                          <BaseArrowLinkTw size="small" color="black" />
                        </div>
                      </div>
                    </Link>
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        </div>
      </div>
    </div>
  );
}

