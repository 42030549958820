/* eslint-disable */

import React, { useState, useEffect } from "react";
import PARSE from "html-react-parser";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { ExpandMore } from "@mui/icons-material";
import Api from "./Api";
import TableContent from "./TableContent";
import replaceAssetLink from "../../context/utils/replaceAssetLink";

export default function TBS2030() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);
  // console.log("ðŸš€ ~ file: TBS_2030.jsx:17 ~ activeLang:", activeLang);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let downloadFile;
  let title;
  let summary;
  let content;
  let table;
  let icon;
  let sectionName;
  let cardArticle;
  let otherArticle;
  let MainImgdesc;
  let ClosingParagraph = "";
  let tbs2030DownloadButtonText = "";
  let tbs2030DownloadButtonPath = null;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const articleContent = section.widget_contents[0];
            title = articleContent?.article_language?.title;
            summary = articleContent?.article_language?.summary;
            content = articleContent?.article_language?.content;
            icon = replaceAssetLink(articleContent?.assets[0]?.asset_file);
            MainImgdesc = articleContent?.assets[0]?.description

            break;
          case 1:
            const tableContent = section.widget_contents;
            table = tableContent;

            break;
          case 2:
            const cardContent = section.widget_contents;
            cardArticle = cardContent;
            break;
          case 3:
            const otherContent = section.widget_contents[0];
            otherArticle = otherContent;
            break;
          case 4:
            const asset = section.widget_contents[0];
            downloadFile = replaceAssetLink(asset?.file);
            break;
            case 5:
              // console.log("section.widget_contents[0] ", section.widget_contents[0]);
              ClosingParagraph = section.widget_contents[0]?.article_language?.content;
              tbs2030DownloadButtonText = section.widget_contents[0]?.article_language?.summary;
              tbs2030DownloadButtonPath = replaceAssetLink(section.widget_contents[0]?.article_language?.path);
              break;
          default:
            break;
        }
      });
    }
  }

  let modifiedContent
  if (content) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    const h4Elements = tempDiv.querySelectorAll('h4');

    h4Elements.forEach(element => {
      element.classList.add('text-lg')
    });

    modifiedContent = tempDiv.innerHTML;
  }

  return (
    <div
      id={`${sectionName}`}
      className={`section-container-pl  ${
        sections.length > 0 && sections[0].index === 3
          ? "mt-index-section__3"
          : "mt-index-section"
      }`}
    >
      {sections.length > 0 ? (
        <div className="relative  max-w-[800px] xl:mx-0 mx-auto">
          <div className="">
            <div className="">
              {icon && (
                <img
                  src={icon}
                  alt={MainImgdesc}
                  className=" mb-[45px] w-[200px] h-[30px]"
                />
              )}
            </div>
            <h2 className="font-inter-bold heading-title text-[#C3C3C3]">
              {" "}
              {title}
            </h2>
            <h3 className="heading-summary"> {summary}</h3>
            <div className="relative mt-[45px]">
              {modifiedContent && (
                <div className="mt-[45px]">
                  <div 
                    className="font-inter mt-[45px] prose-p:text-[16px] prose-p:leading-[26px] text-[#1A1A1A] leading-[26px] prose-strong:font-inter-bold prose-strong:font-semibold prose-strong:leading-[25px] !text-justify"
                  >
                    {PARSE(modifiedContent)}
                  </div>
                </div>
              )}
            </div>
            <div className="grid md:grid-cols-3 s:grid-cols-2 grid-cols-1 gap-[10px] mt-[25px]">
              {cardArticle.map((art) => {
                // console.log(
                //   "ðŸš€ ~ file: TBS_2030.jsx:97 ~ {cardArticle.map ~ art:",
                //   art
                // );
                const artTitle = art?.article_language?.title;
                const artAsset = art?.assets[0]?.asset_file;
                const artContent = art?.article_language?.content;

                return (
                  <div key={art.id} className="p-[20px] bg-[#F5F5F5]">
                    {activeLang.toString() === "3" ? (
                      <div className="flex space-x-2 items-center h-[80px]">
                        {artAsset && (
                          <img
                            src={artAsset}
                            alt={art?.assets[0]?.description}
                            className="w-[50px] h-[50px]"
                          />
                        )}
                        <div className="font-inter-bold text-[18px] max-w-[100px]">
                          {artTitle}
                        </div>
                      </div>
                    ) : (
                      <div className="flex space-x-2 items-center">
                        {artAsset && (
                          <img
                            src={artAsset}
                            alt={art?.assets[0]?.description}
                            className="w-[50px] h-[50px]"
                          />
                        )}
                        <div className="font-inter-bold text-[18px] max-w-[100px]">
                          {artTitle}
                        </div>
                      </div>
                    )}
                    {artContent && (
                      <div className="font-inter text-[12px] mt-[15px] text-justify">
                        {PARSE(artContent)}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-[45px] ">
            <h4 className="text-lg font-extrabold">
              <strong>
                {otherArticle?.article_language?.title}
              </strong>
            </h4>
            {otherArticle?.article_language?.content && (
              <div className="!my-[25px] typo-style">
                {PARSE(otherArticle?.article_language?.content)}
              </div>
            )}
            {otherArticle?.article_language?.summary && (
              <a href={downloadFile}>
                <div className="w-fit mt-[25px] px-[15px] py-[11px] text-white bg-[#004A98]">
                  {otherArticle?.article_language?.summary}
                </div>
              </a>
            )}
          </div>
          <div className="overflow-x-auto">
            <table
              id="customers"
              className="table-auto w-full min-w-[800px] border border-2 mt-[45px] "
            >
              {table.map((tab) => {
                const tableHeader = tab?.table_language?.headers;
                const tableContent = tab?.table_language?.content;
                const tableName = tab?.table_language?.name;
                const spanVal = table[0]?.table_language?.headers.length;

                return (
                  <>
                    <thead>
                      <tr className="">
                        {tableHeader?.map((head) => {
                          if (head) {
                            return (
                              <th className="border-2 border-b-green px-4 py-2   font-medium">
                                {head}
                              </th>
                            );
                          }
                        })}
                      </tr>
                    </thead>

                    <TableContent
                      tableContent={tableContent}
                      tableName={tableName}
                      spanVal={spanVal}
                    />
                  </>
                );
              })}
            </table>
          </div>
          {
            ClosingParagraph != "" && (
              <>              
                <div className="mt-[25px] typo-style">
                  {PARSE(ClosingParagraph)}
                </div>
                <a href={`${tbs2030DownloadButtonPath}`} target="_blank" rel="noreferrer">
                  <div className="w-fit mt-[25px] px-[15px] py-[11px] text-white bg-[#004A98]">
                    {tbs2030DownloadButtonText}
                  </div>
                </a>
              </>
            )
            
          }
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="max-w-2xl">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </div>
  );
}

