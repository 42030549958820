/* eslint-disable */

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PARSE from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "moment";
import { Helmet } from "react-helmet";
import YouTube from 'react-youtube';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BaseNoArticleFound from "../../context/components/Base/BaseNoArticleFound";

import Api from "./Api";
import GlobalApi from "../../context/api/GlobalApi";
import { getNewsCategory } from "../../context/store/slices/newsSlice";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import { Skeleton } from "@mui/material";

function checkArrayForId(array, id) {
  return array?.some((obj) => obj.id.toString() === id.toString());
}

function TBS_Initiatives_Detail() {
  const dispatch = useDispatch();
  const currentPath = window.location.href;
  const [article, setArticle] = useState();
  const [articleContent, setArticleContent] = useState();
  // const [url, setUrl] = useState('')
  const [sections, setSections] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const paramID = useParams();
  const navigate = useNavigate();

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionsDetail = useCallback(() => {
    const api = new Api();
    api
      .getSectionDetail({ language: activeLang })
      .then((res) => {
        if (res.status === 200 && res.data.results) {
          const sectionFill = res.data.results;
          // setArticleCount(res.data.count);
          setSections(sectionFill);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [activeLang]);

  useEffect(() => {
    getSectionsDetail();
  }, [getSectionsDetail]);

  const getDetailArticle = useCallback(() => {
    const api = new GlobalApi();
    api
      .getArticleDetailNew({ id: paramID.param1, all_languages: "False" })
      .then((res) => {
        setArticle(res.data);
        const { article_languages } = res.data;
        setArticleContent(article_languages);
        setIsFetching(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsFetching(false);
      });
  }, [paramID.param1]);

  useEffect(() => {
    getDetailArticle();
  }, [activeLang, getDetailArticle]);

  let text;

  if (sections) {
    const sec = sections[0]?.widgets;
    // const sec = sections;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const textData = section.widget_contents;
            if (textData && textData.length > 0) {
              text = textData || { content: "" };
            }
            break;

          default:
            break;
        }
      });
    }
  }

  const checkCat = checkArrayForId(article?.categories, 70);

  useEffect(() => {
    if (article && checkCat) {
      dispatch(getNewsCategory(true));
    } else dispatch(getNewsCategory(false));
  }, [activeLang, article, checkCat, dispatch]);

  const fliterLangKey = article?.article_keywords?.filter(
    (key) => key?.language?.id === activeLang
  );

  const videoEl = useRef(null);

  if (isFetching)
    return (
      <div className="h-full max-w-[800px] mx-auto mt-[80px] px-8">
        <div className="">
          <h1 className="font-inter-bold text-[28px]">
            <Skeleton />
          </h1>
          <Skeleton />
          {articleContent?.path && (
            <div className="mt-[15px]  ">
              <Skeleton />
            </div>
          )}
        </div>
        <div className="mt-[45px]">
          <Skeleton variant="rect" width="100%" height={350} />
        </div>
        <div className="typo-style">
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    );
  return !isFetching && sections && sections.length > 0 && article ? (
    <>
      {articleContent?.title && (
        <Helmet>
          <meta name="description" content={articleContent.description} />
          <meta
            name="keywords"
            content={fliterLangKey?.map((key) => key?.keyword?.name)}
          />
          <title>{articleContent.title}</title>
          <link rel="canonical" href={currentPath} />
        </Helmet>
      )}
      <div className="h-full max-w-[800px] mx-auto mt-[80px] px-8">
        <div className="">
          <h1 className="font-inter-bold text-[28px]">
            {articleContent && articleContent.title}
          </h1>
          <p className="font-inter-bold mt-[15px]">
            {article && Moment(article.date).format("DD MMMM YYYY")}
          </p>
          {articleContent?.path && (
            <div className="mt-[15px]  ">
              <div className="inline-flex font-inter-bold">{`${text[0]?.content} : `}</div>
              <a
                className=""
                href={articleContent?.path}
                target="_blank"
                rel="noopener noreferrer"
              >
                {` ${articleContent?.path}`}
              </a>
            </div>
          )}
        </div>
        <div className="mt-[45px]">
          <LazyLoadImage
            src={replaceAssetLink(
              article?.article_assets[0]?.asset?.file_medium
            )}
            alt={article?.article_assets[0]?.asset?.description}
            title={article?.article_assets[0]?.asset?.description}
            className="w-full"
            width="414"
            height="736"
          />
        </div>
        <div className="typo-style mt-[45px]">
          {articleContent?.content && PARSE(articleContent?.content)}
        </div>
        {article?.article_assets.length > 1 && (
          <video
            style={{
              backgroundColor: "black",
              maxWidth: "100%",
              maxHeight: "100%",
              width: "100%",
              height: "550px",
            }}
            controls
            alt="All the devices"
            ref={videoEl}
          >
            <source
              src={article?.article_assets[1]?.asset?.asset_file}
              type="video/mp4"
            />
          </video>
          // <YouTube videoId={article?.article_assets[1]?.asset?.asset_file} className="aspect-w-16 aspect-h-9" iframeClassName="h-full w-full" opts={videoOptions} />
        )}
        {/* <button className="border-2  p-2 mt-4" onClick={() => navigate(-1)}>
          {text[1]?.content}
        </button> */}
      </div>
    </>
  ) : (
    !isFetching && !article && (
      <BaseNoArticleFound label={text[2]?.content || ""} />
    )
  );
}

export default TBS_Initiatives_Detail;
