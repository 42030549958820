/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Api from './Api';
import Styles from './TBS_Financial_Highlights.module.css';

function TBS_Financial_Highlights() {
  const [sections, setSections] = useState([]);
  const [slugName, setSlugName] = useState('');
  const activeLang = useSelector((state) => state.content.activeLanguage);

  useEffect(() => {
    const FetchData = async () => {
      const api = new Api();
      try {
        const sectionResponse = await api.getSectionDetail({ language: activeLang });
        const sectionData = await sectionResponse.data;
        const sectionWidgets = sectionData.results[0].widgets;
        const sectionId = sectionData.results[0].slug_name;

        setSlugName(sectionId);
        setSections(sectionWidgets);
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, [activeLang]);

  let title;
  let slug_name;
  let table_desc;
  let table_header;
  let table_content;
  let table_desc2;
  let table_header2;
  let table_content2;
  if (sections) {
    slug_name = sections.slug_name;

    sections.forEach((data, index) => {
      const { widget_contents } = data;

      if (index === 0) {
        title = widget_contents[0].content;
      }

      if (index === 1) {
        // console.log('ini data 1', widget_contents);
        table_desc = widget_contents[0].table_language.description;
        table_header = widget_contents[0].table_language.headers;
        table_content = widget_contents[0].table_language.content;
      }

      if (index === 2) {
        // console.log('ini data 2', widget_contents);
        table_desc2 = widget_contents[0].table_language.description;
        table_header2 = widget_contents[0].table_language.headers;
        table_content2 = widget_contents[0].table_language.content;
      }
    });
  }

  return (
    <div id={`${slugName}`} className={`section-container-pl  ${sections.length > 0 && sections[0].index === 3 ? 'mt-index-section__3' : 'mt-index-section'}`}>
      <div className="section-width overflow-x-hidden">
        <h2 className="heading-title">{title || ''}</h2>
        <div className="mt-[40px]">
          <div className="mb-[20px]">
            <p>{table_desc2 || ''}</p>
          </div>
          <div className="overflow-x-scroll md:overflow-x-auto">
            <table id="customers" className="table-auto w-full divide-x-0">
              <thead className="sticky top-0">
                <tr>
                  {table_header2?.map((head, i) => {
                    if (i !== 0) {
                      return <th className="border-2 border-white px-4 py-2 text-white bg-[#18467f] font-medium">{head}</th>;
                    } else {
                      return <th className="w-[300px] outline-2 outline-white border-y-2 border-white px-4 py-2 text-white bg-[#18467f] font-medium">{head}</th>;
                    }
                  })}
                </tr>
              </thead>
              <tbody className="  font-medium">
                {table_content2?.map((head, index, total) => {
                  return (
                    <tr key={head} className="bg-[#F2F5F6] hover:bg-[#d4e8ed]">
                      {head?.map((cont, i) => {
                        if (head.includes('')) {
                          return (
                            <td
                              className={
                                i === 0
                                  ? `text-left p-2 outline-2 outline-white border-b-2 border-r-2 border-white ${Styles['data-text-last']}`
                                  : `p-2 text-right ${Styles['data-text']}`
                              }
                            >
                              {cont || ''}
                            </td>
                          );
                        } else if (index === total.length - 1) {
                          return (
                            <td
                              className={
                                i === 0
                                  ? `text-left p-2 outline-2 outline-white border-b-2 border-white ${Styles['data-text-last']}`
                                  : `p-2 text-right border-2 border-white ${Styles['data-text-last']}`
                              }
                            >
                              {cont || ''}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              className={
                                i === 0
                                  ? `text-left p-2 outline-2 outline-white border-b-2 border-white ${Styles['data-text']}`
                                  : `p-2 text-right border-2 border-white ${Styles['data-text']}`
                              }
                            >
                              {cont || ''}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        
        <div className="mt-[40px]">
          <div className="mb-[20px]">
            <p>{table_desc || ''}</p>
          </div>
          <div className="overflow-x-scroll md:overflow-x-auto">
            <table id="customers" className="table-auto w-full divide-x-0">
              <thead>
                <tr className="">
                  {table_header?.map((head, idx) => (
                    <th
                      className={
                        idx === 0
                          ? `outline-2 outline-white border-y-2 border-white px-4 py-2 text-white bg-[#18467f] font-medium sticky`
                          : `border-2 border-white px-4 py-2 text-white bg-[#18467f] font-medium z-50`
                      }
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="outline-2 outline-white  font-medium">
                {table_content?.map((head, i, total) => {
                  if (total.length - i <= 3) {
                    return (
                      <tr key={head} className=" bg-[#F2F5F6] hover:bg-[#d4e8ed]">
                        {head?.map((cont, i) => {
                          return (
                            <td
                              className={
                                i === 0
                                  ? `text-left p-2 outline-2 outline-white border-b-2 border-white ${Styles['data-text-last']}`
                                  : `p-2 text-right border-2 border-white ${Styles['data-text-last']}`
                              }
                            >
                              {cont}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }
                  return (
                    <tr key={head} className="p-2 bg-[#F2F5F6] hover:bg-[#d4e8ed]">
                      {head?.map((cont, i) => {
                        return (
                          <td
                            className={
                              i === 0
                                ? `text-left p-2 outline-2 outline-white border-b-2 border-white ${Styles['data-text']}`
                                : `p-2 text-right border-2 border-white ${Styles['data-text']}`
                            }
                          >
                            {cont}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TBS_Financial_Highlights;
