/* eslint-disable */

import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PAGE_176 from "../pages/page_176/page_176";
import PAGE_263 from "../pages/page_263/page_263";
import PAGE_318 from "../pages/page_318/page_318";
import PAGE_321 from "../pages/page_321/page_321";
import PAGE_325 from "../pages/page_325/page_325";
import PAGE_329 from "../pages/page_329/page_329";
import PAGE_335 from "../pages/page_335/page_335";
import PAGE_337 from "../pages/page_337/page_337";
import PAGE_338 from "../pages/page_338/page_338";
import PAGE_340 from "../pages/page_340/page_340";
import PAGE_344 from "../pages/page_344/page_344";
import PAGE_370 from "../pages/page_370/page_370";
import PAGE_439 from "../pages/page_439/page_439";
import PAGE_440 from "../pages/page_440/page_440";
import PAGE_445 from "../pages/page_445/page_445";
import PAGE_447 from "../pages/page_447/page_447";
import PAGE_217 from "../pages/page_217/page_217";
import PAGE_319 from "../pages/page_319/page_319";
import PAGE_322 from "../pages/page_322/page_322";
import PAGE_330 from "../pages/page_330/page_330";
import PAGE_334 from "../pages/page_334/page_334";
import PAGE_336 from "../pages/page_336/page_336";
import PAGE_342 from "../pages/page_342/page_342";
import PAGE_441 from "../pages/page_441/page_441";
import PAGE_446 from "../pages/page_446/page_446";
import PAGE_247 from "../pages/page_247/page_247";
import PAGE_320 from "../pages/page_320/page_320";
import PAGE_324 from "../pages/page_324/page_324";
import PAGE_331 from "../pages/page_331/page_331";
import PAGE_347 from "../pages/page_347/page_347";
import PAGE_442 from "../pages/page_442/page_442";
import PAGE_448 from "../pages/page_448/page_448";
import PAGE_260 from "../pages/page_260/page_260";
import PAGE_267 from "../pages/page_267/page_267";
import PAGE_316 from "../pages/page_316/page_316";
import PAGE_332 from "../pages/page_332/page_332";
import PAGE_343 from "../pages/page_343/page_343";
import PAGE_443 from "../pages/page_443/page_443";
import PAGE_449 from "../pages/page_449/page_449";
import PAGE_221 from "../pages/page_221/page_221";
import PAGE_245 from "../pages/page_245/page_245";
import PAGE_326 from "../pages/page_326/page_326";
import PAGE_435 from "../pages/page_435/page_435";
import PAGE_444 from "../pages/page_444/page_444";
import PAGE_450 from "../pages/page_450/page_450";
import PAGE_266 from "../pages/page_266/page_266";
import PAGE_310 from "../pages/page_310/page_310";
import PAGE_327 from "../pages/page_327/page_327";
import PAGE_403 from "../pages/page_403/page_403";
import PAGE_451 from "../pages/page_451/page_451";
import PAGE_227 from "../pages/page_227/page_227";
import PAGE_311 from "../pages/page_311/page_311";
import PAGE_328 from "../pages/page_328/page_328";
import PAGE_452 from "../pages/page_452/page_452";
import PAGE_261 from "../pages/page_261/page_261";
import PAGE_312 from "../pages/page_312/page_312";
import PAGE_333 from "../pages/page_333/page_333";
import PAGE_453 from "../pages/page_453/page_453";
import PAGE_265 from "../pages/page_265/page_265";
import PAGE_313 from "../pages/page_313/page_313";
import PAGE_416 from "../pages/page_416/page_416";
import PAGE_454 from "../pages/page_454/page_454";
import PAGE_314 from "../pages/page_314/page_314";
import PAGE_345 from "../pages/page_345/page_345";
import PAGE_455 from "../pages/page_455/page_455";
import PAGE_315 from "../pages/page_315/page_315";
import PAGE_371 from "../pages/page_371/page_371";
import PAGE_456 from "../pages/page_456/page_456";
import PAGE_457 from "../pages/page_457/page_457";
import PAGE_458 from "../pages/page_458/page_458";
import PAGE_459 from "../pages/page_459/page_459";
import PAGE_460 from "../pages/page_460/page_460";
import PAGE_461 from "../pages/page_461/page_461";
import PAGE_462 from "../pages/page_462/page_462";
import PAGE_463 from "../pages/page_463/page_463";
import PAGE_464 from "../pages/page_464/page_464";
import TBS_MENU_356 from '../sections/TBS_Menu_356/TBS_Menu';
import TBS_FOOTER_289 from '../sections/TBS_Footer_289/TBS_Footer';

export default function Site() {
    let location = useLocation();
    let path_name = location.pathname;
    let path_name_without_last_slash = path_name.replace(/\/$/, '');
    if (path_name === '/') path_name_without_last_slash = '/';
    const urls_use_global_sections = [
        "/about-us",
        "/tentang-kami",
        "/business/plantation",
        "/bisnis/perkebunan",
        "/news",
        "/berita",
        "/governance",
        "/tata-kelola",
        "/business",
        "/bisnis",
        "/sustainability",
        "/keberlanjutan",
        "/career",
        "/karir",
        "/news/read-news",
        "/berita/baca-berita",
        "/investor",
        "/investor",
        "/investor/stock-information",
        "/investor/informasi-saham",
        "/sustainability/sustainability-reports",
        "/keberlanjutan/laporan-keberlanjutan",
        "/sustainability/sustainability-approach",
        "/keberlanjutan/pendekatan-keberlanjutan",
        "/sustainability/tbs2030",
        "/keberlanjutan/tbs2030",
        "/sustainability/environmental",
        "/keberlanjutan/lingkungan",
        "/sustainability/social",
        "/keberlanjutan/sosial",
        "/sustainability/sustainability-governance",
        "/keberlanjutan/tata-kelola-keberlanjutan",
        "/business/coal",
        "/bisnis/batubara",
        "/business/renewable-energy",
        "/bisnis/energi-terbarukan",
        "/business/electric-vehicle",
        "/bisnis/kendaraan-listrik",
        "/business/cfpp",
        "/bisnis/pltu",
        "/career/our-people",
        "/karir/karyawan-kami",
        "/career/women-in-tbs",
        "/karir/wanita-di-tbs",
        "/career/job-opportunities",
        "/karir/peluang-kerja",
        "/investor/annual-report",
        "/investor/laporan-tahunan",
        "/investor/public-expose",
        "/investor/paparan-publik",
        "/investor/information-disclosure",
        "/investor/keterbukaan-informasi",
        "/investor/other-information",
        "/investor/other-information",
        "/governance/general-meeting-of-shareholders",
        "/tata-kelola/rapat-umum-pemegang-saham",
        "/governance/corporate-governance",
        "/tata-kelola/tata-kelola-perusahaan",
        "/governance/company-files",
        "/tata-kelola/file-perusahaan",
        "/governance/capital-market-supporting-institution",
        "/tata-kelola/lembaga-penunjang-pasar-modal",
        "/investor/investor-relations-team",
        "/investor/tim-hubungan-investor",
        "/investor/financial-performance",
        "/investor/kinerja-keuangan",
        "/about-us/board-of-directors",
        "/tentang-kami/direksi",
        "/about-us/board-of-commissioners",
        "/tentang-kami/dewan-komisaris",
        "/investor/investor-relations-team/ir-team",
        "/investor/tim-hubungan-investor/tim-ir",
        "/career/women-in-tbs/women-in-tbs-details",
        "/karir/wanita-di-tbs/detil-wanita-di-tbs",
        "/career/our-people/read-profile",
        "/karir/karyawan-kami/baca-profil",
        "/whistleblower/whistleblower-form",
        "/whistleblower/formulir-whistleblower",
        "/career/inside",
        "/karir/inside",
        "/career/inside/inside-details",
        "/karir/inside/inside-details",
        "/search",
        "/pencarian",
        "/investor/management-discussion-&-analysis",
        "/investor/diskusi-&-analisis-manajemen",
        "/whistleblower/whistleblower-policy",
        "/whistleblower/kebijakan-whistleblower",
        "/whistleblower",
        "/whistleblower",
        "/business/waste-management",
        "/bisnis/pengelolaan-limbah",
        "/investor/rating",
        "/investor/pemeringkatan",
        "/career/tbs-opportunity-program",
        "/karir/tbs-opportunity-program",
        "/new-sustainability",
        "/new-sustainability",
        "/sustainability/2023-highlights",
        "/keberlanjutan/ikhtisar-keberlanjutan-2023",
        "/sustainability/messages-from-the-board",
        "/keberlanjutan/sambutan-dari-dewan",
        "/sustainability/our-approach",
        "/keberlanjutan/pendekatan-kami",
        "/sustainability/our-reports",
        "/keberlanjutan/laporan-kami",
        "/sustainability/our-materiality",
        "/keberlanjutan/materialitas-kami",
        "/sustainability/our-materiality/emissions-and-climate-opportunities",
        "/keberlanjutan/materialitas-kami/emisi-dan-peluang-iklim",
        "/sustainability/our-materiality/capital-risks-and-opportunities",
        "/keberlanjutan/materialitas-kami/risiko-dan-peluang-permodalan",
        "/sustainability/2023-highlights/industry-initiatives",
        "/keberlanjutan/ikhtisar-keberlanjutan-2023/inisiatif-industri",
        "/sustainability/our-materiality/water-usage",
        "/keberlanjutan/materialitas-kami/penggunaan-air",
        "/sustainability/our-materiality/waste-disposal",
        "/keberlanjutan/materialitas-kami/pembuangan-limbah",
        "/sustainability/our-materiality/land-rehabilitation",
        "/keberlanjutan/materialitas-kami/rehabilitasi-lahan",
        "/sustainability/our-materiality/biodiversity-and-valuing-natural-capital",
        "/keberlanjutan/materialitas-kami/keanekaragaman-hayati-dan-menghargai-modal-alam",
        "/sustainability/our-materiality/local-communities",
        "/keberlanjutan/materialitas-kami/masyarakat-lokal",
        "/sustainability/our-materiality/affordable-clean-power",
        "/keberlanjutan/materialitas-kami/energi-bersih-yang-terjangkau",
        "/sustainability/our-materiality/occupational-health-and-safety",
        "/keberlanjutan/materialitas-kami/kesehatan-dan-keselamatan-kerja",
        "/sustainability/our-materiality/human-rights",
        "/keberlanjutan/materialitas-kami/hak-asasi-manusia",
        "/sustainability/our-materiality/improving-urban-mobility-with-ev-business",
        "/keberlanjutan/materialitas-kami/meningkatkan-mobilitas-perkotaan-dengan-bisnis-kendaraan-listrik",
        "/sustainability/our-materiality/workforce-competency",
        "/keberlanjutan/materialitas-kami/kompetensi-tenaga-kerja",
        "/sustainability/our-materiality/access-to-clean-water",
        "/keberlanjutan/materialitas-kami/akses-terhadap-air-bersih",
        "/sustainability/our-materiality/governance-materiality",
        "/keberlanjutan/materialitas-kami/tata-kelola-materiality",
        "/sustainability/our-materiality/value-chain-management",
        "/keberlanjutan/materialitas-kami/manajemen-rantai-nilai",
        "/sustainability/our-materiality/geopolitics",
        "/keberlanjutan/materialitas-kami/geopolitik",
        "/sustainability/our-materiality/data-privacy",
        "/keberlanjutan/materialitas-kami/privasi-data",
        "/sustainability/our-materiality/business-model-transition-and-innovation",
        "/keberlanjutan/materialitas-kami/transisi-model-bisnis-dan-inovasi",
        "/sustainability/our-materiality/economic-performance",
        "/keberlanjutan/materialitas-kami/kinerja-ekonomi",
        "/",
    ];
    const urls_use_global_sections_and_has_params = [
        "/news/read-news",
        "/berita/baca-berita",
        "/about-us/board-of-directors",
        "/tentang-kami/direksi",
        "/about-us/board-of-commissioners",
        "/tentang-kami/dewan-komisaris",
        "/investor/investor-relations-team/ir-team",
        "/investor/tim-hubungan-investor/tim-ir",
        "/career/women-in-tbs/women-in-tbs-details",
        "/karir/wanita-di-tbs/detil-wanita-di-tbs",
        "/career/our-people/read-profile",
        "/karir/karyawan-kami/baca-profil",
        "/career/inside/inside-details",
        "/karir/inside/inside-details",
        "/sustainability/2023-highlights/industry-initiatives",
        "/keberlanjutan/ikhtisar-keberlanjutan-2023/inisiatif-industri",
    ];
    const is_use_global_sections = urls_use_global_sections.includes(path_name_without_last_slash);
    const use_global_sections_and_has_params = urls_use_global_sections_and_has_params.some(url => path_name_without_last_slash.startsWith(url));
    let header = null
    if (is_use_global_sections || use_global_sections_and_has_params) header = <TBS_MENU_356/>
    let footer = null
    if (is_use_global_sections || use_global_sections_and_has_params) footer = <TBS_FOOTER_289/>
    return (
        <>
            {header}
            <Routes>
                <Route path={`/whistleblower`} exact element={<Navigate to={`/whistleblower/whistleblower-policy`} replace />}/>
                <Route path={`/whistleblower`} exact element={<Navigate to={`/whistleblower/whistleblower-policy`} replace />}/>
                <Route path={`/whistleblower/whistleblower-policy`} exact element={<PAGE_370/>}/>
                <Route path={`/whistleblower/whistleblower-form`} exact element={<PAGE_342/>}/>
                <Route path={`/whistleblower/kebijakan-whistleblower`} exact element={<PAGE_370/>}/>
                <Route path={`/whistleblower/formulir-whistleblower`} exact element={<PAGE_342/>}/>
                <Route path={`/tentang-kami`} exact element={<PAGE_217/>}/>
                <Route path={`/tentang-kami/direksi/:param1/:param2`} exact element={<PAGE_335/>}/>
                <Route path={`/tentang-kami/dewan-komisaris/:param1/:param2`} exact element={<PAGE_336/>}/>
                <Route path={`/tata-kelola`} exact element={<Navigate to={`/governance/general-meeting-of-shareholders`} replace />}/>
                <Route path={`/tata-kelola/tata-kelola-perusahaan`} exact element={<PAGE_330/>}/>
                <Route path={`/tata-kelola/rapat-umum-pemegang-saham`} exact element={<PAGE_329/>}/>
                <Route path={`/tata-kelola/lembaga-penunjang-pasar-modal`} exact element={<PAGE_332/>}/>
                <Route path={`/tata-kelola/file-perusahaan`} exact element={<PAGE_331/>}/>
                <Route path={`/sustainability`} exact element={<Navigate to={`/keberlanjutan/ikhtisar-keberlanjutan-2023`} replace />}/>
                <Route path={`/sustainability/tbs2030`} exact element={<PAGE_312/>}/>
                <Route path={`/sustainability/sustainability-reports`} exact element={<PAGE_310/>}/>
                <Route path={`/sustainability/sustainability-governance`} exact element={<PAGE_315/>}/>
                <Route path={`/sustainability/sustainability-approach`} exact element={<PAGE_311/>}/>
                <Route path={`/sustainability/social`} exact element={<PAGE_314/>}/>
                <Route path={`/sustainability/our-reports`} exact element={<PAGE_443/>}/>
                <Route path={`/sustainability/our-materiality`} exact element={<Navigate to={`/keberlanjutan/materialitas-kami/emisi-dan-peluang-iklim`} replace />}/>
                <Route path={`/sustainability/our-materiality/workforce-competency`} exact element={<PAGE_457/>}/>
                <Route path={`/sustainability/our-materiality/water-usage`} exact element={<PAGE_448/>}/>
                <Route path={`/sustainability/our-materiality/waste-disposal`} exact element={<PAGE_449/>}/>
                <Route path={`/sustainability/our-materiality/value-chain-management`} exact element={<PAGE_460/>}/>
                <Route path={`/sustainability/our-materiality/occupational-health-and-safety`} exact element={<PAGE_454/>}/>
                <Route path={`/sustainability/our-materiality/local-communities`} exact element={<PAGE_452/>}/>
                <Route path={`/sustainability/our-materiality/land-rehabilitation`} exact element={<PAGE_450/>}/>
                <Route path={`/sustainability/our-materiality/improving-urban-mobility-with-ev-business`} exact element={<PAGE_456/>}/>
                <Route path={`/sustainability/our-materiality/human-rights`} exact element={<PAGE_455/>}/>
                <Route path={`/sustainability/our-materiality/governance-materiality`} exact element={<PAGE_459/>}/>
                <Route path={`/sustainability/our-materiality/geopolitics`} exact element={<PAGE_461/>}/>
                <Route path={`/sustainability/our-materiality/emissions-and-climate-opportunities`} exact element={<PAGE_445/>}/>
                <Route path={`/sustainability/our-materiality/economic-performance`} exact element={<PAGE_464/>}/>
                <Route path={`/sustainability/our-materiality/data-privacy`} exact element={<PAGE_462/>}/>
                <Route path={`/sustainability/our-materiality/capital-risks-and-opportunities`} exact element={<PAGE_446/>}/>
                <Route path={`/sustainability/our-materiality/business-model-transition-and-innovation`} exact element={<PAGE_463/>}/>
                <Route path={`/sustainability/our-materiality/biodiversity-and-valuing-natural-capital`} exact element={<PAGE_451/>}/>
                <Route path={`/sustainability/our-materiality/affordable-clean-power`} exact element={<PAGE_453/>}/>
                <Route path={`/sustainability/our-materiality/access-to-clean-water`} exact element={<PAGE_458/>}/>
                <Route path={`/sustainability/our-approach`} exact element={<PAGE_442/>}/>
                <Route path={`/sustainability/messages-from-the-board`} exact element={<PAGE_441/>}/>
                <Route path={`/sustainability/environmental`} exact element={<PAGE_313/>}/>
                <Route path={`/sustainability/2023-highlights`} exact element={<PAGE_440/>}/>
                <Route path={`/sustainability/2023-highlights/industry-initiatives/:param1/:param2`} exact element={<PAGE_447/>}/>
                <Route path={`/search`} exact element={<PAGE_345/>}/>
                <Route path={`/pencarian`} exact element={<PAGE_345/>}/>
                <Route path={`/news`} exact element={<PAGE_227/>}/>
                <Route path={`/news/read-news/:param1/:param2`} exact element={<PAGE_263/>}/>
                <Route path={`/new-sustainability`} exact element={<PAGE_439/>}/>
                <Route path={`/new-sustainability`} exact element={<PAGE_439/>}/>
                <Route path={`/keberlanjutan`} exact element={<Navigate to={`/keberlanjutan/ikhtisar-keberlanjutan-2023`} replace />}/>
                <Route path={`/keberlanjutan/tbs2030`} exact element={<PAGE_312/>}/>
                <Route path={`/keberlanjutan/tata-kelola-keberlanjutan`} exact element={<PAGE_315/>}/>
                <Route path={`/keberlanjutan/sosial`} exact element={<PAGE_314/>}/>
                <Route path={`/keberlanjutan/sambutan-dari-dewan`} exact element={<PAGE_441/>}/>
                <Route path={`/keberlanjutan/pendekatan-keberlanjutan`} exact element={<PAGE_311/>}/>
                <Route path={`/keberlanjutan/pendekatan-kami`} exact element={<PAGE_442/>}/>
                <Route path={`/keberlanjutan/materialitas-kami`} exact element={<Navigate to={`/keberlanjutan/materialitas-kami/emisi-dan-peluang-iklim`} replace />}/>
                <Route path={`/keberlanjutan/materialitas-kami/transisi-model-bisnis-dan-inovasi`} exact element={<PAGE_463/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/tata-kelola-materiality`} exact element={<PAGE_459/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/risiko-dan-peluang-permodalan`} exact element={<PAGE_446/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/rehabilitasi-lahan`} exact element={<PAGE_450/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/privasi-data`} exact element={<PAGE_462/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/penggunaan-air`} exact element={<PAGE_448/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/pembuangan-limbah`} exact element={<PAGE_449/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/meningkatkan-mobilitas-perkotaan-dengan-bisnis-kendaraan-listrik`} exact element={<PAGE_456/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/masyarakat-lokal`} exact element={<PAGE_452/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/manajemen-rantai-nilai`} exact element={<PAGE_460/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/kompetensi-tenaga-kerja`} exact element={<PAGE_457/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/kinerja-ekonomi`} exact element={<PAGE_464/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/kesehatan-dan-keselamatan-kerja`} exact element={<PAGE_454/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/keanekaragaman-hayati-dan-menghargai-modal-alam`} exact element={<PAGE_451/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/hak-asasi-manusia`} exact element={<PAGE_455/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/geopolitik`} exact element={<PAGE_461/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/energi-bersih-yang-terjangkau`} exact element={<PAGE_453/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/emisi-dan-peluang-iklim`} exact element={<PAGE_445/>}/>
                <Route path={`/keberlanjutan/materialitas-kami/akses-terhadap-air-bersih`} exact element={<PAGE_458/>}/>
                <Route path={`/keberlanjutan/lingkungan`} exact element={<PAGE_313/>}/>
                <Route path={`/keberlanjutan/laporan-keberlanjutan`} exact element={<PAGE_310/>}/>
                <Route path={`/keberlanjutan/laporan-kami`} exact element={<PAGE_443/>}/>
                <Route path={`/keberlanjutan/ikhtisar-keberlanjutan-2023`} exact element={<PAGE_440/>}/>
                <Route path={`/keberlanjutan/ikhtisar-keberlanjutan-2023/inisiatif-industri/:param1/:param2`} exact element={<PAGE_447/>}/>
                <Route path={`/karir`} exact element={<Navigate to={`/career/our-people`} replace />}/>
                <Route path={`/karir/wanita-di-tbs`} exact element={<PAGE_322/>}/>
                <Route path={`/karir/wanita-di-tbs/detil-wanita-di-tbs/:param1/:param2`} exact element={<PAGE_338/>}/>
                <Route path={`/karir/tbs-opportunity-program`} exact element={<PAGE_435/>}/>
                <Route path={`/karir/peluang-kerja`} exact element={<PAGE_324/>}/>
                <Route path={`/karir/karyawan-kami`} exact element={<PAGE_321/>}/>
                <Route path={`/karir/karyawan-kami/baca-profil/:param1/:param2`} exact element={<PAGE_340/>}/>
                <Route path={`/karir/inside`} exact element={<PAGE_343/>}/>
                <Route path={`/karir/inside/inside-details/:param1/:param2`} exact element={<PAGE_344/>}/>
                <Route path={`/investor`} exact element={<Navigate to={`/investor/annual-report`} replace />}/>
                <Route path={`/investor`} exact element={<Navigate to={`/investor/annual-report`} replace />}/>
                <Route path={`/investor/tim-hubungan-investor`} exact element={<PAGE_333/>}/>
                <Route path={`/investor/tim-hubungan-investor/tim-ir/:param1/:param2`} exact element={<PAGE_337/>}/>
                <Route path={`/investor/stock-information`} exact element={<PAGE_267/>}/>
                <Route path={`/investor/rating`} exact element={<PAGE_416/>}/>
                <Route path={`/investor/public-expose`} exact element={<PAGE_326/>}/>
                <Route path={`/investor/pemeringkatan`} exact element={<PAGE_416/>}/>
                <Route path={`/investor/paparan-publik`} exact element={<PAGE_326/>}/>
                <Route path={`/investor/other-information`} exact element={<PAGE_328/>}/>
                <Route path={`/investor/other-information`} exact element={<PAGE_328/>}/>
                <Route path={`/investor/management-discussion-&-analysis`} exact element={<PAGE_347/>}/>
                <Route path={`/investor/laporan-tahunan`} exact element={<PAGE_325/>}/>
                <Route path={`/investor/kinerja-keuangan`} exact element={<PAGE_334/>}/>
                <Route path={`/investor/keterbukaan-informasi`} exact element={<PAGE_327/>}/>
                <Route path={`/investor/investor-relations-team`} exact element={<PAGE_333/>}/>
                <Route path={`/investor/investor-relations-team/ir-team/:param1/:param2`} exact element={<PAGE_337/>}/>
                <Route path={`/investor/information-disclosure`} exact element={<PAGE_327/>}/>
                <Route path={`/investor/informasi-saham`} exact element={<PAGE_267/>}/>
                <Route path={`/investor/financial-performance`} exact element={<PAGE_334/>}/>
                <Route path={`/investor/diskusi-&-analisis-manajemen`} exact element={<PAGE_347/>}/>
                <Route path={`/investor/annual-report`} exact element={<PAGE_325/>}/>
                <Route path={`/governance`} exact element={<Navigate to={`/governance/general-meeting-of-shareholders`} replace />}/>
                <Route path={`/governance/general-meeting-of-shareholders`} exact element={<PAGE_329/>}/>
                <Route path={`/governance/corporate-governance`} exact element={<PAGE_330/>}/>
                <Route path={`/governance/company-files`} exact element={<PAGE_331/>}/>
                <Route path={`/governance/capital-market-supporting-institution`} exact element={<PAGE_332/>}/>
                <Route path={`/career`} exact element={<Navigate to={`/career/our-people`} replace />}/>
                <Route path={`/career/women-in-tbs`} exact element={<PAGE_322/>}/>
                <Route path={`/career/women-in-tbs/women-in-tbs-details/:param1/:param2`} exact element={<PAGE_338/>}/>
                <Route path={`/career/tbs-opportunity-program`} exact element={<PAGE_435/>}/>
                <Route path={`/career/our-people`} exact element={<PAGE_321/>}/>
                <Route path={`/career/our-people/read-profile/:param1/:param2`} exact element={<PAGE_340/>}/>
                <Route path={`/career/job-opportunities`} exact element={<PAGE_324/>}/>
                <Route path={`/career/inside`} exact element={<PAGE_343/>}/>
                <Route path={`/career/inside/inside-details/:param1/:param2`} exact element={<PAGE_344/>}/>
                <Route path={`/business`} exact element={<Navigate to={`/business/renewable-energy`} replace />}/>
                <Route path={`/business/waste-management`} exact element={<PAGE_403/>}/>
                <Route path={`/business/renewable-energy`} exact element={<PAGE_318/>}/>
                <Route path={`/business/plantation`} exact element={<PAGE_221/>}/>
                <Route path={`/business/electric-vehicle`} exact element={<PAGE_319/>}/>
                <Route path={`/business/coal`} exact element={<PAGE_316/>}/>
                <Route path={`/business/cfpp`} exact element={<PAGE_320/>}/>
                <Route path={`/bisnis`} exact element={<Navigate to={`/business/renewable-energy`} replace />}/>
                <Route path={`/bisnis/pltu`} exact element={<PAGE_320/>}/>
                <Route path={`/bisnis/perkebunan`} exact element={<PAGE_221/>}/>
                <Route path={`/bisnis/pengelolaan-limbah`} exact element={<PAGE_403/>}/>
                <Route path={`/bisnis/kendaraan-listrik`} exact element={<PAGE_319/>}/>
                <Route path={`/bisnis/energi-terbarukan`} exact element={<PAGE_318/>}/>
                <Route path={`/bisnis/batubara`} exact element={<PAGE_316/>}/>
                <Route path={`/berita`} exact element={<PAGE_227/>}/>
                <Route path={`/berita/baca-berita/:param1/:param2`} exact element={<PAGE_263/>}/>
                <Route path={`/about-us`} exact element={<PAGE_217/>}/>
                <Route path={`/about-us/board-of-directors/:param1/:param2`} exact element={<PAGE_335/>}/>
                <Route path={`/about-us/board-of-commissioners/:param1/:param2`} exact element={<PAGE_336/>}/>
                <Route path={`/error/:id`} exact element={<PAGE_265/>} />
                <Route path={`/`} exact element={<PAGE_176/>}/>
                <Route path="*" element={<PAGE_265/>} />
            </Routes>
            {footer}
         </>
         );
}
