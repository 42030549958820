/* eslint-disable */

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import slugify from "@sindresorhus/slugify";
import PARSE from "html-react-parser";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import GlobalApi from "../../context/api/GlobalApi";
import clsxm from "../../context/utils/clsxm";
import replaceAssetLink from "../../context/utils/replaceAssetLink";
import Api from "./Api";
import BaseContainerPl from "../../context/components/Base/BaseContainerPl";

export default function TBS_Our_Materiality() {
  let indexTopicCard = 1;
  const [sections, setSections] = useState([]);
  const [listCategoryArticle, setListCategoryArticle] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [widgetId, setWidgetId] = useState();

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    const parsedData = await response;
    const sectionsData = parsedData;

    const apiSectionData = sectionsData.data.results;
    setSections(apiSectionData);
    if (apiSectionData) {
      const sec = apiSectionData[0]?.widgets;
      if (sec && sec.length !== 0) {
        setWidgetId(sec[5].id);
        setListCategory(sec[5]?.article_categories);
      }
    }
    return sections;
  };

  const listIdCategory = listCategory?.map((cat) => `&id=${cat.id}`);

  const getArticleDetail = useCallback(() => {
    const api = new GlobalApi();
    const catId = listIdCategory.join("");
    const fetchGetGroupArticle = api.getGroupArticle({
      widget: widgetId,
      category: catId,
      limit: 7,
      order: "date",
    });

    fetchGetGroupArticle.then((res) => {
      setListCategoryArticle(res?.data?.results);
    });

    // console.log(response);
  }, [widgetId]);

  useEffect(() => {
    getSectionDetail();
    if (widgetId) {
      getArticleDetail();
    }
  }, [widgetId]);

  let graphFile;
  let graphDescription;
  let title;
  let summary;
  let content;
  let table;
  let donwoloadFile;
  let sectionName;
  let cardArticle;
  let otherArticle;
  let MainImgdesc;
  let texts = [];
  let collecCat = [];

  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const articleContent = section.widget_contents[0];
            title = articleContent?.article_language?.title;
            summary = articleContent?.article_language?.summary;
            content = articleContent?.article_language?.content;
            donwoloadFile = replaceAssetLink(
              articleContent?.assets[0]?.asset_file
            );
            MainImgdesc = articleContent?.assets[0]?.description;

            break;
          case 1:
            const textContent = section.widget_contents;
            texts = textContent;

            break;
          case 2:
            const otherContent = section.widget_contents;
            otherArticle = otherContent;
            break;
          case 3:
            const asset = section.widget_contents[0];
            graphFile = replaceAssetLink(asset?.file);
            graphDescription = asset?.description;
            break;
          case 4:
            const cardContent = section.widget_contents;
            cardArticle = cardContent;
            break;
          case 6:
            const cats = section.widget_contents;
            collecCat = cats;
            break;
          case 4:
            break;
          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl
      sectionName={sectionName}
      sectionIndex={sections && sections.length > 0 && sections[0].index}
    >
      {sections.length > 0 ? (
        <div className="relative section-width-new xl:mx-0 mx-auto">
          <div className="">
            <h2 className="heading-title-new"> {title}</h2>
            <div className="relative mt-[45px]">
              {content && (
                <div className="mt-[45px]">
                  <div className="font-inter mt-[45px] prose-p:text-[16px] prose-p:leading-[26px] text-[#1A1A1A] leading-[26px] prose-strong:font-inter-bold prose-strong:font-semibold prose-strong:leading-[25px] !text-justify">
                    {PARSE(content)}
                  </div>
                </div>
              )}
              <a
                href={replaceAssetLink(donwoloadFile)}
                target="_blank"
                rel="noreferrer"
              >
                <div className="md:w-fit w-full text-center mt-[23.5px] md:px-[44px] px-[12px] py-[9px] button-new border rounded-[20px]">
                  {summary}
                </div>
              </a>
            </div>
            <div className="mt-[37.5px] flex md:flex-row flex-col-reverse md:space-y-0 space-y-[31px]">
              <div className="md:w-[256px] w-full  md:mt-0 mt-[31px]">
                {otherArticle?.map((grData) => {
                  const { article_language } = grData;
                  const { summary, title, path } = article_language;
                  return (
                    <div className="flex space-x-[15px] border-b mr-[13px]">
                      <div
                        className={clsxm(
                          "text-[10px] font-inter-bold w-[24px] ",
                          path ? "text-[#004A98]" : ""
                        )}
                      >
                        {summary}
                      </div>
                      <div
                        className={clsxm(
                          "md:text-[11px] text-[13px] px-[5px] leading-[16px]",
                          path ? " text-white bg-[#004A98]" : ""
                        )}
                      >
                        {path ? (
                          <NavHashLink
                            smooth
                            className="!max-w-[193px] inline-block "
                            to={`#${slugify(path || "")}`}
                          >
                            {title}
                          </NavHashLink>
                        ) : (
                          <div className="!max-w-[193px] inline-block ">
                            {title}
                          </div>
                        )}{" "}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="h-auto md:w-[calc(100%-256px)]">
                {graphFile && (
                  <img
                    src={graphFile}
                    alt={graphDescription}
                    className="h-auto w-auto "
                  />
                )}
              </div>
            </div>
            <div className=" mt-[58px]">
              <div className="text-[23px] font-inter-600 leading-[130%] mb-[17px]">
                {texts[0]?.content}
              </div>
              <div className="space-y-[16px]">
                {cardArticle.map((art) => {
                  const artTitle = art?.article_language?.title;
                  const artSummary = art?.article_language?.summary;
                  const artAsset = art?.assets[0]?.asset_file;
                  const artContent = art?.article_language?.content;

                  return (
                    <div
                      id={slugify(artSummary)}
                      key={art.id}
                      className="p-[20px] scrollTop flex md:flex-row flex-col relative bg-tbs-dark-green/5 md:space-x-[24px]"
                    >
                      <div className="md:w-[160px] rounded-[10px] md:h-[160px] h-[81px] w-[81px] bg-tbs-dark-green border flex items-center justify-center relative">
                        <div className="absolute md:top-[10px] top-[4px] md:right-[10px] right-[4px] text-tbs-dark-green bg-white rounded-full md:text-[10px] text-[8px] font-inter-bold md:p-[5px] p-[4px] leading-[130%]">
                          {art?.assets[0]?.description}
                        </div>
                        {artAsset && (
                          <img
                            src={artAsset}
                            alt={art?.assets[0]?.description}
                            className="md:w-auto md:h-auto h-[40px] w-[40px] mx-auto"
                          />
                        )}
                      </div>
                      <div className="md:w-[calc(100%-160px)] md:mt-[23px] mt-[21px] ">
                        <div className="pb-[17px] border-b border-tbs-dark-green/20">
                          <div className="font-inter-600 text-[20px] leading-[22px]  ">
                            {artTitle}
                          </div>
                          <div className="font-inter text-[20px] leading-[22px]  mt-[5px]  ">
                            {artSummary}
                          </div>
                        </div>
                        {artContent && (
                          <div className="font-inter text-[18px] leading-[24px] mt-[24px] typo-stylce">
                            {PARSE(artContent)}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-[50px]">
                <div className="text-[23px] font-inter-600 leading-[130%]">
                  {texts[1]?.content}
                </div>
                {listCategoryArticle && listCategoryArticle.length > 0 ? (
                  <div className="section-width-new flex flex-col space-y-[24px]">
                    {listCategoryArticle?.map((list, idxCat) => {
                      const { name, articles } = list;

                      const { assets: assetCats } = collecCat[idxCat];

                      return (
                        <div className="">
                          <div className="mb-[16px] flex items-end space-x-[24px]">
                            <img
                              src={assetCats[0]?.asset_file}
                              alt={name}
                              className=""
                            />
                            <div className="text-[20px] font-inter font-[500]">
                              {name}
                            </div>
                          </div>
                          <div
                            className={clsxm(
                              "md:gap-[24px] gap-[10px] grid-cols-4  grid justify-center items-center flex-1",
                              articles.length > 4 ? " " : ""
                            )}
                          >
                            {" "}
                            {/* <div class="grid grid-cols-subgrid gap-4 col-span-1">
                            <div class="col-start-1">06</div>
                          </div> */}
                            {articles.map((art, idx) => {
                              const artTitle = art?.article_languages?.title;
                              const artSummary =
                                art?.article_languages?.summary;
                              const artContent =
                                art?.article_languages?.content;
                              const artPath = art?.article_languages?.path;
                              const artAsset =
                                art?.article_assets[0]?.asset?.asset_file;

                              return (
                                <div
                                  className={clsxm(
                                    "",
                                    articles.length > 4 &&
                                      articles.length <= 5 &&
                                      idx > 2 &&
                                      idx < 4
                                      ? "md:col-start-1 "
                                      : " ",
                                    idx + 1 === articles.length
                                      ? "md:col-span-1 col-span-2 col-start-2"
                                      : "md:col-span-1 col-span-2"
                                  )}
                                  // className="articles.length unded-[10px] h-[160px] bg-tbs-dark-green border flex flex-col items-center justify-center relative"
                                >
                                  <Link
                                    to={artPath || "/"}
                                    className="rounded-[10px] h-[160px] bg-tbs-dark-green border flex flex-col items-center justify-center relative"
                                  >
                                    <div className="absolute top-[10px] right-[10px] text-tbs-dark-green bg-white rounded-full text-[10px] font-inter-bold  text-center flex-items-center justify-center leading-[130%] w-[22px] h-[22px]">
                                      <div className="flex h-full w-full items-center justify-center">
                                        {indexTopicCard++}
                                        {/* {art?.index} */}
                                      </div>
                                    </div>
                                    {artAsset && (
                                      <img
                                        src={artAsset}
                                        alt={artTitle}
                                        className="w-auto h-auto mx-auto"
                                      />
                                    )}
                                    {artContent && (
                                      <div className="text-white prose-p:md:text-[15px] prose-p:text-[14px] prose-p:font-inter-600 p-[5px] md:leading-[18.5px] leading-[17px] text-center">
                                        {PARSE(artContent)}
                                      </div>
                                    )}
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="section-width-new space-y-[45px] mt-[45px]">
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton width="60%" />
                    </Box>
                    <div className="md:gap-[24px] gap-[10px] grid-cols-4  grid justify-center items-center flex-1">
                      {Array.from({ length: 12 }, (_, index) => (
                        <Box>
                          <Skeleton
                            key={index}
                            variant="rect"
                            width="100%"
                            height={160}
                          />
                          {/* <Box sx={{ pt: 0.5 }}>
                            <Skeleton width="60%" />
                          </Box> */}
                        </Box>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="max-w-2xl">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}
    </BaseContainerPl>
  );
}
