import React from 'react';
import { motion } from 'framer-motion';

export default function MotionDiv({ styles = { width: 360 }, children }) {
  const { width } = styles;
  return (
    <motion.div
      initial={{ width: 0, right: -360, opacity: 0 }}
      animate={{ width, right: 0, opacity: 1 }}
      exit={{
        right: -360,
        opacity: 0,
        width: 0,
        transition: { delay: 0.3, duration: 0.3 },
      }}
    >
      {children}
    </motion.div>
  );
}
