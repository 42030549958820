/* eslint-disable */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import PARSE from 'html-react-parser';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import Api from './Api';
import swal from 'sweetalert';
import GlobalApi from '../../context/api/GlobalApi';
import { useForm, Controller, useFieldArray, useFormContext } from 'react-hook-form';
import CustomSelect from './CustomSelect';
import { DevTool } from '@hookform/devtools';
import { Bounce } from 'react-reveal';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import logo from './assets/logo-color.svg';
import { CircularProgress } from '@mui/material';
import { CheckCircleOutline, AddBoxIcon, IndeterminateCheckBoxIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    maxWidth: '701px',
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TBSWhistleblower() {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({});

  const { fields, append, prepend, remove } = useFieldArray({
    name: "reported_names",
    control,
    rules: {
      required: "Please append at least 1 item"
    }
  });

  const [sections, setSections] = useState([]);
  const [empReportedData, setEmpReportedData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [allegationData, setAllegationData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedUnitName, setSelectedUnitName] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [whistleblowerStatus, setWhistleblowerStatus] = useState();
  const [selectedFile, setSelectedFile] = useState([]);
  const [suspectName, setSuspectName] = useState([]);
  const [indexes, setIndexes] = useState([]);
  const [indexesInput, setIndexesInput] = useState([]);
  const [counter, setCounter] = useState(0);
  const [counterIdInput, setCounterIdInput] = useState(0);
  const [whistleblowerBusinessUnit, setWhistleblowerBusinessUnit] = useState(null)
  const [whistleblowerName, setWhistleblowerName] = useState(null)
  const [whistleblowerOrganization, setWhistleblowerOrganization] = useState(null)
  const [reportSuccess, setReportSuccess] = useState(false)
  const [otherDepartmentDetail, setOtherDepartmentDetail] = useState("")
  const [reportSent, setReportSent] = useState({ modal: false, text: 'The report that you have sent has been submitted', success: false });

  const navigate = useNavigate()
  const policyApproval = window.sessionStorage.getItem('policyApproval')

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = useCallback(() => {
    const api = new Api();
    api
      .getSectionDetail({ language: activeLang })
      .then((res) => {
        if (res.status === 200 && res.data.results) {
          const sectionsData = res.data.results;
          setSections(sectionsData);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [activeLang]);

  let title;
  let summary;
  let content;
  let icon;
  let sectionName;
  let chooseText;
  let employeeText1;
  let nonEmployeeText1;
  let nonEmployeeText2;
  const formTitle = [];
  const formText = [];
  let link;
  const whistleblower_status = []
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        let { widget_contents } = section;
        switch (i) {
          case 0:
            // const text = section.widget_contents[0];
            title = widget_contents[0]?.article_language?.title;
            summary = widget_contents[0]?.article_language?.summary;
            content = widget_contents[0]?.article_language?.content;
            icon = widget_contents[0]?.assets[0]?.asset_file;
            whistleblower_status.push(widget_contents[1]?.article_language?.title, widget_contents[1]?.article_language?.summary)
            break;
          case 1:
            chooseText = widget_contents[0]?.content;
            break;
          case 2:
            employeeText1 = widget_contents[0]?.article_language?.title;
            nonEmployeeText1 = widget_contents[1]?.article_language?.title;
            nonEmployeeText2 = widget_contents[1]?.article_language?.summary;
            break;
          case 3:
            widget_contents?.forEach((val) => {
              formTitle.push(val?.content)
            })
            break;
          case 4:
            widget_contents?.forEach((val) => {
              formText.push(val?.content)
            })
            break;
            case 5:
              link = widget_contents[0]?.article_language?.path;
              break;
          default:
            break;
        }
      });
    }
  }

  useEffect(() => {
    if(link != undefined || link != null) {
      if(policyApproval === false || policyApproval === null) {
        navigate(link)
      } else {
  
      }
    }
  }, [policyApproval, link])

  useEffect(() => {
    setWhistleblowerBusinessUnit(null)
    setWhistleblowerName(null)
    setWhistleblowerOrganization(null)
  }, [whistleblowerStatus])

  const handleChangeUnit = (name ,id) => {
    if (selectedUnit === id) {
      setSelectedUnit('');
      setDepartmentData([]);
      setSelectedUnitName('')
    } else {
      setSelectedUnit(id);
      setSelectedUnitName(name)
    }
  };

  const handleChangeDepartment = (id) => {
    if (selectedDepartment === id) {
      setSelectedDepartment('');
    } else {
      setSelectedDepartment(id);
    }
  };

  const handleChangeWhistleblowerUnit = (id) => {
    if (whistleblowerBusinessUnit === id) {
      setWhistleblowerBusinessUnit(null);
    } else {
      setWhistleblowerBusinessUnit(id);
    }
  };

  const handleWhistleblowerStatus = (id) => {
    if (whistleblowerStatus === id) {
      setWhistleblowerStatus();
    } else {
      setWhistleblowerStatus(id);
    }
  };

  const handleChangeAllegation = (e) => {
    const { value } = e.target;

    setAllegationData(value);
  };

  const handleClose = () => {
    setReportSent(false);
  };

  const getUnit = useCallback(() => {
    const api = new GlobalApi();
    api
      .getUnit()
      .then((res) => {
        if (res.status === 200 && res.data) {
          setUnitData(res.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const getDepartment = useCallback((id) => {
    const api = new GlobalApi();
    api
      .getDepartment(id)
      .then((res) => {
        if (res.status === 200 && res.data.results) {
          setDepartmentData(res.data.results);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [selectedUnit]);

  const getEmployee = useCallback(() => {
    const api = new GlobalApi();
    if (selectedDepartment > 0) {
      api
        .getEmployee({ unit: selectedUnit, department: selectedDepartment })
        .then((res) => {
          if (res.status === 200 && res.data) {
            // console.log({res});
            setEmployeeData(res.data);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [selectedDepartment, selectedUnit]);


  useEffect(() => {
    getSectionDetail();
    getEmployee();
    getUnit();
  }, [getDepartment, getEmployee, getSectionDetail, getUnit]);

  useEffect(() => {
    if(selectedUnit !== '') {
      getDepartment(selectedUnit);
    }
  }, [selectedUnit])

  const handleActionSubmit = async (data) => {
    const api = new GlobalApi();
    // e.preventDefault();
    
    let reportData = {
      ...data
    }
    
    reportData.client = 22
    reportData.whistleblower_name = whistleblowerName
    reportData.whistleblower_organization = whistleblowerOrganization

    if (selectedDepartment !== -1) {
      reportData.reported_department = selectedDepartment
    } else {
      reportData.reported_department_name = otherDepartmentDetail
      reportData.reported_business_unit_name = selectedUnitName
    }


    if (whistleblowerBusinessUnit !== null) {
      reportData.whistleblower_business_unit = whistleblowerBusinessUnit
    }

    if (selectedFile.length) {
      reportData.supporting_documents = selectedFile
    }
    
    if (whistleblowerStatus === undefined) {
      swal({
        icon: 'error',
        title: 'Oops...',
        text: 'Select One Whistleblower Status',
        button: true,
      });
    } else {
      try {
        if (reportData.allegation === '') {
          swal({
            icon: 'error',
            title: 'Oops...',
            text: 'allegation cannot be empty',
            button: true,
          });
        } else if ((reportData.reported_names && !reportData.reported_names.length)) {
          swal({
            icon: 'error',
            title: 'Oops...',
            text: 'reported names cannot be empty',
            button: true,
          });
        } else if (reportData.reported_department === "") {
          swal({
            icon: 'error',
            title: 'Oops...',
            text: 'reported department cannot be empty',
            button: true,
          });
        }else {
          setReportSent({ modal: true, text: 'Sending Report', success: false });
          const sendEmail = await api.addReport(reportData);
          return (
            sendEmail, 
            // console.log({reportData}),
            setReportSent({ modal: true, text: 'The report that you have sent has been submitted.', success: true }), 
            setWhistleblowerStatus(),
            setWhistleblowerBusinessUnit(null),
            setSelectedUnit(''),
            setDepartmentData([]),
            setSelectedDepartment(''),
            setCounter(0),
            setCounterIdInput(0),
            setIndexes([]),
            setIndexesInput([]),
            setSelectedFile([]),
            reset()
            );
        }
      } catch (error) {
        setReportSent({ modal: false, text: '', success: false }), 
        swal({
          icon: 'error',
          title: 'Oops...',
          text: 'error when sending...',
          button: true,
        });
        console.log(error)
      }
    }
  };

  const addReportedNames = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeReportedNames = index => () => {
    setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
    setCounter(prevCounter => prevCounter - 1);
    remove(index)
  };

  const addFileInput = () => {
    setIndexesInput(prevIndexes => [...prevIndexes, counterIdInput]);
    setCounterIdInput(prevCounter => prevCounter + 1);
  };

  const removeFileInput = index => () => {
    setIndexesInput(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
    setCounterIdInput(prevCounter => prevCounter - 1);
    setSelectedFile(selectedFile.slice(0, index))
  };

  const handleFileInput = (e) => {
    const files = e.target.files
		setSelectedFile((data) => [...data, ...files]);
	};

  const emplList =
    employeeData?.map((item) => {
      return ({
        value: item?.id,
        label: `${item?.first_name}  ${item?.last_name}`,
  })}) || [];
  
  return (
    <div id={`${sectionName}`} className={`section-container-pl  ${sections.length > 0 && sections[0].index === 3 ? 'mt-index-section__3' : 'mt-index-section'}`}>
      {sections.length > 0 ? (
        <div className="section-width">
          <div className="heading-title "> {title || 'Whistleblower Hotline'}</div>
          <div className="heading-summary max-w-2xl "> {summary || 'Form Pelaporan Whistleblowing System PT TBS Energi Utama & Group'}</div>
          <div className="mt-10 border rounded-lg ">
            <div className="font-inter-bold w-full border-b p-4">{formTitle[0]}</div>
            <div className="p-4 space-y-[45px]">
              <div className="space-y-2">
                <div className="space-y-2">
                  <div className="flex items-center space-x-4">
                    <div
                      onClick={() => handleWhistleblowerStatus(1)}
                      className={['w-5 h-5 border-2 p-[2.5px] rounded-full', whistleblowerStatus === 1 ? 'border-[#004A98]' : 'border-gray-500'].join(' ')}
                    >
                      <div className={['w-full h-full rounded-full', whistleblowerStatus === 1 ? 'bg-[#004A98]' : 'bg-white'].join(' ')} />
                    </div>
                    <div className="w-full border p-2 rounded-lg">{whistleblower_status[0]}</div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div
                      onClick={() => handleWhistleblowerStatus(2)}
                      className={['w-5 h-5 border-2 p-[2.5px] rounded-full', whistleblowerStatus === 2 ? 'border-[#004A98]' : 'border-gray-500'].join(' ')}
                    >
                      <div className={['w-full h-full rounded-full', whistleblowerStatus === 2 ? 'bg-[#004A98]' : 'bg-white'].join(' ')} />
                    </div>
                    <div className="w-full border p-2 rounded-lg">{whistleblower_status[1]}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            whistleblowerStatus === 1 ?
            (
              <div className="mt-10 border rounded-lg ">
              <div className="font-inter-bold w-full border-b p-4">{formTitle[1]}</div>
              <div className="p-4 space-y-[45px]">
                <div className="space-y-2">
                <div className="font-inter-bold">{chooseText}</div>
                  {unitData?.map((empl) => {
                    const { id, name } = empl;
                    return (
                      <div key={id} className="">
                        <div className="flex items-center space-x-4">
                          <div
                            onClick={() => handleChangeWhistleblowerUnit(id)}
                            className={['w-5 h-5 border-2 p-[2.5px] rounded-full', whistleblowerBusinessUnit === id ? 'border-[#004A98]' : 'border-gray-500'].join(' ')}
                          >
                            <div className={['w-full h-full rounded-full', whistleblowerBusinessUnit === id ? 'bg-[#004A98]' : 'bg-white'].join(' ')} />
                          </div>
                          <div className="w-full border p-2 rounded-lg">{name}</div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="space-y-4 pt-[25px]">
                    <div className="font-inter-bold">{employeeText1}</div>
                    <div className="space-y-2">
                      <input type="text" className="w-full border p-2 rounded-lg focus:outline-gray-500" onChange={(e) => setWhistleblowerName(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )
            : whistleblowerStatus === 2 ?
            (
            <div className="mt-10 border rounded-lg ">
              <div className="font-inter-bold w-full border-b p-4">{formTitle[2]}</div>
              <div className="p-4 space-y-[45px]">
                <div className="space-y-4">
                  <div className="font-inter-bold">{nonEmployeeText1}</div>
                  <div className="space-y-2">
                    <input type="text" className="w-full border p-2 rounded-lg focus:outline-gray-500" onChange={(e) => setWhistleblowerOrganization(e.target.value)} />
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="font-inter-bold">{nonEmployeeText2}</div>
                  <div className="space-y-2">
                    <input type="text" className="w-full border p-2 rounded-lg focus:outline-gray-500" onChange={(e) => setWhistleblowerName(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
            )
            :
            ''
          }

          <form onSubmit={handleSubmit(handleActionSubmit)} className="mt-4">
            <div className="mt-10 border rounded-lg ">
              <div className="font-inter-bold w-full border-b p-4">{formTitle[3]}</div>
              <div className="p-4 space-y-[45px]">
                <div className="space-y-4">
                  <div className="font-inter-bold">{formText[0]}</div>
                  <div className="font-inter-bold">{chooseText}</div>
                  <div className="space-y-2">
                    {unitData?.map((empl) => {
                      const { id, name } = empl;
                      return (
                        <div key={id} className="">
                          <div className="flex items-center space-x-4">
                            <div
                              onClick={() => handleChangeUnit(name, id)}
                              className={['w-5 h-5 border-2 p-[2.5px] rounded-full', selectedUnit === id ? 'border-[#004A98]' : 'border-gray-500'].join(' ')}
                            >
                              <div className={['w-full h-full rounded-full', selectedUnit === id ? 'bg-[#004A98]' : 'bg-white'].join(' ')} />
                            </div>
                            <div className="w-full border p-2 rounded-lg">{name}</div>
                          </div>
                        </div>
                      );
                    })}
                    {/* <div className="flex items-center space-x-4">
                      <div
                        onClick={() => setSelectedUnit('tidak')}
                        className={['w-5 h-5 border-2 p-[1px] rounded-full border-gray-500', selectedUnit === 'tidak' ? 'bg-[#004A98]' : 'bg-white'].join(' ')}
                      />
                      <div className="w-full border p-2 rounded-lg">Tidak tahu</div>
                    </div> */}
                  </div>
                </div>
                { departmentData && departmentData.length ? (
                  <div className="space-y-4">
                    <div className="font-inter-bold">{formText[1]}</div>
                    <div className="font-inter-bold">{chooseText}</div>
                    <div className="space-y-2">
                      {departmentData?.map((empl) => {
                        const { id, name } = empl;
                        return (
                          <div key={id} className="">
                            <div className="flex items-center space-x-4">
                              <div
                                onClick={() => handleChangeDepartment(id)}
                                className={['w-5 h-5 border-2 p-[2.5px] rounded-full', selectedDepartment === id ? 'border-[#004A98]' : 'border-gray-500'].join(' ')}
                              >
                                <div className={['w-full h-full rounded-full', selectedDepartment === id ? 'bg-[#004A98]' : 'bg-white'].join(' ')} />
                              </div>
                              {/* <input type="radio" name="department" value={selectedDepartment} onChange={handleChangeDepartment} className="w-5 h-5" /> */}
                              <div className="w-full border p-2 rounded-lg">{name}</div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="">
                        <div className="flex items-center space-x-4">
                          <div
                            onClick={() => handleChangeDepartment(-1)}
                            className={['w-5 h-5 border-2 p-[2.5px] rounded-full', selectedDepartment === -1 ? 'border-[#004A98]' : 'border-gray-500'].join(' ')}
                          >
                            <div className={['w-full h-full rounded-full', selectedDepartment === -1 ? 'bg-[#004A98]' : 'bg-white'].join(' ')} />
                          </div>
                          <div className="flex gap-2 w-full">
                            <div className="w-[25%] border p-2 rounded-lg">Others</div>
                            {/* <div className=" border p-2 rounded-lg">Others</div> */}
                            <input type="text" className="w-[75%] border p-2 rounded-lg focus:outline-gray-500" disabled={selectedDepartment !== -1} onChange={(e) => setOtherDepartmentDetail(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ):''}
                <div className="space-y-4">
                  <div className="font-inter-bold">{formText[2]}</div>
                  {
                    indexes && indexes.map((index) => {
                      const reportedName = `reported_names[${index}]`;

                      return (
                        <div key={index} className="space-y-2">
                        <input type="text" name={`${reportedName}`} {...register(`${reportedName}`)} className="w-full border p-2 rounded-lg focus:outline-gray-500" />
                      </div>
                      )
                    })
                  }
                  <div className='flex justify-end gap-x-3 min-h-[25px]'>
                    <div className='border rounded-lg bg-[#ff1414] cursor-pointer'>
                      <RemoveIcon className='text-white' onClick={removeReportedNames(indexes.length - 1)} />
                    </div>
                    <div className='border rounded-lg bg-[#2b6aad] cursor-pointer'>
                      <AddIcon className='text-white' onClick={addReportedNames} />
                    </div>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="font-inter-bold">{formText[3]}</div>
                  {
                    indexesInput && indexesInput.map((index) => {
                      return (
                        <input key={index} className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" name="file" onChange={handleFileInput} />
                      )
                    })
                  }
                  {/* <input class="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="formFileMultiple" multiple /> */}
                  <div className='flex justify-end gap-x-3 min-h-[25px]'>
                    <div className='border rounded-lg bg-[#ff1414] cursor-pointer'>
                      <RemoveIcon className='text-white' onClick={removeFileInput(indexesInput.length - 1)} />
                    </div>
                    <div className='border rounded-lg bg-[#2b6aad] cursor-pointer'>
                      <AddIcon className='text-white' onClick={addFileInput} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="relative mt-6">
                    {/* <div className="">{icon && <img src={icon} alt="" className="max-w-2xl mb-3 w-full" />}</div> */}
                    {content && (
                      <div className="mt-6">
                        <div className="max-w-2xl">{PARSE(content)}</div>
                      </div>
                    )}
                  </div>
                  <div className="mt-6">
                    <textarea
                      id="message"
                      // value={allegationData}
                      // onChange={handleChangeAllegation}
                      name="allegation"
                      {...register(`allegation`)}
                      rows="4"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder={formText[5]}
                    ></textarea>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="font-inter-bold">{formText[6]}</div>
                  <div className="space-y-2">
                    <input type="text" name="whistleblower_contact" {...register(`whistleblower_contact`)} className="w-full border p-2 rounded-lg focus:outline-gray-500" />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5">
              <button className="bg-blue text-white font-inter-bold p-2 rounded" type="submit">
                {formText[4]}
              </button>
            </div>
          </form>
          <DevTool control={control} />
        </div>
      ) : (
        <Box sx={{ pt: 0.5 }} className="max-w-2xl">
          <Skeleton width="60%" />
          <Skeleton />
          <Skeleton variant="rectangular" width="100%" height={118} />
        </Box>
      )}

      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={reportSent.modal} sx={{ zIndex: 15000, outline: 'none' }}>
        <BootstrapDialogTitle id="customized-dialog-title" className="w-[400px]">
          <div className="flex justify-between">
            <img src={logo} alt="" />
          </div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="flex flex-col justify-center items-center p-5 gap-y-7">
            <div className="">{reportSent.success ? <CheckCircleOutline className="text-green !w-20 !h-20" /> : <CircularProgress />}</div>
            <div className="font-inter">{reportSent.text}</div>
            {reportSent.success && (
              <div className="flex items-center ">
                <button className=" bg-blue text-white rounded font-inter-bold p-2" onClick={handleClose} type="button">
                  Close
                </button>
                {/* <CloseIcon className="cursor-pointer active:text-[#808080]" onClick={handleClose} /> */}
              </div>
            )}
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
