import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { isMobile, useMobileOrientation } from "react-device-detect";
import { useSelector } from "react-redux";

import MenuContext from "./MenuContext";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import Api from "./Api";

const getWidgetContent = (widget) => {
  if (!widget.length) {
    return false;
  }
  return widget.map((w) => w);
};

const getData = (item) => {
  const items = {
    logo: [],
    language: [],
    text: [],
  };
  if (item.widgets.length) {
    item.widgets.forEach((itm, i) => {
      if (i === 0) {
        items.logo = getWidgetContent(itm.widget_contents);
      }
      if (i === 1) {
        items.language = getWidgetContent(itm.widget_contents);
      }
      if (i === 2) {
        items.text = getWidgetContent(itm.widget_contents);
      }
      if (i === 3) {
        items.link = getWidgetContent(itm.widget_contents);
      }
    });
  }
  return items;
};

const api = new Api();
const getWindowSize = (orientation) => {
  const { innerWidth, innerHeight } = window;
  return {
    innerWidth,
    innerHeight,
    isDesktop:
      (!isMobile && innerWidth > 1024) ||
      (isMobile && orientation === "landscape" && innerWidth > 1024),
  };
};

export default function TBS_Menu() {
  const activeLanguage = useSelector((state) => state.content.activeLanguage);
  const { isLandscape } = useMobileOrientation();
  // const [isInitialize, setIsInitialize] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sections, setSections] = useState("");
  const [menu, setMenu] = useState("");
  const [data, setData] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const getMenuDetail = useCallback((params) => {
    return new Promise((resolve, reject) => {
      api
        .getMenuDetail(params)
        .then((res) => {
          if (res.status === 200 && res.data) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  const getSectionDetail = useCallback((index) => {
    return new Promise((resolve, reject) => {
      api
        .getSectionDetail(index)
        .then((res) => {
          if (res.status === 200 && res.data.results.length) {
            resolve(res.data.results[0]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  const getDefaultData = useCallback(async () => {
    const [a, b] = await Promise.all([
      getMenuDetail({ language: activeLanguage }),
      getSectionDetail({ language: activeLanguage }),
    ]);
    if (a && b) {
      const newData = getData(b);
      setData(newData);
      const filteredMenu = a.items.filter(
        (itm) => itm.id !== 575 && itm.id !== 636
      );

      setMenu(filteredMenu);
      setSections(b);
      setIsLoaded(true);
    }
  }, [getMenuDetail, getSectionDetail, activeLanguage]);

  useLayoutEffect(() => {
    const ws = getWindowSize(isLandscape);
    setIsDesktop(ws.isDesktop);
    getDefaultData();
    return () => {};
  }, [getDefaultData, isLandscape]);

  // TODO handler realtime responsive
  //* Screen Resize event
  useEffect(() => {
    function handleWindowResize() {
      // setIsDesktop(getWindowSize().isDesktop);
      const ws = getWindowSize();
      const { innerWidth: iW } = ws;
      if ((!isMobile && iW > 1024) || (isMobile && isLandscape && iW > 1024)) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isLandscape]);

  let html = <div />;
  if (sections && menu) {
    html = isDesktop ? (
      <DesktopMenu isLoaded={isLoaded} data={sections} menu={menu} />
    ) : (
      <MobileMenu isLoaded={isLoaded} data={sections} menu={menu} />
    );
  }

  const context = useMemo(
    () => ({ isDesktop, isSearch, setIsSearch, sections, menu, data }),
    [isDesktop, isSearch, sections, menu, data]
  );

  return <MenuContext.Provider value={context}>{html}</MenuContext.Provider>;
}

