/* eslint-disable */

import React from "react";
import { Link } from "react-router-dom";

import styles from "../TBS_Menu.module.css";
import replaceAssetLink from "../../../context/utils/replaceAssetLink";

const renderChildItem = (itm) => {
  let type = "button";
  if (itm.url_extension) {
    type = "external";
  }
  if (itm.url && itm.url !== "/") {
    type = "internal";
  }
  return type;
};

export default function ItemMobile({ itm, closeMenu }) {
  let html;
  const selectedType = renderChildItem(itm);
  const style = [
    styles.menuTitleM,
    "w-full",
    itm.children?.length > 0 &&
      "border-r-2 border-black border-opacity-30 mr-2",
    itm.url && "text-[#8C8C8C]",
  ].join(" ");

  switch (selectedType) {
    case "button":
      html = <span className={style}>{itm.title}</span>;
      break;
    case "internal":
      const isScrollTo = itm.url.includes("#");
      let handleClick = () => {
        closeMenu();
      };
      if (isScrollTo) {
        const elDestination = itm.url?.substring(itm.url.indexOf("#") + 1);
        if (elDestination) {
          handleClick = () => {
            closeMenu();
            const elTarget = document.getElementById(elDestination);
            if (elTarget) {
              const getY =
                elTarget.getBoundingClientRect().top + window.pageYOffset - 80;
              setTimeout(() => {
                window.scrollTo({ top: getY, behavior: "smooth" });
              }, 300);
            }
          };
        }
      }
      html = (
        <Link
          className={style}
          to={itm.url}
          type="button"
          onClick={handleClick}
        >
          {itm.title}
        </Link>
      );
      break;
    case "external":
      html = (
        <a
          className={style}
          href={itm.url_extension}
          type="button"
          target="_blank"
          rel="noreferrer"
        >
          {itm.title}
        </a>
      );
      break;
    default:
      break;
  }

  if (itm.article.article_assets && itm.article.article_assets.length === 0) {
    return html;
  } else
    return itm.article.article_assets[0]?.asset?.asset_type === 1 ? (
      html
    ) : (
      <a
        className={style}
        href={replaceAssetLink(
          itm?.article?.article_assets[0]?.asset?.asset_file
        )}
        type="button"
        target="_blank"
        rel="noreferrer"
      >
        {itm.title}
      </a>
    );
}
