/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PARSE from 'html-react-parser';
import Api from './Api';
import { useSelector } from 'react-redux';
import BaseContainerPl from '../../context/components/Base/BaseContainerPl';
import replaceAssetLink from '../../context/utils/replaceAssetLink';

export default function TBS_GridAssets_Article() {
  const [sections, setSections] = useState([]);

  const activeLang = useSelector((state) => state.content.activeLanguage);

  const getSectionDetail = async () => {
    const api = new Api();
    const response = await api.getSectionDetail({ language: activeLang });
    // console.log(response);
    const parsedData = await response;
    const sectionsData = parsedData;
    // console.log(sectionsData.data.results);
    setSections(sectionsData.data.results);
    return sections;
  };

  useEffect(() => {
    getSectionDetail();
  }, []);

  let title;
  let summary;
  let content;
  let firstImage;
  let listImage;
  let sectionName;
  if (sections) {
    sectionName = sections[0]?.slug_name;
    const sec = sections[0]?.widgets;
    if (sec && sec.length !== 0) {
      sec.forEach((section, i) => {
        switch (i) {
          case 0:
            const text = section.widget_contents[0];
            title = text?.article_language?.title;
            summary = text?.article_language?.summary;
            content = text?.article_language?.content;
            firstImage = text?.assets[0]?.asset_file;
            listImage = text?.assets;

            break;
          case 2:
            break;
          case 3:
            break;
          default:
            break;
        }
      });
    }
  }

  return (
    <BaseContainerPl sectionName={sectionName} sectionIndex={sections && sections.length > 0 && sections[0].index}>
      <div className="section-width">
        <h2 className="heading-title"> {title}</h2>
        <h3 className="heading-summary"> {summary}</h3>
        <div className="margin-content">
          <div className="flex gap-3 s:flex-row flex-col  w-full">
            {listImage &&
              listImage.map((img, idx) => {
                return idx < 2 ? (
                  <div className="w-full">
                    <img src={replaceAssetLink(img.file_medium)} alt={img?.description || title} className="h-[200px] w-full" />
                  </div>
                ) : (
                  <></>
                );
              })}
          </div>
          <div className="typo-style">{content && PARSE(content)}</div>
        </div>
      </div>
    </BaseContainerPl>
  );
}
