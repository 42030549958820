/* eslint-disable */
import React, { Component, createRef } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { isMobile  } from 'react-device-detect';
import TBS_HERO_581 from '../../sections/TBS_Hero_581/TBS_Hero';
import TBS_SIDENAVIGATION_607 from '../../sections/TBS_SideNavigation_607/TBS_SideNavigation';
import TBS_ACCORDION_REPORTS_722 from '../../sections/TBS_Accordion_Reports_722/TBS_Accordion_Reports';
import TBS_ACCORDION_REPORTS_1130 from '../../sections/TBS_Accordion_Reports_1130/TBS_Accordion_Reports';
import TBS_RELATED_PAGES_640 from '../../sections/TBS_Related_Pages_640/TBS_Related_Pages';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class project1 extends Component {
   constructor(props) {
       super(props);
       this.state = {
           currentPage: null,
           currentSection: null,
           override: true,
           section: [
               { id: 1, slug: localStorage.getItem('language') === "English"? 'hero' : 'hero'},
               { id: 2, slug: localStorage.getItem('language') === "English"? 'sidenav' : 'sidenav'},
               { id: 3, slug: localStorage.getItem('language') === "English"? 'annual' : 'tahunan'},
               { id: 4, slug: localStorage.getItem('language') === "English"? 'insidental' : 'insidental'},
               { id: 5, slug: localStorage.getItem('language') === "English"? 'related-pages' : 'related-pages'},
           ],
       };
       this.scrollToSection = this.scrollToSection.bind(this);
       this.scrollAction = this.scrollAction.bind(this);
        this.isStateChanging = false
   }
   componentDidMount() {
       this.scrollToSection();
       ReactGA.initialize('G-7WJJLX00XL')
       ReactGA.pageview(window.location.pathname + window.location.search);
   }
   componentWillUnmount(){
   }
   section1 = createRef()
   section2 = createRef()
   section3 = createRef()
   section4 = createRef()
   section5 = createRef()
   scrollToSection() {
       let currentLocation = window.location.href
       const hasSectionAnchor = currentLocation.includes('/#')
       if (hasSectionAnchor) {
           let anchorSectionId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
           setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
       }
   }
   scrollAction(anchorSectionId) {
       const uri_dec = decodeURIComponent(anchorSectionId)
       const key = this.state.section.filter(x => x.slug == uri_dec).map(obj => `section${obj.id}`)
       const getY = (element) => element.getBoundingClientRect().top + window.pageYOffset - 140;
       if (key) {
          if (key[0] === 'section1') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section1.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section2') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section2.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section3') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section3.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section4') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section4.current), behavior: 'smooth' })}, 1000)
          } else if (key[0] === 'section5') {
               setTimeout(() => {window.scrollTo({ top: getY(this.section5.current), behavior: 'smooth' })}, 1000)
            }
       }
   }
   render() {
       const getHelmetTitle = () => {
           var title;
           switch (localStorage.getItem('language')) {
               case 'English':
                   title = <title> Public Expose | TBS Energi Utama</title>;
                   break;
               case 'Indonesian':
                   title = <title> Paparan Publik | TBS Energi Utama</title>;
                   break;
               case 'Vietnamese':
                   title = <title> Public Expose | TBS Energi Utama</title>;
                   break;
               default:
                   title = <title> Public Expose | TBS Energi Utama</title>;
                   break;
           }
           return title;
       };
       const getHelmetDescription = () => {
           var description;
           switch (localStorage.getItem('language')) {
               case 'English':
                   description = <meta name='description' content='TBS discloses performance and business info through Public Exposures, promoting transparency, engaging stakeholders, and informing the public and investors.' />;
                   break;
               case 'Indonesian':
                   description = <meta name='description' content='TBS rutin ungkapkan kinerja dan informasi bisnis melalui Public Expose untuk transparansi, melibatkan pemangku kepentingan, dan informasikan publik dan investor' />;
                   break;
               case 'Vietnamese':
                   description = <meta name='description' content='None' />;
                   break;
               default:
                   description = <meta name='description' content='TBS discloses performance and business info through Public Exposures, promoting transparency, engaging stakeholders, and informing the public and investors.' />;
                   break;
           }
           return description;
       };
       const getHelmetKeywords = () => {
           var keywords;
           switch (localStorage.getItem('language')) {
               case 'English':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Indonesian':
                   keywords = <meta name='keywords' content='' />;
                   break;
               case 'Vietnamese':
                   keywords = <meta name='keywords' content='' />;
                   break;
               default:
                   keywords = <meta name='keywords' content='' />;
                   break;
           }
           return keywords;
       };
         let helmet = getHelmetTitle();
         let description = getHelmetDescription();
         let keywords = getHelmetKeywords();
       return (
           <>
               <Helmet>
                   {helmet}
                   {description}
                   {keywords}
               </Helmet>
            <div ref={this.section1}>
                <TBS_HERO_581 />
            </div>
            <div ref={this.section2}>
                <TBS_SIDENAVIGATION_607 />
            </div>
            <div ref={this.section3}>
                <TBS_ACCORDION_REPORTS_722 />
            </div>
            <div ref={this.section4}>
                <TBS_ACCORDION_REPORTS_1130 />
            </div>
            <div ref={this.section5}>
                <TBS_RELATED_PAGES_640 />
            </div>
           </>
       );
   }
}
export default withParams(project1);